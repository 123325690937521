// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import {Link} from "react-router-dom";

// Components
import ContentHeader from "../Template/components/ContentHeader";

export default function NotFound () {
    return (
        <div className="content-wrapper position-relative">
            <ContentHeader title={"404 Error Page"} breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "404 Error Page",
                    active: true
                }
            ]}/>

            <section className="content d-flex align-items-center" style={{position: "absolute", top: 0, bottom: 0, width: "100%"}}>
                <div className="error-page">
                    <h2 className="headline text-warning">404</h2>

                    <div className="error-content">
                        <h3><i className="fas fa-exclamation-triangle text-warning mr-3"/> Oops! Page not found.</h3>

                        <p>
                            We could not find the page you were looking for.
                            Meanwhile, you may <Link to="/dashboard"><b><u>return to dashboard</u></b></Link> or try using the
                            search form.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}