// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {RoomsStore} from "../../../stores/RoomsStore";

// MUI
import Image from 'material-ui-image';

// Component
import CustomTable from "../../../components/table/CustomTable";
import {StyledButton} from "../../../components/styled/StyledButton";

export const RoomBoothsList = props => {
    const navigate = useNavigate();

    const {onError} = props;
    const {state, dispatch} = useContext(RoomsStore);

    // Single row actions
    const onOpenClick = ({id, roomId}) => navigate(`/room/${roomId}/prop/${id}`);
    const openCMSEditorNew = rowData => {
        const {id, roomId} = rowData;
        navigate(`/cms/room/${roomId}/prop/${id}`);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'thumbnail',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
            cell: rowData => {
                return (
                    rowData?.assetId && rowData?.assetId?.length > 0 ?
                        <Image src={Utils.buildURLs(`/asset/thumbnail/${rowData?.assetId}`, false, null, {networkId: Utils.getNetwork()})}
                            imageStyle={{
                                position: "initial",
                                width: "5rem", height: "5rem",
                                objectFit: "cover"
                            }}
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                background: "transparent",
                                padding: 0
                            }}/> : null
                );
            },
            width: Utils.RemToPx(9)
        },
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.id}</span>
                </div>
            )
        },
        {
            name: 'prop_name',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Booth name</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.title}
                </span>
            )
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-wrap align-items-center">
                    <StyledButton onClick={() => openCMSEditorNew(rowData)} title={<i className="fas fa-images"/>} type="default"
                                  className="btn btn-sm btn-warning h-100 mr-2" tooltip="Open prop content"/>
                    <StyledButton title={<i className="fas fa-pencil-alt"/>} type="success"
                                  onClick={() => onOpenClick(rowData)} tooltip="Open prop page"/>
                </div>
            )
        }
    ]);
    const getList = useCallback(() => state?.boothsList, [state?.boothsList]);
    const getListItems = useCallback(async ({offset, size, text}, onError) => {
        const {props} = (await API.Rooms.getRoomBoothList(state?.model?.id, onError)).data;

        dispatch({type: "SET_BOOTHS_LIST", payload: props});

        return props?.length ?? 0;
    }, [state?.model?.id]);
    const clearLists = () => dispatch({type: "CLEAR_LIST"});

    // Effects
    useEffect(() => {
        return () => {
            dispatch({
                type: "SET_BOOTHS_LIST",
                payload: []
            });
        };
    }, []);

    return (
        <div id="table-container">
            <CustomTable state={{canSelect: false, canHover: false, canSearch: false, pagination: false}}
                         callbacks={{getList, getColumns, getListItems, clearLists, onError}}
                         texts={{noItems: "This room has no booths..."}}
            />
        </div>
    );
}
