// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useContext, useState } from "react";
import { API } from "../../../api/API";
import { RoomsStore } from "../../../stores/RoomsStore";

// Components
import { StyledButton } from "../../../components/styled/StyledButton";

export const RoomSubscriptionStatus = ({onError}) => {
    const {state, dispatch} = useContext(RoomsStore);

    // Base state
    const [value, setValue] = useState(state?.model?.subscriptionStatus || "free");
    const [isExpireSubscriptionDisabled, setExpireSubscriptionDisabled] = useState(value !== "active");
    const [isCreateNewSubscriptionDisabled, setCreateNewSubscriptionDisabled] = useState(value !== "expired");

    const updateState = (subscriptionStatus) => {
        dispatch({type: "SET_ROOM_SUBSCRIPTION_STATUS", payload: subscriptionStatus});
        setValue(subscriptionStatus);
        setExpireSubscriptionDisabled(subscriptionStatus !== "active")
        setCreateNewSubscriptionDisabled(subscriptionStatus !== "expired")
    }

    // Main functions
    const expireSubscription = async() => {
        setExpireSubscriptionDisabled(true);
        const result = await API.Rooms.expireRoomSubscription(state?.model?.id, onError);
        setExpireSubscriptionDisabled(false);

        if(result?.error?.code !== 200) onError(new Error(result?.error?.message));
        else updateState("expired");
    };

    const createNewSubscription = async() => {
        setCreateNewSubscriptionDisabled(true);
        const result = await API.Rooms.createNewRoomSubscription(state?.model?.id, onError);
        setCreateNewSubscriptionDisabled(false);

        if(result?.error?.code !== 200) onError(new Error(result?.error?.message));
        else updateState("active");
    };

    return (
        <>
            <h4 className="my-4">Room subscription status</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0">
                        <div className="d-flex align-items-center" id="room-show-status">
                            <h6 className="mr-3 mb-1">Status: {value}</h6>
                            <div className="mr-3">
                                <StyledButton onClick={createNewSubscription}
                                              disabled={isCreateNewSubscriptionDisabled}
                                              title="Create New" type="success" noLoading={true}/>
                            </div>
                            <div className="mr-3">
                                <StyledButton onClick={expireSubscription}
                                              disabled={isExpireSubscriptionDisabled}
                                              title="Expire" type="warning" noLoading={true}/>
                            </div>
                        </div>
                    </div>
                    <p className="mt-3 text-muted">
                        If room subscription status is expired, client would not be able to visit room.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
}
