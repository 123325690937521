import React from 'react';
import {Line} from '@reactchartjs/react-chart.js';
import Utils from "../../../scripts/Utils";

const buildInfo = (labels, data) => ({
    labels,
    datasets: [
        {
            data,
            fill: false,
            backgroundColor: 'rgba(40,167,69,0.9)',
            borderColor: 'rgba(40,167,69,0.8)',
        },
    ],
});

const options = {

    legend: {
        display: false
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                },
            },
        ],
    },
};

export const SessionsChart = props => {
    const {data} = props;

    return (
        <div className="card card-success px-0 mt-3" style={{width: "48%"}}>
            <div className="card-header">
                <h2 className="card-title mr-2">Sessions</h2> <small>for the last week</small>

                <div className="card-tools"/>
            </div>
            <div className="card-body">
                <div className="chart">
                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                            <div className=""/>
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                            <div className=""/>
                        </div>
                    </div>
                    <Line data={buildInfo(Utils.getLast7Days(), data)} options={options}/>
                </div>
            </div>
        </div>
    )
};
