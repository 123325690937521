// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import Utils from "../../scripts/Utils";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {UserCard} from "./components/UserCard";
import {UserPanel} from "./components/UserPanel";
import Loading from "../../components/Loading";

export default function User() {
    const {id} = useParams();
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(state.myRoles);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    useEffect(() => {
        (async () => {
            const rights = Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]);

            const result = (await (rights ? API.Users.getModel(id, onError) : API.Users.getSimpleModel(id, onError)));

            if(result?.error?.code === 200 && result?.data?.user && !Utils.isObjectEmpty(result?.data?.user))
                dispatch({
                    type:    "SET_USER_MODEL",
                    key:     "openedUserModel",
                    payload: result?.data?.user
                });
            else if(result?.error?.code === 1022) navigate('/accessrestricted');
            else navigate('/notFound');
        })();

        (async() => {
            if(Utils.hasPermission(state.myRoles, "balance.manage")) {
                const result = await API.Users.getUserBalance(id, onError);
                if(result?.error?.code === 200) dispatch({type: "SET_USER_BALANCE", payload: result?.data?.balance});
            }
        })();

        return () => {
            dispatch({
                type:    "SET_USER_MODEL",
                key:     "openedUserModel",
                payload: {}
            });
        };
    }, []);

    return (
        state?.openedUserModel && !Utils.isObjectEmpty(state?.openedUserModel) ?
            <div className="content-wrapper">
                <ContentHeader title={`${state.openedUserModel.firstName} ${state.openedUserModel.lastName}'s profile`}
                               breadcrumbs={[
                                   {
                                       title: "Home",
                                       link: "/home"
                                   },
                                   {
                                       title: "Users",
                                       link: `/users`
                                   },
                                   {
                                       title: `${state.openedUserModel.firstName} ${state.openedUserModel.lastName}'s profile`,
                                       link: `/users/${id}`,
                                       active: true
                                   }
                               ]}
                />

                <section className="content">
                    <div className="container-fluid">
                        <div className="row align-items-start">
                            <UserCard onError={onError} model={state.openedUserModel} showViewProfileBtn={false}/>
                            {(isSuperAdmin || isNetworkAdmin) && <UserPanel onError={onError} model={state.openedUserModel} modelKey="openedUserModel"/>}
                        </div>
                    </div>
                </section>
            </div>
            : <Loading/>
    );
}