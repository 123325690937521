// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

// Styles
import {makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',

        width: "100%",
        minHeight: "calc(100vh - calc(3.5rem + 1px) - calc(3.5rem + 1px))"
    },
    icon: {
        width: "2.5rem !important",
        height: "2.5rem !important"
    }
}));

const Loading = () => {
    const classes = useStyles();

    return (
        <div className="content-wrapper">
            <section className="content">
                <div className={`container-fluid ${classes.container}`}>
                    <div className="d-flex align-items-center">
                        <h1 className="mb-0 mr-4">Loading</h1>
                        <CircularProgress className={classes.icon}/>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Loading;
