// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from 'react';
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import {makeStyles} from "@material-ui/core";
import {Line} from '@reactchartjs/react-chart.js';

// Components
import {UserRooms} from "../../../components/list/Rooms/UserRooms";
import {AllRooms} from "../../../components/list/Rooms/AllRooms";
import {UserPropsWithFilters} from "../../../components/list/Props/UserPropsWithFilters";
import {AllProps} from "../../../components/list/Props/AllProps";

const buildInfo = (labels, data) => ({
    labels,
    datasets: [
        {
            data,
            fill: false,
            backgroundColor: 'rgba(40,167,69,0.9)',
            borderColor: 'rgba(40,167,69,0.8)',
        },
    ],
});
const options = {
    legend: {
        display: false
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                },
            },
        ],
    },
};

const useStyles = makeStyles((theme) => ({
    propChartHeader: {
        display: "flex",
        justifyContent: "space-between",

        "&::after": {
            content: "unset"
        }
    }
}));

export const PropCharts = props => {
    const {onError} = props;

    const classes = useStyles();

    const {state} = useContext(UsersStore);

    const isSuperAdmin = () => Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = () => Utils.isNetworkAdmin(state.myRoles);
    const isRoomAdmin = () => Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = () => Utils.isPropAdmin(state.myRoles);

    const [room, setRoom] = useState(null);
    const [prop, setProp] = useState(null);
    const [propRoomId, setPropRoomId] = useState(null);

    const [chartData, setChartData] = useState([]);

    const onRoomSelected = selectedRoom => {
        setRoom(selectedRoom);
        setPropRoomId(selectedRoom?.id);
    };
    const onPropSelected = selectedProp => {
        setProp(selectedProp);
        if (selectedProp?.roomId) setPropRoomId(selectedProp?.roomId);
    };

    useEffect(() => {
        (async () => {
            if (prop?.id?.length > 0 && (room?.id?.length > 0 || propRoomId?.length > 0)) {
                const {data, error} = await API.Analytics.getPropsVisits({
                    localDate: Utils.getTime(),
                    roomId: room?.id || propRoomId,
                    propId: prop?.id
                }, Utils.getNetwork(), onError);
                if (error.code === 200) setChartData(data.propVisitsForLastWeek);
                else onError(new Error(error.message));
            }
        })();
    }, [room?.id, prop?.id]);

    return (
        <>
            {(isSuperAdmin() || isNetworkAdmin() || isRoomAdmin()) &&
            (<div className="row mb-2">
                <div className="col-7">
                    <div className="d-flex align-items-center w-100">
                        <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select room: </h6>
                        {isRoomAdmin() || isPropAdmin()
                            ? <UserRooms room={room} onChange={onRoomSelected} networkId={Utils.getNetwork()}/>
                            : <AllRooms room={room} onChange={onRoomSelected} networkId={Utils.getNetwork()}/>}
                    </div>
                </div>
            </div>)}

            <div className={`row ${chartData?.length > 0 ? "mb-5" : ""}`}>
                <div className="col-7">
                    <div className="d-flex align-items-center w-100">
                        <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select prop: </h6>
                        {isSuperAdmin() || isNetworkAdmin() || isRoomAdmin()
                            ? <AllProps prop={prop} onChange={onPropSelected} networkId={Utils.getNetwork()} roomId={room?.id}/>
                            : <UserPropsWithFilters prop={prop} onChange={onPropSelected}/>}
                    </div>
                </div>
            </div>

            <div className="row justify-content-between mx-0">
                {prop && prop?.id?.length > 0 && propRoomId?.length > 0 &&
                Utils.hasPermission(state.myRoles, "prop.manage", [Utils.getNetwork(), propRoomId, prop?.id]) &&
                    chartData?.length > 0 &&
                <div className="card card-success px-0" style={{width: "50%"}}>
                    <div className={`card-header ${classes.propChartHeader} d-flex justify-content-between`}>
                        <div>
                            <h2 className="card-title mr-2">Prop visits</h2>
                            <small>for the last week</small>
                        </div>
                        <div className="d-inline-flex flex-column">
                            {isSuperAdmin() || isNetworkAdmin() || isRoomAdmin()
                                ? <span>Room: {room?.metadata?.name}</span>
                                : <span>Room ID: {propRoomId}</span>}
                            <span>Prop: {`${prop?.title === "" ? `[${prop?.assetTitle ?? ""}]` : prop?.title} (#${prop?.id})`}</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="chart">
                            <div className="chartjs-size-monitor">
                                <div className="chartjs-size-monitor-expand">
                                    <div className=""/>
                                </div>
                                <div className="chartjs-size-monitor-shrink">
                                    <div className=""/>
                                </div>
                            </div>
                            <Line data={buildInfo(Utils.getLast7Days(), chartData)}
                                  options={options}/>
                        </div>
                    </div>
                </div>}
            </div>
        </>
    )
};
