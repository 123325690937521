// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class RolesManagerAPI {
    static async list (onError) {
        return await customFetch({path: "/jsonRpc/rbac.listRoles", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async create (body, onError) {
        return await customFetch({path: "/jsonRpc/rbac.createRole", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async setPermissions (roleId, permissions, onError) {
        return await customFetch({path: "/jsonRpc/rbac.setRolePermissions", body: {roleId, permissions}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async delete (roleId, onError) {
        return await customFetch({path: "/jsonRpc/rbac.deleteRole", body: {roleId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }


    static async listAssignedUserRoles (userId, onError) {
        return await customFetch({path: "/jsonRpc/rbac.listAssignedUserRoles", body: {userId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async addUserRole (body, onError) {
        return await customFetch({path: "/jsonRpc/rbac.addUserRole", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async deleteUserRole (body, onError) {
        return await customFetch({path: "/jsonRpc/rbac.deleteUserRole", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async listPermissions (onError) {
        return await customFetch({path: "/jsonRpc/rbac.listPermissions", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}