// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";
import {UsersStore} from "../../../stores/UsersStore";
import {customStyles} from "../../../styles/dropdown";

// Components
import {StyledImage} from "../../../components/styled/StyledImage";
import {StyledCheckbox, StyledInput} from "../../../components/styled/StyledInput";
import {StyledButton} from "../../../components/styled/StyledButton";
import {RoomDelete} from "../components/RoomDelete";

export const RoomInfo = ({roomId, onError}) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(RoomsStore);
    const {state: userState} = useContext(UsersStore);

    // Base state
    const [isLoading, setIsLoading] = useState(false);

    // Fields state
    const [name, setName] = useState(state.model?.metadata?.name);
    const [description, setDescription] = useState(state.model?.metadata?.description);
    const [location, setLocation] = useState(state.model?.metadata?.location);
    const [privacy, setPrivacy] = useState(state.model?.metadata?.privacy);
    const [tags, setTags] = useState(state.model?.metadata?.tags?.join(", "));
    const [firstStyle, setFirstStyle] = useState(state.model?.metadata?.firstStyle);
    const [moderateComments, setModerateComments] = useState(state.model?.metadata?.moderateComments || false);
    const [highResolution, setHighResolution] = useState(state.model?.metadata?.highResolution || false);

    // Main functions
    const updateMetadata = async () => {
        setIsLoading(true);

        await API.Rooms.updateRoomMetadata({
            roomId,
            metadata: {
                name,
                description,
                location,
                privacy,
                tags: tags.split(",").map(el => el.trim()),
                firstStyle,
                moderateComments,
                highResolution
            }
        }, onError);

        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Success!",
                text: "Room info was successfully updated!",
                color: "success"
            }
        });

        setIsLoading(false);
    };

    // Effects
    useEffect(() => {
        return () => {
            setIsLoading(false);
            setName("");
            setDescription("");
            setLocation("");
            setPrivacy("");
            setTags("");
            setFirstStyle("");
            setModerateComments(false);
            setHighResolution(false);
        };
    }, []);

    return (
        <div className="d-flex flex-column align-items-start">
            <div className="form-group w-100 mb-1 d-flex">
                <div className="ml-3">
                    <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${state.model?.thumbnail?.id}`, false, null, {networkId: Utils.getNetwork()})}
                                 size={10} />
                </div>
                <div className="w-100 d-flex flex-column pl-5">
                    <div className="form-group w-100 d-flex mb-0">
                        <div className="col-1"><label className="col-form-label">ID:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">{state.model?.id}</span>
                        </div>
                    </div>
                    <div className="form-group w-100 d-flex mb-0 mt-1">
                        <div className="col-1"><label className="col-form-label">Owners:</label></div>
                        <div className="col-11 d-flex align-items-center">
                            <span className="pl-2">
                                {(state.model?.owners.map(item => `${item.firstName} ${item.lastName}`)).join(", ")}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <hr className="w-100"/>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Name:</label></div>
                <div className="w-50 d-flex align-items-center">
                    <StyledInput fullWidth noMargin placeholder="Name" defaultValue={name}
                                     onChange={e => setName(e.target.value)}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Description:</label></div>
                <div className="w-50 d-flex align-items-center">
                    <StyledInput fullWidth noMargin placeholder="Description" isTextarea
                                 defaultValue={description} onChange={e => setDescription(e.target.value)}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Location:</label></div>
                <div className="w-50 d-flex align-items-center">
                    <StyledInput fullWidth noMargin placeholder="Location" defaultValue={location}
                                     onChange={e => setLocation(e.target.value)}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Privacy:</label></div>
                <div className="w-50 d-flex align-items-center px-2">
                    <Select onChange={selected => setPrivacy(selected?.value)} defaultValue={{label: Utils.capitalizeFirstLetter(privacy), value: privacy}}
                            styles={customStyles(true)}
                            options={[
                                {label: "Public", value: "public"},
                                {label: "Private", value: "private"}
                            ]}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-2">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Tags:</label></div>
                <div className="w-50 d-flex align-items-center">
                    <StyledInput fullWidth noMargin placeholder="Tags" defaultValue={tags}
                                     onChange={e => setTags(e.target.value)}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">Moderate comments:</label></div>
                <div className="w-50 d-flex align-items-center ml-2">
                    <StyledCheckbox checked={moderateComments} centerHorizontal={false}
                                    onChange={e => setModerateComments(e.target.checked)}/>
                </div>
            </div>
            <div className="form-group w-100 d-flex mb-0 mt-1">
                <div style={{minWidth: "12.5rem"}}><label className="col-form-label">High resolution:</label></div>
                <div className="w-50 d-flex align-items-center ml-2">
                    <StyledCheckbox checked={highResolution} centerHorizontal={false}
                                    onChange={e => setHighResolution(e.target.checked)}/>
                </div>
            </div>
            <div className="form-group d-flex justify-content-end mt-1 mb-0 pr-2" style={{width: `calc(50% + 12.5rem)`}}>
                <StyledButton title="Update room metadata" type="success" disabled={isLoading} onClick={updateMetadata}/>
            </div>
            <hr className="w-100"/>

            {Utils.hasPermission(userState.myRoles, "network.manage", [Utils.getNetwork()]) &&
            Utils.hasPermission(userState.myRoles, "room.manage", [Utils.getNetwork(), roomId]) &&
            <RoomDelete onError={onError}/>}
        </div>
    );
}

