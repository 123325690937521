export const customStyles = fullWidth => ({
    control: provided => ({
        ...provided,
        minHeight: "2.375rem"
    }),
    container: () => ({
        position: "relative",
        ...(fullWidth ? {width: "100%"} : {})
    }),
    input: () => ({minWidth: "12.5rem", width: "100%"}),
    valueContainer: provided => ({
        ...provided,
        padding: "0.125rem 0.5rem"
    }),
    indicatorsContainer: provided => ({
        ...provided,
        "&>div" : {
            padding: "0.5rem"
        }
    }),
    menu: provided => ({
        ...provided,
        zIndex: 15,
        marginBottom: "0.5rem",
        marginTop: "0.5rem"
    }),
    menuList: provided => ({
        ...provided,
        maxHeight: "18.75rem",
        paddingBottom: "0.25rem",
        paddingTop: "0.25rem"
    }),
    option: provided => ({
        ...provided,
        padding: "0.5rem 0.75rem"
    })
});
