// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {ThemedTabs, ThemedTab, TabPanel} from "../../components/styled/StyledTabs";
import {NetworkCharts} from "./tabs/NetworkCharts";
import {RoomCharts} from "./tabs/RoomCharts";
import {PropCharts} from "./tabs/PropCharts";

export default function Dashboard() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(state.myRoles);
    const isRoomAdmin = Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = Utils.isPropAdmin(state.myRoles);

    const getTabValue = () => {
        switch (true) {
            case isSuperAdmin:
            case isNetworkAdmin:
                return 0;
            case isRoomAdmin:
                return 1;
            case isPropAdmin:
                return 2;
            default:
                return 0;
        }
    };

    const [tabValue, setTabValue] = useState(getTabValue());
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    return (
        <div className="content-wrapper">
            <ContentHeader title="Dashboard" breadcrumbs={[
                {
                    title : "Home",
                    link : "/"
                },
                {
                    title : "Dashboard",
                    link : "/dashboard",
                    active : true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="card">
                        <div className="card-header p-2 d-flex custom justify-content-between">
                            <ThemedTabs value={tabValue} onChange={onTabChange}>
                                {(isSuperAdmin || isNetworkAdmin) &&
                                <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0} label="Network"/>}

                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin) &&
                                <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1} label="Room"/>}

                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin || isPropAdmin) &&
                                <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={2} label="Prop"/>}
                            </ThemedTabs>
                        </div>

                        <div className="card-body">
                            <div className="tab-content">
                                {(isSuperAdmin || isNetworkAdmin) &&
                                <TabPanel value={tabValue} index={0}><NetworkCharts onError={onError}/></TabPanel>}

                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin) &&
                                <TabPanel value={tabValue} index={1}><RoomCharts onError={onError}/></TabPanel>}

                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin || isPropAdmin) &&
                                <TabPanel value={tabValue} index={2}><PropCharts onError={onError}/></TabPanel>}

                                {(!isSuperAdmin && !isNetworkAdmin && !isRoomAdmin && !isPropAdmin) &&
                                <h4>You are guest, so you dont have permissions to show any charts.</h4>}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}