// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext} from "react";
import Utils from "../../../scripts/Utils";

import {ChatsStore} from "../../../stores/ChatsStore";
import {UsersStore} from "../../../stores/UsersStore";
import {RoomsStore} from "../../../stores/RoomsStore";
import {NetworksStore} from "../../../stores/NetworksStore";
import {RolesStore} from "../../../stores/RolesStore";
import {PropsStore} from "../../../stores/PropsStore";
import {SocialEventsStore} from "../../../stores/SocialEventsStore";

export default function Footer() {
    const {state, dispatch} = useContext(ChatsStore);
    const {state: networkState} = useContext(NetworksStore);
    const {state: userState} = useContext(UsersStore);
    const {state: roomState} = useContext(RoomsStore);
    const {state: rolesState} = useContext(RolesStore);
    const {state: propsState} = useContext(PropsStore);
    const {state: socialEventsState} = useContext(SocialEventsStore);

    const isSuperAdmin = Utils.isSuperAdmin(userState.myRoles);

    return (
        <footer className="main-footer" style={{maxHeight: "56px"}}>
            <div className="float-right d-none d-sm-block">
                Version <i>1.0.0</i>
            </div>
            <span>Copyright &copy; 2015-{(new Date()).getFullYear()} <a href="https://roomful.net/">Roomful</a>.</span> All rights reserved.

            {(isSuperAdmin || Utils.isLocalhost()) && (
                <>
                    <button onClick={() => {
                        console.log("networkState", networkState);
                        console.log("userState", userState);
                        console.log("roomState", roomState);
                        console.log("propsState", propsState);
                        console.log("rolesState", rolesState);
                        console.log("socialEventsState", socialEventsState);
                    }} className="mx-3">Show state
                    </button>
                    <button onClick={() => {
                        console.log("current network", networkState?.currentNetworkInfo);
                    }} className="mr-3">Show current network
                    </button>
                    <button onClick={() => {
                        console.log("chat state", state);
                    }}>Show chat state
                    </button>
                </>
            )}
        </footer>
    );
}