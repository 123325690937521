// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {ChatsStore} from "../../../stores/ChatsStore";
import {UsersStore} from "../../../stores/UsersStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// Components
import {ActiveNetworks} from "../../../components/list/Networks/ActiveNetworks";
import {UserNetworks} from "../../../components/list/Networks/UserNetworks";

const Navbar = props => {
    const {loading, onError} = props;

    const navigate = useNavigate();
    const {state, dispatch} = useContext(ChatsStore);
    const {state: networkState, dispatch: networksDispatch} = useContext(NetworksStore);
    const {state: usersState, dispatch: usersDispatch} = useContext(UsersStore);

    const isSuperAdmin = () => Utils.isSuperAdmin(usersState.myRoles);

    // Base state
    const [isLoaded, setIsLoaded] = useState(false);

    // Network state
    const [network, setNetwork] = useState(null);

    // Notifications state
    const notifications = useRef(null);
    const [totalNotificationCount, setTotalsNotificationCount] = useState(0);
    const [unreadMessagesCount, setUnreadMessagesCount] = useState(0);
    const [unreadMessagesTime, setUnreadMessagesTime] = useState(0);

    // Handlers
    const onNotificationToggle = e => {
        e.stopPropagation();
        notifications.current.classList.toggle("show");
    };
    const openChat = e => {
        e.preventDefault();
        navigate("/chats");
    };
    const fixHeaders = () => {
        if (document.querySelector("nav") && document.querySelector("#logo-text")) {
            const navHeight = document.querySelector("nav").offsetHeight;
            document.querySelector("#logo-text").style.minHeight = `${navHeight}px`;
        }
    };

    // Effects
    useEffect(() => {
        setIsLoaded(loading === "loaded");
    }, [loading]);
    useEffect(() => {
        window.addEventListener("DOMContentLoaded", () => setTimeout(fixHeaders, 1000));

        if (state.connection) {
            (async () => {
                const result = await API.Networks.getModel(Utils.getNetwork());

                if (result?.error?.code === 200) {
                    setNetwork(result?.data?.network);
                    await state.connection.sendSocketRequest("network:switch", {data: {networkId: result?.data?.network?.id}});
                }
                else onError(new Error(result?.error?.message));
            })();
        }
    }, [state.connection]);

    useEffect(() => {
        (async () => {
            if (state.reRenderNotifications && Cookies.get("sessionId")) {
                const result = (await API.Notification.getNotificationCounts(onError))?.data?.textchat;

                setUnreadMessagesCount(result?.count ?? 0);
                setUnreadMessagesTime(result?.time ?? 0 );
                setTotalsNotificationCount((result?.count ?? 0) + 0);

                dispatch({type: "SET_RERENDER_NOTIFICATIONS", payload: false});
            }
        })();
    }, [state.connection, state.reRenderNotifications]);

    useEffect(() => {
        (async () => {
            if (network && network?.id !== Utils.getNetwork()) {
                const result = await API.Users.isUserSubscribedToNetwork(network?.id, usersState?.model?.id, onError);

                if (result?.error?.code === 200) {
                    const isSubscribed = result?.data?.isSubscribed;

                    if (!isSubscribed) {
                        const result = await API.Users.subscribeUserToNetwork(network?.id, usersState?.model?.id, onError);

                        if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                    }

                    // Switching network
                    Cookies.set("networkId", network?.id);
                    Cookies.set("subdomainName", network?.fullName);

                    networksDispatch({type: "SET_CURRENT_NETWORK", payload: networkState?.openedNetwork?.model});
                    usersDispatch({type: "SET_UPDATING_MODEL", payload: true});

                    await state.connection.sendSocketRequest("network:switch", {data: {networkId: network?.id}});

                    // Reloading
                    const pathArr = window.location.pathname.split("/").map(el => el.trim()).filter(el => el);

                    if (pathArr[0] === "user") window.location.href = "/users/list";
                    else if (pathArr[0] === "room") window.location.href = "/rooms/my";
                    else if (pathArr[0] === "cms" && pathArr[1] === "room" && pathArr.length === 3) window.location.href = "/cms/room";
                    else if (pathArr[0] === "cms" && pathArr[1] === "room" && pathArr[3] === "prop" && pathArr.length === 5) window.location.href = "/cms/prop";
                    else if (pathArr[0] === "cms" && pathArr[1] === "upload" && pathArr[2] === "room" && pathArr.length === 4) window.location.href = "/cms/room";
                    else if (pathArr[0] === "cms" && pathArr[1] === "upload" && pathArr[2] === "room" && pathArr[4] === "prop" && pathArr.length === 6) window.location.href = "/cms/prop";
                    else window.location.reload();

                } else onError(new Error(result?.error?.message));
            }
        })();
    }, [network]);

    useEffect(() => {
        (async () => {
            const result = await API.Networks.getNetworkInfo(Utils.getNetwork(), onError);

            if (result && result?.error?.code === 200) {
                networksDispatch({type: "SET_CURRENT_NETWORK_INFO", payload: result.data});
                usersDispatch({type: "SET_UPDATING_MODEL", payload: true});

                await state?.connection?.sendSocketRequest("network:switch", {data: {networkId: result.data.network?.id}});
            }
            else onError(new Error(`Cant choose "${Cookies.get("subdomainName")}" network! Please, try again later.`));
        })();
    }, []);

    useEffect(() => {
        networksDispatch({type: "SET_CURRENT_NETWORK", payload: network});
    },[network]);

    return (
        <nav className="main-header navbar navbar-expand navbar-white navbar-light" style={{height: "4rem"}}>
            <ul className="navbar-nav">
                <li className="nav-item">
                    <a className="nav-link" data-widget="pushmenu" href="#" role="button">
                        <i className="fas fa-bars"/>
                    </a>
                </li>
            </ul>

            <ul className="navbar-nav ml-auto mr-2 align-items-center">
                <li className="nav-item d-none d-sm-inline-flex mr-5" style={{minWidth: "28.875rem"}}>
                    {isLoaded && (
                        network && (
                            isSuperAdmin()
                                ? <ActiveNetworks network={network} onChange={selectedNetwork => setNetwork(selectedNetwork)}
                                                   settings={{preLoadedNetwork: true}}/>
                                : <UserNetworks network={network} onChange={selectedNetwork => setNetwork(selectedNetwork)}
                                                   settings={{preLoadedNetwork: true}}/>
                        )
                    )}
                </li>

                <li className="nav-item dropdown h-100">
                    <a className="nav-link" data-toggle="dropdown" href="#" onClick={onNotificationToggle}>
                        <i className="far fa-bell"/>
                        {totalNotificationCount > 0 && <span className="badge badge-warning navbar-badge">{totalNotificationCount}</span>}
                    </a>
                    <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" ref={notifications} id="notifications">
                        <span className="dropdown-item dropdown-header no-hover-styles">{totalNotificationCount} Notifications</span>
                        <div className="dropdown-divider"/>
                        <a href="#" className="dropdown-item d-flex flex-column" onClick={openChat}>
                            <span><i className="fas fa-envelope mr-2"/> {unreadMessagesCount} new messages</span>
                            {totalNotificationCount > 0 && <span className="float-right text-muted text-sm">{Utils.formatDate(new Date(unreadMessagesTime))}</span>}
                        </a>
                        {totalNotificationCount > 0 && <a href="#" className="dropdown-item dropdown-footer" onClick={openChat}>See All Messages</a>}
                    </div>
                </li>
            </ul>
        </nav>
    );
}

export default Navbar;
