// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// Components
import {StyledButton, StyledDownloadButton} from "../../../components/styled/StyledButton";
import {FeedbackTable} from "../../../components/table/FeedbackTable";

export const RoomBoothsSpreadsheets = props => {
    const {onError} = props;
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    // Base state
    const [updateBoothSpreadsheet, setUpdateBoothSpreadsheet] = useState(null);
    const [isUpdateBoothBtnLoading, setIsUpdateBoothBtnLoading] = useState(false);

    // Main functions
    const uploadFile = () => Utils.uploadFileInput(null, file => {
        setIsUpdateBoothBtnLoading(true);
        setUpdateBoothSpreadsheet(file);
    });
    const getColumns = () => ([
        {
            name: "updatedElement",
            header: "Updated element",
            cell: rowData => rowData?.updatedElement
        },
        {
            name: "boothId",
            header: "Booth ID",
            cell: rowData => rowData?.boothId
        },
        {
            name: "status",
            header: "Status",
            cell: rowData => rowData?.status
        }
    ]);
    const getData = useCallback(() => state?.feedbackTableItems?.booths, [state?.feedbackTableItems?.booths]);

    // Callbacks
    const getItems = useCallback(async () => state?.parsedBooths, [state?.parsedBooths]);
    const getResult = async booth => {
        const {id: propId, title, members} = booth;
        const result = await API.Rooms.updateBoothTitle({
            roomId: state.model?.id,
            propId,
            title
        }, onError);

        return (!Utils.isObjectEmpty(booth) && result?.error?.code === 200
            ? {
                error: {code: 200},
                feedback: {
                    ...booth,
                    updatedElement: `booth title : ${title}`,
                    boothId: propId,
                    status: result?.error?.code === 200 ? "success" : result?.error?.message
                },
                items: members
            }
            : {error: result?.error});
    };
    const getSubResult = async (body, memberEmail) => {
        const result = await API.Rooms.updateBoothMember({
            roomId: state.model?.id,
            propId: body?.feedback?.id,
            email: memberEmail
        }, onError);
        const isError = result?.error?.code !== 200;

        return {
            items: {
                updatedElement: `member : ${memberEmail}`,
                boothId: body?.feedback?.id,
                status: isError ? result?.error?.message : "success"
            }
        };
    };
    const onFinish = () => {
        setIsUpdateBoothBtnLoading(false);
        setUpdateBoothSpreadsheet(null);
        dispatch({type: "SET_BOOTH_FEEDBACK_NEED_RENDER", payload: false});
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "All booths updated",
                text: "See results in the table below.",
                color: "success"
            }
        });
    };
    const addItemToFeedbackTable = item => {
        dispatch({type: "SET_BOOTH_FEEDBACK_TABLE_ITEMS", payload: item});
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (updateBoothSpreadsheet && updateBoothSpreadsheet instanceof File) {
                try {
                    const result = await API.Rooms.parseBooths(updateBoothSpreadsheet, onError);

                    dispatch({
                        type: "SET_PARSED_BOOTHS",
                        payload: result?.booths
                    });

                    dispatch({type: "SET_SHOW_BOOTH_FEEDBACK", payload: true});

                    if (state?.feedbackTableItems?.booths?.length > 0) {
                        dispatch({type: "RESET_BOOTH_FEEDBACK_TABLE_ITEMS"});
                        dispatch({type: "SET_BOOTH_FEEDBACK_NEED_RENDER", payload: true});
                    }
                }
                catch (e) {
                    onError(e);
                }
            }
        })();
    }, [updateBoothSpreadsheet]);

    return (
        <>
            <h4 className="my-4">Booths spreadsheets</h4>
            <div>
                <div className="row ml-0">
                    <div className="mr-3">
                        <StyledDownloadButton
                            href={Utils.buildURLs(`/files/generateRoomBoothsSpreadsheet/${state.model?.id}`, false)}
                            title="Generate booths spreadsheet" type="success" download={`${state.model?.id}.xlsx`}/>
                    </div>
                    <div><StyledButton onClick={uploadFile} disabled={isUpdateBoothBtnLoading} title="Update booths" type="warning"/></div>
                </div>
                <p className="mt-3 text-muted">Upload file format (.xlsx): roomId, boothId, title, members</p>

                <FeedbackTable show={state?.feedbackTableItems?.showBoothsFeedback} settings={{needSubLoop: true}}
                           callbacks={{onError, getColumns, getData, getItems, getResult, getSubResult, addItemToFeedbackTable, onFinish}}
                           texts={{title: "Booth update feedback table", progressBarTitles: ["Booths updated : ", "Updated booth's members : "]}}
                />

                <hr/>
            </div>
        </>
    );
}
