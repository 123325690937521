// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";

import {Store} from "../../stores/MainStore";
import {NetworksStore} from "../../stores/NetworksStore";
import {UsersStore} from "../../stores/UsersStore";
import {ChatsStore} from "../../stores/ChatsStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {TabPanel, ThemedTab, ThemedTabs} from "../../components/styled/StyledTabs";
import {NetworkInfo} from "./tabs/NetworkInfo";
import {NetworkThumbnail} from "./tabs/NetworkThumbnail";
import {NetworkCommands} from "./tabs/NetworkCommands";
import {NetworkPluginConfigurator} from "./tabs/NetworkPluginConfigurator";
import {NetworkStatus} from "./components/NetworkStatus";
import Loading from "../../components/Loading";

export default function Network() {
    const {id} = useParams();
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);
    const {state: userState, dispatch: userDispatch} = useContext(UsersStore);
    const {state: chatsState} = useContext(ChatsStore);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };
    const getNetworkModel = async () => {
        const data = (
            await (
                Utils.hasPermission(userState.myRoles, "all")
                ? API.Networks.getPrivateModel(id, onError)
                : API.Networks.getModel(id, onError)
            )
        ).data;

        if (data?.network && !Utils.isObjectEmpty(data?.network)) dispatch({
            type: "SET_NETWORK_MODEL",
            payload: data.network
        });
        else navigate(`/notFound`);
    };
    useEffect(() => {
        (async () => {
            await getNetworkModel();

            // if (Cookies.get("networkId") !== id) {
            //     Cookies.set("networkId", id);
            //     Cookies.set("subdomainName", state?.openedNetwork?.model?.fullName);
            //
            //     dispatch({
            //         type: "SET_CURRENT_NETWORK",
            //         payload: state?.openedNetwork?.model
            //     });
            //     userDispatch({type: "SET_UPDATING_MODEL", payload: true});
            //
            //     navigate(0);
            //
            //     await chatsState.connection.sendSocketRequest("network:switch", {data: {networkId: id}});
            // }
        })();
    }, []);

    const [tabValue, setTabValue] = useState(Utils.hasPermission(userState.myRoles, "all") ? 0 : 1);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    return (
        state.openedNetwork?.model && !Utils.isObjectEmpty(state.openedNetwork?.model) ?
            <div className="content-wrapper">

                <ContentHeader
                    title={`Network: ${state.openedNetwork?.model?.fullName} (id: ${state.openedNetwork?.model?.id})`}
                    breadcrumbs={[
                        {
                            title: "Home",
                            link: "/home"
                        },
                        {
                            title: "Networks",
                            link: `/networks/list`
                        },
                        {
                            title: `${state.openedNetwork?.model?.fullName}`,
                            link: `/network/${id}`,
                            active: true
                        }
                    ]}/>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header p-2 d-flex custom justify-content-between">
                                        <ThemedTabs value={tabValue} onChange={onTabChange}>
                                            {Utils.hasPermission(userState.myRoles, "all") &&
                                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0}
                                                       label="Settings"/>}
                                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1}
                                                       label="Thumbnail"/>
                                            {
                                                Utils.hasPermission(userState.myRoles, "all") &&
                                                <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={2}
                                                           label="Commands"/>}
                                            {
                                                Utils.hasPermission(userState.myRoles, "all") &&
                                                <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={3}
                                                           label="Configure HTML Plugin"/>}
                                        </ThemedTabs>
                                        {Utils.hasPermission(userState.myRoles, "all") &&
                                        <NetworkStatus onError={onError}/>}
                                    </div>

                                    <div className="card-body">
                                        <div className="tab-content">
                                            {Utils.hasPermission(userState.myRoles, "all") &&
                                            <TabPanel value={tabValue} index={0}><NetworkInfo
                                                onError={onError}/></TabPanel>}
                                            <TabPanel value={tabValue} index={1}><NetworkThumbnail
                                                onError={onError}/></TabPanel>
                                            {Utils.hasPermission(userState.myRoles, "all") &&
                                            <TabPanel value={tabValue} index={2}><NetworkCommands
                                                onError={onError}/></TabPanel>}
                                            {Utils.hasPermission(userState.myRoles, "all") &&
                                            <TabPanel value={tabValue} index={3}><NetworkPluginConfigurator
                                                onError={onError}/></TabPanel>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            : <Loading/>
    );
}