// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {UsersStore} from "../../../stores/UsersStore";

// Material UI
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box} from "@material-ui/core";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {AllRooms} from "../../../components/list/Rooms/AllRooms";
import {AllProps} from "../../../components/list/Props/AllProps";

export default function AddAsTeamMember(props) {
    const {isOpened, setIsOpened, onError} = props;

    const {state} = useContext(UsersStore);

    // Base state
    const [startProceeding, setStartProceeding] = useState(false);
    const [isDone, setIsDone] = useState(false);

    // Dropdowns state
    const [room, setRoom] = useState(null);
    const [prop, setProp] = useState(null);

    // Main functions
    const add = async () => {
        setStartProceeding(true);

        for (let i = 0; i < state.selectedUsers?.length; i++) {
            const userId = state.selectedUsers[i];

            const result = await API.Props.inviteToProp({
                roomId: room?.id,
                propId: prop?.id,
                invitedUser: userId,
                permissions: {
                    manage: true
                }
            }, onError);

            console.log(userId, result);
        }

        setStartProceeding(false);
        setIsDone(true);
    };
    const cancel = () => {
        setIsOpened(false);
        setIsDone(false);
    };

    return (
        <Dialog open={isOpened} onClose={() => setIsOpened(false)} fullWidth={true} maxWidth="sm"
                disableBackdropClick={true} disableEscapeKeyDown={true}>
            <DialogTitle id="form-dialog-title">Add as exhibitors</DialogTitle>
            <DialogContent>
                <DialogContentText>Add selected users as exhibitors to :</DialogContentText>

                <div className="mb-3">
                    <AllRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} networkId={Utils.getNetwork()}/>
                </div>
                <AllProps prop={prop} onChange={selectedProp => setProp(selectedProp)} networkId={Utils.getNetwork()} roomId={room?.id}/>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="flex-end" width="100%">
                    <div className="mr-3">
                        <StyledButton onClick={cancel} type="default" title="Cancel" disabled={startProceeding}/>
                    </div>
                    <StyledButton onClick={isDone ? cancel : add} disabled={startProceeding}
                                  type="success" title={isDone ? "Done" : (startProceeding ? "Adding..." : "Add")} />
                </Box>
            </DialogActions>
        </Dialog>
    );
}