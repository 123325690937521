// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Utils from "../../../../../scripts/Utils";
import {API} from "../../../../../api/API";
import {CMSStore} from "../../../../../stores/CMSStore";
import {RoomsStore} from "../../../../../stores/RoomsStore";
import {UsersStore} from "../../../../../stores/UsersStore";

// MUI
import {CircularProgress} from "@material-ui/core";

// Components
import {MySortableContainer, MySortableItem} from "./MySortableTable";
import AddToPropPanel from "./AddToPropPanel";
import EditPanel from "./EditPanel";
import ContextMenu from "../../../components/ContextMenu";
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {UserRooms} from "../../../../../components/list/Rooms/UserRooms";
import {AllRooms} from "../../../../../components/list/Rooms/AllRooms";

export default function PageContent({onError}) {
	const {roomId = null} = useParams();
	const navigate = useNavigate();

	// Context
	const {state: cmsState, dispatch: cmsDispatch} = useContext(CMSStore);
    const {state, dispatch} = useContext(RoomsStore);
    const {state: usersState} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(usersState.myRoles);

    // Base state
	const [isLoading, setIsLoading] = useState(true);

	const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
	const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);

	const [isAddBtnDisabled, setIsAddBtnDisabled] = useState(false);

	// RCP state
	const [room, setRoom] = useState(null);

	// Main functions
	const addFilesToProp = () => {
        cmsDispatch({type: "SET_SHOW_ADD_TO_PROP", payload: true});
    };
	const onDeleteSelected = useCallback(async () => {
		setIsDeleteBtnDisabled(true);
		setIsDeleteBtnLoading(true);

		await API.Rooms.deleteResourcesFromSortingTable({roomId: room?.id, resourceIds: cmsState.selectedItemsIds}, onError);

		const content = state.cmsContent.filter(el => !(cmsState.selectedItemsIds.indexOf(el.id) > -1));
        dispatch({type: "SET_CMS_CONTENT", payload: content});
        cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});

        setIsDeleteBtnLoading(false);
	}, [cmsState.selectedItemsIds, state.cmsContent, room?.id]);
	const onRoomSelected = selectedRoom => {
		setRoom(selectedRoom);

		if (selectedRoom?.id?.length > 0 && selectedRoom?.id !== "all")
			navigate(`/cms/room/${selectedRoom?.id}`);
	};
	const uploadFiles = useCallback(() => {
		cmsDispatch({type: "SET_SHOW_FILE_EDIT_MENU"});
		cmsDispatch({type: "SET_SHOW_ADD_TO_PROP"});
		cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});
		cmsDispatch({type: "SET_HIDE_CONTEXT_MENU"});

		if (roomId?.length > 0 && roomId !== "all") navigate(`/cms/upload/room/${roomId}`);
	}, [roomId]);

	// Effects
	useEffect(() => {
		(async () => {
			if (roomId && roomId?.length > 0 && roomId !== "all") {
				const result = (await API.Rooms.getModel(roomId, onError));

				if (result?.error?.code === 200) setRoom(result.data.room);
				else onError(new Error(result?.error?.message));

				setIsLoading(false);
			}
		})();
	}, [roomId]);
	useEffect(() => {
		if (room && room?.id !== "all" && room?.id?.length > 0) {
			(async () => {
				setIsLoading(true);
				const items = (await API.Rooms.getSortingTable({
					roomId: room?.id,
					offset: 0,
					limit: 100
				}, onError)).data.content;

				dispatch({type: "SET_CMS_CONTENT", payload: items});
				setIsLoading(false);
			})();
		}
	}, [room?.id]);
	useEffect(() => {
		setIsDeleteBtnDisabled(cmsState.selectedItemsIds?.length === 0);
		setIsAddBtnDisabled(cmsState.selectedItemsIds?.length === 0);
	}, [cmsState.selectedItemsIds]);

	return (
		<div className="container-fluid card p-3">
			<section className="content">
				<div className="container-fluid">

					<div className="w-100 mt-2 d-flex flex-column">
						<h6>Select room:</h6>

						{(isSuperAdmin || isNetworkAdmin)
							? <AllRooms room={room} onChange={onRoomSelected} networkId={Utils.getNetwork()}
										settings={{preLoadedRoom: (roomId && roomId?.length > 0)}} allowAll={false}/>
							: <UserRooms room={room} onChange={onRoomSelected} networkId={Utils.getNetwork()}
										 settings={{preLoadedRoom: (roomId && roomId?.length > 0)}} allowAll={false}/>}
					</div>

					<div className="w-100 py-3 px-2 controls d-flex justify-content-between align-items-center">
						<div>
							<span className="mr-4">Files: {state.cmsContent?.length} </span>
							<span>Selected items: {cmsState.selectedItemsIds?.length} </span>
						</div>
						<div className="d-flex">
							<div className="mr-3">
								<StyledButton onClick={addFilesToProp} type="success" disabled={isAddBtnDisabled} noLoading
											  title={`Add to prop (${cmsState.selectedItemsIds?.length} files)`}/>
							</div>
							<div className="mr-3">
								<StyledButton onClick={onDeleteSelected} type="danger"
											  disabled={isDeleteBtnDisabled} noLoading={!isDeleteBtnLoading}
											  title={`Delete (${cmsState.selectedItemsIds?.length} files)`} />
							</div>
							<StyledButton onClick={uploadFiles} type="warning" title="Add files"/>
						</div>
					</div>

					<hr/>

					<div className="mt-4 d-flex w-100 align-items-stretch">
						<div className="overflow-auto w-100 d-flex justify-content-between align-items-start">
							{
								isLoading
									? (
										<div className="d-flex justify-content-center align-items-center py-5 w-100">
											<CircularProgress className="mr-3"/>
											<h3>Loading...</h3>
										</div>
									)
									: (
										<MySortableContainer onSortEnd={() => false} axis="xy"
															 itemsCount={state.cmsContent?.length} distance={10}>
											{state.cmsContent.map((item, index) =>
												<MySortableItem key={`item-${item.id}`} index={index} disabled onError={onError}
																value={{
																	...item,
																	roomId: room?.id,
																	isLogo: cmsState.logoCount !== 0 && index < cmsState.logoCount,
																	isThumbnail: cmsState.logoCount !== 0 && index >= cmsState.logoCount && index < cmsState.thumbnailCount + cmsState.logoCount,
																	isSpecial: cmsState.contentPickerType,
																	count: index + 1,
																	index
																}}/>)}
										</MySortableContainer>
									)
							}
						</div>

						{state.cmsContent?.length > 0 && <AddToPropPanel onError={onError}/>}
						{state.cmsContent?.length > 0 && <EditPanel onError={onError}/>}
					</div>

					<ContextMenu options={[
						// {
						// 	title: "Open / Download",
						// 	icon: <i className="fas fa-download"/>,
						// 	callback: () => {
						// 		window.open(`/resource/${cmsState.selectedContentID}?networkId=${Utils.getNetwork()}`, '_blank').focus();
						// 	}
						// },
						{
							title: cmsState.selectedItemsIds?.length > 1 ? `Add to prop (${cmsState.selectedItemsIds?.length} items)` : "Add to prop",
							icon: <i className="fas fa-puzzle-piece"/>,
							showWhenOpenedEditPanel: false,
							callback: setShow => {
								setShow(false);

								if (cmsState.selectedItemsIds?.length === 0)
									cmsDispatch({type: "ADD_SELECTED_ITEM", payload: cmsState.selectedContentID});
								cmsDispatch({type: "SET_SHOW_ADD_TO_PROP", payload: true});
								cmsDispatch({type: "ADD_SELECTED_TO_PROP", payload: true});
							}
						},
						({
							title: "Edit",
							icon: <i className="fas fa-edit"/>,
							showWhenOpenedEditPanel: false,
							callback: setShow => {
								setShow(false);

								cmsDispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: true});

								const file = state.cmsContent.find(el => el.id === cmsState.selectedContentID);
								cmsDispatch({
									type: "SET_FILE_INFO",
									payload: {
										id: file.id,
										fileName: file.metadata.fileName,
										title: file.title,
										description: file.description,
										author: file.author || "",
										linkShortDescription: file.linkShortDescription || "",
										webLink: file.webLink || "",
										date: file.date,
										location: file.location
									}
								});
							}
						}),
						{
							title: cmsState.selectedItemsIds?.length > 1 ? `Delete (${cmsState.selectedItemsIds?.length} items)` : "Delete",
							icon: <i className="fas fa-trash"/>,
							callback: setShow => {
								setShow(false);

								const isMultiple = !(cmsState.selectedItemsIds?.length === 0);

								(async () => {
									await API.Rooms.deleteResourcesFromSortingTable({
										roomId: room?.id,
										resourceIds: isMultiple
											? cmsState.selectedItemsIds
											: [(state.cmsContent.find(el => el.id === cmsState.selectedContentID))?.id]
									}, onError);

									const content = state.cmsContent.filter(el => {
										if (isMultiple) return !(cmsState.selectedItemsIds.indexOf(el.id) > -1);
										else return !(cmsState.selectedContentID === el.id);
									});
									dispatch({type: "SET_CMS_CONTENT", payload: content});
									if (isMultiple) cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});
								})();
							}
						}
					]}/>

				</div>
			</section>
		</div>
	);
}
