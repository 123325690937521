// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {Fragment, useContext, useEffect, useRef, useState} from "react";
import {ChatsStore} from "../../../stores/ChatsStore";
import Utils from "../../../scripts/Utils";

// Message list
import InfiniteScroll from "react-infinite-scroll-component";

// Scrollbars
import '../../../plugins/OverlayScrollbars/OverlayScrollbars.min.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';

// Components
import {ChatMessage, SystemMessage} from "./ChatMessage";

export const ChatBody = () => {
    const {state, dispatch} = useContext(ChatsStore);
    const ref = useRef();
    const [fieldWidth, setFieldWidth] = useState(0);

    const [size] = useState(25);

    const fetchMoreData = async () => {
        const newOffset = state.messagesOffset + size;
        dispatch({type: "SET_MESSAGES_OFFSET", payload: newOffset});
        await setMessages(newOffset);
    };

    const setMessages = async newOffset => {
        const _offset = newOffset ?? state.messagesOffset;
        const {messages, participants} = (await state.connection.sendSocketRequest("textchat:listMessages", {data: {offset: _offset, size, textchatId: state.currentTextchat.id}})).data;
        const newMessages = [...state.messages, ...messages];

        if (newMessages.length > state.messages.length) {
            dispatch({type: "SET_MESSAGES", payload: newMessages});
            dispatch({type: "SET_PARTICIPANTS", payload: participants});
        }
    };

    useEffect(() => {
        const el = ref.current.children[0].children[0];
        const scrollBarWidth = el.offsetWidth - el.clientWidth;

        setFieldWidth(el.getBoundingClientRect().width - scrollBarWidth);
    });

    useEffect(() => {
        if (state.connection && !Utils.isObjectEmpty(state.currentTextchat)) {
            dispatch({type: "SET_MESSAGES_OFFSET", payload: 0});
            setMessages();
        }
    }, [state.connection, state.currentTextchat]);
    useEffect(() => {
        console.log("state.messages", state.messages);
        if (state.messages?.length === 0) dispatch({type: "SET_MESSAGES_OFFSET", payload: 0});
    }, [state.messages]);

    return (
        <div className="h-100 direct-chat direct-chat-success">
            <div className="h-100 card-body overflow-hidden">
                <div className="h-100 direct-chat-messages p-0 messages-list overflow-hidden" ref={ref}>
                    {
                        !Utils.isObjectEmpty(state.currentTextchat) && state.messages?.length > 0
                            ? (
                                <InfiniteScroll
                                    inverse={true}
                                    initialScrollY={document.querySelector(".messages-list>div")?.scrollHeight || 0}
                                    style={{ display: 'flex', flexDirection: 'column-reverse' }}
                                    scrollableTarget="scrollableDiv"
                                    height={document?.querySelector(".messages-list")?.getBoundingClientRect()?.height}
                                    children={state.messages}
                                    dataLength={state.messages.length}
                                    next={fetchMoreData}
                                    hasMore={true}>
                                    {state.messages.map((message, index) => {
                                        const prevMessage = Object.values(state.messages)[index - 1];
                                        const currentMessageDate = (new Date(message.created));
                                        const nextMessageDate = (new Date(prevMessage?.created));

                                        let DateFilter = () => null;

                                        if (prevMessage) {
                                            if (Utils.dateDiffInDays(currentMessageDate, nextMessageDate) >= 1) {
                                                DateFilter = () => (
                                                    <div className="date-filtering">
                                                        <span className="line"/>
                                                        <span className="main">{Utils.getDateText(prevMessage.created)}</span>
                                                        <span className="line"/>
                                                    </div>
                                                );
                                            }
                                        }

                                        if (index + 1 === state.messages?.length) {
                                            DateFilter = () => (
                                                <div className="date-filtering">
                                                    <span className="line"/>
                                                    <span className="main">{Utils.getDateText(currentMessageDate)}</span>
                                                    <span className="line"/>
                                                </div>
                                            );
                                        }

                                        const attachToStart = (prevMessage && Utils.dateDiffInDays(currentMessageDate, nextMessageDate) >= 1) && (index + 1 !== state.messages?.length);

                                        return (
                                            <Fragment key={`wrapper-${message.id}`}>
                                                {attachToStart && <DateFilter key={`date-filter-${message.id}`}/>}
                                                {message.messageType === 3 || message.messageType === 4
                                                        ? <SystemMessage key={message.id} created={message.created} messageBody={message.messageBody}
                                                                         isJson={message.messageType === 4} index={index}/>
                                                        : <ChatMessage key={message.id} data={{...message, index}} fieldWidth={fieldWidth}/>}
                                                {!attachToStart && <DateFilter key={`date-filter-${message.id}`}/>}
                                            </Fragment>
                                        );
                                    })}
                                </InfiniteScroll>
                            )
                            : (
                                <div className="h-100 d-flex justify-content-center align-items-center px-3 px-sm-5">
                                    <h2>No messages...</h2>
                                </div>
                            )
                    }
                </div>
            </div>
        </div>
    );
}
