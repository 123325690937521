// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";

import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, InputAdornment, IconButton, Box, CircularProgress} from "@material-ui/core";
import {ArrowDropUp, ArrowDropDown, Loop} from '@material-ui/icons';
import Image from 'material-ui-image';

// Style
import useStyles from "../../../styles/dropdownStyles";

// Components
import {StyledImage} from "../../styled/StyledImage";

let timeout = null;

export const ActiveNetworks = props => {
    const {network, onChange, settings = {}} = props;
    const {preLoadedNetwork = false} = settings;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);

    const classes = useStyles();
    const readOnly = true; // prevent all searching functional

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const [value, setValue] = useState(network);
    const [isLoading, setIsLoading] = useState(false);

    // Base state
    const [needRender, setNeedRender] = useState(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);

    // Search state
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);

    // Buttons, dropdown state, errors state, etc
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isErrorInInput, setIsErrorInInput] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);

    const loadMoreItems = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);
        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };

    const getList = () => {
        return state.activeNetworksList;
    };
    const setList = (list) => {
        dispatch({type: 'SET_ACTIVE_NETWORKS_LIST', payload: list});
    };

    const getListItems = useCallback(async(list) => {
        setIsLoading(true);

        const data = (await API.Networks.getAllActive(onError)).data;
        const items = data?.networks ?? [];

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && items.length > 0) ? list : [];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);

        setList(itemsToAdd);
        if(!loadMoreActive && !preLoadedNetwork) setValue(itemsToAdd[0]);

        if(loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setSearchBtnLoading(false);
    }, [loadMoreActive, prevSearchInputText, searchInputText]);

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(true);
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !readOnly) loadMoreItems();
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        clearTimeout(timeout);
        timeout = setTimeout(search, 1000);
        setPrevSearchInputText(searchInputText);
        setSearchInputText(e.target.value);
    };
    const onItemSelected = (e, value) => {
        setValue(value);
        setIsDropdownOpen(false);
        setIsErrorInInput(false);
    };

    // Effects
    useEffect(() => {
        return () => {
            setValue(null);
            setIsLoading(false);
            setNeedRender(true);
            setOffset(0);
            setSearchInputText("");
            setPrevSearchInputText("");
            setLoadMoreActive(false);
            setIsDropdownOpen(false);
            setIsErrorInInput(false);
            setSearchBtnLoading(false);

            // dispatch({type: "CLEAR_ACTIVE_NETWORKS_LIST"});
        };
    }, []);
    useEffect(() => {
        (async () => {
            if (needRender) {
                const list = getList();

                await getListItems(list);
            }
        })();
    }, [needRender]);
    useEffect(() => {
        if (value) setIsLoading(false);

        if (onChange && typeof onChange === "function") onChange(value);
    }, [value]);
    useEffect(() => {
        setValue(network);
        if (network?.id !== value?.id) setIsDropdownOpen(false);
    }, [network]);

    return (
        (getList())?.length > 0
        ?
        <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                      renderInput={params => (
                          <div className="d-flex align-items-center">
                              {value?.thumbnail && (
                                  <Image imageStyle={{
                                      position: "initial",
                                      margin: "0.25rem",
                                      width: "3rem",
                                      height: "calc(3.5rem - 0.5rem - 2px)",
                                      objectFit: "cover"
                                  }} style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      background: "#fff",
                                      padding: 0,
                                      border: "1px solid hsl(0, 0%, 80%)",
                                      borderRight: 0,
                                      borderTopRightRadius: 0,
                                      borderTopLeftRadius: "4px",
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: "4px",
                                  }} src={Utils.buildURLs(`/resource/thumbnail/256/${value?.thumbnail}`)}/>
                              )}
                              <TextField {...params} variant="outlined" value={searchInputText}
                                         onChange={onSearchInputChange} error={isErrorInInput} size="small"
                                         InputProps={{
                                             readOnly,
                                             ...params.InputProps,
                                             style: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "3.5rem"},
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     {searchBtnLoading
                                                         ? <IconButton edge="end"><Loop/></IconButton>
                                                         :
                                                         <IconButton edge="end" onClick={onOpenDropdown}>
                                                             {isDropdownOpen ? <ArrowDropUp/> : <ArrowDropDown/>}
                                                         </IconButton>}
                                                 </InputAdornment>
                                             ),
                                             onClick: () => setIsDropdownOpen(true)
                                         }}
                                         onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                              />
                          </div>
                      )} style={{width: "100%"}} freeSolo disableClearable forcePopupIcon={false} open={isDropdownOpen}
                      value={value} onChange={onItemSelected} filterOptions={options => options}
                      options={getList()}
                      getOptionLabel={option => {
                          return `${option?.fullName} ${option?.id ? `(#${option?.id})` : ""}`;
                      }}
                      renderOption={option => (
                          <Box data-id={option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                              <StyledImage size={3} src={Utils.buildURLs(`/resource/thumbnail/256/${option?.thumbnail}`)}/>

                              <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                                  <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                                      {option?.fullName}
                                  </span>
                                  <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                                      {option?.id}
                                  </span>
                              </div>
                          </Box>
                      )}
        />
        : (
            <div style={{
                border: "1px solid hsl(0, 0%, 80%)",
                background: "#fff",
                width: "100%", height: "3.5rem",
                padding: "9px", borderRadius: "4px"
            }} className="d-flex align-items-center">
                {isLoading
                    ? <><CircularProgress className="mr-3"/> Loading...</>
                    : "No results found"}
            </div>
        )
    );
}