// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {UsersStore} from "../../../stores/UsersStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const UserSessionsReport = props => {
    const {onError, params : {startDate, endDate}} = props;
    const [disabled, setDisabled] = useState(false);
    const {state: userState} = useContext(UsersStore);

    const onClick = async () => {
        if (Utils.hasPermission(userState.myRoles, "network.manage", [Utils.getNetwork()])) {
            setDisabled(true);

            await API.Analytics.generateReport(`/files/analytics/userSessions`, {
                startDate,
                endDate,
                networkId: Utils.getNetwork()
            }, Utils.getNetwork(), onError);

            setDisabled(false);
        }
        else onError(new Error("Permission denied"));
    };

    return (
        <>
            <h4 className="my-4">User sessions.</h4>
            <div>
                <div className="mb-1">
                    <StyledButton title="Generate analytics: user sessions" type="success" disabled={disabled} onClick={onClick}/>
                    <p className="mt-3 text-muted">
                        Report represents users who created new sessions in provided amount of time.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
};