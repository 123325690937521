// Copyright © 2015-2023 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class SocialEventsAPI {
	static async getSocialEvents(onError) {
		return await customFetch({path: "/jsonRpc/social.getSocialEvents", body: null})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async getSocialEvent(body, onError) {
		return await customFetch({path: "/jsonRpc/social.getSocialEvent", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async createSocialEvent(body, onError) {
		return await customFetch({path: "/jsonRpc/social.createSocialEvent", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async updateSocialEvent(body, onError) {
		return await customFetch({path: "/jsonRpc/social.updateSocialEvent", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async deleteSocialEvent(body, onError) {
		return await customFetch({path: "/jsonRpc/social.deleteSocialEvent", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async getSocialEventTickets(onError) {
		return await customFetch({path: "/jsonRpc/ticket.getPurchasableTickets", body: null})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async createSocialEventTicket(body, onError) {
		return await customFetch({path: "/jsonRpc/ticket.createPurchasableTicket", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async updateSocialEventTicket(body, onError) {
		return await customFetch({path: "/jsonRpc/ticket.updatePurchasableTicket", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async deleteSocialEventTicket(body, onError) {
		return await customFetch({path: "/jsonRpc/ticket.deletePurchasableTicket", body})
			.then(resultData => resultData.json())
			.catch(onError);
	}
}
