// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useState} from "react";
import {useStyles} from "../../../style";
import {API} from "../../../../../api/API";
import {initialState, Store} from "../../../../../stores/MainStore";
import {CMSStore} from "../../../../../stores/CMSStore";
import {UsersStore} from "../../../../../stores/UsersStore";

// Components
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {AllPropsWithFilters} from "../../../../../components/list/Props/AllPropsWithFilters";

export default function AddToPropPanel({onError}) {
    const classes = useStyles();

    // Context
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(CMSStore);
    const {state: usersState, dispatch: usersDispatch} = useContext(UsersStore);

    // Base state
    const [isLoadingAddBtn, setIsLoadingAddBtn] = useState(false);
    const [prop, setProp] = useState(null);

    // Main functions
    const close = () => dispatch({type: "SET_SHOW_ADD_TO_PROP", payload: false});
    const addResource = useCallback(async () => {
        if (prop) {
            setIsLoadingAddBtn(true);
            const result = await API.Props.changePropContent({
                roomId: prop?.roomId,
                propId: prop?.id,
                appendToListEnd: true,
                moveToProp: state.selectedItemsIds,
                removeFromProp: []
            }, onError);

            if (result.error.code === 200) {
                mainDispatch({
                    type: "SET_MODAL_SETTINGS",
                    payload: {
                        show: true,
                        title: "Success!",
                        text: `All selected resources successfully added to prop (#${prop?.roomId}/${prop?.id})!`,
                        color: "success",
                        useCustomOnOkFunction: true,
                        onOk: () => {
                            const itemsToRemoveFromContent = state.selectedItemsIds;
                            const newContent = usersState.cmsContent.filter(el => !(itemsToRemoveFromContent.indexOf(el.id) > -1));

                            usersDispatch({type: "SET_CMS_CONTENT", payload: newContent});
                            mainDispatch({type: "SET_MODAL_SETTINGS", payload: {...initialState, show: false}});
                        }
                    }
                });
            }
            else onError(new Error(result.error.message));

            dispatch({type: "CLEAR_SELECTED_ITEMS"});

            close();
            setIsLoadingAddBtn(false);
        }
    }, [prop, state.selectedItemsIds, usersState.cmsContent]);

    return (
        <div className={`${classes.editPanel} ${state.showAddToPropMenu ? "d-flex" : "d-none"} p-2 flex-column align-items-start overflow-auto`}>
            <div className="p-2 w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">Add resource to prop</h5>
                    <div className="ml-1">
                        <StyledButton onClick={close} type="danger" title="Close" size="sm"/>
                    </div>
                </div>
                <hr/>
            </div>

            <div className="w-100 h-100">
				<AllPropsWithFilters prop={prop} onChange={selectedProp => setProp(selectedProp)}/>

                <div className="px-3 m-2 w-100 d-flex justify-content-end">
                    <StyledButton onClick={addResource} type="success" title="Add resource"
								  disabled={isLoadingAddBtn} noLoading={!isLoadingAddBtn}/>
                </div>
            </div>

        </div>
    );
}