// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {createResource} from "../../../api/Requests";
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// MUI
import Image from 'material-ui-image';

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const NetworkThumbnail = props => {
    const {onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);

    const [thumbnail, setThumbnail] = useState(Utils.buildURLs(`/resource/${state?.openedNetwork?.model.thumbnail}`));

    const [uploadedFile, setUploadedFile] = useState("");
    const [uploadFileBtnDisabled, setUploadFileBtnDisabled] = useState(false);
    const [saveFileBtnDisabled, setSaveFileBtnDisabled] = useState(false);

    const uploadThumbnail = () => Utils.uploadFileInput("image/*", file => {
        setUploadFileBtnDisabled(true);
        setUploadedFile(file);
        setThumbnail(URL.createObjectURL(file));
        setUploadFileBtnDisabled(false);
    });

    const showSuccessModal = () => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Success!",
            text: "Network thumbnail was updated successfully!",
            color: "success"
        }
    });

    const saveThumbnail = async () => {
        if (uploadedFile && uploadedFile instanceof File) {
            setSaveFileBtnDisabled(true);

            const resourceID = await createResource(uploadedFile, "roomful", onError);
            const result = await API.Networks.setThumbnail(state?.openedNetwork?.model?.id, resourceID, onError);
            if (result.error.code === 200) showSuccessModal();
            else onError(result.error);

            setSaveFileBtnDisabled(false);

            console.log({...state?.openedNetwork?.model, ...{thumbnail: resourceID}});

            dispatch({
                type: "SET_NETWORK_MODEL",
                payload: {...state?.openedNetwork?.model, ...{thumbnail: resourceID}}
            });
        }
    };

    return (
        <>
            <div className="mb-3">
                <Image imageStyle={{objectFit: "contain"}} style={{width: "100%", height: "14.063rem", padding: 0}}
                       src={thumbnail}/>
            </div>
            <div className="row">
                <StyledButton onClick={uploadThumbnail} disabled={uploadFileBtnDisabled}
                              title="Upload new thumbnail" type="primary"/>
                <div className="ml-5">
                    <StyledButton disabled={saveFileBtnDisabled} title="Save thumbnail" type="primary"
                                  onClick={saveThumbnail}/>
                </div>
            </div>
        </>
    );
}