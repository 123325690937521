// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
    model: {},
    featuring: {},

    list: [],
    listAllowAll: [],

    userRoomsListAllowAll: [],
    userRoomsList: [],
    userRoomsTotalCount: 0,

    templatesRoomsList: [],
    templatesRoomsListTotalCount: 0,

    userAvailableRoomsList: [],

    boothsList: [],
    fileCabinetsList: [],
    propsList: [],

    feedbackTableItems: {
        showBoothsFeedback : false,
        showMembersFeedback : false,

        isBoothNeedRender : true,
        isMemberNeedRender : true,

        booths: [],
        members: []
    },

    parsedBooths : [],

    cmsContent: []
};

export const RoomsStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_ROOM_MODEL':
            return {...state, model: action.payload};
        case 'SET_ROOM_FEATURING':
            return {...state, featuring: action.payload};
        case 'SET_ROOM_SUBSCRIPTION_STATUS':
            return {...state, model: Object.assign(state.model, {subscriptionStatus: action.payload})};

        case 'CLEAR_LIST':
            return {...state, list: []};
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'CLEAR_ALLOW_ALL_LIST':
            return {...state, listAllowAll: []};
        case 'SET_ALLOW_ALL_LIST':
            return {...state, listAllowAll: action.payload};

        case 'CLEAR_USER_AVAILABLE_LIST':
            return {...state, userAvailableRoomsList: []};
        case 'SET_USER_AVAILABLE_LIST':
            return {...state, userAvailableRoomsList: action.payload};

        case 'SET_BOOTHS_LIST':
            return {...state, boothsList: action.payload};
        case 'SET_FILE_CABINETS_LIST':
            return {...state, fileCabinetsList: action.payload};
        case 'SET_PROPS_LIST':
            return {...state, propsList: action.payload};

        case 'CLEAR_USER_ROOMS_LIST':
            return {...state, userRoomsList: []};
        case 'SET_USER_ROOMS_LIST':
            return {...state, userRoomsList: action.payload};
        case 'CLEAR_USER_ROOMS_ALLOW_ALL_LIST':
            return {...state, userRoomsListAllowAll: []};
        case 'SET_USER_ROOMS_ALLOW_ALL_LIST':
            return {...state, userRoomsListAllowAll: action.payload};
        case 'SET_USER_ROOMS_LIST_TOTAL_COUNT':
            return {...state, userRoomsTotalCount: action.payload};

        case 'SET_BOOTH_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {booths: [...state.feedbackTableItems.booths, action.payload]})};
        case 'RESET_BOOTH_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {booths: [], members: state.feedbackTableItems.members})};
        case 'SET_SHOW_BOOTH_FEEDBACK':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {showBoothsFeedback: action.payload})};
        case 'SET_BOOTH_FEEDBACK_NEED_RENDER':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {isBoothNeedRender: action.payload})};

        case 'SET_MEMBERS_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {members: [...state.feedbackTableItems.members, action.payload]})};
        case 'RESET_MEMBERS_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {booths: state.feedbackTableItems.booths, members: []})};
        case 'SET_SHOW_MEMBERS_FEEDBACK':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {showMembersFeedback: action.payload})};
        case 'SET_MEMBERS_FEEDBACK_NEED_RENDER':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {isMemberNeedRender: action.payload})};

        case 'SET_PARSED_BOOTHS':
            return {...state, parsedBooths: action.payload};

        case 'SET_CMS_CONTENT':
            return {...state, cmsContent: action.payload};

        case 'SET_TEMPLATES_ROOMS_LIST':
            return {...state, templatesRoomsList: action.payload};
        case 'SET_TEMPLATES_ROOMS_LIST_TOTAL_COUNT':
            return {...state, templatesRoomsListTotalCount: action.payload};

        default:
            return state;
    }
}

export const RoomProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <RoomsStore.Provider value={value}>{props.children}</RoomsStore.Provider>;
};