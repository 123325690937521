// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    roles: {},
    updateRolesList: true
};

export const RolesStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_ROLES':
            return {...state, roles: action.payload};

        case 'SET_PERMISSIONS':
            return {...state, roles: {...state.roles, [action.id] : {...state.roles[action.id], editedPermissions: action.payload}}};
        case 'SET_ROLE_NOT_EDITABLE':
            return {...state, roles: {...state.roles, [action.id] : {...state.roles[action.id], disabled: action.payload}}};
        case 'UPDATE_ROLES_LIST':
            return {...state, updateRolesList: action.payload};

        default:
            return state;
    }
}

export const RolesProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <RolesStore.Provider value={value}>{props.children}</RolesStore.Provider>;
};