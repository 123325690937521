// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useDropzone} from "react-dropzone";
import {useStyles} from "../../../style";
import Utils from "../../../../../scripts/Utils";
import {createResource} from "../../../../../api/Requests";
import {Store} from "../../../../../stores/MainStore";
import {CMSStore} from "../../../../../stores/CMSStore";

// MUI
import Image from 'material-ui-image';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import {MyAccordion, MyAccordionDetails} from "../../../../../components/styled/StyledAccordion";
import SimpleCustomTable from "../../../../../components/table/SimpleCustomTable";
import {MyItem} from "../components/MySortableTable";
import {StyledButton} from "../../../../../components/styled/StyledButton";

export default function DirectUpload () {
	const navigate = useNavigate();
	const {roomId = null} = useParams();
	const classes = useStyles();
	const {acceptedFiles, getRootProps, getInputProps} = useDropzone();

	// Context
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(CMSStore);

    // Base state
	const [files, setFiles] = useState([]);
    const [feedbackTable, setFeedbackTable] = useState({});
    const [isUploading, setIsUploading] = useState(false);
	const [isDone, setIsDone] = useState(false);

    // Handlers
    const onError = error => {
    	mainDispatch({
			type: "SET_MODAL_SETTINGS",
			payload: {
				show: true,
				title: "Error!",
				text: error.message,
				color: "default"
			}
		});
	};
    const onDelete = index => {
		const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
	};
	const onClear = () => {
		setFiles([]);
        setIsUploading(false);
        setIsDone(false);
        setFeedbackTable({});
	};
	const onUploadFiles = async () => {
		setIsUploading(true);
        dispatch({type: "SET_CAN_LEAVE", payload: false});
        setIsDone(true);

        setFiles([]);

        for (let i = 0; i < files.length; i++) {
            setItemToFeedbackTable({
                id: i,
                image: URL.createObjectURL(files[i]),
                title: files[i].name,
                status: "Uploading..."
            });
        }

        await Utils.awaitAll(files, async (file, index) => {
            await createResource(file, Utils.getNetwork(), onError, {contributeToRoom: roomId});
            setItemToFeedbackTable({id: index, image: URL.createObjectURL(file), title: file.name, status: "Finished"});
        });

        setIsUploading(false);
        dispatch({type: "SET_CAN_LEAVE", payload: true});
	};

	// Main functions
	const goBack = () => navigate(-1);
	const setItemToFeedbackTable = item => setFeedbackTable(prevState => ({...prevState, [item?.id]: item}));

    // Effects
	useEffect(() => {
		setFiles(prevState => [...prevState, ...acceptedFiles]);
	}, [acceptedFiles]);


    return (
		<div className="container-fluid card p-3">
			<section className="content">
				<div className="container-fluid">
					<div className="d-flex flex-column pt-3">

						{!isDone && <div className={classes.uploadFromPC}>
							<div {...getRootProps({className: 'dropzone'})}>
								<input {...getInputProps()} />
								<p>Drag 'n' drop some files here, or click to select files</p>
							</div>
						</div>}

						{(Object.values(feedbackTable))?.length > 0 && (
							<div className="py-3">
								<MyAccordion square defaultExpanded={true}>
									<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
										<h5 style={{marginBottom: 0}}>Resource upload feedback table</h5>
									</AccordionSummary>
									<MyAccordionDetails>
										<div id="table-container">
											<SimpleCustomTable data={Object.values(feedbackTable).map(el => el)}
															   columns={[
																   {
																	   name: "image",
																	   header: "Image",
																	   cell: rowData => (
																		   <Image src={rowData?.image} imageStyle={{
																			   position: "initial",
																			   width: "6rem", height: "6rem",
																			   objectFit: "cover",
																		   }} style={{
																			   display: "flex",
																			   justifyContent: "center",
																			   background: "transparent",
																			   padding: 0
																		   }}/>
																	   ),
																	   isCustom: true
																   },
																   {
																	   name: "resourceName",
																	   header: "Resource name",
																	   cell: rowData => rowData?.title
																   },
																   {
																	   name: "status",
																	   header: "Status",
																	   cell: rowData => rowData?.status
																   },
															   ]}/>
										</div>
									</MyAccordionDetails>
								</MyAccordion>
							</div>)}

						{files.length > 0 && (
							<div className={classes.uploadedFiles}>
								{files.map((file, index) =>
									<MyItem key={`item-${index}`} disabled onError={onError}
													isCustom={true} callbacks={{onDeleteCustom: () => onDelete(index)}}
													value={{
														id: "",
														title: file.name,
														filePreview: URL.createObjectURL(file),
														metadata: {
															fileName: file.name,
															contentType: file.type
														}
													}}/>
								)}
							</div>)}

						<div className="mt-4 d-flex justify-content-between align-items-center">
							<div className="controls">
								{!isDone && (`Files on request: ${files.length ?? 0}`)}
							</div>

							{
								!isDone
									? (files?.length > 0
									? (
										<div className="d-flex">
											<StyledButton title="Clear queue" type="default" noLoading
														  onClick={() => setFiles([])} size="lg"/>
											<div className="ml-3">
												<StyledButton onClick={onUploadFiles} type="warning"
															  title={isUploading ? "Uploading..." : "Upload files"}
															  size="lg" disabled={isUploading}/>
											</div>
										</div>
									)
									: null)
									: (
										<div className="d-flex">
											<StyledButton title="Clear and upload more" type="warning" noLoading
														  disabled={!state?.canLeave}
														  onClick={onClear} size="lg"/>
											<div className="ml-3">
												<StyledButton title="Back to content overview" type="secondary" noLoading
															  disabled={!state?.canLeave}
															  onClick={goBack} size="lg"/>
											</div>
										</div>
									)
							}
						</div>

					</div>
				</div>
			</section>
		</div>
    );
}