// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class GamificationRankingAPI {
    static async getGamificationRanking (body, onError) {
        return await customFetch({path: "/jsonRpc/gamification.getGamificationRanking", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchGamificationRanking (body, onError) {
        return await customFetch({path: "/jsonRpc/gamification.searchGamificationRanking", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async deleteUserGamificationRanking (userId, onError) {
        return await customFetch({path: "/jsonRpc/gamification.deleteUserGamificationRanking", body: {userId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async deleteAllGamificationRankings (onError) {
        return await customFetch({path: "/jsonRpc/gamification.deleteAllGamificationRankings", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}