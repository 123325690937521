// Copyright © 2015-2023 Roomful Co. All rights reserved

import Cookies from 'js-cookie';
import Utils from "../scripts/Utils";
import {API} from "./API";

const defaultHeaders = {
    'X-Network': Cookies.get('networkId') || "roomful",
};

export class CustomResponse {

    result = null;
    value = null;
    status = 400;
    ok = true;

    constructor(value, status, ok) {
        this.value = value;
        this.status = status;
        this.ok = ok;
    }

    observe() {
        if(!this.ok) throw {message: this.value};

        if(this.result) return this.result;

        try {
            this.result = JSON.parse(this.value);
        } catch(e) {
            throw {message: this.value, status: 404, ok: false};
        }

        if(this.result.error.status) {
            throw {message: this.result.error.message, status: this.result.error.status, ok: false};
        }

        return this.result;
    }

    json() {
        return this.observe();
    }

    text() {
        return this.observe();
    }
}

export const customFetchOld = async settings => {
    const {path, method = "POST", body = {}, headers : userHeaders, params, network, withoutNetwork, noSession} = settings;

    const networkHeaders = network ? {'X-Network' : network} : defaultHeaders;
    const contentTypeHeaders = userHeaders && userHeaders["Content-Type"] ? {} : {'Content-Type': 'application/json'};

    return fetch(Utils.buildURLs(path, withoutNetwork, noSession, params), {
        method,
        headers: {
            ...(Cookies.get('sessionId') ? {'X-Session-Id': Cookies.get('sessionId')} : {}),
            ...networkHeaders,
            ...userHeaders,
            ...contentTypeHeaders
        },
        body: method === "POST" ? JSON.stringify(body) : null
    });
};

export const customFetch = async settings => {
    const {path, method = "POST", body = {}, headers : userHeaders, params, network, withoutNetwork, noSession} = settings;

    const networkHeaders = network ? {'X-Network' : network} : defaultHeaders;
    const contentTypeHeaders = userHeaders && userHeaders["Content-Type"] ? {} : {'Content-Type': 'application/json'};

    const result = await fetch(Utils.buildURLs(path, withoutNetwork, noSession, params), {
        method,
        headers: {
            ...(Cookies.get('sessionId') ? {'X-Session-Id': Cookies.get('sessionId')} : {}),
            ...networkHeaders,
            ...userHeaders,
            ...contentTypeHeaders
        },
        body:    method === "POST" ? JSON.stringify(body) : null
    });

    if(result.status === 401) {
        window.location.href = "/auth";
    }

    if(result.status < 200 || result.status >= 400) {
        throw {message: await result.text(), status: result.status, ok: result.ok};
    }

    return new CustomResponse(await result.text(), result.status, result.ok);
};

export const parseSpreadsheetRequest = async settings => {
    const {path, body = {}} = settings;

    const formData = new FormData();
    formData.append("file", body);

    return fetch(Utils.buildURLs(path), {
        method: "POST",
        headers: {
            ...defaultHeaders,
        },
        body: formData
    });
};

export const createResource = async (file, networkId = "roomful", onError, customBodyParams = {}) => {
    const resourceMetadata = {
        resource: {
            metadata: {
                fileName: file.name,
                fileSize: file.size,
                contentType: file.type
            }
        },
        ...customBodyParams
    };

    const resource = (await API.Resource.createResourceMetadata(resourceMetadata, networkId, onError)).data.resource;
    const url = await API.Resource.getLink(resource.id, networkId, onError);

    const directUploadToGoogleBucket = async file => {
        return new Promise(resolve => {
            let reader = new FileReader();

            reader.onload = function () {
                const arrayBuffer = this.result;

                resolve(arrayBuffer);
            };
            reader.onerror = onError;

            reader.readAsArrayBuffer(file);
        })
    };

    const arrayBuffer = await directUploadToGoogleBucket(file);

    await API.Resource.directUploadToGoogleBucket(url, arrayBuffer, file.type, onError);

    await API.Resource.completeUpload(resource.id, networkId, onError);

    return resource.id;
};