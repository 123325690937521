// Copyright © 2015-2022 Roomful Co. All rights reserved

import Notification from "../assets/audio/notification.wav";

export class Sounds {
    static context = null;
    static tracks = {};

    static initTracks () {
        this.tracks["notification"] = Notification;
    }

    static getTrack = trackName => this.tracks[trackName];

    static async play (trackName, volume = 3) {
        if (this.context) this.context?.close();
        this.context = new AudioContext();
        const gainNode = this.context?.createGain();

        const source = this.context?.createBufferSource();

        source.buffer = await fetch(this.getTrack(trackName))
            .then(res => res.arrayBuffer())
            .then(arrayBuffer => this.context?.decodeAudioData(arrayBuffer));

        source.connect(gainNode);
        gainNode.connect(this.context?.destination);
        gainNode.gain.setValueAtTime(volume, this.context?.currentTime);

        source.connect(this.context?.destination);
        source.start();
    }
}
