// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {useStyles} from "../style";
import {ChatsStore} from "../../../stores/ChatsStore";
import Utils from "../../../scripts/Utils";
import Image from 'material-ui-image';
import {useNavigate} from "react-router-dom";

// MUI
import {IconButton} from "@material-ui/core";
import {MoreVert} from "@material-ui/icons";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {ChatShowParticipants} from "../dialogs/ChatShowParticipants";
import CircularProgress from "@material-ui/core/CircularProgress";
import {initialState, Store} from "../../../stores/MainStore";

export const ChatHeader = ({onError}) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(ChatsStore);
    const classes = useStyles();
    const navigate = useNavigate();

    const [show, setShow] = useState(false);

    const showParticipants = () => {
        dispatch({type: "SET_SHOW_PARTICIPANTS", payload: true});
        setShow(false);
    };

    const getChatMode = type => {
        if (type === "direct") return "Direct chat";
        else if (type === "room") return "Room chat";
        else if (type === "prop") return "Prop chat";
        else if (type === "booth") return "Booth chat";
        else if (type === "social circle") return "Social circle chat";
        else return "";
    };
    const getChatThumbnail = type => {
        if (type === "direct") return state.currentInterlocutor?.user?.avatar;
        else return state.currentTextchat?.thumbnail;
    };

    const openProfile = () => {
        if (state.currentTextchat?.textchatMode === "direct")
            navigate(`/user/${state.currentInterlocutor?.user?.id}`);
        else if (state.currentTextchat?.textchatMode === "room")
            navigate(`/room/${state.currentTextchat?.textchatSource?.roomId}`);
    };

    const setIsImportant = async isImportant => {
        const currentEvent = isImportant ? "textchat:setTextchatAsNotImportant" : "textchat:setTextchatAsImportant";
        const {error} = (await state.connection.sendSocketRequest(currentEvent, {data: {textchatId: state.currentTextchat?.id}}));

        if (error?.code !== 200) console.error(isImportant, error);

        setShow(false);
        dispatch({type: "SET_TEXTCHAT_IS_IMPORTANT", id: state.currentTextchat?.id, payload: !isImportant});
    };
    const deleteChat = async () => {
        setShow(false);
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Warning!",
                text: `Are you sure you want to delete chat with "${state.currentInterlocutor?.user?.firstName} ${state.currentInterlocutor?.user?.lastName}"? This operation cannot be restored.`,
                color: "danger",
                onCancel: () => {
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                },
                useCustomOnCancelFunction: true,
                useCustomCancelText: "Cancel",
                onOk: async () => {
                    const {error} = (await state.connection.sendSocketRequest("textchat:deleteTextchat", {data: {textchatId: state.currentTextchat?.id}}));

                    if (error?.code !== 200) console.error("delete chat", error);

                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                    dispatch({type: "DELETE_TEXTCHAT", payload: state.currentTextchat?.id});
                    dispatch({type: "CLEAR_CURRENT_TEXTCHAT"});
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };

    return (
        (!Utils.isObjectEmpty(state.currentInterlocutor ?? {}) || !Utils.isObjectEmpty(state.currentTextchat ?? {})) ?
        (<div className="h-100 px-3 d-flex justify-content-between align-items-center">
            <span className="d-inline-flex align-items-center position-relative w-100 h-100" style={{cursor: "initial"}}>
                <Image className="contacts-list-img" alt="User Avatar" imageStyle={{width: "2.5rem", height: "2.5rem"}}
                       style={{width: "2.5rem", height: "2.5rem", padding: 0, borderRadius: "50%", float: "left", marginRight: "1.25em"}}
                       src={Utils.buildURLs(`/resource/${getChatThumbnail(state.currentTextchat?.textchatSourceType)}`,
                           false, null, {networkId: Utils.getNetwork()})}
                       loading={<CircularProgress/>}
                />

                <div className={`contacts-list-info ${classes.primaryColor} flex-column ml-2`}>
                    <span className="contacts-list-name" onClick={openProfile}>
                        {state.currentTextchat?.title
                            ? `${getChatMode(state.currentTextchat?.textchatSourceType)}: ${state.currentTextchat?.title}`
                            : `${state.currentInterlocutor?.user?.firstName} ${state.currentInterlocutor?.user?.lastName}`}
                    </span>
                    {/*<span style={{color: "#28a745"}}>Online &#9679;</span>*/}
                </div>

                {!Utils.isObjectEmpty(state.participants) && state.currentTextchat?.textchatSource?.roomId?.length > 0 && (
                    <span className="ml-5 d-flex align-items-center position-absolute" style={{top: 0, right: "3.75em", bottom: 0}}>
                        <StyledButton onClick={showParticipants} type="primary" title="Show participants" size="sm" outline={true}/>
                        <ChatShowParticipants onError={onError}/>
                    </span>
                )}

                <div className={`input-group ${classes.headerDots} h-100`}>
                    <div className="input-group-prepend position-relative" style={{width: "2.5rem"}}>
                        <IconButton component="span" className={classes.headerDots} onClick={() => setShow(true)}><MoreVert/></IconButton>

                        <div id="pad" className={`${show ? "show" : ""} ${classes.pad}`} onClick={() => setShow(false)}/>
                        <div className={`dropdown-menu ${show ? "show" : ""} ${classes.filtersDropdownMenu}`} style={{transform: "translateX(-100%)"}}>
                            {state.currentTextchat?.textchatMode === "direct" && <a className="dropdown-item"
                                onClick={() => navigate(`/user/${state.currentInterlocutor?.user?.id}`)}>Go to user
                                profile</a>}
                            <a className="dropdown-item" onClick={e => setIsImportant(state.currentTextchat?.isImportant)}>
                                Mark as {state.currentTextchat?.isImportant ? "un" : ""}important
                            </a>
                            {state.currentTextchat?.textchatSourceType === "direct" && <a className="dropdown-item" onClick={deleteChat}>Delete</a>}
                        </div>
                    </div>
                </div>

            </span>
        </div>) : ""
    );
}