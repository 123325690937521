// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Select from "react-select";
import {useStyles} from "../../Chats/style";

// Components
import {Checkbox} from "@material-ui/core";
import {customStyles} from "../../../styles/dropdown";
import {UserRoomsList} from "../../../components/list/new/UserRoomsList";
import {UserPropsList, UserPropsListUncontrolled} from "../../../components/list/new/UserPropsList";
import {Done, Tune} from "@material-ui/icons";
import Utils from "../../../scripts/Utils";
import {StyledButton} from "../../../components/styled/StyledButton";
import {useStateSafe} from "../../../scripts/SaveUseState";
import {CMSStore} from "../../../stores/CMSStore";
import {PropsStore} from "../../../stores/PropsStore";
import {RoomsStore} from "../../../stores/RoomsStore";
import {UsersStore} from "../../../stores/UsersStore";
import {AllRoomsList} from "../../../components/list/new/AllRoomsList";
import {AllPropsList, AllPropsListUncontrolled} from "../../../components/list/new/AllPropsList";

export default function Filters (props) {
    const {
        preSelectedFirstValue = false,
        prop, roomId = null,
        onPropSelected = () => false, onFiltersChanged = s => false,
        hideSelect = false, settings = {}
    } = props;
    const {
        fullWidth = false,
        leftDropdownMenu = false,
        defaultAsPropManager = null,
        withImage = false,
        useUncontrolled = false,
        doNotClearSelectedItems = false,
        withoutRoomSelector = false
    } = settings;

    const {state, dispatch} = useContext(CMSStore);
    const {dispatch: propsDispatch} = useContext(PropsStore);
    const {dispatch: roomsDispatch} = useContext(RoomsStore);
    const {state: usersState} = useContext(UsersStore);
    const classes = useStyles();

    const isSuperAdmin = () => Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = () => Utils.isNetworkAdmin(usersState.myRoles);

    const [showConfigMenu, setShowConfigMenu] = useStateSafe(false);

    const [showOnlyTeamMemberObjects, setShowOnlyTeamMemberObjects] = useStateSafe(defaultAsPropManager ? defaultAsPropManager : false);
    const [objectType, setObjectType] = useStateSafe({label: "Booth and File cabinet", value: ["booth", "fileCabinet"]});

    const [selectedProp, setProp] = useStateSafe({});
    const onPropChange = selected => setProp(selected);

    const [room, setRoom] = useStateSafe({});
    const onRoomChange = selected => setRoom(selected);

    const [customBody, setCustomBody] = useStateSafe({
        asPropManager: showOnlyTeamMemberObjects,
        propTypes: objectType?.value,
        roomId: room?.id && room?.id !== "all" ? room?.id : ""
    });

    // Effects
    useEffect(() => {
        onPropSelected(selectedProp);
    }, [selectedProp]);

    useEffect(() => {
        if (roomId && roomId?.length > 0) {
            console.log("updating...", {...customBody, roomId});
            setCustomBody({...customBody, roomId});
        }
    }, [roomId]);

    const applyFilters = useCallback(() => {
        const settings = {
            asPropManager: showOnlyTeamMemberObjects,
            propTypes: objectType?.value,
            roomId: room?.id && room?.id !== "all" ? room?.id : ""
        };

        if (!doNotClearSelectedItems) dispatch({type: "CLEAR_SELECTED_ITEMS"});
        setCustomBody(settings);
        onFiltersChanged(settings);
        setShowConfigMenu(false);
    }, [objectType?.value, room?.id, showOnlyTeamMemberObjects, onFiltersChanged]);

    return (
        <div className="d-flex align-items-center" style={{width: fullWidth ? "100%" : "65%"}}>
            <div className={`w-100 mt-2 d-flex ${hideSelect ? "flex-row align-items-start" : "flex-column"}`}>
                {!hideSelect && <h6>Select prop:</h6>}
                <div className={`d-flex align-items-center ${hideSelect ? "mr-3" : ""}`}>
                    {!hideSelect && (
                        isSuperAdmin() || isNetworkAdmin()
                        ? (
                            useUncontrolled
                                ? <AllPropsListUncontrolled onChange={onPropChange} customBody={customBody}/>
                                : <AllPropsList prop={prop} preSelectedFirstValue={preSelectedFirstValue} withImage={withImage} onChange={onPropChange} customBody={customBody}/>
                        ) : (
                            useUncontrolled
                            ? <UserPropsListUncontrolled onChange={onPropChange} customBody={customBody}/>
                            : <UserPropsList prop={prop} preSelectedFirstValue={preSelectedFirstValue} withImage={withImage} onChange={onPropChange} customBody={customBody}/>
                        )
                    )}

                    <div className={`input-group-prepend position-relative ${!hideSelect ? "ml-2" : "w-100"}`}>
                        <div className={`d-flex align-items-center pl-3 ${hideSelect ? "py-2" : ""} pr-4 ${classes.filterButton}`} style={{height: withImage ? "3.5rem" : "2.5rem"}}
                              onClick={() => setShowConfigMenu(true)}>
                            <Tune style={{color: "hsl(0, 0%, 50%)"}}/>
                            <h6 className="mb-0 ml-2">Filters</h6>
                        </div>

                        <div id="pad" className={`${showConfigMenu ? "show" : ""} ${classes.pad}`}
                             onClick={() => setShowConfigMenu(false)}/>

                        <div className={`dropdown-menu ${showConfigMenu ? "show" : ""} ${classes.filtersDropdownMenu} ${leftDropdownMenu ? "left-menu" : ""}`}
                             style={{width: `${Utils.RemToPx(25)}px`}}>
                            <div className={`row flex-column m-0 p-3`}>
                                {!withoutRoomSelector && <div className="w-100 mt-2 d-flex flex-column">
                                    <h6>Select room:</h6>
                                    {
                                        isSuperAdmin() || isNetworkAdmin()
                                            ? <AllRoomsList onChange={onRoomChange} allowAll={true} canType={false}/>
                                            : <UserRoomsList onChange={onRoomChange} allowAll={true} canType={false}/>
                                    }
                                </div>}
                                <div className={`w-100 mt-${!withoutRoomSelector ? 3 : 0} mb-3 d-flex flex-column`}>
                                    <h6>Select prop type:</h6>
                                    <Select value={objectType} onChange={selected => setObjectType(selected)}
                                            options={["All", "Booth", "File cabinet", "Booth and File cabinet"].map((el, index) => ({
                                                label: el,
                                                value: (
                                                    index === 0 ? [] :
                                                        index === 1 ? ["booth"] :
                                                            index === 2 ? ["fileCabinet"] :
                                                                index === 3 ? ["booth", "fileCabinet"] : []
                                                )}
                                                ))}
                                            styles={customStyles(true)}/>
                                </div>
                                <div className="w-100 d-flex flex-column align-items-start">
                                    <h6 className="mb-0">Show only props administrated by me:</h6>
                                    <div className="d-flex align-items-center">
                                        <Checkbox checked={showOnlyTeamMemberObjects} color="primary"
                                                  onChange={e => setShowOnlyTeamMemberObjects(e.target.checked)}/>
                                        <b>Yes</b>
                                    </div>
                                </div>
                                <div className={`w-100 d-flex justify-content-end`}>
                                    <StyledButton onClick={applyFilters} type="success"
                                                  title={<><h6 className="mb-1 mr-2">Apply</h6> <Done/></>}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`d-flex align-items-center position-relative mx-0 ${classes.activeFilters} border-top-0`}>
                    {showOnlyTeamMemberObjects && <span>Administrated by me</span>}
                    {room?.id !== "all" ? <span>Room: {room?.metadata?.name} (#{room?.id})</span> : <span>In all rooms</span>}
                    <span>Prop type: {objectType?.value.map(el => el === "" ? "all" : Utils.unCamelize(el, " ")).join(", ")}</span>
                </div>
            </div>
        </div>
    );
}