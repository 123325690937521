// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useState} from "react";
import { Link } from "react-router-dom";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";

export const StyledButton = props => {
    const {
        title,
        type,
        size,
        outline = false,
        fullWidth,
        disabled,
        onClick,
        style,
        noLoading,
        id,
        className,
        elRef,
        tooltip = null,
        href = ""
    } = props;

    const fullWidthClass = fullWidth ? "btn-block" : "";
    const sizeClass = size ? `btn-${size}` : "";
    const disabledClass = disabled ? "disabled" : "";

    const classes = `btn ${fullWidthClass} ${outline ? `btn-outline-${type}` : `btn-${type}`} ${sizeClass} ${disabledClass} d-flex justify-content-center align-items-center`;

    const isLink = href !== "";

    const onLinkClick = onClick && isLink ? e => {
        e.preventDefault();
        onClick(e);
    } : null;

    return (
        isLink
            ? (
                <Link to={href} onClick={disabled ? () => false : onLinkClick} {...(style ? {style} : {})}
                   ref={elRef ?? null} id={id} title={tooltip}
                   className={className ?? classes}>{title}</Link>
            )
            : (
                <button id={id} type="button" onClick={disabled ? () => false : onClick} {...(style ? {style} : {})}
                        ref={elRef ?? null} className={className ?? classes} title={tooltip}>
                    {title}
                    {disabled && !noLoading ? <CircularProgress size={18} className="ml-3" style={{color: "white"}}/> : null}
                </button>
            )
    );
};

export const StyledDownloadButton = props => {
    const {title, type, size, fullWidth, disabled, download, href} = props;

    const fullWidthClass = fullWidth ? "btn-block" : "";
    const sizeClass = size ? `btn-${size}` : "";
    const disabledClass = disabled ? "disabled" : "";

    return (
        <a href={href} target="_blank" download={download}
           className={`btn ${fullWidthClass} btn-${type} ${sizeClass} ${disabledClass}`}>{title}</a>
    );
};

export const StyledButtonWithOptions = props => {
    const {title, type, size, fullWidth, disabled, options, CustomButton = false, activeElement} = props;
    const [show, setShow] = useState(false);

    const fullWidthClass = fullWidth ? "btn-block" : "";
    const sizeClass = size ? `btn-${size}` : "";
    const disabledClass = disabled ? "disabled" : "";

    return (
        <div className="input-group">
            <div className="input-group-prepend">
                {
                    CustomButton ? <CustomButton onClick={disabled ? () => false : () => setShow(!show)}/> : (
                        <button type="button" data-toggle="dropdown"
                                onClick={disabled ? () => false : () => setShow(!show)}
                                style={{borderTopRightRadius: "0.25rem", borderBottomRightRadius: "0.25rem"}}
                                className={`btn ${fullWidthClass} dropdown-toggle btn-${type} ${sizeClass} ${disabledClass}`}>
                            {title}
                        </button>
                    )
                }
                <div className={`dropdown-menu ${show ? "show" : ""}`}>
                    {
                        options && typeof options !== "undefined" && options.map((el, index) =>
                            el.divider
                                ? <div className="dropdown-divider" key={index}/>
                                : <a className={`dropdown-item ${activeElement === index ? "active" : ""}`}
                                     href="" onClick={disabled ? () => false : e => {
                                    e.preventDefault();
                                    if (!el.noForceClose) setShow(!show);
                                    el.onClick && typeof el.onClick === "function" && el.onClick(e, setShow);
                                }} key={index}>{el.title}</a>
                        )
                    }
                </div>
            </div>
        </div>
    );
};