// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useCallback, useContext, useEffect, useState } from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {FeedbackTable} from "../../../components/table/FeedbackTable";
import {ActiveUsers} from "../../../components/list/Users/ActiveUsers";

export const NetworkSubscribeUsers = props => {
    const {onError} = props;
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);

    // Base state
    const [users, setUsers] = useState([]);
    const [subscribeBtnLoading, setSubscribeBtnLoading] = useState(false);

    // Main functions
    const getColumns = () => ([
        {
            name: "userId",
            header: "User id",
            cell: rowData => `#${rowData?.id}`
        },
        {
            name: "status",
            header: "Status",
            cell: rowData => rowData?.status
        }
    ]);
    const getData = useCallback(() => state?.feedbackTableItems?.subscribedUsers, [state?.feedbackTableItems?.subscribedUsers]);

    // Callbacks
    const getItems = useCallback(() => users, [users]);
    const getResult = async user => {
        const networkId = state?.openedNetwork?.model?.id || state?.currentNetworkInfo?.network?.id;
        const result = await API.Users.subscribeUserToNetwork(networkId, user.id, onError);

        return (!Utils.isObjectEmpty(user) && result?.error?.code === 200
            ? {error: {code: 200}, feedback: {id: user?.id, status: "success"}}
            : {error: result?.error});
    };
    const onFinish = () => {
        dispatch({type: "SET_FEEDBACK_NEED_RENDER", key: "isSubscribedUsersNeedRender", payload: false});
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "All users subscribed correctly!",
                text: "See results in the table below.",
                color: "success"
            }
        });
        setSubscribeBtnLoading(false);
    };
    const addItemToFeedbackTable = (item, user) => {
        dispatch({type: "SET_FEEDBACK_TABLE_ITEMS", key: "subscribedUsers", payload: {...item, id: user?.id}})
    };
    const subscribe = () => {
        setSubscribeBtnLoading(true);

        dispatch({type: "SET_SHOW_FEEDBACK", key: "showSubscribedUsersFeedback", payload: true});
        if (state?.feedbackTableItems?.subscribedUsers?.length > 0) {
            dispatch({type: "RESET_FEEDBACK_TABLE_ITEMS", key: "subscribedUsers"});
            dispatch({type: "SET_FEEDBACK_NEED_RENDER", key: "isSubscribedUsersNeedRender", payload: true});
        }
    };

    useEffect(() => {
        return () => {
            dispatch({type: "RESET_FEEDBACK_TABLE_ITEMS", key: "subscribedUsers", });
            dispatch({type: "SET_SHOW_FEEDBACK", key: "showSubscribedUsersFeedback", payload: false});
        };
    }, []);

    return (
        <>
            <h4 className="my-4">Subscribe users</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0 justify-content-start align-items-center">
                        <div className="col-10">
                            <ActiveUsers user={users} onChange={selectedUsers => setUsers(selectedUsers)}
                                         networkId="roomful" settings={{multiple: true}}/>
                        </div>

                        <div className="col-2 d-flex justify-content-start">
                            <StyledButton onClick={subscribe} title="Subscribe" type="success"
                                          disabled={subscribeBtnLoading} noLoading={!subscribeBtnLoading}/>
                        </div>
                    </div>
                    <p className="mt-3 text-muted">Search user and subscribe users to network</p>

                    <FeedbackTable show={state?.feedbackTableItems?.showSubscribedUsersFeedback} settings={{needSubLoop: false}}
                           callbacks={{onError, getColumns, getData, getItems, getResult, addItemToFeedbackTable, onFinish}}
                           texts={{title: "Subscribed users feedback table", progressBarTitles: ["Subscribed : "]}}
                    />

                </div>

                <hr/>
            </div>
        </>
    );
}
