// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {initialState, Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {GamificationRankingStore} from "../../stores/GamificationRankingStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {StyledButton} from "../../components/styled/StyledButton";
import CustomTable from "../../components/table/CustomTable";
import {StyledImage} from "../../components/styled/StyledImage";

export default function GamificationRanking() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state: usersState} = useContext(UsersStore);
    const {state, dispatch} = useContext(GamificationRankingStore);

    const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(usersState.myRoles);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // One-row functions
    const deleteUserRanking = (userId, name) => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Warning!",
                text: `Do you want to delete ${name} ranking?`,
                color: "warning",
                onCancel: () => mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal}),
                useCustomOnCancelFunction: true,
                onOk: async () => {
                    const result = await API.GamificationRanking.deleteUserGamificationRanking(userId, onError);

                    const newList = state?.list?.filter(el => el?.user?.id !== userId);
                    dispatch({type: "SET_LIST", payload: newList});

                    if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };
    const deleteAllRanking = async () => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Warning!",
                text: `Do you want to delete all users ranking?`,
                color: "warning",
                onCancel: () => mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal}),
                useCustomOnCancelFunction: true,
                onOk: async () => {
                    const result = await API.GamificationRanking.deleteAllGamificationRankings(onError);

                    dispatch({type: "SET_LIST", payload: []});

                    if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'avatar',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Avatar</span>,
            cell: rowData =>
                rowData?.user?.avatar && rowData?.user?.avatar?.length > 0
                    ? <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.user?.avatar}`,
                    false, null, {networkId: Utils.getNetwork()})} size={5}/>
                    : null,
            width: Utils.RemToPx(9)
        },
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.user?.id}</span>
                </div>
            )
        },
        {
            name: 'name',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{`${rowData?.user?.firstName} ${rowData?.user?.lastName}`}</span>
                </div>
            )
        },
        {
            name: 'rank',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Rank</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.rank}
                </span>
            )
        },
        {
            name: 'points',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Points</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.points}
                </span>
            )
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-wrap">
                    {(isSuperAdmin || isNetworkAdmin || rowData?.user?.id === usersState?.model?.id) && (
                        <StyledButton className="btn btn-sm bg-danger h-100 mr-2"
                                      title={<i className="fas fa-trash-alt"/>} tooltip="Delete ranking"
                                      onClick={() => deleteUserRanking(rowData?.user?.id, `${rowData?.user?.firstName} ${rowData?.user?.lastName}`)}/>
                    )}
                </div>
            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = useCallback(async({offset, size, text}, onError) => {
        const {ranking = {}} = (
            await (typeof text === "string" && text.length > 0
                    ? API.GamificationRanking.searchGamificationRanking({offset, size, text}, onError)
                    : API.GamificationRanking.getGamificationRanking({offset, size}, onError)
            )
        ).data;
        const {top = [], own = {}, total = 0} = ranking;

        dispatch({type: "SET_LIST", payload: top});
        dispatch({type: "SET_TOTAL_COUNT", payload: total});

        return total ?? 0;
    }, []);

    const clearList = () => dispatch({type: "SET_LIST", payload: []});

    useEffect(() => {
        return () => {
            clearList();
        };
    }, []);

    return (
        <div className="content-wrapper">

            <ContentHeader title="Gamification ranking" breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Gamification ranking",
                    link: `/gamificationRanking`,
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">

                    {(isSuperAdmin || isNetworkAdmin) && (
                        <div className="card card-body d-flex flex-column">
                            <h5 className="mb-4">Admin functions:</h5>
                            <div>
                                <StyledButton className="btn btn-sm bg-danger h-100 mr-2"
                                              title={<><i className="fas fa-trash-alt mr-3"/>Delete all ranking </>}
                                              tooltip="Delete all ranking"
                                              onClick={deleteAllRanking}/>
                            </div>
                        </div>)}

                    <div id="table-container" className="mr-3">
                        <CustomTable state={{canSelect: false, canHover: false, canSearch: true, pagination: true}}
                                     callbacks={{getList, getColumns, getListItems, clearLists: clearList, onError}}
                                     texts={{noItems: "No users..."}}/>
                    </div>
                </div>
            </section>
        </div>
    );
}