import React, {useContext, useEffect, useState} from 'react';
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import {makeStyles} from "@material-ui/core";
import {Line} from '@reactchartjs/react-chart.js';

// Components
import {AllRooms} from "../../../components/list/Rooms/AllRooms";
import {UserRooms} from "../../../components/list/Rooms/UserRooms";

const buildInfo = (labels, data) => ({
    labels,
    datasets: [
        {
            data,
            fill: false,
            backgroundColor: 'rgba(40,167,69,0.9)',
            borderColor: 'rgba(40,167,69,0.8)',
        },
    ],
});
const options = {
    legend: {
        display: false
    },
    scales: {
        yAxes: [
            {
                ticks: {
                    beginAtZero: true,
                    stepSize: 1
                },
            },
        ],
    },
};

const useStyles = makeStyles((theme) => ({
    propChartHeader: {
        display: "flex",
        justifyContent: "space-between",

        "&::after": {
            content: "unset"
        }
    }
}));

export const RoomCharts = props => {
    const {onError} = props;

    const classes = useStyles();

    const {state} = useContext(UsersStore);

    const isRoomAdmin = () => Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = () => Utils.isPropAdmin(state.myRoles);

    const [room, setRoom] = useState(null);
    const [chartData, setChartData] = useState([]);

    useEffect(() => {
        (async () => {
            if (room && room?.id?.length > 0) {
                const {data, error} = await API.Analytics.getRoomsVisits({
                    localDate: Utils.getTime(),
                    roomId: room?.id
                }, Utils.getNetwork(), onError);
                if (error.code === 200) setChartData(data.roomVisitsForLastWeek);
                else onError(new Error(error.message));
            }
        })();
    }, [room?.id]);

    return (
        <>
            <div className={`row ${chartData?.length > 0 ? "mb-5" : ""}`}>
                <div className="col-7">
                    <div className="d-flex align-items-center w-100">
                        <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select room: </h6>
                        {isRoomAdmin() || isPropAdmin()
                            ? <UserRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} networkId={Utils.getNetwork()}/>
                            : <AllRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} networkId={Utils.getNetwork()}/>}
                    </div>
                </div>
            </div>

            <div className="row justify-content-between mx-0">

                {room && room?.id?.length > 0 && Utils.hasPermission(state.myRoles, "room.manage", [Utils.getNetwork(), room?.id])
                    && chartData?.length > 0 &&

                <div className="card card-success px-0" style={{width: "48%"}}>
                    <div className={`card-header ${classes.propChartHeader} d-flex justify-content-between`}>
                        <div>
                            <h2 className="card-title mr-2">Room visits</h2>
                            <small>for the last week</small>
                        </div>
                        <div className="d-inline-flex flex-column">
                            <span>Room: {room?.metadata?.name}</span>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="chart">
                            <div className="chartjs-size-monitor">
                                <div className="chartjs-size-monitor-expand">
                                    <div className=""/>
                                </div>
                                <div className="chartjs-size-monitor-shrink">
                                    <div className=""/>
                                </div>
                            </div>
                            <Line data={buildInfo(Utils.getLast7Days(), chartData)} options={options}/>
                        </div>
                    </div>
                </div>}

            </div>
        </>
    )
};
