// Copyright © 2015-2022 Roomful Co. All rights reserved

import {makeStyles} from '@material-ui/core/styles';

const borderValue = "1px solid #e8e8e8";
const buildHeight = x => `calc(100vh - ${x}*(3.5rem)) !important`;

export const useStyles = makeStyles((theme) => ({
    primaryColor: {color: "rgb(33, 37, 41)"},
    secondaryColor: {color: "#84898e"},

    wrapper: {background: "#fff", height: buildHeight(4)},

    participantContainer: {height: '60vh !important', overflowY: "auto !important"},
    participant: {
        height: "4.125rem",
        "&>span>.contacts-list-info" : {display: "flex", alignItems: "center", height: "2.5rem"}
    },

    leftColumn: {borderRight: borderValue},

    search: {
        height: "3.5rem",
        "&>input" : {
            display: "inline-block",
            border: "none",
            width: "calc(100% - (2em + 2rem + 1.5rem + 2rem))"
        }
    },
    filter: {
        width: "3rem",
        height: "3rem",

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    pad: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1200,

        width: "100%",
        height: "100vh",
        display: "none",

        "&.show": {
            display: "block"
        }
    },
    filtersDropdownMenu: {
        display: "none",
        "&.show": {
            display: "block"
        },
        "&.left-menu": {
            transform: "translate(-70%)"
        },

        "&>.dropdown-item" : {cursor: "pointer"},
        "&>.dropdown-item.noActiveStyles:not(.active):active" : {
            color: "#212529 !important",
            backgroundColor: "transparent !important"
        },
        "&>a.noActiveStyles>div input": {
            padding: "0.5em 0 0.5em 0.75em"
        }
    },
    activeFilters: {
        borderTop: borderValue,
        height: "2.5rem",
        fontSize: "0.8rem",
        paddingRight: "2.5rem !important",

        "&>span": {
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
            padding: "2.5px 5px",
            color: "#474747",
            background: "#e8e8e8",

            "&>span": {padding: 0}
        },
        "&>i": {
            position: "absolute",
            top: 0,
            right: 0,
            padding: 0,
            width: "2.5rem",
            height: "2.5rem"
        }
    },
    filterButton: {
        border: "1px solid hsl(0, 0%, 80%)",
        transition: "0.15s all easy-in-out",
        borderRadius: "4px",
        cursor: "pointer",

        "&:hover": {
            background: "hsl(0, 0%, 95%)"
        }
    },

    contacts: {top: "6rem", height: "calc(100% - 6rem)", background: "#fff", borderTop: borderValue, borderRight: borderValue},
    interlocutor: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        padding: "0 0.625em 0.1875em 1.5625em !important",
        height: "4.125rem",
        borderBottom: `${borderValue} !important`,

        "&.active" : {backgroundColor: "#007bff"},
        "&.active *" : {color: "#fff"},
        "&:first-child": {height: "calc(4.125rem - 1px)"},
        "&:not(.active):hover" : {background: "rgba(232, 232, 232, 0.5)", cursor: "pointer"},
        "&>.contacts-list-info" : {display: "flex", alignItems: "center", height: "100%"},
        "& .is-important" : {
            padding: "0.1875em",
            color: "#ffb700",
            fontSize: "1.8rem"
        }
    },

    rightColumn: {
        "&>.header": {
            height: "3.5rem",

            "& .contacts-list-name": {
                cursor: "pointer",
                transition: "0.15s all linear",
                borderRadius: "0.625em",
                padding: "0.313em",
                "&:hover": {background: "#e8e8e8"}
            }
        },
        "&>.body": {
            height: buildHeight("2px - 4"), borderTop: borderValue,
            "& .messages-list": {
                height: buildHeight("2px - 4"),
                "& .date-filtering": {
                    display: "flex",
                    alignItems: "center",
                    width: "calc(100% - 5em)",
                    margin: "1em auto"
                },
                "& .date-filtering>.main": {
                    padding: "0 1em",
                    textAlign: "center",
                    whiteSpace: "nowrap"
                },
                "& .date-filtering>.line": {
                    width: "100%",
                    height: "0.0625em",
                    background: "#e8e8e8"
                }
            }
        },
        "&>.message": {
            height: "3.5rem",
            "&>.card-footer textarea": {resize: "none", overflow: "hidden", height: "2.375rem"}
        }
    },
    message: {
        paddingLeft: "0.625em",
        width: "fit-content",
        minWidth: "25% !important",
        maxWidth: "80% !important",
        "& .message-status": {color: "#757575", fontSize: "0.65rem", fontWeight: "bold"},
        "& .direct-chat-text": {
            wordBreak: "break-word",
            background: "#e8e8e8 !important",
            borderColor: "#e8e8e8 !important",
            color: "#000000 !important"
        },
        "& .direct-chat-text::after": {borderRightColor: "#e8e8e8 !important"}
    },
    systemMessage: {
        minWidth: "35%",
        margin: "1rem auto",
        background: "#e8e8e8",
        padding: "0.5rem",
        borderRadius: "0.3rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        "&>p, &>h6": {marginBottom: 0},
        "&>p": {margin: "0.5rem 0"},
        "&>span": {color: "#757575", fontSize: "0.65rem", fontWeight: "bold"}
    },
    myMessage: {
        marginRight: 0,
        paddingRight: "0.625em",
        "& .direct-chat-text": {
            background: "#bfe6b8 !important",
            borderColor: "#bfe6b8 !important",
            color: "#000000 !important"
        },
        "& .direct-chat-text::before": {borderLeftColor: "#bfe6b8 !important"},
        "& .direct-chat-text::after": {borderLeftColor: "transparent !important", borderRightColor: "transparent !important"},
    },
    disableTriangle: {
        "&::before" : {display: "none !important"},
        "&::after" : {display: "none !important"},
    },

    headerDots: {
        position: "absolute",
        top: "0.25rem",
        right: 0,
        bottom: 0,
        padding: 0,
        width: "2.5rem",
        height: "2.5rem"
    },

    sendMessage: {
        borderTop: borderValue,
        background: "rgba(232, 232, 232, 0.2) !important"
    }

}));