// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
    list: [],

    isOpened: false,
    editMode: false,
    editableEvent: null
};

export const SocialEventsStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'ADD_TO_LIST':
            return {...state, list: [...state.list, action.payload]};
        case 'EDIT_ITEM':
            const oldState = [...state.list];
            const newState = state.list.map(el => {
                if (el?.id === action.id) return action.payload;
                else return el;
            });
            return {...state, list: newState};

        case 'SET_IS_OPENED':
            return {...state, isOpened: action.payload};
        case 'SET_EDIT_MODE':
            return {...state, editMode: action.payload};
        case 'SET_EDITABLE_EVENT':
            return {...state, editableEvent: action.payload};

        default:
            return state;
    }
}

export const SocialEventsProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <SocialEventsStore.Provider value={value}>{props.children}</SocialEventsStore.Provider>;
};