// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import Utils from "../../scripts/Utils";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {ChatsStore} from "../../stores/ChatsStore";
import {RoomsStore} from "../../stores/RoomsStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {CircleTabPanel, ThemedCircleTab, ThemedCircleTabs} from "../../components/styled/StyledTabs";
import CustomTable from "../../components/table/CustomTable";
import {StyledImage} from "../../components/styled/StyledImage";
import {StyledButton} from "../../components/styled/StyledButton";
import Filters from "./components/Filters";
import {useStateSafe} from "../../scripts/SaveUseState";

let customBody = {
    onlyPublic: false
};

export default function RoomsList() {
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);
    const {state: chatsState, dispatch: chatsDispatch} = useContext(ChatsStore);
    const {state: userState} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(userState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(userState.myRoles);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // Base state
    const [tabValue, setTabValue] = useState(0);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const [needReRender, setNeedReRender] = useStateSafe(false);

    // Single row functions
    const openChat = async (id, thumbnail, name) => {
        const {error, data} = await chatsState.connection.sendSocketRequest("textchat:getTextchatForRoom", {data: {roomId: id}});

        if (error.code === 200) {
            chatsDispatch({type: "CLEAR_MESSAGES"});
            chatsDispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: {
                user: {
                    id, avatar: thumbnail, firstName: `Room chat: ${name}`, lastName : ""
                }
            }});
            chatsDispatch({type: "SET_CURRENT_TEXTCHAT", payload: data?.textchat});
            chatsDispatch({type: "SET_IS_FIRST_LOADING_FOR_CURRENT_CHAT", payload: true});

            chatsDispatch({type: "SET_CHOSEN_ROOMID", payload: id});
            chatsDispatch({type: "CHANGE_CATEGORY_ACTIVE", category: "rooms", payload: true});

            navigate('/chats');
        }
        else onError(new Error(error.message));
    };

    // Callbacks function
    const onFiltersChanged = filters => {
        customBody = filters;
        setNeedReRender(true);
    };
    const getColumns = () => ([
        {
            name:   'thumbnail',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
            cell:   rowData =>
                        rowData?.thumbnail?.id && rowData?.thumbnail?.id?.length > 0
                            ? <Link to={`/room/${rowData?.id}`}>
                                <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.thumbnail?.id}`,
                                    false, null, {networkId: Utils.getNetwork()})} size={5}/>
                            </Link>
                            : null,
            width:  Utils.RemToPx(9)
        },
        {
            name:   'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell:   rowData => (
                <div className="d-flex flex-column align-items-center">
                    <Link to={`/room/${rowData?.id}`} className="underline-link">
                        <span>{rowData?.id}</span>
                    </Link>
                </div>
            )
        },
        {
            name:   'name',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Room name</span>,
            cell:   rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.metadata?.name}</span>
                </div>
            )
        },
        {
            name:   'alias',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Room name aliases</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.alias?.length > 0 ? rowData?.alias : "-"}
                </span>
            )
        },
        {
            name:   'privacy',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Privacy</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.metadata?.privacy}
                </span>
            )
        },
        {
            name:   'owners',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Owners</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {(rowData?.owners.map(item => `${item.firstName} ${item.lastName}`)).join(", ")}
                </span>
            )
        },
        {
            name:   'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell:   rowData => (
                <div className="d-flex flex-wrap" style={{justifyContent: "center"}}>
                    <StyledButton href={`/room/${rowData?.id}/info`}
                                  title={<i className="fas fa-info"/>} tooltip="Room info"
                                  className="btn btn-sm bg-info h-100 mr-2"/>
                    <StyledButton href={`/room/${rowData?.id}/commands`}
                                  title={<i className="fas fa-pencil-alt"/>} tooltip="Room commands"
                                  className="btn btn-sm bg-success h-100 mr-2"/>
                    <StyledButton href={`/cms/room/${rowData?.id}`}
                                  title={<i className="fas fa-images"/>} tooltip="Room content"
                                  className="btn btn-sm bg-warning h-100 mr-2"/>
                    <StyledButton href={"/chats"} onClick={() => openChat(rowData?.id)}
                                  title={<i className="fas fa-comments"/>} tooltip="Room chat"
                                  className="btn btn-sm bg-primary h-100 mr-2"/>
                </div>
            )
        }
    ]);
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = useCallback(async ({offset, size, text}, onError) => {
        const {rooms = [], total = 0} = (
            await (
                typeof text === "string" && text.length > 0
                    ? API.Rooms.search({offset, size, text, ...customBody}, Utils.getNetwork(), onError)
                    : API.Rooms.getList({offset, size, ...customBody}, Utils.getNetwork(), onError)
            )).data;

        dispatch({type: "SET_LIST", payload: rooms});
        dispatch({type: "SET_TOTAL_COUNT", payload: total});

        return total ?? 0;
    }, [customBody]);

    const clearLists = () => dispatch({type: "CLEAR_LIST"});

    // Effects
    useEffect(() => {
        return () => {
            clearLists();
        };
    }, []);
    useEffect(() => {
        if (tabValue === 1) {
            clearLists();
            navigate("/rooms/my");
        }
        if (tabValue === 2) {
            clearLists();
            navigate("/rooms/templates");
        }
    }, [tabValue]);

    return (
        <div className="content-wrapper">

            <ContentHeader hasContentHeader={false}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-tabs" style={{background: "transparent", boxShadow: "none"}}>
                        <div className="card-header p-0 pt-1 d-flex custom justify-content-between"
                             style={{borderTop: "none"}} id="table-header">
                            <ThemedCircleTabs value={tabValue} onChange={onTabChange}>
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0} label="All Rooms"/>
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1} label="My rooms"/>
                                {(isSuperAdmin || isNetworkAdmin) &&
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={2} label="Template rooms"/>}
                            </ThemedCircleTabs>
                        </div>

                        <div className="card-body px-0" id="table-container"
                             style={{borderTop: "1px solid rgb(222, 226, 230)"}}>
                            <div className="tab-content">
                                <CircleTabPanel value={tabValue} index={0}>
                                    <div className="container-fluid">
                                        <div id="commands" className="card p-3 shadow-none" style={{border: "1px solid #e8e8e8"}}>
                                            <div className="d-flex align-items-center">
                                                <Filters onFiltersChanged={onFiltersChanged} hideSelect settings={{fullWidth: true}}/>
                                            </div>
                                        </div>

                                        <div id="table-container" className="mb-3" style={{width: `100%`}}>
                                            <CustomTable state={{
                                                canSelect: false, canHover: false, canSearch: true,
                                                reRender: needReRender, setReRender: value => setNeedReRender(value)
                                            }} callbacks={{getList, getColumns, getListItems, clearLists, onError}}/>
                                        </div>
                                    </div>
                                </CircleTabPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
