// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    model: {
        id: "",
        metadata: {name: ""}
    },

    list: [],
    totalCount: 0,

    teamMembers: [],
    cmsContent: [],
};

export const PropsStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_PROP_MODEL':
            return {...state, model: action.payload};

        case 'CLEAR_LIST':
            return {...state, list: []};
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'SET_TOTAL_COUNT':
            return {...state, totalCount: action.payload};

        case 'ADD_TEAM_MEMBERS':
            return {...state, teamMembers: [...state.teamMembers, action.payload]};
        case 'SET_TEAM_MEMBERS':
            return {...state, teamMembers: action.payload};
        case 'CHANGE_TEAM_MEMBERS_EDIT_RIGHT':
            const {userId, canEdit} = action.payload;

            const newTeamMembers = state.teamMembers.map(teamMember => {
                if (teamMember.invitedUser.id === userId) {
                    teamMember.permissions.comment = canEdit;
                    teamMember.permissions.contribute = canEdit;
                    teamMember.permissions.edit = canEdit;
                    teamMember.permissions.manage = canEdit;
                }

                return teamMember;
            });

            return {...state, teamMembers: newTeamMembers};
        case 'SET_CMS_CONTENT':
            return {...state, cmsContent: action.payload};

        default:
            return state;
    }
}

export const PropsProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <PropsStore.Provider value={value}>{props.children}</PropsStore.Provider>;
};