// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect} from "react";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import Utils from "../../scripts/Utils";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {UserCard} from "../User/components/UserCard";
import {UserPanel} from "../User/components/UserPanel";

export default function Profile() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const getUserModel = async () => dispatch({
        type: "SET_USER_MODEL",
        key: "model",
        payload: (await API.Users.getModel(state.model.id, onError, "roomful")).data.user
    });
    const getUserBalance = async () => dispatch({
        type: "SET_USER_BALANCE",
        key: "balance",
        payload: (await API.Users.getUserBalance(state.model.id, onError)).data.balance
    });

    useEffect(() => {
        if (!state?.model) getUserModel();
        if (Utils.hasPermission(state?.myRoles, "balance.manage")) getUserBalance();
    }, [state.model]);

    return (
        (state?.model && !Utils.isObjectEmpty(state?.model)) ?
        <div className="content-wrapper">
            <ContentHeader title={"Your profile"} breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Profile",
                    link: "/profile",
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="row align-items-start">
                        <UserCard onError={onError} model={state.model} showViewProfileBtn={false}/>
                        <UserPanel onError={onError} model={state.model} modelKey="model"/>
                    </div>
                </div>
            </section>
        </div> : null
    );
}