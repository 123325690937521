// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, { useContext, useEffect, useState } from "react";
import Utils from "../../../scripts/Utils";
import { API } from "../../../api/API";
import { Store } from "../../../stores/MainStore";
import { UsersStore } from "../../../stores/UsersStore";

// Components
import { StyledButton } from "../../../components/styled/StyledButton";
import SimpleCustomTable from "../../../components/table/SimpleCustomTable";

export const UserSocialLinks = props => {
    const {model, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: userState, dispatch: userDispatch} = useContext(UsersStore);

    useEffect(() => {
        if(userState.updatingSocialModels) {
            (async() => {
                const {data} = await API.Users.getSocialModels({userId: model?.id}, onError);
                userDispatch({type: "SET_SOCIAL_MODELS", payload: data?.social || []});
            })();
        }
        return () => {
            if(!userState.updatingSocialModels) {
                userDispatch({type: "CLEAR_SOCIAL_MODELS"});
            }
        }
    }, [userState.updatingSocialModels]);

    const Actions = ({userId, kind}) => {
        const [isUnlinkButtonLoading, setIsUnlinkButtonLoading] = useState(false);

        const onUnlinkSocial = async() => {
            setIsUnlinkButtonLoading(true);
            await API.Users.unlinkSocial({userId, socialKind: kind}, onError);
            userDispatch({type: "SET_UPDATING_SOCIAL_MODELS", payload: true});
            setIsUnlinkButtonLoading(false);
        };

        return (
            <div className="d-flex justify-content-center">
                <StyledButton id="add-role-button" type="danger" size="sm"
                              title={<>Unlink <i className="ml-2 fas fa-trash-alt"/></>}
                              onClick={onUnlinkSocial}
                              disabled={isUnlinkButtonLoading}/>
            </div>
        );
    };
    const socialModels = Object.values(Utils.groupBy(userState.socialModels, "kind"));

    return (
        <>
            <div id="table-container">
                <SimpleCustomTable data={socialModels} settings={{rowHeight: Utils.RemToPx(4)}}
                                   columns={[
                                       {
                                           name:   "socialKind",
                                           header: "Kind",
                                           cell:   rowData => rowData[0].kind
                                       },
                                       {
                                           name:   "socialId",
                                           header: "Social ID",
                                           cell:   rowData => rowData[0].socialId
                                       },
                                       {
                                           name:   "actions",
                                           header: "Actions",
                                           cell:   rowData => <Actions userId={model.id} kind={rowData[0].kind}/>
                                       },
                                   ]}/>
            </div>
        </>
    );
}