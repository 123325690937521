// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import Cookies from 'js-cookie';
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {initialState, Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {NetworksStore} from "../../stores/NetworksStore";
import {ChatsStore} from "../../stores/ChatsStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import CustomTable from "../../components/table/CustomTable";
import {StyledImage} from "../../components/styled/StyledImage";
import {StyledButton} from "../../components/styled/StyledButton";

export default function NetworksList() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state: chatsState} = useContext(ChatsStore);
    const {state, dispatch} = useContext(NetworksStore);
    const {state: userState, dispatch: userDispatch} = useContext(UsersStore);

    const navigate = useNavigate();

    const isSuperAdmin = Utils.isSuperAdmin(userState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(userState.myRoles);

    // Main Functions
    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };
    const openNetwork = (id, name, isEnabled) => {
        if (Utils.getNetwork() === id || (isSuperAdmin ? !isEnabled : false))
            navigate(`/network/${id}`);
        else {
            mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Warning! Network context switching",
                    text: `Do you want to switch network context to ${name} (#${id})?`,
                    color: "warning",
                    onCancel: () => {
                        console.log("simple opening network settings without switching network context");
                        navigate(`/network/${id}`);
                        mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                    },
                    useCustomOnCancelFunction: true,
                    useCustomCancelText: "No, just open network editing",
                    onOk: async () => {
                        console.log("okay");

                        if (isSuperAdmin) {
                            const result = await API.Users.isUserSubscribedToNetwork(id, userState?.model?.id, onError);

                            if (result?.error?.code === 200) {
                                const isSubscribed = result?.data?.isSubscribed;

                                if (!isSubscribed) {
                                    const result = await API.Users.subscribeUserToNetwork(id, userState?.model?.id, onError);

                                    if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                                }

                                // Switching network
                                Cookies.set("networkId", id);
                                Cookies.set("subdomainName", name);

                                dispatch({
                                    type: "SET_CURRENT_NETWORK",
                                    payload: state?.openedNetwork?.model
                                });
                                userDispatch({type: "SET_UPDATING_MODEL", payload: true});

                                await chatsState.connection.sendSocketRequest("network:switch", {data: {networkId: id}});
                                window.location.href = `/network/${id}`;

                            } else onError(new Error(result?.error?.message));

                            mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                        }
                        else {
                            Cookies.set("networkId", id);
                            Cookies.set("subdomainName", name);

                            dispatch({
                                type: "SET_CURRENT_NETWORK",
                                payload: state?.openedNetwork?.model
                            });
                            userDispatch({type: "SET_UPDATING_MODEL", payload: true});

                            await chatsState.connection.sendSocketRequest("network:switch", {data: {networkId: id}});
                            window.location.href = `/network/${id}`;
                        }
                    },
                    useCustomOnOkFunction: true,
                    useCustomOkText: "Yes"
                }
            });
        }
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'thumbnail',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
            cell: rowData =>
                rowData?.thumbnail && rowData?.thumbnail?.length > 0
                    ? <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.thumbnail}`,
                    false, null, {networkId: Utils.getNetwork()})} size={5}/>
                    : null,
            width: Utils.RemToPx(9)
        },
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.id}</span>
                </div>
            )
        },
        {
            name: 'network_name',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Network name</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.fullName}</span>
                </div>
            )
        },
        isSuperAdmin ? {
            name: 'status',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Status</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.isActive ? "enabled" : "disabled"}
                </span>
            )
        } : null,
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-wrap">
                    {Utils.hasPermission(userState.myRoles, "network.manage", [rowData?.id]) && (
                        <StyledButton className="btn btn-sm bg-success h-100 mr-2" href={`/network/${rowData?.id}`}
                                      title={<i className="fas fa-pencil-alt"/>} tooltip="Open network page"
                                      onClick={() => openNetwork(rowData?.id, rowData?.fullName, rowData?.isActive)}
                        />)}
                </div>
            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = useCallback(async ({offset, size, text}, onError) => {
        const {networks = [], total = 0} = (
            await (isSuperAdmin
                ? (typeof text === "string" && text.length > 0
                    ? API.Networks.searchNetworkModels({offset, size, text}, onError)
                    : API.Networks.getAll(onError)
                ) : API.Networks.getUserNetworks(onError))
        ).data;

        dispatch({type: "SET_LIST", payload: networks});
        dispatch({type: "SET_TOTAL_COUNT", payload: total});

        return total ?? 0;
    }, []);

    const clearLists = () => dispatch({type: "CLEAR_LIST"});

    useEffect(() => {
        return () => {
            if (!isNetworkAdmin) clearLists(); // because top panel use users network list (state.networkList)
        };
    }, []);

    return (
        <div className="content-wrapper">

            <ContentHeader title="Networks list" breadcrumbs={[
                {
                    title: "Home",
                    link: "/"
                },
                {
                    title: "Networks list",
                    link: "/networks/list",
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <div id="table-container" className="mb-3" style={{width: `calc(100% - ${Utils.RemToPx(1)}px)`}}>
                        <CustomTable
                            state={{canSelect: false, canHover: false, canSearch: true, pagination: false}}
                            callbacks={{getList, getColumns, getListItems, clearLists, onError}}/>
                    </div>
                </div>
            </section>
        </div>
    );
}