// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect} from "react";
import {useStyles} from "./style";

import {Store} from "../../stores/MainStore";
import {ChatsStore} from "../../stores/ChatsStore";

// Components
import {InterlocutorsList} from "./components/InterlocutorsList";
import {ChatHeader} from "./components/ChatHeader";
import {ChatBody} from "./components/ChatBody";
import {ChatSendMessage} from "./components/ChatSendMessage";

export default function Chats () {
    const classes = useStyles();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch: chatsDispatch} = useContext(ChatsStore);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    useEffect(() => {
        return () => {
            chatsDispatch({type: "CLEAR_LIST"});
            chatsDispatch({type: "SET_MESSAGES_OFFSET", payload: 0});
            chatsDispatch({type: "SET_SHOW_PARTICIPANTS", payload: false});
            chatsDispatch({type: "RERENDER_LIST", payload: false});
            chatsDispatch({type: "SET_NEED_RE_RENDER_INTERLOCUTORS_LIST", payload: false});
            chatsDispatch({type: "CLEAR_FILTERS"});
            chatsDispatch({type: "CLEAR_CURRENT_TEXTCHAT"});
        };
    }, []);
    useEffect(() => {
        if (state.reRenderList) {
            chatsDispatch({type: "SET_FILTERS_CHOSEN_ROOMID", payload: ""});
            chatsDispatch({type: "CLEAR_LIST"});
            chatsDispatch({type: "RERENDER_LIST", payload: false});
        }
    }, [state.reRenderList]);

    return (
        <>
            <div className={`content-wrapper ${classes.wrapper}`}>
                <section className={`content px-0 h-100`}>
                    <div className={`container-fluid h-100`}>
                        <div className={`row align-items-stretch h-100`}>
                            <div className={`col-4 px-0 ${classes.interlocutors}`}>
                                <InterlocutorsList onError={onError}/>
                            </div>
                            <div className="col-8 px-0">
                                <div className={`flex-column h-100 ${classes.rightColumn}`}>
                                    <div className="header"><ChatHeader onError={onError}/></div>
                                    <div className="body"><ChatBody/></div>
                                    <div className="message"><ChatSendMessage/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    );
}