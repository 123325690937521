// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {ActiveUsers} from "../../../components/list/Users/ActiveUsers";

export const RoomAddOwner = ({onError}) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(RoomsStore);

    // Base state
    const [addOwnerBtnLoading, setAddOwnerBtnLoading] = useState(false);
    const [user, setUser] = useState(null);

    // Main functions
    const onAddOwnerClicked = async () => {
        if (user && user?.id?.length > 0) {
            setAddOwnerBtnLoading(true);

            const result = await API.Rooms.addOwner({roomId: state.model?.id, userId: user?.id}, onError);

            if (result?.error?.code === 200) mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Success",
                    text: `Added user ${user?.id?.length > 0 ? `(${user?.id}) ` : ""}as owner to room (${state.model?.id})`,
                    color: "success"
                }
            });
            else onError(new Error(`Can't add user ${user?.id?.length > 0 ? `(${user?.id}) ` : ""}as owner to room (${state.model?.id})`));
        }
    };

    // Handlers
    const onUserChanged = selectedUser => setUser(selectedUser);

    // Effects
    useEffect(() => {
        setAddOwnerBtnLoading(!user);
    }, [user]);

    return (
        <>
            <h4 className="my-4">Add room owner</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0 justify-content-start align-items-center">
                        <div className="col-10 pl-0">
                            <ActiveUsers user={user} onChange={onUserChanged}
                                         networkId={Utils.getNetwork()} settings={{preloadUser: true}}/>
                        </div>

                        <div className="col-2 d-flex justify-content-start">
                            <StyledButton onClick={onAddOwnerClicked} title="Add owner" type="success"
                                          disabled={addOwnerBtnLoading} noLoading={addOwnerBtnLoading}/>
                        </div>
                    </div>
                    <p className="mt-3 text-muted">
                        By pressing "Add owner" button you will set user role as owner of this room in this network.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
}
