// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";
import Cookies from 'js-cookie';

// MUI
import {Box} from "@material-ui/core";
import Image from 'material-ui-image';

import {UniversalList} from "../UniversalList";

export const AllRoomsList = props => {
    const {onChange, allowAll = false, canType, networkId = Utils.getNetwork()} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const getList = useCallback(() => {
        let list = state.list;
        list = list.filter(item => item !== undefined && item?.id !== undefined);
        if (allowAll && list?.length === 0) list.unshift({id: "all", metadata: {name: "All"}});

        return list;
    }, [state.list]);
    const setList = useCallback((list) => {
        list = list.filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i).filter(item => item !== undefined && item?.id !== undefined);

        if (allowAll && list[0]?.id !== "all")
            list.unshift({id: "all", metadata: {name: "All"}});

        dispatch({type: 'SET_LIST', payload: list});
    }, []);
    const getItems = useCallback(async (offset, size, searchText) => {
        const {rooms} = (await (typeof searchText === "string" && searchText.length > 0
            ? API.Rooms.search({offset, size, text: searchText}, Utils.getNetwork(), onError, true)
            : API.Rooms.getList({offset, size}, Utils.getNetwork(), onError, true))).data;

        let list = state.list;
        list = list.filter(item => item !== undefined && item?.id !== undefined);
        if (allowAll && list?.length === 0) list.unshift({id: "all", metadata: {name: "All"}});

        return [...list, ...rooms];
    }, [state.list, allowAll]);
    const getOptionLabel = useCallback(option => {
        return option?.id === "all" ? option?.metadata?.name : `${option?.metadata?.name} (#${option?.id})`;
    }, []);
    const getOption = useCallback(option => {
        return (
            <Box data-id={option?.id === "all" ? Math.random() : option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                {option?.id !== "all" && <Image imageStyle={{
                    position: "initial",
                    width: "3rem",
                    height: "3rem",
                    objectFit: "cover"
                }} style={{
                    display: "flex",
                    justifyContent: "center",
                    background: "transparent",
                    padding: 0
                }} src={Utils.buildURLs(`/resource/thumbnail/256/${option?.thumbnail?.id}`, false, null, {params: networkId})}/>}
                <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                    <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                        {option?.id === "all" ? option?.metadata?.name : `${option?.metadata?.name}`}
                    </span>
                    <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                        {option?.id === "all" ? "" : option?.id}
                    </span>
                </div>
            </Box>
        );
    }, []);

    return (
        <UniversalList OnChange={onChange} callbacks={{getList, setList, getItems, getOptionLabel, getOption}}
            settings={{canType}}/>
    );
};