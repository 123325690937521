// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import Switch from "react-switch";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const NetworkStatus = props => {
    const {onError} = props;
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);
    const {state: userState} = useContext(UsersStore);

    const [isDisabled, setIsDisabled] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [changeStatusBtnDisabled, setChangeStatusBtnDisabled] = useState(false);

    const changeStatus = async () => {
        setChangeStatusBtnDisabled(true);

        let result;
        if (isDisabled) result = await API.Networks.activate(state.openedNetwork?.model?.id, onError);
        else result = await API.Networks.disable(state.openedNetwork?.model?.id, onError);

        if (result && result.error && result.error.code === 200) {
            setChangeStatusBtnDisabled(false);
            setStatusModalShow(false);
            dispatch({
                type: "SET_NETWORK_STATUS",
                payload: !isDisabled
            });

            mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Success!",
                    text: "Network status was updated successfully!",
                    color: "success"
                }
            });
        } else onError(new Error("Unknown error occurred during status updating, please try again later."));
    };

    return (
        <>
            <div className="d-flex align-items-center" id="network-status">
                <h5 className="mr-3 mb-0">Network status</h5>
                <Switch onChange={checked => {
                    setStatusModalShow(true);
                    setIsDisabled(checked);
                }} checked={!state.openedNetwork?.isDisabled}
                        width={Utils.emToPx(3.5, document.querySelector("#network-status > div"))}
                        height={Utils.emToPx(1.75, document.querySelector("#network-status > div"))}
                />
            </div>

            <Dialog open={statusModalShow} onClose={() => setStatusModalShow(false)}>
                <DialogTitle>
                    Are you sure to change network status
                    to {state.openedNetwork?.isDisabled ? "Active" : "Disabled"}?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        By pressing "Agree" you will change network status immediately!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={() => setStatusModalShow(false)} title="Disagree" type="danger"
                                  variant="contained"/>
                    <StyledButton onClick={changeStatus} autoFocus disabled={changeStatusBtnDisabled}
                                  title="Agree" type="success" variant="contained"/>
                </DialogActions>
            </Dialog>
        </>
    );
}