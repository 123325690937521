// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useEffect, useState} from "react";
import Utils from "../../scripts/Utils";
import {useStateSafe} from "../../scripts/SaveUseState";

// Components
import MuiTable from "./MuiTable";

export default function SimpleCustomTable(props) {
    const {columns = [], data = [], settings = {}} = props;
    const {rowHeight = Utils.RemToPx(8), offsetLeftRight = 0, noItems = null} = settings;

    const [tableWidth, setTableWidth] = useState(0);

    // Height calculation
    const getTableSize = () => {
        const tableContainer = document.querySelector("#table-container");

        if (tableContainer) {
            const tableContainerWidth = tableContainer.getBoundingClientRect().width;
            setTableWidth(tableContainerWidth - offsetLeftRight);
        }
    };
    useEffect(() => {
        window.addEventListener("resize", getTableSize);
        getTableSize();

        return () => {
            window.removeEventListener("resize", getTableSize);
        };
    }, []);

    const getColumns = () => {
        return columns.map(column => ({
            name: column.name,
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>{column.header}</span>,
            cell: rowData =>
                column?.isCustom
                    ? column.cell(rowData)
                    : <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>{column.cell(rowData)}</span>
        }));
    };

    return (
        data?.length > 0
            ? (
                <MuiTable
                    data={data}
                    width={tableWidth}
                    rowHeight={rowHeight}
                    columns={getColumns()}
                    includeHeaders={true}
                />
            )
            : (
                <div className="card">
                    <div className="card-body">
                        <h6 className="mb-0">
                            {noItems ?? "No items found..."}
                        </h6>
                    </div>
                </div>
            )
    );
}
