// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {RolesStore} from "../../../stores/RolesStore";
import {API} from "../../../api/API";
import {useStyles} from "../style";
import Select from "react-select";

// Components
import {StyledInput} from "../../../components/styled/StyledInput";
import {StyledButton} from "../../../components/styled/StyledButton";
import {customStyles} from "../../../styles/dropdown";

export default function AddRole ({onError}) {
    const classes = useStyles();
    const {state, dispatch} = useContext(RolesStore);

    const [roleName, setRoleName] = useState("");
    const [selectedTargetType, setSelectedTargetType] = useState({label: "Target type", value: ""});
    const [permissions, setPermissions] = useState("");

    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);

    const add = async () => {
        setIsAddBtnLoading(true);

        const {error} = await API.RolesManager.create({
            roleName,
            targetType: selectedTargetType.value,
            permissions: permissions.split(",").map(el => el.trim())}, onError);

        if (error.code === 200) dispatch({type: "UPDATE_ROLES_LIST", payload: true});
        else onError(new Error(error.message));

        setIsAddBtnLoading(false);
    };

    return (
        <div className="mb-5">
            <div className="card card-primary card-outline p-2">
                <h5 className="px-3 pt-2">New role configuration: </h5>
                <div className="row px-2 mt-3">
                    <div className="col-2"><StyledInput fullWidth placeholder="Name" value={roleName} onChange={e => setRoleName(e.target.value)}/></div>
                    <div className="col-2">
                        <Select styles={customStyles(true)}
                                onChange={selected => setSelectedTargetType(selected)} defaultValue={selectedTargetType}
                                options={[
                                    {label: "Network", value: "network"},
                                    {label: "Room", value: "room"},
                                    {label: "Prop", value: "prop"}
                                ]}/>
                    </div>
                    <div className={`col-6 ${classes.textareaContainer}`}>
                        <StyledInput isTextarea fullWidth placeholder="Permissions list divided by comma" value={permissions} onChange={e => setPermissions(e.target.value)}/>
                    </div>
                    <div className="col-2 d-flex align-items-start">
                        <StyledButton onClick={add} disabled={isAddBtnLoading} title={<>Add <i className="ml-3 fas fa-plus-circle"/></>} type="success"/>
                    </div>
                </div>
            </div>
        </div>
    );
}