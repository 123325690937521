// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {PropsStore} from "../../../stores/PropsStore";

// MUI
import {Box} from "@material-ui/core";
import Image from 'material-ui-image';

import {ControlledUniversalList, UniversalList} from "../UniversalList";
import {RoomsStore} from "../../../stores/RoomsStore";

export const AllPropsList = props => {
    const {prop, preSelectedFirstValue = false, withImage = false, customBody, onChange} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const getList = useCallback(() => {
        return state.propsList;
    }, [state.propsList]);
    const setList = useCallback((list) => {
        dispatch({type: 'SET_PROPS_LIST', payload: list});
    }, []);
    const getItems = useCallback(async (offset, size, searchText) => {
        const {props} = (await API.Rooms.getRoomAllPropsList(customBody, onError)).data;

        return props;
    }, [customBody]);
    const getOptionLabel = useCallback(option => {
        return (`${option?.title === "" ? `[${option?.assetTitle}]` : option?.title} (#${option?.id})`);
    }, []);
    const getOption = useCallback(option => {
        return (
            <Box data-id={option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                <Image imageStyle={{
                            position: "initial",
                            width: "3rem",
                            height: "3rem",
                            objectFit: "cover"
                        }} style={{
                           display: "flex",
                           justifyContent: "center",
                           background: "transparent",
                           padding: 0
                       }}
                       src={`/asset/thumbnail/${option?.assetId}`}/>
                <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                    <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>{option?.title === "" ? `[${option?.assetTitle}]` : option?.title}</span>
                    <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                        {option?.id} /
                        {option?.propTypes?.indexOf("booth") > -1
                            ? " Booth"
                            : (option?.propTypes?.indexOf("fileCabinet") > -1 ? " File Cabinet" : " Prop")}
                    </span>
                </div>
            </Box>
        );
    }, []);

    return (
        <ControlledUniversalList value={prop} OnChange={onChange} settings={{customBody, preSelectedFirstValue, withImage}}
                       callbacks={{getList, setList, getItems, getOptionLabel, getOption}}/>
    );
};

export const AllPropsListUncontrolled = props => {
    const {customBody, onChange} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const getList = useCallback(() => {
        return state.propsList;
    }, [state.propsList]);
    const setList = useCallback((list) => {
        dispatch({type: 'SET_PROPS_LIST', payload: list});
    }, []);
    const getItems = useCallback(async (offset, size, searchText) => {
        const {props} = (await API.Rooms.getRoomAllPropsList({...customBody, offset, size}, onError)).data;

        return props;
    }, [customBody]);
    const getOptionLabel = useCallback(option => {
        return (`${option?.title === "" ? `[${option?.assetTitle}]` : option?.title} (#${option?.id})`);
    }, []);
    const getOption = useCallback(option => {
        return (
            <Box data-id={option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                <Image imageStyle={{
                            position: "initial",
                            width: "3rem",
                            height: "3rem",
                            objectFit: "cover"
                        }} style={{
                           display: "flex",
                           justifyContent: "center",
                           background: "transparent",
                           padding: 0
                       }}
                       src={`/asset/thumbnail/${option?.assetId}`}/>
                <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                    <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>{option?.title === "" ? `[${option?.assetTitle}]` : option?.title}</span>
                    <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                        {option?.id} /
                        {option?.propTypes?.indexOf("booth") > -1
                            ? " Booth"
                            : (option?.propTypes?.indexOf("fileCabinet") > -1 ? " File Cabinet" : " Prop")}
                    </span>
                </div>
            </Box>
        );
    }, []);

    return (<UniversalList OnChange={onChange} settings={{customBody}}
                       callbacks={{getList, setList, getItems, getOptionLabel, getOption}}/>
    );
};