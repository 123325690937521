// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";

import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, InputAdornment, IconButton, Box, CircularProgress} from "@material-ui/core";
import {ArrowDropUp, ArrowDropDown, Loop, Close, Search, Clear} from '@material-ui/icons';
import Image from 'material-ui-image';

// Style
import useStyles from "../../../styles/dropdownStyles";

// Components
import {StyledImage} from "../../styled/StyledImage";

let timeout = null;

export const ActiveUsers = props => {
    const {user, onChange, networkId = null, settings = {}} = props;
    const {multiple = false, preloadUser = false} = settings;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const classes = useStyles();
    const readOnly = false; // prevent all searching functional

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const [selectedNetworkId, setSelectedNetworkId] = useState(networkId || Utils.getNetwork());
    const [value, setValue] = useState(user);
    const [isLoading, setIsLoading] = useState(false);

    // Base state
    const [needRender, setNeedRender] = useState(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    // Search state
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);
    const [needClear, setNeedClear] = useState(false);

    // Buttons, dropdown state, errors state, etc
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isErrorInInput, setIsErrorInInput] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);

    const loadMoreItems = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);

        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const search = () => {
        setIsLoading(false);
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };
    const initSearch = () => {
        setNeedClear(true);
        setSearchBtnLoading(false);
        setValue(null);
        if (!isDropdownOpen) setIsDropdownOpen(true);
    };
    const clear = () => {
        dispatch({type: 'SET_ACTIVE_USERS_LIST', payload: []});
        setLoadMoreActive(false);
        setValue(null);
        setIsLoading(false);
        setSearchInputText("");
        setPrevSearchInputText("");
        setNeedClear(false);
        setSearchBtnLoading(false);
        setOffset(0);
        setIsDropdownOpen(false);

        setNeedRender(true);
    };

    const getList = useCallback(() => {
        return state.activeUsersList;
    }, [state.activeUsersList]);
    const setList = (list) => {
        dispatch({type: 'SET_ACTIVE_USERS_LIST', payload: list});
    };

    const getListItems = async (body, list) => {
        if (!loadMoreActive) setIsLoading(true);

        const data = (await (typeof body.text === "string" && body.text.length > 0
            ? API.Users.searchActive(networkId, true, body, onError)
            : API.Users.getActiveList(networkId, true, body, onError))).data;

        const items = data?.users ?? [];
        setTotal(data?.total ?? 0);

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && items.length > 0) ? list : [];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);

        setList(itemsToAdd);
        if (!loadMoreActive && !multiple && !needClear && !preloadUser) setValue(itemsToAdd[0]);

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setSearchBtnLoading(false);
    };
    const clearAll = () => {
        setSearchBtnLoading(false);
        setOffset(0);
        setTotal(0);
        setSearchInputText("");
        setPrevSearchInputText("");
        setLoadMoreActive(false);
        setSelectedNetworkId(networkId);
        setList([]);
        setNeedRender(true);
    };

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(true);
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !readOnly && !loadMoreActive && (offset + size < total)) loadMoreItems();
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        clearTimeout(timeout);

        if (!needClear) setNeedClear(true);
        timeout = setTimeout(search, 1000);

        setPrevSearchInputText(searchInputText);
        setSearchInputText(e.target.value);
    };
    const onItemSelected = (e, value) => {
        setValue(value);
        setNeedClear(false);
        setIsDropdownOpen(false);
        setIsErrorInInput(false);
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (needRender) {
                const body = {offset, size, text: searchInputText};
                const list = getList();

                await getListItems(body, list);
                setIsLoading(false);
            }
        })();
    }, [needRender]);

    useEffect(() => {
        if (networkId && selectedNetworkId !== networkId) {
            setSearchBtnLoading(false);
            setOffset(0);
            setSelectedNetworkId(networkId);
            setList([]);
            setNeedRender(true);
        }
    }, [networkId]);

    useEffect(() => {
        return () => {
            setList([]);
        };
    }, []);
    useEffect(() => {
        if (value) setIsLoading(false);

        if (onChange && typeof onChange === "function") onChange(value);
    }, [value]);
    useEffect(() => {
        if (multiple) setValue(user);
    }, [user]);

    const isSearchActive = typeof searchInputText === "string" && searchInputText.length > 0

    return (
        isSearchActive || !isLoading
        ?
        <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                      renderInput={params => (
                          <div className="d-flex align-items-center">
                              {!multiple && value?.avatar && <Image imageStyle={{
                                  position: "initial",
                                  margin: "0.25rem",
                                  width: "3rem",
                                  height: "calc(3.5rem - 0.5rem - 2px)",
                                  objectFit: "cover"
                              }} style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  background: "#fff",
                                  padding: 0,
                                  border: "1px solid hsl(0, 0%, 80%)",
                                  borderRight: 0,
                                  borderTopRightRadius: 0,
                                  borderTopLeftRadius: "4px",
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: "4px",
                              }} src={Utils.buildURLs(`/resource/thumbnail/256/${value?.avatar}`,
                                  false, null, {networkId})}/>}
                              <TextField {...params} variant="outlined" value={searchInputText}
                                         placeholder={multiple && !(user?.length > 0) ? `Click on the input to select multiple values...` : ""}
                                         onChange={onSearchInputChange} error={isErrorInInput} size="small"
                                         InputProps={{
                                             readOnly,
                                             ...params.InputProps,
                                             style: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, ...(multiple ? {minHeight: "3.5rem", paddingRight: "3.5rem"} : {height: "3.5rem"})},
                                             endAdornment: (
                                                 <InputAdornment position="end" style={multiple ? {position: "absolute", top: "1.75rem", right: "1rem"} : {}}>
                                                      {needClear
                                                         ? <IconButton edge="end" className="search-clear" onClick={clear}><Clear/></IconButton>
                                                         : <IconButton edge="end" className="search" onClick={initSearch}><Search/></IconButton>}

                                                     {searchBtnLoading
                                                         ? <IconButton edge="end"><Loop/></IconButton>
                                                         :
                                                         <IconButton edge="end" onClick={onOpenDropdown}>
                                                             {isDropdownOpen ? <ArrowDropUp/> : <ArrowDropDown/>}
                                                         </IconButton>}
                                                 </InputAdornment>
                                             ),
                                             onKeyDown: e => {
                                                 if (e.key === 'Enter') {
                                                     e.preventDefault();
                                                     e.stopPropagation();

                                                     if (searchInputText !== "") search();
                                                     return;
                                                 }
                                             },
                                             onClick: () => setIsDropdownOpen(true)
                                         }}
                                         onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                              />
                          </div>
                      )} style={{width: "100%"}} freeSolo disableClearable forcePopupIcon={false} open={isDropdownOpen}
                      onChange={onItemSelected} filterOptions={options => options}
                      options={getList()}
                      multiple={multiple} value={value}
                      getOptionLabel={option => {
                          return `${option.firstName} ${option.lastName} ${option?.id ? `(#${option?.id}${option.email ? `, ${option.email}` : ""})` : ""}`;
                      }}
                      renderOption={option => (
                          <Box data-id={option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                              <StyledImage size={3} src={Utils.buildURLs(`/resource/thumbnail/256/${option?.avatar}`,
                                  false, null, {networkId})}/>

                              <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                                  <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                                      {`${option.firstName} ${option.lastName}`}
                                  </span>
                                  <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                                      {option.id}
                                      {option.email ? ` / ${option.email}` : ""}
                                  </span>
                              </div>
                          </Box>
                      )}
        />
        : (
            <div style={{
                border: "1px solid hsl(0, 0%, 80%)",
                background: "#fff",
                width: "100%", height: "3.5rem",
                padding: "9px", borderRadius: "4px"
            }} className="d-flex align-items-center">
                {getList()?.length > 0
                    ? <><CircularProgress className="mr-3"/> Loading...</>
                    : (
                        <div className="d-flex justify-content-between align-items-center w-100">
                            No users found
                            <IconButton edge="end" onClick={clearAll} className="mr-1"><Close/></IconButton>
                        </div>
                    )
                }
            </div>
        )
    );
}