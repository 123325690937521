// Copyright © 2015-2024 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    activeNetworksList : [],

    list: [],

    currentNetwork : {},
    currentNetworkInfo : {},

    parsedParticipants : [],
    feedbackTableItems : {
        showParticipantsFeedback : false,
        showSubscribedUsersFeedback : false,

        isParticipantsNeedRender : true,
        isSubscribedUsersNeedRender : true,

        participants : [],
        subscribedUsers : []
    },

    openedNetwork : {
        model: {},
        isDisabled: false,
        newNetworkSettings: {
            fullName: {value: ""},
            type: {list: ["Public", "Matchmaking", "BusinessSocial", "NetworkConference", "Humanity"], value: "Public", returnedType: "string"},
            subscriptionOptions: {list: ["free"], value: "free", returnedType: "string"}
        },
        settingsNetworks: {
            general: {
                domainNames: {type: "input", value: [""], helperText: "Network domain names, separated by commas. First domain will be used as default for share server and other services."},
                presentationDomain: {type: "input", value: "", helperText: "Default domain for presentation client build (without redactor)."},
                studioDomain: {type: "input", value: "", helperText: "Default domain for studio client build (with redactor)."},
                shareDomain: {type: "input", value: "", helperText: "Domain for share server. NB! Domain must be configured on a server as well."},
                ssoProviders: {type: "input", value: [""], helperText: "Single sign on providers."},
                applicationName: {type: "input", value: "", helperText: "Set in case if network has its own application."},
                loaderType: {type: "select", list: ["Logo", "Hybrid", "Slideshow", "Video"], value: 0, helperText: "What loader to show on application startup."},
                logoUrl: {type: "input", value: "", helperText: "Url of logo to show (for example - conference logo)."},
                loadingVideoUrl: {type: "input", value: "", helperText: "Url or youtube id of video to show on application loading."},
                defaultPassword: {type: "input", value: "", helperText: "Default password for conference users."},
                disableEmailLogin: {type: "checkbox", value: false, helperText: "if true, client application shouldn't show email login option"},
                disableSsoLogin: {type: "checkbox", value: false, helperText: "if true, client application shouldn't show sso login options"},
                sendTextchatEmail: {type: "checkbox", value: false, helperText: "If true, send email that user has new direct textchat message."},
                disableDefaultTemplates: {type: "checkbox", value: false, helperText: "If true, return room templates only from current network."},
            },
            defaults: {
                metaverseCurrency: {type: "input", value: "", helperText: "Metaverse currency."},
                enablePayments: {type: "checkbox", value: false, helperText: "If true - client could purchase products; else - all products are free."},
                enableTokenomics: {type: "checkbox", value: false, helperText: "If true - user will mint or spend tokens for his actions (like placing content in room)."},
                disableBalance: {type: "checkbox", value: false, helperText: "DEPRECATED"},
                roomId: {type: "input", value: "", helperText: "Default room in network."},
                hasAnalytics: {type: "checkbox", value: false, helperText: "Enables analytics (should have data collection enabled)."},
                storylineVideoSound: {type: "checkbox", value: false, helperText: "Enables video sound in storyline by default."},
                hasQuestionnaire: {type: "checkbox", value: false, helperText: "Enables matchmaking questionnaire."},
                hasSearchCard: {type: "checkbox", value: false, helperText: "Enables matchmaking search by search card."},
                hasGamingBalance: {type: "checkbox", value: false, helperText: "Enables gaming balance."},
                hasExpo: {type: "checkbox", value: false, helperText: "Enables conference."},
                expoStartDate: {type: "timestamp", value: "", helperText: "Texhibition application will order networks (on startup) by expo start date."},
                expoEndDate: {type: "timestamp", value: "", helperText: "Texhibition application will show networks (on startup) that have expo end date in future."},
                hasAttendees: {type: "checkbox", value: false, helperText: "Enables access to rooms by conference attendees role."},
                allUsersAreConnected: {type: "checkbox", value: false, helperText: "If true, all users in network are friends by default."},
                orderByFirstComments: {type: "checkbox", value: false, helperText: "If true, show first resource comments first."},
                hideTextchat: {type: "checkbox", value: false, helperText: "If true, client shouldn't show text chat UI."},
                hideVideochat: {type: "checkbox", value: false, helperText: "If true, client shouldn't show videochat UI."},
                hideSocialSessions: {type: "checkbox", value: false, helperText: "If true, client shouldn't show social sessions UI."},
                allowHostSetup: {type: "checkbox", value: false, helperText: "If true, allows user to setup personalised custom params for props in room."},
                requireZipCode : {type: "checkbox", value: false, helperText: "If true, registration form must require zip code."},
                disableOwnerBadge: {type: "checkbox", value: false, helperText: "If true, disables room owner badge (crown) near avatar."},
                disableFavoriteRooms: {type: "checkbox", value: false, helperText: "If true, disables favorite room appearance on client."},
                disableShareRoom: {type: "checkbox", value: false, helperText: "If true, disables room sharing on client."},
                disableRoomCreation: {type: "checkbox", value: false, helperText: "If true, disables room creation on client."},
                hasCascadeTextchatSkin : {type: "checkbox", value: false, helperText: "If true, cascade textchat appearance will be applied in application."},
                animatedBackButton : {type: "checkbox", value: false, helperText: "If true, back button icon will be animated in application."},
                allowAnonymous : {type: "checkbox", value: false, helperText: "If true, users will be allowed to enter network without authorization."},
                paymentProviders: {type: "input", value: [""], helperText: "Restricts use of payment providers (stripe/braintree/apple) in network; if empty, all providers are available."},
                aiEndpoint: {type: "input", value: "", helperText: "Address for AI server."},
                loadBalancerUrl: {type: "input", value: "", helperText: "Url of load balancer for playgrounds."},
                gamificationParticlesIndex: {type: "select", list: ["Default", "Epam"], value: 0, helperText: "Type of particles for gamification easter eggs"},
                colorizationSchemeType: {type: "select", list: ["Default", "Fabuwood"], value: 0, helperText: "Colorization schema of application interface"},
                defaultNavigationMode: {type: "select", list: ["Classic", "Advanced", "Pro"], value: 0, helperText: "Default navigation mode for users"},
                defaultButtonAppearance: {type: "select", list: ["Default", "Show", "Hide"], value: 0, helperText: "Default helper button appearance"},
            },
            dataCollection: {
                useDefault: {type: "checkbox", value: false, helperText: "If true, network will use data collection server from default network."},
            },
            videochat: {
                defaultVideochatSettings: {
                    videochatMode: {type: "select", list: ["default", "videochat", "conference"], value: "default", returnedType: "string"},
                    videochatType: {type: "select", list: ["default", "peer-to-peer", "group", "group-small"], value: "default", returnedType: "string"},
                    videochatEngine: {type: "select", list: ["default", "twilio", "agora"], value: "default", returnedType: "string"},
                    videochatInitUser: {type: "select", list: ["default", "any", "manager"], value: "default", returnedType: "string"},
                    excludeListeners: {type: "checkbox", value: false},
                    usePresentationBoard: {type: "checkbox", value: false},
                    autoPromotion: {
                        useCustomSettings: {type: "checkbox", value: false},
                        autoDemoteOnLimit: {type: "checkbox", value: false},
                        autoPromotionLimit: {type: "int", value: 0},
                    },
                    limitSettings: {
                        useCustomSettings: {type: "checkbox", value: false},
                        videochatLimit: {type: "int", value: 0}
                    }
                }
            }
        },
        pluginSettings: {
            scroll: "horizontal",
            autoPlayOnClick: false,
            autoPlayOnStart: false,
            disclaimer: `To run Virtual 3D Expo on your computer, use a supported, up-to-date Chrome browser.
In order to have the best experience in the expo your computer system should have at least 2GB of VRAM, and at least 8GB of RAM. Occasionally users may see only a black screen which means your computer does not support WebGL graphics acceleration, please try another device to connect to the expo hall. If there is a constantly rotating icon at the top-right corner of your browser canvas it means you have lost connection to the expo hall, please reload the browser page to continue.`,
            expoTitle: "",
            appStoreId: "",
            googleStoreId: "",
            initRoute: {
                room: "",
                prop: ""
            },
            style: "",
            logoUrl: "",
            expositionDomain: "",
            menuItems: [],
            helpLink: {
                link: "",
                title: "",
                target: ""
            },
            directory: [{
                title: "123123123",
                target: "",
                room: "",
                prop: "",
                tag: "",
                tagSortBy: "",
                link: "",
                interactive: false,
                icon: "",
                items: []
            }]
        }
    }
};

export const NetworksStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_CURRENT_NETWORK':
            return {...state, currentNetwork: action.payload};
        case 'SET_CURRENT_NETWORK_INFO':
            return {...state, currentNetworkInfo: action.payload};

        case 'SET_PARSED_PARTICIPANTS':
            return {...state, parsedParticipants: action.payload};
        case 'SET_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {[action.key]: [...state.feedbackTableItems[action.key], action.payload]})};
        case 'RESET_FEEDBACK_TABLE_ITEMS':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {[action.key]: []})};
        case 'SET_SHOW_FEEDBACK':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {[action.key]: action.payload})};
        case 'SET_FEEDBACK_NEED_RENDER':
            return {...state, feedbackTableItems: Object.assign(state.feedbackTableItems, {[action.key]: action.payload})};

        case 'CLEAR_ACTIVE_NETWORKS_LIST':
            return {...state, activeNetworksList: []};
        case 'SET_ACTIVE_NETWORKS_LIST':
            return {...state, activeNetworksList: action.payload};

        case 'CLEAR_LIST':
            return {...state, list: []};
        case 'SET_LIST':
            return {...state, list: action.payload};

        case 'SET_NETWORK_MODEL':
            return {...state, openedNetwork: Object.assign(state.openedNetwork, {model: action.payload})};
        case 'SET_NETWORK_STATUS':
            return {...state, openedNetwork: Object.assign(state.openedNetwork, {isDisabled: action.payload})};
        case 'SET_NETWORK_MODEL_SETTINGS':
            return {...state, openedNetwork: Object.assign(state.openedNetwork, {settingsNetworks: action.payload})};

        case 'SET_NETWORK_PLUGIN_SETTINGS':
            return {...state, openedNetwork: Object.assign(state.openedNetwork, {pluginSettings: action.payload})};

        case 'SET_NEW_NETWORK_SETTINGS':
            return {...state, openedNetwork: Object.assign(state.openedNetwork, {newNetworkSettings: action.payload})};

        default:
            return state;
    }
}

export const NetworksProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <NetworksStore.Provider value={value}>{props.children}</NetworksStore.Provider>;
};