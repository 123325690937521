// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class NotificationAPI {
    static async getNotificationCounts(onError) {
        return await customFetch({path: "/jsonRpc/notification.getNotificationCounts", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}