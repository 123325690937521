// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Cookies from 'js-cookie';
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";
import {RolesStore} from "../../../stores/RolesStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// Styles
import {customStyles} from "../../../styles/dropdown";

// MUI
import Select from 'react-select';
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import SimpleCustomTable from "../../../components/table/SimpleCustomTable";
import {ActiveNetworks} from "../../../components/list/Networks/ActiveNetworks";
import {AllRooms} from "../../../components/list/Rooms/AllRooms";
import {AllProps} from "../../../components/list/Props/AllProps";

export const UserRoles = props => {
    const {model, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: networkState} = useContext(NetworksStore);
    const {state: userState, dispatch: userDispatch} = useContext(UsersStore);
    const {state, dispatch} = useContext(RolesStore);

    const getDefaultNetwork = () => ({label: Cookies.get('subdomainName') || "Roomful", value: Utils.getNetwork()});

    const isSuperAdmin = () => Utils.isSuperAdmin(userState.myRoles);
    const isNetworkAdmin = () => Utils.isNetworkAdmin(userState.myRoles);

    // Dialog state
    const [showSubscribeUserDialog, setSubscribeUserDialog] = useState(false);
    const [agreeBtnDisabled, setAgreeBtnDisabled] = useState(false);
    const [networkInDialog, setNetworkInDialog] = useState(getDefaultNetwork());

    // Base state
    const [isAddBtnLoading, setIsAddBtnLoading] = useState(false);
    const [isLoadingRolesList, setIsLoadingRolesList] = useState(false);
    const [selectedRole, setSelectedRole] = useState({label: "Choose role", value: ""});
    const [selectedRoleTargetType, setSelectedRoleTargetType] = useState(null);

    // Role params state
    const [network, setNetwork] = useState({
        id: Utils.getNetwork(),
        fullName: Cookies.get("subdomainName"),
        thumbnail: ""
    });
    const [room, setRoom] = useState(null);
    const [prop, setProp] = useState(null);

    // Roles list updating
    useEffect(() => {
        if (!(state.roles?.length > 0)) {
            (async () => {
                const {error, data} = await API.RolesManager.list(onError);

                if (error.code === 200) {
                    const items = {};
                    data.roles.map(item => items[item.id] = item);
                    dispatch({type: "SET_ROLES", payload: items});
                    dispatch({type: "UPDATE_ROLES_LIST", payload: false});
                } else onError(new Error(error.message));
            })();
        }
    }, [state.updateRolesList]);
    useEffect(() => {
        if (userState.updateUserRolesList) {
            setIsLoadingRolesList(true);
            API.RolesManager.listAssignedUserRoles(model?.id, onError).then(_data => {
                const {error, data} = _data;
                if (error.code === 200) {
                    const items = {};
                    data.roles.map(item => items[`${item.id}_${item?.targetId || ""}`] = item);
                    userDispatch({type: "SET_ROLES", payload: items});
                    userDispatch({type: "UPDATE_USER_ROLES_LIST", payload: false});
                } else onError(new Error(error.message));
            });
            setIsLoadingRolesList(false);
        }
    }, [userState.updateUserRolesList]);

    // Main functions
    const isRoleParamsValid = () => {
        if (selectedRoleTargetType === "") return true;
        else if (selectedRoleTargetType === "network") return network && network?.id !== "";
        else if (selectedRoleTargetType === "room") return network && network?.id !== "" && room && room?.id !== "";
        else if (selectedRoleTargetType === "prop") return network && network?.id !== "" && room && room?.id !== "" && prop && prop?.id !== "";
        else return false;
    };
    const add = async () => {
        if (selectedRole.value?.length > 0 && isRoleParamsValid()) {
            setIsAddBtnLoading(true);

            const settings = {
                userId: model.id,
                roleId: selectedRole.value
            };

            if (selectedRoleTargetType === "network") settings.targetId = `/${network?.id}`;
            if (selectedRoleTargetType === "room") settings.targetId = `/${network?.id}/${room?.id}`;
            if (selectedRoleTargetType === "prop") settings.targetId = `/${network?.id}/${room?.id}/${prop?.id}`;

            const rights = Utils.hasPermission(state.myRoles, "network.manage", [network?.id]);

            const {error} = (await (rights ? API.Users.getModel(model.id, onError, network?.id) : API.Users.getSimpleModel(model.id, onError, network?.id)));
            if (error?.code === 1021) {
                setNetworkInDialog(network);
                setSubscribeUserDialog(true);
            }
            else if (error?.code === 200) {
                const {error} = await API.RolesManager.addUserRole(settings, onError);

                if (error.code === 200) userDispatch({type: "UPDATE_USER_ROLES_LIST", payload: true});
                else onError(new Error(error.message));

                setSelectedRoleTargetType(null);
                setSelectedRole({label: "Choose role", value: ""});
            }
            else console.log("error code", error);

            setIsAddBtnLoading(false);
        }
    };
    const onRoleChanged = selected => {
        setSelectedRole(selected);
        const targetType = state.roles[selected.value]?.targetType;
        setSelectedRoleTargetType(targetType?.length > 0 ? targetType : "");
    };

    const subscribeUserToCurrentNetwork = useCallback(async () => {
        setAgreeBtnDisabled(true);

        const result = await API.Users.subscribeUserToNetwork(networkInDialog?.id, model.id, onError);

        if (result?.error?.code === 200) {
            mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Success",
                    text: "User has successfully subscribed to the network!",
                    color: "success"
                }
            });
        }
        else onError(new Error(result?.error?.message));

        const settings = {
            userId: model.id,
            roleId: selectedRole.value
        };
        const {error} = await API.RolesManager.addUserRole(settings, onError);

        if (error.code === 200) userDispatch({type: "UPDATE_USER_ROLES_LIST", payload: true});
        else onError(new Error(error.message));

        setSelectedRoleTargetType(null);
        setSelectedRole({label: "Choose role", value: ""});

        setAgreeBtnDisabled(false);
        setSubscribeUserDialog(false);
    }, [networkInDialog?.value, model.id, selectedRole.value]);

    const canSetNetworkAdminRole = () => {
        return (selectedRoleTargetType === "network" || selectedRoleTargetType === "room" || selectedRoleTargetType === "prop");
    };
    const canSetRoomAdminRole = () => {
        return (selectedRoleTargetType === "room" || selectedRoleTargetType === "prop");
    };
    const canSetPropAdminRole = () => {
        return (selectedRoleTargetType === "prop");
    };

    const getRolesOptions = () => {
        return Object.values(state.roles).map(el => {
            if (isSuperAdmin() || (isNetworkAdmin() && el.targetType !== "")){
                return {
                    label: el.roleName,
                    value: el.id
                };
            }
        }).filter(el => !!el);
    };

    const Actions = ({userId, roleId, targetId}) => {
        const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);

        const onDeleteRole = async () => {
            setIsDeleteButtonLoading(true);

            const {error} = await API.RolesManager.deleteUserRole({
                userId,
                roleId,
                targetId
            }, onError);

            if (error.code === 200) userDispatch({type: "UPDATE_USER_ROLES_LIST", payload: true});
            else onError(new Error(error.message));

            setIsDeleteButtonLoading(false);
        };

        return (
            <div className="d-flex justify-content-center">
                <StyledButton id="add-role-button" type="danger" size="sm"
                              title={<>Delete <i className="ml-2 fas fa-trash-alt"/></>}
                              onClick={onDeleteRole}
                              disabled={isDeleteButtonLoading}/>
            </div>
        );
    };

    return (
        <>
            <div className="mb-5">
                <div className="card card-primary card-outline p-2">
                    <h3 className="px-3">Add new role: </h3>
                    <div className="row px-3 mx-0 mt-3">
                        <div className={`col-${selectedRoleTargetType !== null ? 10 : 12}`}>
                            <div className="row select-styles-reset">
                                <Select styles={customStyles(true)} isLoading={isLoadingRolesList}
                                        onChange={onRoleChanged} value={selectedRole} isSearchable={false}
                                        defaultValue={selectedRole} options={getRolesOptions()}/>
                            </div>
                        </div>
                        {selectedRoleTargetType !== null && (
                            <div className="col-2 d-flex align-items-start">
                                <StyledButton onClick={add} disabled={isAddBtnLoading} noLoading={isAddBtnLoading}
                                              type="success"
                                              title={<>Add <i className="ml-3 fas fa-plus-circle"/></>}/>
                            </div>
                        )}
                    </div>

                    {selectedRoleTargetType !== null && selectedRoleTargetType !== "" && (
                        <>
                            <h5 className="px-3 mt-3">Role params: </h5>
                            <div className="row px-3 mx-0 mt-2">
                                {canSetNetworkAdminRole() && (!isSuperAdmin() && isNetworkAdmin()
                                    ? <h6>Network : #{Utils.getNetwork()}</h6>
                                    : (
                                        <div className="d-flex align-items-center w-100">
                                            <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select network: </h6>
                                            <ActiveNetworks network={network} settings={{preLoadedNetwork: true}}
                                                            onChange={selectedNetwork => setNetwork(selectedNetwork)}/>
                                        </div>
                                    )
                                )}

                                <div className="my-2 w-100">
                                    {canSetRoomAdminRole() && (
                                        <div className="d-flex align-items-center w-100">
                                            <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select room: </h6>
                                            <AllRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} networkId={network?.id}/>
                                        </div>
                                    )}
                                </div>

                                {canSetPropAdminRole() && (
                                    <div className="d-flex align-items-center w-100">
                                        <h6 className="text-nowrap" style={{minWidth: "8rem"}}>Select prop: </h6>
                                        <AllProps prop={prop} onChange={selectedProp => setProp(selectedProp)} networkId={network?.id} roomId={room?.id}/>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div id="table-container">
                <SimpleCustomTable data={Object.values(userState.roles)} settings={{rowHeight: Utils.RemToPx(4)}}
                                   columns={[
                                       {
                                           name: "roleName",
                                           header: "Role name",
                                           cell: rowData => rowData.roleName
                                       },
                                       {
                                           name: "targetType",
                                           header: "Target type",
                                           cell: rowData => rowData.targetType
                                       },
                                       {
                                           name: "targetId",
                                           header: "Target Id",
                                           cell: rowData => <i>{rowData.targetId}</i>
                                       },
                                       {
                                           name: "actions",
                                           header: "Actions",
                                           cell: rowData => <Actions userId={model.id} roleId={rowData.id} targetId={rowData.targetId} />
                                       },
                                   ]}/>
            </div>

            <Dialog open={showSubscribeUserDialog} onClose={() => setSubscribeUserDialog(false)}>
                <DialogTitle>
                    Subscribe user to network?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        Do you want to add the current user ({`${model.firstName} ${model.lastName}`}) to this network ({networkInDialog?.label})?
                        By pressing "Agree" you will add user to this network immediately!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={() => setSubscribeUserDialog(false)} title="Disagree" type="danger"
                                  variant="contained"/>
                    <StyledButton onClick={subscribeUserToCurrentNetwork} autoFocus disabled={agreeBtnDisabled}
                                  title="Agree" type="success" variant="contained"/>
                </DialogActions>
            </Dialog>
        </>
    );
}