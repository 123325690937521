// Copyright © 2015-2023 Roomful Co. All rights reserved

import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { BOOTSTRAP } from "./bootstrap";

// Default styles
import "./styles/reset.css";
import "fontsource-roboto";

// Set theme
import {ThemeProvider} from '@material-ui/styles';
import {THEME} from "./styles/theme";
import Template from "./pages/Template/Template";

// Providers
import { RequireAuth } from './scripts/RequireAuth';
import { MainProvider } from "./stores/MainStore";
import { UserProvider } from "./stores/UsersStore";
import { ChatsProvider } from "./stores/ChatsStore";
import { NetworksProvider } from "./stores/NetworksStore";
import { RoomProvider } from "./stores/RoomsStore";
import { RolesProvider } from "./stores/RolesStore";
import { CMSProvider } from "./stores/CMSStore";
import { PropsProvider } from "./stores/PropsStore";
import { GamificationRankingProvider } from "./stores/GamificationRankingStore";
import { KaraokeProvider } from "./stores/KaraokeStore";
import { SocialEventsProvider } from "./stores/SocialEventsStore";

// System pages
import AccessRestricted from "./pages/AccessRestricted/AccessRestricted";
import NotFound from "./pages/404/NotFound";

// Basic pages
import Auth from "./pages/Auth/Auth";
import Dashboard from "./pages/Dashboard/Dashboard";
import Profile from "./pages/Profile/Profile";
import AnalyticsReport from "./pages/AnalyticsReport/AnalyticsReport";
import RoleManager from "./pages/RolesManager/RolesManager";
import Contacts from "./pages/Contacts/Contacts";
import Chats from "./pages/Chats/Chats";
import GamificationRanking from "./pages/GamificationRanking/GamificationRanking";
import SocialEvents from "./pages/SocialEvents/SocialEvents";
import SocialEventTickets from "./pages/SocialEvents/Tickets";
import Karaoke from "./pages/Karaoke/Karaoke";
import { EventsWrapper } from "./pages/Chats/events";

// Network pages
import NetworksList from "./pages/NetworksList/NetworksList";
import Network from "./pages/Network/Network";
import CreateNetwork from "./pages/CreateNetwork/CreateNetwork";

// Room pages
import RoomsList from "./pages/RoomsList/RoomsList";
import MyRoomsList from "./pages/RoomsList/MyRoomsList";
import TemplatesRoomsList from "./pages/RoomsList/TemplatesRoomsList";
import Room from "./pages/Room/Room";

// Prop pages
import PropsList from "./pages/PropsList/PropsList";
import Prop from "./pages/Prop/Prop";

// User pages
import UsersList from "./pages/UsersList/UsersList";
import User from "./pages/User/User";
import TeamMemberPropsList from "./pages/PropsList/TeamMembers";

// CMS pages
import MyContent from "./pages/CMS/pages/MyContent/MyContent";
import MyContentAddFilesPage from "./pages/CMS/pages/MyContent/AddFilesPage";
import RoomContributionPanel from "./pages/CMS/pages/RoomContributionPanel/RoomContributionPanel";
import RoomContributionPanelAddFilesPage from "./pages/CMS/pages/RoomContributionPanel/AddFilesPage";
import PropContent from "./pages/CMS/pages/PropContent/PropContent";
import PropContentAddFilesPage from "./pages/CMS/pages/PropContent/AddFilesPage";
import {SocialEventTicketsProvider} from "./stores/TicketsStore";

const APPLICATION = () => {
    return (
<MainProvider>
<UserProvider>
<ThemeProvider theme={THEME}>
<BrowserRouter>
    <Routes>
        <Route path='/' element={<Auth/>}/>
        <Route path="/auth" element={<Auth/>}/>
        <Route path="*" element={
            <RequireAuth>
            <ChatsProvider>
            <NetworksProvider>
            <RoomProvider>
            <PropsProvider>
            <RolesProvider>
            <CMSProvider>
            <GamificationRankingProvider>
            <KaraokeProvider>
            <SocialEventsProvider>
            <SocialEventTicketsProvider>
            <EventsWrapper>
            <Template>
                <Routes>
                    <Route path='/home' element={<Dashboard/>}/>
                    <Route path='/dashboard' element={<Dashboard/>}/>
                    <Route path='/profile' element={<Profile/>}/>
                    <Route path='/contacts' element={<Contacts/>}/>
                    <Route path='/analytics' element={<AnalyticsReport/>}/>
                    <Route path='/roles' element={<RoleManager/>}/>
                    <Route path='/chats' element={<Chats/>}/>
                    <Route path="/gamificationRanking" element={<GamificationRanking/>}/>
                    <Route path="/socialEvents" element={<SocialEvents/>}/>
                    <Route path="/socialEvents/tickets" element={<SocialEventTickets/>}/>
                    <Route path="/karaoke" element={<Karaoke/>}/>

                    <Route path='/users/:tab' element={<UsersList/>}/>
                    <Route path='/user/:id' element={<User/>}/>

                    <Route path='/rooms/list' element={<RoomsList/>}/>
                    <Route path='/rooms/my' element={<MyRoomsList/>}/>
                    <Route path='/rooms/templates' element={<TemplatesRoomsList/>}/>
                    <Route path='/room/:roomId' element={<Room/>}/>
                    <Route path='/room/:roomId/:tab' element={<Room/>}/>
                    <Route path='/room/:roomId/prop/:propId' element={<Prop/>}/>

                    <Route path='/props/list' element={<PropsList/>}/>
                    <Route path='/props/my' element={<TeamMemberPropsList/>}/>

                    <Route path='/networks/create' element={<CreateNetwork/>}/>
                    <Route path='/networks/list' element={<NetworksList/>}/>
                    <Route path='/network/:id' element={<Network/>}/>

                    <Route path='/cms' element={<MyContent/>} exact/>
                    <Route path='/cms/prop' element={<PropContent/>} exact/>
                    <Route path='/cms/room/:roomId/prop/:propId' element={<PropContent/>} exact/>
                    <Route path='/cms/room/:roomId/prop' element={<PropContent/>} exact/>
                    <Route path='/cms/room/:roomId' element={<RoomContributionPanel/>} exact/>
                    <Route path='/cms/room' element={<RoomContributionPanel/>} exact/>

                    <Route path='/cms/upload' element={<MyContentAddFilesPage/>} exact/>
                    <Route path='/cms/upload/prop' element={<PropContentAddFilesPage/>} exact/>
                    <Route path='/cms/upload/room/:roomId/prop/:propId' element={<PropContentAddFilesPage/>} exact/>
                    <Route path="/cms/upload/room/:roomId" element={<RoomContributionPanelAddFilesPage/>} exact/>

                    <Route path='/accessrestricted' element={<AccessRestricted/>}/>
                    <Route path='/notfound' element={<NotFound/>}/>
                </Routes>
            </Template>
            </EventsWrapper>
            </SocialEventTicketsProvider>
            </SocialEventsProvider>
            </KaraokeProvider>
            </GamificationRankingProvider>
            </CMSProvider>
            </RolesProvider>
            </PropsProvider>
            </RoomProvider>
            </NetworksProvider>
            </ChatsProvider>
            </RequireAuth>
        }/>
    </Routes>
</BrowserRouter>
</ThemeProvider>
</UserProvider>
</MainProvider>
    );
};

BOOTSTRAP();

ReactDOM.render(<APPLICATION/>, document.getElementById('root'));

