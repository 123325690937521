// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect} from "react";
import {ChatsStore} from "../../../stores/ChatsStore";
import {useStyles} from "../style";
import Utils from "../../../scripts/Utils";

// MUI
import Image from 'material-ui-image';
import {Star} from "@material-ui/icons";
import {CircularProgress, IconButton} from '@material-ui/core';

export const Interlocutor = props => {
    const {onInterlocutorSelected, data: {opponentUser, textchat}} = props;
    const classes = useStyles();
    const {state, dispatch} = useContext(ChatsStore);

    const getChatMode = type => {
        if (type === "direct") return " (Direct chat)";
        else if (type === "room") return " (Room chat)";
        else if (type === "prop") return " (Prop chat)";
        else if (type === "booth") return " (Booth chat)";
        else if (type === "social circle") return " (Social circle chat)";
        else return "";
    };

    const isActive = () => state.currentTextchat?.id ? (state.currentTextchat?.id === textchat?.id) : false;

    return (
        <li className={`${classes.interlocutor} ${isActive() ? "active" : ""}`} onClick={() => onInterlocutorSelected(props.data)}>
            <Image className="contacts-list-img" alt="User Avatar" imageStyle={{width: "2.5rem", height: "2.5rem"}}
                   style={{width: "2.5rem", height: "2.5rem", padding: 0, borderRadius: "50%", float: "left", marginRight: "1.25em", background: "none"}}
                   src={Utils.buildURLs(`/resource/${textchat?.thumbnail || opponentUser?.user?.avatar}`, false)}
                   loading={<CircularProgress/>}
            />

            <div className={`contacts-list-info ${classes.primaryColor} ml-0 w-100`}>
                <span className="contacts-list-name d-flex flex-column justify-content-center w-100 h-100">
                    <span className="w-100 d-flex justify-content-between align-items-center">
                        <span>
                            {
                                textchat?.textchatMode === "videochat"
                                    ? `ID: ${textchat.id}`
                                    : (Utils.limitStringLength(textchat?.title
                                        ? textchat?.title
                                        : `${opponentUser?.user?.firstName} ${opponentUser?.user?.lastName}`, 30))
                            }
                        </span>
                        <span className="d-flex align-items-center">
                            {Number(textchat?.unreadMessageCount) !== 0
                                    && <span className="badge badge-info right">{textchat?.unreadMessageCount}</span>}
                            {textchat?.isImportant && <Star className="is-important"/>}
                        </span>
                    </span>

                    <div className="w-100 d-flex justify-content-between align-items-end">
                        <small>
                            {`${opponentUser?.user?.companyName ?? ""} ${opponentUser?.user?.companyTitle 
                                ? `, ${opponentUser?.user?.companyTitle}` 
                                : ""}`}
                            {getChatMode(textchat?.textchatSourceType)}
                        </small>
                        <small className={`contacts-list-date float-right ${classes.secondaryColor}`}>
                            {Utils.formatDate(new Date(textchat?.lastMessageTime))}
                        </small>
                    </div>
                </span>
            </div>
        </li>
    );
};

export const NoInterlocutors = () => {
    return (
        <div className="h-100 d-flex justify-content-center align-items-center">
            <h4 style={{color: "rgb(33, 37, 41)"}}>No contacts...</h4>
        </div>
    );
};