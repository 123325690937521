// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";

export const StyledProgressBar = props => {
    const {title, now, min, max} = props;

    return (
        <div className="row align-items-center mb-3">
            <p style={{margin: "0 32px"}}>{title}</p>
            <div className="progress" style={{width: "200px"}}>
                <div className="progress-bar bg-success" role="progressbar" aria-valuenow={now} aria-valuemin={min}
                     aria-valuemax={max} style={{width: `${now}%`}}>
                    <span className="sr-only">{now}% Complete (success)</span>
                </div>
            </div>
        </div>
    );
};
