// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import {Link} from "react-router-dom";

export default function ContentHeader(props) {
    const {title, breadcrumbs, hasContentHeader = true} = props;

    const hasBreadcrumbs = breadcrumbs && breadcrumbs.length > 0;

    return (
        hasContentHeader ? (
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{title}</h1>
                        </div>
                        {hasBreadcrumbs &&
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    {breadcrumbs.map((breadcrumb, index) =>
                                        <li className={`breadcrumb-item ${breadcrumb.active ? "active" : ""}`}
                                            key={index}>
                                            <Link to={breadcrumb.link || ""}>{breadcrumb.title}</Link>
                                        </li>)}
                                </ol>
                            </div>
                        }
                    </div>
                </div>
            </section>
        ) : <div className="mb-3"/>
    );
}