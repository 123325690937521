// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch, parseSpreadsheetRequest} from "../Requests";

export class NetworksAPI {
    static async getAllActive(onError) {
        return await customFetch({path: "/jsonRpc/network.listActiveNetworkModels", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getUserNetworks(onError) {
        return await customFetch({path: "/jsonRpc/network.listUserNetworks", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getAll(onError) {
        return await customFetch({path: "/jsonRpc/network.listNetworkModels", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchNetworkModels(body, onError) {
        return await customFetch({path: "/jsonRpc/network.searchNetworkModels", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getModel(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.getNetworkModel", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getPrivateModel(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.getPrivateNetworkModel", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getNetworkInfo(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.getNetworkInfo", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async parseNetworkParticipantSpreadsheet(body, onError) {
        return await parseSpreadsheetRequest({path: "/files/parseNetworkParticipantSpreadsheet", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async addParticipant(body, onError) {
        return await customFetch({path: "/jsonRpc/network.addNetworkParticipant", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async activate(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.activateNetwork", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async disable(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.disableNetwork", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async setSettings(settings, onError) {
        return await customFetch({path: "/jsonRpc/network.setNetworkSettings", body: settings})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async setThumbnail(networkId, thumbnail, onError) {
        return await customFetch({path: "/jsonRpc/network.setNetworkThumbnail", body: {networkId, thumbnail}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async generatePluginSettings(networkId, onError) {
        return await customFetch({path: "/jsonRpc/network.generateNetworkPluginSettings", body: {networkId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async setNetworkPluginSettings(settings, onError) {
        return await customFetch({path: "/jsonRpc/network.setNetworkPluginSettings", body: settings})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async createNetwork(networkSettings, onSuccess, onError) {
        return await customFetch({path: "/jsonRpc/network.createNetwork", body: networkSettings})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}