// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";

import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {PropsStore} from "../../../stores/PropsStore";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {Box, Checkbox, CircularProgress, IconButton, InputAdornment, makeStyles, TextField} from "@material-ui/core";
import {ArrowDropDown, ArrowDropUp, Done, Loop, Tune} from '@material-ui/icons';
import Image from 'material-ui-image';
import Select from "react-select";

// Components
import {StyledImage} from "../../styled/StyledImage";
import {StyledButton} from "../../styled/StyledButton";
import {AllRooms} from "../Rooms/AllRooms";
import {UserRooms} from "../Rooms/UserRooms";

// Style
import useStyles from "../../../styles/dropdownStyles";
import {customStyles} from "../../../styles/dropdown";

let timeout = null;
let prevBody = {};
let requestBody = {};

const useFiltersStyles = makeStyles((theme) => ({
    filter: {
        width: "3rem",
        height: "3rem",

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    pad: {
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 1200,

        width: "100%",
        height: "100vh",
        display: "none",

        "&.show": {
            display: "block"
        }
    },
    filtersDropdownMenu: {
        display: "none",
        "&.show": {
            display: "block"
        },
        "&.left-menu": {
            transform: "translate(-79%)"
        },

        "&>.dropdown-item" : {cursor: "pointer"},
        "&>.dropdown-item.noActiveStyles:not(.active):active" : {
            color: "#212529 !important",
            backgroundColor: "transparent !important"
        },
        "&>a.noActiveStyles>div input": {
            padding: "0.5em 0 0.5em 0.75em"
        }
    },
    activeFilters: {
        borderTop: "1px solid #e8e8e8",
        height: "2.5rem",
        fontSize: "0.8rem",
        paddingRight: "2.5rem !important",

        "&>span": {
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
            padding: "2.5px 5px",
            color: "#474747",
            background: "#e8e8e8",

            "&>span": {padding: 0}
        },
        "&>i": {
            position: "absolute",
            top: 0,
            right: 0,
            padding: 0,
            width: "2.5rem",
            height: "2.5rem"
        }
    },
    filterButton: {
        background: "#fff",
        border: "1px solid hsl(0, 0%, 80%)",
        transition: "0.15s all easy-in-out",
        borderRadius: "4px",
        cursor: "pointer",

        "&:hover": {
            background: "hsl(0, 0%, 95%)"
        }
    }
}));

export const UserPropsWithFilters = props => {
    const {prop, onChange, networkId = null} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(PropsStore);
    const {state: usersState} = useContext(UsersStore);

    const isSuperAdmin = () => Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = () => Utils.isNetworkAdmin(usersState.myRoles);

    const classes = useStyles();
    const filtersClasses = useFiltersStyles();
    const readOnly = true; // prevent all searching functional

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const [selectedNetworkId] = useState(networkId || Utils.getNetwork());
    const [value, setValue] = useState(prop);
    const [isLoading, setIsLoading] = useState(false);

    // Filters state
    const [showConfigMenu, setShowConfigMenu] = useState(false);
    const [room, setRoom] = useState(null);
    const [objectType, setObjectType] = useState({label: "Booth and File cabinet", value: ["booth", "fileCabinet"]});
    const [showOnlyTeamMemberObjects, setShowOnlyTeamMemberObjects] = useState(false);

    // Base state
    const [needRender, setNeedRender] = useState(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);
    const [total, setTotal] = useState(0);

    // Search state
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);

    // Buttons, dropdown state, errors state, etc
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isErrorInInput, setIsErrorInInput] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);

    const loadMoreItems = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);
        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };
    const setList = (list) => {
        dispatch({type: 'SET_LIST', payload: list});
    };

    const getListItems = async (body, list) => {
        if (!loadMoreActive) setIsLoading(true);

        const data = (await (API.Rooms.getUserPropsList(body, onError, networkId))).data;

        const items = data?.props ?? [];
        setTotal(data?.total ?? 0);

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && items.length > 0) ? list : [];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);

        setList(itemsToAdd);
        if (!loadMoreActive) setValue(itemsToAdd[0]);

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setSearchBtnLoading(false);
    };

    const applyFilters = useCallback(() => {
        requestBody = {
            asPropManager: showOnlyTeamMemberObjects,
            propTypes: objectType?.value,
            roomId: room?.id && room?.id !== "all" ? room?.id : ""
        };

        setShowConfigMenu(false);
    }, [objectType?.value, room?.id, showOnlyTeamMemberObjects]);

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(true);
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !loadMoreActive && (offset + size < total)) loadMoreItems();
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        clearTimeout(timeout);
        timeout = setTimeout(search, 1000);
        setPrevSearchInputText(searchInputText);
        setSearchInputText(e.target.value);
    };
    const onItemSelected = (e, value) => {
        setValue(value);
        setIsDropdownOpen(false);
        setIsErrorInInput(false);
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (needRender && !isLoading) {
                const body = {
                    offset,
                    size,
                    roomId: room?.id === "all" ? "" : room?.id,
                    asPropManager: showOnlyTeamMemberObjects,
                    propTypes: objectType.value
                };

                if (Utils.isObjectEmpty(prevBody) || (prevBody.offset !== body.offset) || (prevBody.size !== body.size)
                    || (prevBody.roomId !== body.roomId) || (prevBody.asPropManager !== body.asPropManager) || !Utils.isArraysIdentical(prevBody.propTypes, body.propTypes)) {
                    await getListItems(body, state.list);
                    prevBody = body;
                    setIsLoading(false);
                }
            }
        })();
    }, [needRender]);

    useEffect(() => {
        if (requestBody) {
            setSearchBtnLoading(false);
            setOffset(0);
            setList([]);
            setNeedRender(true);
        }
    }, [requestBody]);

    useEffect(() => {
        return () => {
            dispatch({type: 'CLEAR_LIST'});

            setValue(null);
            setIsLoading(false);
            setOffset(0);
            setSearchInputText("");
            setPrevSearchInputText("");
            setLoadMoreActive(false);
            setIsDropdownOpen(false);
            setIsErrorInInput(false);
            setSearchBtnLoading(false);

            prevBody = {};
            requestBody = {};
            timeout = null;
        };
    }, []);
    useEffect(() => {
        if (value) setIsLoading(false);

        if (onChange && typeof onChange === "function") onChange(value);
    }, [value]);

    const isSearchActive = typeof searchInputText === "string" && searchInputText.length > 0

    return (
        <div className="d-flex w-100">
            {((state.list?.length > 0) && !isLoading) || isSearchActive
            ?
            <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                          renderInput={params => (
                              <div className="d-flex align-items-center">
                                  {value?.assetId && <Image imageStyle={{
                                      position: "initial",
                                      margin: "0.25rem",
                                      width: "3rem",
                                      height: "calc(3.5rem - 0.5rem - 2px)",
                                      objectFit: "cover"
                                  }} style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      background: "#fff",
                                      padding: 0,
                                      border: "1px solid hsl(0, 0%, 80%)",
                                      borderRight: 0,
                                      borderTopRightRadius: 0,
                                      borderTopLeftRadius: "4px",
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: "4px",
                                  }} src={Utils.buildURLs(`/asset/thumbnail/${value?.assetId}`,
                                      false, null, {networkId: selectedNetworkId})}/>}
                                  <TextField {...params} variant="outlined" value={searchInputText}
                                             onChange={onSearchInputChange} error={isErrorInInput} size="small"
                                             InputProps={{
                                                 readOnly,
                                                 ...params.InputProps,
                                                 style: {
                                                     borderTopLeftRadius: 0,
                                                     borderBottomLeftRadius: 0,
                                                     height: "3.5rem"
                                                 },
                                                 endAdornment: (
                                                     <InputAdornment position="end">
                                                         {searchBtnLoading
                                                             ? <IconButton edge="end"><Loop/></IconButton>
                                                             :
                                                             <IconButton edge="end" onClick={onOpenDropdown}>
                                                                 {isDropdownOpen ? <ArrowDropUp/> :
                                                                     <ArrowDropDown/>}
                                                             </IconButton>}
                                                     </InputAdornment>
                                                 ),
                                                 onKeyDown: e => {
                                                     if (e.key === 'Enter') {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         return;
                                                     }
                                                 },
                                                 onClick: () => setIsDropdownOpen(true)
                                             }}
                                             onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                                  />
                              </div>
                          )} style={{width: "100%"}} freeSolo disableClearable forcePopupIcon={false}
                          open={isDropdownOpen}
                          value={value} onChange={onItemSelected} filterOptions={options => options}
                          options={state.list}
                          getOptionLabel={option => {
                              return (`${option?.title === "" ? `[${option?.assetTitle ?? ""}]` : option?.title} (#${option?.id})`)
                          }}
                          renderOption={option => (
                              <Box data-id={option?.id} className="d-flex align-items-center"
                                   style={{height: `${Utils.RemToPx(4.125)}px`}}>
                                  <StyledImage size={3} src={Utils.buildURLs(`/asset/thumbnail/${option?.assetId}`,
                                      false, null, {networkId: selectedNetworkId})}/>

                                  <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                                  <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                                      {option?.title === "" ? `[${option?.assetTitle ?? ""}]` : option?.title}
                                  </span>
                                      <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                                      {option?.id} /
                                          {option?.propTypes?.indexOf("booth") > -1
                                              ? " Booth"
                                              : (option?.propTypes?.indexOf("fileCabinet") > -1 ? " File Cabinet" : " Prop")}
                                  </span>
                                  </div>
                              </Box>
                          )}
            />
            : (
                <div style={{
                    border: "1px solid hsl(0, 0%, 80%)",
                    background: "#fff",
                    width: "100%", height: "3.5rem",
                    padding: "9px", borderRadius: "4px"
                }} className="d-flex align-items-center">
                    {isLoading
                        ? <><CircularProgress className="mr-3"/> Loading...</>
                        : "No props in this room"}
                </div>
            )}

            <div className={`input-group-prepend position-relative ml-2`}>
                <div
                    className={`d-flex align-items-center pl-3 pr-4 ${filtersClasses.filterButton}`}
                    style={{height: "3.5rem"}} onClick={() => setShowConfigMenu(true)}>
                    <Tune style={{color: "hsl(0, 0%, 50%)"}}/>
                    <h6 className="mb-0 ml-2">Filters</h6>
                </div>

                <div id="pad" className={`${showConfigMenu ? "show" : ""} ${filtersClasses.pad}`}
                     onClick={() => setShowConfigMenu(false)}/>

                <div
                    className={`dropdown-menu ${showConfigMenu ? "show" : ""} ${filtersClasses.filtersDropdownMenu} left-menu`}
                    style={{width: `${Utils.RemToPx(35)}px`}}>
                    <div className={`row flex-column m-0 p-3`}>
                        <div className="w-100 mt-2 d-flex flex-column">
                            <h6>Select room:</h6>
                            {
                                isSuperAdmin() || isNetworkAdmin()
                                    ? <AllRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} allowAll={true}/>
                                    : <UserRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)} allowAll={true}/>
                            }
                        </div>
                        <div className={`w-100 mt-3 mb-3 d-flex flex-column`}>
                            <h6>Select prop type:</h6>
                            <Select value={objectType} onChange={selected => setObjectType(selected)}
                                    options={["All", "Booth", "File cabinet", "Booth and File cabinet"].map((el, index) => ({
                                            label: el,
                                            value: (
                                                index === 0 ? [] :
                                                    index === 1 ? ["booth"] :
                                                        index === 2 ? ["fileCabinet"] :
                                                            index === 3 ? ["booth", "fileCabinet"] : []
                                            )
                                        }
                                    ))}
                                    styles={customStyles(true)}
                            />
                        </div>
                        <div className="w-100 d-flex flex-column align-items-start">
                            <h6 className="mb-0">Show only props administrated by me:</h6>
                            <div className="d-flex align-items-center">
                                <Checkbox checked={showOnlyTeamMemberObjects} color="primary"
                                          onChange={e => setShowOnlyTeamMemberObjects(e.target.checked)}/>
                                <b>Yes</b>
                            </div>
                        </div>
                        <div className={`w-100 d-flex justify-content-end`}>
                            <StyledButton onClick={applyFilters} type="success"
                                          title={<><h6 className="mb-1 mr-2">Apply</h6> <Done/></>}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}