// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import {useStyles} from "../../../style";
import {API} from "../../../../../api/API";
import Utils from "../../../../../scripts/Utils";
import {Store} from "../../../../../stores/MainStore";
import {CMSStore} from "../../../../../stores/CMSStore";

// MUI
import Image from 'material-ui-image';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import {MyAccordion, MyAccordionDetails} from "../../../../../components/styled/StyledAccordion";
import SimpleCustomTable from "../../../../../components/table/SimpleCustomTable";
import {MyItem} from "../components/MySortableTable";
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {StyledInputWithButton} from "../../../../../components/styled/StyledInput";

let timeout = null;

export default function PixabayUpload () {
	const navigate = useNavigate();
	const classes = useStyles();

	// Context
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(CMSStore);

    // Base state
	const [urlsList, setUrlsList] = useState([]);
    const [query, setQuery] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [isDone, setIsDone] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [feedbackTable, setFeedbackTable] = useState({});

    // Handlers
    const onError = error => {
    	mainDispatch({
			type: "SET_MODAL_SETTINGS",
			payload: {
				show: true,
				title: "Error!",
				text: error.message,
				color: "default"
			}
		});
	};
    const onDelete = index => {
		const newUrlsList = [...urlsList];
        newUrlsList.splice(index, 1);
        setUrlsList(newUrlsList);
	};
	const onClear = () => {
		setUrlsList([]);
        setQuery("");
        setIsUploading(false);
        setIsDone(false);
        setIsSearching(false);
        setFeedbackTable({});
	};
	const onUploadFiles = useCallback( async () => {
        setIsUploading(true);
        dispatch({type: "SET_CAN_LEAVE", payload: false});
        setIsDone(true);

        setQuery("");
        setUrlsList([]);
        dispatch({type: "CLEAR_SELECTED_ITEMS"});

        const selectedItems = urlsList.filter(singleImg => state.selectedItemsIds.indexOf(`${singleImg?.id}`) > -1);

        for (let i = 0; i < selectedItems.length; i++) {
            setItemToFeedbackTable({
                id: i,
                title: selectedItems[i]?.previewURL?.substring(selectedItems[i]?.previewURL?.lastIndexOf('/') + 1),
                image: selectedItems[i]?.previewURL,
                status: "Uploading..."
            });
        }

        await Utils.awaitAll(selectedItems, async (item, index) => {
            const result = await API.Resource.uploadRemoteImage({
				addToSortingTable: true,
                source: "pixabay",
                sourceId: `${item?.id}`,
                sourceLink: item?.previewURL,
                url: item?.previewURL
            }, onError);

            setItemToFeedbackTable({
                id: index,
                title: item?.previewURL?.substring(item?.previewURL?.lastIndexOf('/') + 1),
                image: item?.previewURL,
                status: result && typeof result === "object" ? "Finished" : "Failed"
            });
        });

        setIsUploading(false);
        dispatch({type: "SET_CAN_LEAVE", payload: true});
        setIsSearching(false);
    }, [state.selectedItemsIds]);
	const search = async () => {
        setIsDone(false);
        const result = (await API.Resource.searchPixabayImages({query}, onError))?.data?.hits;
        setUrlsList(result);
        setIsSearching(false);
    };

	// Main functions
	const goBack = () => navigate("/cms");
	const setItemToFeedbackTable = item => setFeedbackTable(prevState => ({...prevState, [item?.id]: item}));

    // Effects
	useEffect(() => {
		dispatch({type: "CLEAR_SELECTED_ITEMS"});
	}, []);
	useEffect(() => {
		if (query?.length > 0 && !isSearching) {
			clearTimeout(timeout);
			timeout = setTimeout(() => {
				setIsSearching(true);
				search();
			}, 1000);
		}
	}, [query]);


    return (
		<div className="container-fluid card p-3">
			<section className="content">
				<div className="container-fluid">
					<div className="d-flex flex-column pt-3">

						{!isDone && (
							<div className="d-flex align-items-start">
								<StyledInputWithButton placeholder="Search images..." fullWidth
													   value={query} type="text"
													   onChange={e => setQuery(e.target.value)}
													   onIconClick={search}
													   onKeyDown={e => e.key === "Enter" ? search() : false}/>
							</div>)}

						{(Object.values(feedbackTable))?.length > 0 && (
							<div className="py-3">
								<MyAccordion square defaultExpanded={true}>
									<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
										<h5 style={{marginBottom: 0}}>Resource upload feedback table</h5>
									</AccordionSummary>
									<MyAccordionDetails>
										<div id="table-container">
											<SimpleCustomTable data={Object.values(feedbackTable).map(el => el)}
															   columns={[
																   {
																	   name: "image",
																	   header: "Image",
																	   cell: rowData => (
																		   <Image src={rowData?.image} imageStyle={{
																			   position: "initial",
																			   width: "6rem", height: "6rem",
																			   objectFit: "cover",
																		   }} style={{
																			   display: "flex",
																			   justifyContent: "center",
																			   background: "transparent",
																			   padding: 0
																		   }}/>
																	   ),
																	   isCustom: true
																   },
																   {
																	   name: "resourceName",
																	   header: "Resource name",
																	   cell: rowData => rowData?.title
																   },
																   {
																	   name: "status",
																	   header: "Status",
																	   cell: rowData => rowData?.status
																   },
															   ]}/>
										</div>
									</MyAccordionDetails>
								</MyAccordion>
							</div>)}

						{urlsList.length > 0 && (
							<div className={classes.uploadedFiles}>
								{urlsList.map((singleUrl, index) =>
									<MyItem key={`item-${index}`} disabled onError={onError} settings={{canSelect: true}}
													isCustom={true} callbacks={{onDeleteCustom: () => onDelete(index)}}
													value={{
														id: `${singleUrl?.id}`,
														title: singleUrl?.previewURL?.substring(singleUrl?.previewURL?.lastIndexOf('/') + 1),
														filePreview: singleUrl?.previewURL,
														metadata: {
															fileName: singleUrl?.previewURL?.substring(singleUrl?.previewURL?.lastIndexOf('/') + 1),
															contentType: "image/jpeg"
														}
													}}/>
								)}
							</div>)}

						<div className="mt-4 d-flex justify-content-between align-items-center">
							<div className="controls">
								{!isDone && (`Files on request: ${urlsList.length ?? 0}`)}
								{state.selectedItemsIds?.length > 0 && <span className="ml-3">Selected items: {state.selectedItemsIds?.length} </span>}
							</div>

							{
								!isDone
									? (urlsList?.length > 0
									? (
										<div className="d-flex">
											<StyledButton title="Clear queue" type="default" noLoading
														  onClick={() => setUrlsList([])} size="lg"/>
											<div className="ml-3">
												<StyledButton onClick={onUploadFiles} type="warning"
															  title={isUploading ? "Uploading..." : "Upload files"}
															  size="lg" disabled={isUploading}/>
											</div>
										</div>
									)
									: null)
									: (
										<div className="d-flex">
											<StyledButton title="Clear and upload more" type="warning" noLoading
														  disabled={!state?.canLeave}
														  onClick={onClear} size="lg"/>
											<div className="ml-3">
												<StyledButton title="Back to content overview" type="secondary" noLoading
															  disabled={!state?.canLeave}
															  onClick={goBack} size="lg"/>
											</div>
										</div>
									)
							}
						</div>

					</div>
				</div>
			</section>
		</div>
    );
}