// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useNavigate} from "react-router";
import Utils from "../../../../../scripts/Utils";
import {API} from "../../../../../api/API";
import {CMSStore} from "../../../../../stores/CMSStore";
import {PropsStore} from "../../../../../stores/PropsStore";
import arrayMove from 'array-move';

// MUI
import {CircularProgress} from "@material-ui/core";

// Components
import {MySortableContainer, MySortableItem} from "./MySortableTable";
import EditPanel from "./EditPanel";
import ContextMenu from "../../../components/ContextMenu";
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {AllPropsWithFilters} from "../../../../../components/list/Props/AllPropsWithFilters";



export default function PageContent({onError}) {
	const {roomId = null, propId = null} = useParams();
	const navigate = useNavigate();

	// Context
	const {state: cmsState, dispatch: cmsDispatch} = useContext(CMSStore);
    const {state, dispatch} = useContext(PropsStore);

    // Base state
	const [isLoading, setIsLoading] = useState(true);

	const [isDeleteBtnLoading, setIsDeleteBtnLoading] = useState(false);
	const [isDeleteBtnDisabled, setIsDeleteBtnDisabled] = useState(false);

	// Prop content state
	const [prop, setProp] = useState(null);

	// Main functions
	const onDeleteSelected = async () => {
		setIsDeleteBtnDisabled(true);
		setIsDeleteBtnLoading(true);

		await API.Props.changePropContent({
			roomId: prop?.roomId,
            propId: prop?.id,
            appendToListEnd: false,
            moveToProp: [],
            removeFromProp: cmsState.selectedItemsIds
		}, onError);

		const content = state.cmsContent.filter(el => !(cmsState.selectedItemsIds.indexOf(el.id) > -1));
        dispatch({type: "SET_CMS_CONTENT", payload: content});
        cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});

        setIsDeleteBtnLoading(false);
	};
	const onPropSelected = selectedProp => {
		setProp(selectedProp);
		if (selectedProp?.id?.length > 0 && selectedProp?.roomId?.length > 0)
			navigate(`/cms/room/${selectedProp?.roomId}/prop/${selectedProp?.id}`);
	};
	const uploadFiles = useCallback(() => {
		cmsDispatch({type: "SET_SHOW_FILE_EDIT_MENU"});
		cmsDispatch({type: "SET_SHOW_ADD_TO_PROP"});
		cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});
		cmsDispatch({type: "SET_HIDE_CONTEXT_MENU"});

		if (roomId?.length > 0 && roomId !== "all" && propId?.length > 0) navigate(`/cms/upload/room/${roomId}/prop/${propId}`);
	}, [roomId, propId]);

	const onSortEnd = useCallback(({oldIndex, newIndex, nodes}) => {
		(async () => {
			dispatch({type: "SET_CMS_CONTENT", payload: arrayMove(state.cmsContent, oldIndex, newIndex)});

			await API.Props.changeResourcePosition({
				roomId: prop?.roomId,
				propId: prop?.id,
				resource: nodes[oldIndex].node.dataset.id,
				position: newIndex
			}, onError);
		})();
	}, [state.cmsContent, prop?.roomId, prop?.id]);

	// Effects
	useEffect(() => {
		(async () => {
			if (roomId && roomId?.length > 0 && roomId !== "all" && propId && propId?.length > 0
				&& (prop?.id !== propId && prop?.roomId !== roomId)) {
				const result = (await API.Props.getModel({roomId, propId}, onError));

				if (result?.error?.code === 200) setProp(result.data.prop);
				else onError(new Error(result?.error?.message));

				setIsLoading(false);
			}
		})();
	}, []);
	useEffect(() => {
		if (prop && prop?.id?.length > 0) {
			(async () => {
				setIsLoading(true);
				const items = (await API.Props.getPropContent({
					roomId: prop?.roomId,
					propId: prop?.id,
					offset: 0,
					limit: 100
				}, onError)).data.content;

				dispatch({type: "SET_CMS_CONTENT", payload: items});
				setIsLoading(false);
			})();
		}
	}, [prop?.id]);
	useEffect(() => {
		setIsDeleteBtnDisabled(cmsState.selectedItemsIds?.length === 0);
	}, [cmsState.selectedItemsIds]);

	return (
		<div className="container-fluid card p-3">
			<section className="content">
				<div className="container-fluid">

					<div className="w-100 mt-2 d-flex flex-column">
						<h6>Select prop:</h6>
						<AllPropsWithFilters prop={prop} onChange={onPropSelected}
											 settings={{preLoadedProp: (roomId && roomId?.length > 0 && propId && propId?.length > 0)}}/>
					</div>

					<div className="w-100 py-3 px-2 controls d-flex justify-content-between align-items-center">
						<div>
							<span className="mr-4">Files: {state.cmsContent?.length} </span>
							<span>Selected items: {cmsState.selectedItemsIds?.length} </span>
						</div>
						<div className="d-flex">
							<div className="mr-3">
								<StyledButton onClick={onDeleteSelected} type="danger"
											  disabled={isDeleteBtnDisabled} noLoading={!isDeleteBtnLoading}
											  title={`Delete (${cmsState.selectedItemsIds?.length} files)`} />
							</div>
							<StyledButton onClick={uploadFiles} type="warning" title="Add files"/>
						</div>
					</div>

					<hr/>

					<div className="mt-4 d-flex w-100 align-items-stretch">
						<div className="overflow-auto w-100 d-flex justify-content-between align-items-start">
							{
								isLoading
									? (
										<div className="d-flex justify-content-center align-items-center py-5 w-100">
											<CircularProgress className="mr-3"/>
											<h3>Loading...</h3>
										</div>
									)
									: (
										<MySortableContainer onSortEnd={onSortEnd} axis="xy"
															 itemsCount={state.cmsContent?.length} distance={10}>
											{state.cmsContent.map((item, index) =>
												<MySortableItem key={`item-${item.id}`} index={index} onError={onError}
																value={{
																	...item,
																	prop,
																	isLogo: cmsState.logoCount !== 0 && index < cmsState.logoCount,
																	isThumbnail: cmsState.logoCount !== 0 && index >= cmsState.logoCount && index < cmsState.thumbnailCount + cmsState.logoCount,
																	isSpecial: cmsState.contentPickerType,
																	count: index + 1,
																	index
																}}/>)}
										</MySortableContainer>
									)
							}
						</div>

						<EditPanel onError={onError}/>
					</div>

					<ContextMenu options={[
						// {
						// 	title: "Open / Download",
						// 	icon: <i className="fas fa-download"/>,
						// 	callback: () => {
						// 		window.open(`/resource/${cmsState.selectedContentID}?networkId=${Utils.getNetwork()}`, '_blank').focus();
						// 	}
						// },
						({
							title: "Edit",
							icon: <i className="fas fa-edit"/>,
							showWhenOpenedEditPanel: false,
							callback: setShow => {
								setShow(false);

								cmsDispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: true});

								const file = state.cmsContent.find(el => el.id === cmsState.selectedContentID);
								cmsDispatch({
									type: "SET_FILE_INFO",
									payload: {
										id: file.id,
										fileName: file.metadata.fileName,
										title: file.title,
										description: file.description,
										author: file.author || "",
										linkShortDescription: file.linkShortDescription || "",
										webLink: file.webLink || "",
										date: file.date,
										location: file.location
									}
								});
							}
						}),
						{
							title: cmsState.selectedItemsIds?.length > 1 ? `Delete (${cmsState.selectedItemsIds?.length} items)` : "Delete",
							icon: <i className="fas fa-trash"/>,
							callback: setShow => {
								setShow(false);

								const isMultiple = !(cmsState.selectedItemsIds?.length === 0);

								(async () => {
									await API.Props.changePropContent({
										roomId: prop?.roomId,
										propId: prop?.id,
										appendToListEnd: false,
										moveToProp: [],
										removeFromProp: isMultiple
											? cmsState.selectedItemsIds
											: [(state.cmsContent.find(el => el.id === cmsState.selectedContentID))?.id]
									}, onError);

									const content = state.cmsContent.filter(el => {
										if (isMultiple) return !(cmsState.selectedItemsIds.indexOf(el.id) > -1);
										else return !(cmsState.selectedContentID === el.id);
									});
									dispatch({type: "SET_CMS_CONTENT", payload: content});
									if (isMultiple) cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});
								})();
							}
						}
					]}/>

				</div>
			</section>
		</div>
	);
}
