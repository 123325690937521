// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {PropsStore} from "../../../stores/PropsStore";

// MUI
import Image from 'material-ui-image';
import {Checkbox, FormGroup, FormControlLabel, CircularProgress} from "@material-ui/core";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import SimpleCustomTable from "../../../components/table/SimpleCustomTable";
import {ActiveUsers} from "../../../components/list/Users/ActiveUsers";

export const TeamMembers = props => {
    const {roomId, propId, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(PropsStore);

    const [subscribeBtnLoading, setSubscribeBtnLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [allowToEditContent, setAllowToEditContent] = useState(true);

    // Main functions
    const add = async () => {
        setSubscribeBtnLoading(true);

        for (let i = 0; i < users.length; i++) {
            const userId = users[i].id;

            const result = await API.Props.inviteToProp({
                roomId,
                propId,
                invitedUser: userId,
                permissions: {
                    view: true,
                    comment: allowToEditContent,
                    contribute: allowToEditContent,
                    edit: allowToEditContent,
                    manage: allowToEditContent
                }
            }, onError);

            if (result?.error?.code === 200) {
                dispatch({type: "ADD_TEAM_MEMBERS", payload: result?.data?.invitation});
            }
            else onError(new Error("Can't invite user. Maybe you invited him already"));
        }

        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Success!",
                text: "Users added as exhibitors to current prop!",
                color: "success"
            }
        });

        setUsers([]);
        setSubscribeBtnLoading(false);
    };
    const getData = useCallback(() => {
        if (state?.teamMembers?.length > 0)
            return state?.teamMembers?.map(({invitedUser: {id, avatar, firstName, lastName}, permissions: {manage}}) => ({
                id,
                image: avatar,
                firstName,
                lastName,
                canEditContent: manage
            })).filter((v, i, a) => a.findIndex(t => (t.id === v.id)) === i);
        else return [];
    }, [state?.teamMembers]);

    // Effects
    useEffect(() => {
        (async () => {
            const result = await API.Props.getPropInvitations({roomId, propId}, onError);

            if (result?.error?.code === 200) {
                dispatch({
                    type: "SET_TEAM_MEMBERS",
                    payload: result?.data?.invitations
                });
            } else onError(new Error("Can't load prop exhibitors list!"));
        })();
    }, []);

    // Components
    const Rights = ({rowData}) => {
        const [loading, setLoading] = useState(false);

        const changeExhibitorsRight = async (value, id) => {
            setLoading(true);

            const result = await API.Props.inviteToProp({
                roomId,
                propId,
                invitedUser: id,
                permissions: {
                    view: true,
                    comment: value,
                    contribute: value,
                    edit: value,
                    manage: value
                }
            }, onError);

            if (result?.error?.code !== 200) onError(new Error("Can't change user's right to edit content"));

            dispatch({type: "CHANGE_TEAM_MEMBERS_EDIT_RIGHT", payload: {userId: id, canEdit: value}});
            setLoading(false);
        };

        return (
            <div className="d-flex justify-content-center align-items-center">
                <Checkbox checked={rowData?.canEditContent} color="primary"
                          onChange={e => changeExhibitorsRight(e.target.checked, rowData?.id)}/>
                {loading && <CircularProgress size={18}/>}
            </div>
        );
    };
    const Actions = ({rowData}) => {
        const [loading, setLoading] = useState(false);

        const onDeleteClick = async id => {
            setLoading(true);
            const result = await API.Props.deletePropInvitation({roomId, propId, invitedUser: id}, onError);

            if (result.error.code === 200) {
                const teamMembers = state?.teamMembers.filter(el => el?.invitedUser.id !== id);

                dispatch({type: "SET_TEAM_MEMBERS", payload: teamMembers});
            } else onError(new Error(result.error.message));

            setLoading(false);
        };

        return (
            <div className="d-flex">
                <div className="ml-2">
                    <StyledButton title="Delete" disabled={loading} noLoading={!loading} type="danger" size="sm"
                                  onClick={() => onDeleteClick(rowData.id)}/>
                </div>
            </div>
        );
    };

    return (
        <>
            <p className="text-muted ml-2">Add user as exhibitor to current prop</p>
            <div className="row ml-0 justify-content-start align-items-center">
                <div className="col-10">
                    <ActiveUsers user={users} onChange={selectedUsers => setUsers(selectedUsers)} networkId={Utils.getNetwork()}
                             settings={{multiple: true}}/>
                </div>

                <div className="col-2 d-flex justify-content-start">
                    <StyledButton onClick={add} disabled={subscribeBtnLoading} noLoading={!subscribeBtnLoading} title="Add" type="success"/>
                </div>
            </div>

            <div className="row mb-5 pl-1">
                <FormGroup>
                    <FormControlLabel className="ml-0" control={<Checkbox checked={allowToEditContent} color="primary"
                                                         onChange={e => setAllowToEditContent(e.target.checked)}/>}
                                      label={<b>Allow to edit content</b>}/>
                </FormGroup>
            </div>

            <div id="table-container">
                <SimpleCustomTable
                    data={getData()}
                    columns={[
                        {
                            name: "image",
                            header: "Image",
                            cell: rowData => (
                                <Image src={`/resource/thumbnail/256/${rowData?.image}`}
                                       imageStyle={{
                                           position: "initial",
                                           width: "3rem", height: "3rem",
                                           objectFit: "cover",
                                       }}
                                       style={{
                                           display: "flex",
                                           justifyContent: "center",
                                           background: "transparent",
                                           padding: 0
                                       }}/>
                            ),
                            isCustom: true
                        },
                        {
                            name: "id",
                            header: "ID",
                            cell: rowData => rowData?.id
                        },
                        {
                            name: "firstName",
                            header: "First name",
                            cell: rowData => rowData?.firstName
                        },
                        {
                            name: "lastName",
                            header: "Last name",
                            cell: rowData => rowData?.lastName
                        },
                        {
                            name: "canEditContent",
                            header: "Can edit content",
                            cell: rowData => <Rights rowData={rowData}/>
                        },
                        {
                            name: "actions",
                            header: "Actions",
                            cell: rowData => <Actions rowData={rowData}/>
                        }
                    ]}
                    settings={{
                        rowHeight: Utils.RemToPx(4),
                        noItems: `No exhibitors added yet. Users that you will add will appear in table here`
                    }}
                />
            </div>
        </>
    );
}
