// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    currentScreen: 0,

    selectedContentID: "",

    propContent: [],
    logoCount: 0,
    thumbnailCount: 0,
    contentPickerType: 0,

    showFileEditMenu: false,

    showAddToPropMenu: false,
    addSelectedToPropMenu: false,
    hideContextMenu: false,

    fileInfo: {
        roomId: "",
        propId: "",
        id: "",
        title: "",
        description: "",
        author: "",
        linkShortDescription: "",
        webLink: "",
        date: "",
        location: ""
    },

    filters: {
        activeElement: 4,
        chosenRoom: {id: "", metadata: {name: "Loading..."}},
        isOKButtonPressed: false
    },

    selectedItemsIds: [],

    uploadFileTo: {
        roomId: "",
        propId: ""
    },
    canLeave: true,

    needReRender: false
};

export const CMSStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_CURRENT_SCREEN':
            return {...state, currentScreen: action.payload};

        case 'SET_LOGO_COUNT':
            return {...state, logoCount: action.payload};
        case 'SET_THUMBNAIL_COUNT':
            return {...state, thumbnailCount: action.payload};
        case 'SET_CONTENT_PICKER_TYPE':
            return {...state, contentPickerType: action.payload};

        case 'SET_SELECTED_CONTENT_ID':
            return {...state, selectedContentID: action.payload};
        case 'SET_PROP_CONTENT':
            return {...state, propContent: action.payload};
        case 'SET_SHOW_FILE_EDIT_MENU':
            return {...state, showFileEditMenu: action.payload};

        case 'SET_SHOW_ADD_TO_PROP':
            return {...state, showAddToPropMenu: action.payload};
        case 'ADD_SELECTED_TO_PROP':
            return {...state, addSelectedToPropMenu: action.payload};

        case 'SET_FILE_INFO':
            return {...state, fileInfo: action.payload};

        case 'CLEAR_SELECTED_ITEMS':
            return {...state, selectedItemsIds: []};
        case 'ADD_SELECTED_ITEM':
            return {...state, selectedItemsIds: [...state.selectedItemsIds, action.payload]};
        case 'REMOVE_SELECTED_ITEM':
            return {...state, selectedItemsIds: state.selectedItemsIds.filter(id => id !== action.payload)};

        case 'SET_UPLOAD_TO_ROOMID':
            return {...state, uploadFileTo: {...state.uploadFileTo, roomId: action.payload}};
        case 'SET_UPLOAD_TO_PROPID':
            return {...state, uploadFileTo: {...state.uploadFileTo, propId: action.payload}};

        case 'SET_HIDE_CONTEXT_MENU':
            return {...state, hideContextMenu: action.payload};
        case 'SET_CAN_LEAVE':
            return {...state, canLeave: action.payload};

        case 'SET_NEED_RE_RENDER':
            return {...state, needReRender: action.payload};

        default:
            return state;
    }
}

export const CMSProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <CMSStore.Provider value={value}>{props.children}</CMSStore.Provider>;
};
