import React, {useEffect, useState} from 'react';
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";

// MUI
import {CircularProgress} from "@material-ui/core";

// Components
import {RegistrationsChart} from "../components/RegistrationsChart";
import {RoomsChart} from "../components/RoomsChart";
import {SessionsChart} from "../components/SessionsChart";
import {useStateSafe} from "../../../scripts/SaveUseState";

const InfoPanel = ({title, total, footerText = "", icon}) => (
    <div className="d-inline-flex flex-column card card-body mr-4">
        <span className="d-flex align-items-center mb-2">
            <i className={icon}/>
            <h6 className="ml-2 mb-0">{title}</h6>
        </span>
        <h2>{total}</h2>
        <div>+{footerText} From last week</div>
    </div>
);

export const NetworkCharts = props => {
    const {onError} = props;

    // Registrations
    const [totalUsers, setTotalUsers] = useStateSafe(null);
    const [newUsersForLastWeek, setNewUsersForLastWeek] = useStateSafe(null);

    // Sessions
    const [newSessionsForLastWeek, setNewSessionsForLastWeek] = useStateSafe(null);

    // Rooms
    const [publicRooms, setPublicRooms] = useStateSafe(null);
    const [privateRooms, setPrivateRooms] = useStateSafe(null);
    const [newRooms, setNewRooms] = useStateSafe(null);

    const getRegistrations = async () => {
        const localDate = Utils.getTime();
        const {error, data} = await API.Analytics.getRegistrations(localDate, Utils.getNetwork(), onError);

        if (error.code === 200) {
            setTotalUsers(data?.totalUsers);
            setNewUsersForLastWeek(data?.newUsersForLastWeek);
        } else onError(new Error(error.messages));
    };
    const getRooms = async () => {
        const {error, data} = await API.Analytics.getRooms(Utils.getNetwork(), onError);

        if (error.code === 200) {
            setPublicRooms(data?.publicRooms);
            setPrivateRooms(data?.privateRooms);
            setNewRooms(data?.newRooms);
        } else onError(new Error(error.messages));
    };
    const getSessions = async () => {
        const localDate = Utils.getTime();
        const {error, data} = await API.Analytics.getSessions(localDate, Utils.getNetwork(),  onError);

        if (error.code === 200) {
            setNewSessionsForLastWeek(data?.newSessionsForLastWeek);
        }
        else onError(new Error(error.messages));
    };

    useEffect( () => {
        (async () => {
            await getRegistrations();
            await getRooms();
            await getSessions();
        })();

        return () => {
            setTotalUsers(null);
            setNewUsersForLastWeek(null);
            setNewSessionsForLastWeek(null);
            setPublicRooms(null);
            setPrivateRooms(null);
            setNewRooms(null);
        };
    }, []);

    return (
        <>
            <InfoPanel title="Total users" icon={"fas fa-user fa-sm"} total={totalUsers !== null ? totalUsers : <CircularProgress size={24}/>}
                       footerText={newUsersForLastWeek ? newUsersForLastWeek.reduce((previousValue, currentValue) => previousValue + currentValue, 0) : <CircularProgress size={18}/>} />
            <InfoPanel title="Total rooms" total={(publicRooms !== null && privateRooms !== null) ? (publicRooms + privateRooms) : <CircularProgress size={24}/>}
                       icon={"fas fa-person-booth fa-sm"} footerText={newRooms !== null ? newRooms : <CircularProgress size={18}/>} />

            <div className="row justify-content-between mx-0">
                <RegistrationsChart data={newUsersForLastWeek}/>
                <RoomsChart data={[publicRooms, privateRooms]}/>
                <SessionsChart data={newSessionsForLastWeek}/>
            </div>
        </>
    )
};
