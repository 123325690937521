// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {API} from "../../api/API";
import {createResource} from "../../api/Requests";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {NetworksStore} from "../../stores/NetworksStore";
import {UsersStore} from "../../stores/UsersStore";

// MUI
import Image from 'material-ui-image';
import Select from "react-select";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {NetworkInfo} from "../Network/tabs/NetworkInfo";
import {StyledInput} from "../../components/styled/StyledInput";
import {StyledButton} from "../../components/styled/StyledButton";
import {customStyles} from "../../styles/dropdown";

export default function CreateNetwork() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);
    const {state: userState} = useContext(UsersStore);

    const navigate = useNavigate();

    const [thumbnail, setThumbnail] = useState("null");
    const [uploadedFile, setUploadedFile] = useState("");
    const [saveFileBtnDisabled, setSaveFileBtnDisabled] = useState(false);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const setSettings = (settings, dispatchMode) => {
        console.log(dispatchMode, settings);
        dispatch({type: dispatchMode, payload: settings});
    };

    // Path
    const setValue = (obj, param, value, dispatchMode = "SET_NEW_NETWORK_SETTINGS") => {
        const pluginSettings = obj;
        setToValue(pluginSettings, value, param);
        setSettings(pluginSettings, dispatchMode);
    };
    const getPathByValue = (obj, value, path) => {
        let newPath = "";
        if (typeof obj !== 'object') return;

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let t = path;
                let v = obj[key];

                if (!path) newPath = key;
                else newPath = path + '.' + key;

                if (v === value) return newPath;
                else if (typeof v !== 'object') newPath = t;

                let res = getPathByValue(v, value, newPath);
                if (res) return res;
            }
        }
    };
    const setToValue = (obj, value, path) => {
        let i;
        path = path.split('.');
        for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

        obj[path[i]] = {...obj[path[i]], value};
    };



    const setNewSettingsField = () => {};
    const onSelectValueChanged = () => {};

    const {list: subscriptionOptionsList, value : subscriptionOptionsValue} = state?.openedNetwork?.newNetworkSettings?.subscriptionOptions;
    const {list: typeList, value : typeValue} = state?.openedNetwork?.newNetworkSettings?.type;

    const uploadThumbnail = () => Utils.uploadFileInput("image/*",file => {
        setSaveFileBtnDisabled(true);
        setUploadedFile(file);
        setThumbnail(URL.createObjectURL(file));
        setSaveFileBtnDisabled(false);
    });

    const setDefaultPreset = () => {
        const formattedNetworkFullName = state?.openedNetwork?.newNetworkSettings?.fullName.value.toLowerCase().replace(/\s/g, '');

        setValue(state?.openedNetwork?.newNetworkSettings, "type", "Public");
        setValue(state?.openedNetwork?.settingsNetworks, "general.domainNames", [`${formattedNetworkFullName}.roomful.net`], "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.aiEndpoint", "https://ai.texpo.io", "SET_NETWORK_MODEL_SETTINGS");
    };
    const setConvenexpoPreset = () => {
        console.log(state?.openedNetwork?.newNetworkSettings?.fullName);
        const formattedNetworkFullName = state?.openedNetwork?.newNetworkSettings?.fullName.value.toLowerCase().replace(/\s/g, '');

        setValue(state?.openedNetwork?.newNetworkSettings, "type", "Public");
        setValue(state?.openedNetwork?.settingsNetworks, "general.domainNames",
            [`${formattedNetworkFullName}.roomful.net`, `${formattedNetworkFullName}.convenexpo.com`], "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "general.loaderType", 3, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "general.loadingVideoUrl", "d-XgfTY9M0g", "SET_NETWORK_MODEL_SETTINGS");

        setValue(state?.openedNetwork?.settingsNetworks, "defaults.hasQuestionnaire", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.hasExpo", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.allUsersAreConnected", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.orderByFirstComments", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.hasAnalytics", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.disableBalance", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.disableOwnerBadge", true, "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "defaults.aiEndpoint", "https://ai.texpo.io", "SET_NETWORK_MODEL_SETTINGS");

        setValue(state?.openedNetwork?.settingsNetworks, "dataCollection.useDefault", true, "SET_NETWORK_MODEL_SETTINGS");

        setValue(state?.openedNetwork?.settingsNetworks, "videochat.defaultVideochatSettings.videochatMode", "Videochat", "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "videochat.defaultVideochatSettings.videochatType", "Group", "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "videochat.defaultVideochatSettings.videochatEngine", "Twilio", "SET_NETWORK_MODEL_SETTINGS");
        setValue(state?.openedNetwork?.settingsNetworks, "videochat.defaultVideochatSettings.videochatInitUser", "Any", "SET_NETWORK_MODEL_SETTINGS");
    };

    const saveNetwork = async (settings, setSaveSettingsBtnLoading) => {
        if (uploadedFile && uploadedFile instanceof File) {
            setSaveSettingsBtnLoading(true);

            const fullName = state?.openedNetwork?.newNetworkSettings.fullName.value;
            const type = state?.openedNetwork?.newNetworkSettings.type.value;
            const subscriptionOptions = [{type: state?.openedNetwork?.newNetworkSettings.subscriptionOptions.value}];

            try {
                const resourceID = await createResource(uploadedFile, "roomful", onError);
                const result = await API.Networks.createNetwork({
                    network: {fullName, type, thumbnail: resourceID}, subscriptionOptions, settings
                }, onError);

                if (result?.error?.code === 200) {
                    mainDispatch({
                        type: "SET_MODAL_SETTINGS",
                        payload: {
                            show: true,
                            title: "Success!",
                            text: `Network was created successfully! Network ID : ${result?.data?.network?.id}`,
                            color: "success",
                            useCustomOnOkFunction: true,
                            onOk: () => {
                                mainDispatch({
                                    type: "SET_MODAL_SETTINGS",
                                    payload: {
                                        show: false,
                                        useCustomOnOkFunction: false
                                    }
                                });
                            }
                        }
                    });
                }
                else onError(new Error(result?.error?.message));
            }
            catch (e) {
                onError(new Error(e.message));
            }

            setSaveSettingsBtnLoading(false);
        }
    };

    return (
        Utils.hasPermission(userState.myRoles, "all") && <div className="content-wrapper">

            <ContentHeader title="Create network" breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Create network",
                    link: "/network/crete",
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="row flex-column px-2 mt-3">
                        <div className="mb-3">
                            <StyledInput fullWidth noMargin placeholder="Full name" defaultValue={state?.openedNetwork?.newNetworkSettings?.fullName.value}
                                     onBlur={e => setValue(state?.openedNetwork?.newNetworkSettings, "fullName", e.target.value)}/>
                        </div>

                        <div className="row px-3 mb-4 flex-column justify-content-center">
                            <Image imageStyle={{objectFit: "contain"}} style={{width: "100%", height: "14.063rem", padding: 0}} src={thumbnail}/>
                            <StyledButton fullWidth onClick={uploadThumbnail} disabled={saveFileBtnDisabled} title="Upload thumbnail" type="primary" size="sm"
                                          style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}/>
                        </div>


                        <div className="row px-3 flex-column mb-5">
                            <h5>Presets: </h5>
                            <div className="row px-2">
                                <div className="mr-3"><StyledButton onClick={setDefaultPreset} title="Default" type="default"/></div>
                                <StyledButton onClick={setConvenexpoPreset} title="Expo" type="default"/>
                            </div>
                        </div>

                        <div className="card p-2 mb-0" style={{boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.125)", borderBottom: 0, borderBottomLeftRadius: 0, borderBottomRightRadius: 0}}>
                            <div className="d-flex px-3 justify-content-between select-styles-reset">
                                <span className="nav-link col-2" style={{whiteSpace: "nowrap", paddingLeft: 0}}><b>Subscription options</b></span>
                                <Select onChange={selected => setValue(state?.openedNetwork?.newNetworkSettings, "subscriptionOptions", selected.value.toLowerCase())}
                                        value={{label: subscriptionOptionsValue, value: subscriptionOptionsValue}}
                                        options={subscriptionOptionsList.map(el => ({label: el, value: el}))}
                                        styles={customStyles(true)} className="col-10 p-0"
                                />
                            </div>
                        </div>

                        <div className="card p-2 mb-0" style={{boxShadow: "none", border: "1px solid rgba(0, 0, 0, 0.125)", borderBottom: 0, borderRadius: 0}}>
                            <div className="d-flex px-3 justify-content-between select-styles-reset">
                                <span className="nav-link col-2" style={{whiteSpace: "nowrap", paddingLeft: 0}}><b>Network Type</b></span>
                                <Select onChange={selected => setValue(state?.openedNetwork?.newNetworkSettings, "type", selected.value)}
                                        value={{label: typeValue, value: typeValue}}
                                        options={typeList.map(el => ({label: el, value: el}))}
                                        styles={customStyles(true)} className="col-10 p-0"
                                />
                            </div>
                        </div>

                        <NetworkInfo onError={onError} noDefaultValues={true} saveNetwork={saveNetwork}/>
                    </div>
                </div>
            </section>
        </div>
    );
}