// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useEffect, useState} from "react";

// MUI
import {Accordion, AccordionSummary, AccordionDetails} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {withStyles} from "@material-ui/styles";

// Components
import {StyledProgressBar} from "../styled/StyledProgressBar";
import SimpleCustomTable from "./SimpleCustomTable";
import Utils from "../../scripts/Utils";

const MyAccordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, 0.125)",
        borderRadius: "8px",
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    }
})(Accordion);
const MyAccordionDetails = withStyles({
    root: {
        display: "flex",
        flexDirection: "column"
    }
})(AccordionDetails);

export const FeedbackTable = props => {
    const {show, texts, settings, callbacks} = props;
    const {needSubLoop = false} = settings;
    const {onError, getColumns, getData, getItems, getResult, getSubResult, addItemToFeedbackTable, onFinish} = callbacks;
    const {title, progressBarTitles = []} = texts;

    // ProgressBar state
    const [showProgressBar, setShowProgressBar] = useState(false);
    const [max, setMax] = useState(0);
    const [progress, setProgress] = useState(0);

    const [showSubProgressBar, setShowSubProgressBar] = useState(false);
    const [subMax, setSubMax] = useState(0);
    const [subProgress, setSubProgress] = useState(0);

    // Base state
    const [showTable, setShowTable] = useState(show);
    useEffect(() => {setShowTable(show);}, [show]);

    // Main functions
    const updateItemsAsyncLoops = async items => {
        for (let i = 0; i < items.length; i++) {
            const result = await getResult(items[i]);

            if (result?.error?.code === 200) {
                if (needSubLoop) await updateSubItemsAsyncLoops(result);

                addItemToFeedbackTable(result.feedback, items[i]);

                setProgress(i + 1);
                setShowSubProgressBar(false);
                setSubProgress(0);
            }
            else {
                if (!needSubLoop) {
                    addItemToFeedbackTable({...result.feedback, ...{status: result?.error?.message}}, items[i]);
                    setProgress(i + 1);
                }
                else onError(new Error(result?.error?.message));
            }
        }
    };
    const updateSubItemsAsyncLoops = async body => {
        const {items} = body;

        if (items) {
            setSubMax(items.length);
            setShowSubProgressBar(true);

            for (let i = 0; i < items.length; i++) {
                const result = await getSubResult(body, items[i]);
                addItemToFeedbackTable(result.items);

                setSubProgress(i + 1);
            }
        }
    };
    const allItemsUpdated = () => {
        onFinish();
        setShowSubProgressBar(false);
    };

    const getItemsFromFile = async () => {
        const result = await getItems();

        setMax(result.length);
        setProgress(0);
        setShowProgressBar(true);

        await updateItemsAsyncLoops(result);
        allItemsUpdated();
    };

    // Effects
    useEffect( () => {
        (async () => {
            if (showTable) {
                setMax(0);
                setProgress(0);
                setShowProgressBar(false);

                await getItemsFromFile();
            }
        })();
    }, [showTable]);

    return (
        showTable && (
            <div className="mb-2">
                <MyAccordion square defaultExpanded={true}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                        <h5 style={{marginBottom: 0}}>{title}</h5>
                    </AccordionSummary>
                    <MyAccordionDetails>
                        {showProgressBar &&
                        <StyledProgressBar title={`${progressBarTitles[0]} ${progress}/${max}`} now={progress * 100 / (max)}
                                           min={0} max={100}/>}
                        {showSubProgressBar &&
                        <StyledProgressBar title={`${progressBarTitles[1]} ${subProgress}/${subMax}`}
                                           now={subProgress * 100 / (subMax)} min={0} max={100}/>}

                        <div id="table-container">
                            <SimpleCustomTable data={getData()} columns={getColumns()} settings={{rowHeight: Utils.RemToPx(3), offsetLeftRight: Utils.RemToPx(2.5)}}/>
                        </div>
                    </MyAccordionDetails>
                </MyAccordion>
            </div>
        )
    );
}
