// Copyright © 2015-2023 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class UsersAPI {
    static async getModel(userId, onError, network) {
        return await customFetch({path: "/jsonRpc/user.getUserModel", body: {userId}, network})
            .then(response => response.json())
            .catch(onError);
    }

    static async getSimpleModel(userId, onError, network) {
        return await customFetch({path: "/jsonRpc/user.getUserSimpleModel", body: {userId}, network})
            .then(response => response.json())
            .catch(onError);
    }

    static async getUserBalance(userId, onError) {
        return await customFetch({path: "/jsonRpc/user.getUserBalance", body: {userId}})
            .then(response => response.json())
            .catch(onError);
    }

    static async updateBalance(userId, updateAmount, onError) {
        return await customFetch({path: "/jsonRpc/user.updateBalance", body: {userId, updateAmount}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async activate(userId, onError) {
        return await customFetch({path: "/jsonRpc/user.enableUser", body: {userId}})
            .then(response => response.json())
            .catch(onError);
    }

    static async disable(userId, onError) {
        return await customFetch({path: "/jsonRpc/user.disableUser", body: {userId}})
            .then(response => response.json())
            .catch(onError);
    }

    static async updateProfile(body, onError) {
        return await customFetch({path: "/jsonRpc/user.updateUserProfile", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateCompanyInfo(body, onError) {
        return await customFetch({path: "/jsonRpc/user.updateUserCompanyInfo", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updatePassword(userId, password, onError) {
        return await customFetch({path: "/jsonRpc/user.updateUserPassword", body: {userId, password}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async setAvatar(userId, avatar, onError, network = null) {
        return await customFetch({
            path: "/jsonRpc/user.updateUserAvatar",
            body: {userId, avatar},
            network,
            withoutNetwork: !!network
        })
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getList(body, onError, network = null) {
        return await customFetch({path: "/jsonRpc/user.listUsers", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async search(body, onError, network = null) {
        return await customFetch({path: "/jsonRpc/user.searchUsers", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getActiveList(network = null, withoutNetwork, body, onError) {
        return await customFetch({path: "/jsonRpc/user.listActiveUsers", body, network, withoutNetwork})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchActive(network = null, withoutNetwork, body, onError) {
        return await customFetch({path: "/jsonRpc/user.searchActiveUsers", body, network, withoutNetwork})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async isUserSubscribedToNetwork(networkId, userId, onError) {
        return await customFetch({path: "/jsonRpc/network.isUserSubscribedToNetwork", body: {networkId, userId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async subscribeUserToNetwork(networkId, userId, onError) {
        return await customFetch({path: "/jsonRpc/network.subscribeUserToNetwork", body: {networkId, userId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async unlinkSocial(body, onError) {
        return await customFetch({path: "/jsonRpc/user.unlinkSocial", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getSocialModels(body, onError) {
        return await customFetch({path: "/jsonRpc/user.getSocialModels", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getContacts(body, onError) {
        return await customFetch({path: "/jsonRpc/user.getContacts", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchContacts(body, onError) {
        return await customFetch({path: "/jsonRpc/user.searchContacts", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getSortingTable(body, onError) {
        return await customFetch({path: "/jsonRpc/user.getSortingTable", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async deleteResourcesFromSortingTable(body, onError) {
        return await customFetch({path: "/jsonRpc/user.deleteResourcesFromSortingTable", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

}