// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {BoothVisitsReport} from "./components/BoothVisitsReport";
import {ConferenceVisitsReport} from "./components/ConferenceVisitsReport";
import {ContentVisitsReport} from "./components/ContentVisitsReport";
import {ReportFilters} from "./components/ReportFilters";
import {UserSessionsReport} from "./components/UserSessionsReport";
import AccessRestricted from "../AccessRestricted/AccessRestricted";

export default function AnalyticsReport () {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(UsersStore);

    const getCurrentTime = () => {
        let current = new Date();
        return new Date(current.getTime() - (current.getTimezoneOffset() * 60000)).toISOString();
    };
    const getTimeOneWeekBefore = () => {
        let then = new Date();
        then.setDate(then.getDate() - 7);

        return new Date(then.getTime() - (then.getTimezoneOffset() * 60000)).toISOString();
    };

    const [roomID, setRoomID] = useState("");
    const [propID, setPropID] = useState("");
    const [startDate, setStartDate] = useState(getTimeOneWeekBefore());
    const [endDate, setEndDate] = useState(getCurrentTime());

    const onRoomSelected = value => {
        if (value?.id === "all") {
            setRoomID("");
            setPropID("");
        }
        else setRoomID(value?.id);
    };
    const onPropSelected = value => {
        setPropID(value?.id === "all" ? "" : value?.id);
    };
    const onStartDateSelected = value => setStartDate(value);
    const onEndDateSelected = value => setEndDate(value);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const isRoomAdmin = Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = Utils.isPropAdmin(state.myRoles);

    return (
        <div className="content-wrapper">

            <ContentHeader title="Analytics report" breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Analytics report",
                    link: "/analytics",
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <div className="card card-outline">
                                <div className="card-body">
                                    <div className="tab-content">
                                        <ReportFilters onRoomSelected={onRoomSelected} onPropSelected={onPropSelected}
                                                       isRoomAdmin={isRoomAdmin} isPropAdmin={isPropAdmin}
                                                       startDate={startDate} onStartDateSelected={onStartDateSelected}
                                                       endDate={endDate} onEndDateSelected={onEndDateSelected}
                                        />

                                        <BoothVisitsReport onError={onError} params={{roomID, propID, startDate, endDate}}/>
                                        <ConferenceVisitsReport onError={onError} params={{roomID, startDate, endDate}}/>
                                        <ContentVisitsReport onError={onError} params={{roomID, startDate, endDate}}/>
                                        {!isRoomAdmin && (
                                            <UserSessionsReport onError={onError} params={{startDate, endDate}}/>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}