import React, {useContext} from "react";
import Utils from "../../../scripts/Utils";
import {ChatsStore} from "../../../stores/ChatsStore";
import {useNavigate} from "react-router-dom";

// MUI
import {Link} from "react-router-dom";
import Image from 'material-ui-image';
import {StyledButton} from "../../../components/styled/StyledButton";
import {StyledImage} from "../../../components/styled/StyledImage";

export const UserCard = props => {
    const {model, showChatBtn = true, showViewProfileBtn = true, emptyFooter = false, onError} = props;

    const {state, dispatch} = useContext(ChatsStore);
    const navigate = useNavigate();

    const openChat = async () => {
        const {error, data} = await state.connection.sendSocketRequest("textchat:getTextchatForUser", {data: {targetUser: model.id}});

        if (error.code === 200) {
            dispatch({type: "CLEAR_MESSAGES"});

            const {id, avatar, companyName, companyTitle, firstName, lastName} = model;
            dispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: {user: {id, avatar, firstName, lastName, companyName, companyTitle}}});
            dispatch({type: "SET_CURRENT_TEXTCHAT", payload: data?.textchat});
            dispatch({type: "SET_IS_FIRST_LOADING_FOR_CURRENT_CHAT", payload: true});
            dispatch({type: "CHANGE_CATEGORY_ACTIVE", category: "all", payload: true});

            navigate('/chats');
        }
        else onError(new Error(error.message));
    };

    return (
        <div className="col-4 d-flex justify-content-center align-items-stretch">
            <div className="card bg-light" style={{width: "100%"}}>
                <div className="card-header text-muted border-bottom-0">

                </div>
                <div className="card-body pt-0">
                    <div className="row">
                        <div className="col-7">
                            <h2 className="lead">
                                <b>{`${model.firstName} ${model.lastName} `}</b>
                                <i style={{fontSize: "0.9rem"}}>#{model.id}</i>
                            </h2>
                            <p className="text-muted text-sm">
                                {model.companyName !== "" && model.companyTitle !== "" && `${model.companyName}, ${model.companyTitle}`} <br/>
                                <b>About: </b> {model?.description?.length > 0 ? model?.description : "—"} <br/>
                                {model?.email?.length > 0 && <><b>Email: </b> {model.email}<br/></>}
                                {model?.phoneNumber?.length > 0 && <><b>Phone #: </b> {model.phoneNumber}</>}
                            </p>
                        </div>
                        <div className="col-5 justify-content-center">
                            <StyledImage size={8} circle={true}
                                src={Utils.buildURLs(`/resource/${model.avatar}`, false, null, {networkId: Utils.getNetwork()})}/>
                        </div>
                    </div>
                </div>
                <div className="card-footer" style={{minHeight: "3.438rem", padding: "0.75rem"}}>
                    {!emptyFooter && (
                        <div className="d-flex justify-content-end">
                            {showChatBtn && !model.isDisabled && (
                                    <StyledButton className={`btn btn-sm bg-primary h-100 ${showViewProfileBtn ? "mr-2" : ""}`}
                                        onClick={openChat} title={<><i className="fas fa-comments"/> Open chat</>}/>
                                )
                            }
                            {showViewProfileBtn && (
                                <Link to={`/user/${model.id}`} className="btn btn-sm btn-primary mr-2">
                                    <i className="fas fa-user"/> View Profile
                                </Link>
                                )
                            }
                        </div>
                        )
                    }
                </div>
            </div>
        </div>
    );
};
