// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useState} from "react";
import {useNavigate} from "react-router";
import {useParams} from "react-router-dom";
import {useStyles} from "../../../style";
import {API} from "../../../../../api/API";
import Utils from "../../../../../scripts/Utils";
import {Store} from "../../../../../stores/MainStore";
import {CMSStore} from "../../../../../stores/CMSStore";

// MUI
import Image from 'material-ui-image';
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

// Components
import {MyAccordion, MyAccordionDetails} from "../../../../../components/styled/StyledAccordion";
import SimpleCustomTable from "../../../../../components/table/SimpleCustomTable";
import {MyItem} from "../components/MySortableTable";
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {StyledInput} from "../../../../../components/styled/StyledInput";

export default function RemoteURL () {
	const navigate = useNavigate();
	const {roomId = null, propId = null} = useParams();
	const classes = useStyles();

	// Context
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(CMSStore);

    // Base state
	const [urlsList, setUrlsList] = useState([]);
    const [url, setUrl] = useState("");
    const [isUploading, setIsUploading] = useState(false);
    const [feedbackTable, setFeedbackTable] = useState({});
    const [isDone, setIsDone] = useState(false);

    // Handlers
    const onError = error => {
    	mainDispatch({
			type: "SET_MODAL_SETTINGS",
			payload: {
				show: true,
				title: "Error!",
				text: error.message,
				color: "default"
			}
		});
	};
    const onDelete = index => {
		const newUrlsList = [...urlsList];
        newUrlsList.splice(index, 1);
        setUrlsList(newUrlsList);
	};
	const onClear = () => {
		setUrlsList([]);
		setUrl("");
        setIsUploading(false);
        setIsDone(false);
        setFeedbackTable({});
	};
	const onUploadFiles = async () => {
		setIsUploading(true);
        dispatch({type: "SET_CAN_LEAVE", payload: false});
        setIsDone(true);

        setUrl("");
        setUrlsList([]);

        for (let i = 0; i < urlsList.length; i++) {
            setItemToFeedbackTable({
                id: i,
                title: Utils.limitStringLength(urlsList[i], 80),
                image: urlsList[i],
                status: "Uploading..."
            });
        }

        await Utils.awaitAll(urlsList, async (url, index) => {
            const resource = await API.Resource.uploadRemoteImage({url}, onError);

            let result = null;
            if (typeof resource?.error?.message !== "string") {
				 result = await API.Props.changePropContent({
					roomId,
					propId,
					appendToListEnd: true,
					moveToProp: [resource?.id],
					removeFromProp: []
				}, onError);
			}

            setItemToFeedbackTable({
                id: index,
                title: Utils.limitStringLength(url, 80),
                image: url,
                status: typeof resource?.error?.message !== "string" && result?.error?.code === 200 ? "Finished" : "Failed"
            });
        });

        setIsUploading(false);
        dispatch({type: "SET_CAN_LEAVE", payload: true});
	};

	// Main functions
	const addElement = () => {
        setFeedbackTable({});
        setUrlsList(prevValue => ([...prevValue, url]));
        setUrl("");
        setIsDone(false);
    };
	const goBack = () => navigate(-1);
	const setItemToFeedbackTable = item => setFeedbackTable(prevState => ({...prevState, [item?.id]: item}));

    return (
		<div className="container-fluid card p-3">
			<section className="content">
				<div className="container-fluid">
					<div className="d-flex flex-column pt-3">

						{!isDone && (
							<div className="d-flex align-items-start">
								<StyledInput fullWidth noPadding placeholder="Paste remote url here..." value={url}
											 onChange={e => setUrl(e.target.value)}/>
								<div className="ml-2">
									<StyledButton onClick={addElement} noLoading={!isUploading}
												  disabled={!(url?.length > 0)}
												  title={<>Add <i className="ml-3 fas fa-plus-circle"/></>}
												  type="success"/>
								</div>
							</div>)}

						{(Object.values(feedbackTable))?.length > 0 && (
							<div className="py-3">
								<MyAccordion square defaultExpanded={true}>
									<AccordionSummary expandIcon={<ExpandMoreIcon/>}>
										<h5 style={{marginBottom: 0}}>Resource upload feedback table</h5>
									</AccordionSummary>
									<MyAccordionDetails>
										<div id="table-container">
											<SimpleCustomTable data={Object.values(feedbackTable).map(el => el)}
															   columns={[
																   {
																	   name: "image",
																	   header: "Image",
																	   cell: rowData => (
																		   <Image src={rowData?.image} imageStyle={{
																			   position: "initial",
																			   width: "6rem", height: "6rem",
																			   objectFit: "cover",
																		   }} style={{
																			   display: "flex",
																			   justifyContent: "center",
																			   background: "transparent",
																			   padding: 0
																		   }}/>
																	   ),
																	   isCustom: true
																   },
																   {
																	   name: "resourceName",
																	   header: "Resource name",
																	   cell: rowData => rowData?.title
																   },
																   {
																	   name: "status",
																	   header: "Status",
																	   cell: rowData => rowData?.status
																   },
															   ]}/>
										</div>
									</MyAccordionDetails>
								</MyAccordion>
							</div>)}

						{urlsList.length > 0 && (
							<div className={classes.uploadedFiles}>
								{urlsList.map((singleUrl, index) =>
									<MyItem key={`item-${index}`} disabled onError={onError}
													isCustom={true} callbacks={{onDeleteCustom: () => onDelete(index)}}
													value={{
														id: "",
														title: singleUrl,
														filePreview: singleUrl,
														metadata: {
															fileName: singleUrl,
															contentType: "image/jpeg"
														}
													}}/>
								)}
							</div>)}

						<div className="mt-4 d-flex justify-content-between align-items-center">
							<div className="controls">
								{!isDone && (`Files on request: ${urlsList.length ?? 0}`)}
							</div>

							{
								!isDone
									? (urlsList?.length > 0
									? (
										<div className="d-flex">
											<StyledButton title="Clear queue" type="default" noLoading
														  onClick={() => setUrlsList([])} size="lg"/>
											<div className="ml-3">
												<StyledButton onClick={onUploadFiles} type="warning"
															  title={isUploading ? "Uploading..." : "Upload files"}
															  size="lg" disabled={isUploading}/>
											</div>
										</div>
									)
									: null)
									: (
										<div className="d-flex">
											<StyledButton title="Clear and upload more" type="warning" noLoading
														  disabled={!state?.canLeave}
														  onClick={onClear} size="lg"/>
											<div className="ml-3">
												<StyledButton title="Back to content overview" type="secondary" noLoading
															  disabled={!state?.canLeave}
															  onClick={goBack} size="lg"/>
											</div>
										</div>
									)
							}
						</div>

					</div>
				</div>
			</section>
		</div>
    );
}