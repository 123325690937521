// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from 'react';
import {useStyles} from "./style";
import isEmail from 'validator/lib/isEmail';
import { useNavigate } from "react-router-dom";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import Cookies from 'js-cookie';
import Utils from "../../scripts/Utils";

// Components
import {StyledButton} from "../../components/styled/StyledButton";

// MUI
import {Avatar, TextField, Link, Box, Typography, Container} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

export default function SignIn() {
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);
    const classes = useStyles();

    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [userId, setUserId] = useState(null);

    const onEnterKeyDown = e => e.key === "Enter" && onSubmit();
    const onError = (error, title, text) => {
        console.log(error, title, text);
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: title ?? "Login failed!",
                text: text ?? "Invalid email or password",
                type: "error"
            }
        });
        setIsButtonDisabled(false);
    };
    const onSubmit = async () => {
        Cookies.remove("networkId");
        Cookies.remove("sessionId");
        Cookies.remove("userId");
        Cookies.remove("subdomainName");
        Cookies.remove('firstLoaded');

        if (isEmail(email) && password.length >= 3) {
            setIsButtonDisabled(true);

            try {
                const data = await API.Auth.login({email, password}, onError);

                console.log(data);

                if (data?.status === 200) {
                    const {sessionId, userId, preferredNetworkId, preferredNetworkName} = (await data?.response);

                    if (sessionId !== undefined) Cookies.set('sessionId', sessionId);
                    else console.warn('sessionId', data);

                    if (userId !== undefined) Cookies.set('userId', userId);
                    else console.warn('userId', data);

                    Cookies.set('networkId', preferredNetworkId === "" ? "roomful" : preferredNetworkId);
                    Cookies.set('subdomainName', preferredNetworkName === "" ? "roomful" : preferredNetworkName);

                    if (userId && sessionId) setUserId(userId);
                }
                else if (data?.status === 401) {
                    alert("Wrong login data! Change login or password, and then try again.");
                    setIsButtonDisabled(false);
                }
                else console.warn('response', data);
            }
            catch (e) {
                onError(e, "Error!", e.message)
            }
        }
    };

    useEffect(() => {
        (async () => {
            const result = await API.Auth.isLogged();

            if (result && Cookies.get("userId") && Cookies.get("userId")?.length > 0)
                navigate("/dashboard");
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (Cookies.get('userId') && userId) {
                const {data, error} = (await API.Users.getModel(userId,
                        error => onError(error, "Can't save user data!", error.message),
                        Cookies.get("networkId")
                ));

                if (data?.user && !Utils.isObjectEmpty(data?.user) && error.code === 200) {

                    dispatch({
                        type: "SET_USER_MODEL",
                        key: "model",
                        payload: data.user
                    });
                    dispatch({type: "SET_MY_ROLES", payload: data?.roles});

                    window.location.href = "/dashboard";
                }
                else {
                    mainDispatch({
                        type: "SET_MODAL_SETTINGS",
                        payload: {
                            show: true,
                            title: "Login failed!",
                            text: "Can't retrieve user model or permissions. Try again.",
                            type: "error"
                        }
                    });

                    setIsButtonDisabled(false);
                }
            }
        })();
    }, [userId]);

    return (
        <Container component="main" maxWidth="xs">
            <div className={classes.container}>
                <div className={classes.paper}>
                    <Avatar className={classes.avatar}><LockOutlinedIcon/></Avatar>
                    <Typography component="h1" variant="h5">Sign in</Typography>
                    <form className={classes.form} noValidate>
                        <TextField variant="outlined" margin="normal" required fullWidth id="email"
                                   label="Email Address" onKeyDown={onEnterKeyDown} value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   name="email" autoComplete="email" autoFocus/>
                        <TextField variant="outlined" margin="normal" required fullWidth name="password"
                                   label="Password" onKeyDown={onEnterKeyDown} value={password}
                                   onChange={e => setPassword(e.target.value)}
                                   type="password" id="password" autoComplete="current-password"/>
                        <Box mt={2}>
                            <StyledButton fullWidth variant="contained" type="primary"
                                          onClick={onSubmit} disabled={isButtonDisabled} title="Sign In"/>
                        </Box>
                    </form>
                </div>
                <Box mt={4}>
                    <Typography variant="body2" color="textSecondary" align="center">
                        {'Copyright © '}
                        <Link color="inherit" href="https://roomful.net/">Roomful</Link>
                        {` 2015-${new Date().getFullYear()}.`}
                    </Typography>
                </Box>
            </div>
        </Container>
    );
}
