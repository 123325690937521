// Copyright © 2015-2022 Roomful Co. All rights reserved

import {makeStyles} from '@material-ui/core/styles';

export const imageContainerStyles = (hasBorder) => ({
	position: "relative",
	width: `calc(100% - ${hasBorder ? 6 : 0}px)`,
	marginTop: hasBorder ? "3px" : 0,
	marginLeft: hasBorder ? "3px" : 0,
	height: `calc(6.5rem - 3px)`,
	objectFit: "cover",
	padding: 0,
	cursor: "pointer"
});
export const imageStyles = {
	height: "calc(6.5rem - 3px)",
	display: "flex",
	justifyContent: "center",
	background: "transparent",
	padding: 0,
	pointerEvents: "none",
	userSelect: "none"
};

export const useStyles = makeStyles((theme) => ({

	noItems: {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)"
	},

	contentContainer: {
		position: "relative",

		minWidth: "calc(75% - 0.5rem)",
		width: "100%",
		height: "100%",

		display: "grid",
		flexWrap: "wrap",
		columnGap: "0.5rem",
		rowGap: "0.5rem",
		gridTemplateColumns: "repeat(auto-fill, 8rem)",
		gridTemplateRows: "repeat(auto-fill, 8rem)",

		background: "#fff"
	},

	singleItemContent: {
		position: "relative",
		width: "8rem",
		height: "8rem",
		overflow: "hidden",
		border: "2px solid transparent",

		"&>div.placeholder": {
			width: "100%",
			height: "calc(6.5rem - 3px)",
			display: "flex",
			justifyContent: "center",
			alignItems: "center",

			"&>svg": {
				width: "2.5rem",
				height: "2.5rem",
			}
		},
		"&>span": {
			overflow: "hidden",
			display: "inline-block",
			textOverflow: "ellipsis",
			whiteSpace: "nowrap",
			userSelect: "none",
			width: "8rem",
			height: "1.5rem",
			lineHeight: "1.45rem",
			textAlign: "center",
			fontSize: "0.75rem",
			padding: "0 1rem",
			background: "#e8e8e8"
		},

		"&>div.type, &>div.edit, &>div.delete, &>div.uptext": {
			position: "absolute",
			zIndex: 3,
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			opacity: 0,
			top: 0,
			left: 0,
			width: "1.6875rem",
			height: "1.6875rem",
			lineHeight: "1.0625rem",
			fontSize: "0.875rem",
			transition: "0.15s all linear",
			background: "#d6d6d6",
			padding: "0.3125rem"
		},
		"&>div.edit": {
			top: "unset",
			left: "unset",
			right: 0,
			bottom: "calc(1.5rem - 1px)",
			opacity: 1,
			background: "#e8e8e8",
			transition: "0.15s all linear",

			"&:hover": {
				background: "rgba(212, 175, 55, 1)",
				cursor: "pointer"
			}
		},
		"&>div.delete": {
			left: "unset",
			right: 0,
			transition: "0.15s all linear",

			"&:hover": {
				background: "#d13d3d",
				cursor: "pointer"
			}
		},
		"&>div.uptext": {
			opacity: 1,
			width: "calc(100% - 2*1.6875rem)",
			textAlign: "center",
			fontSize: "0.800rem",
			whiteSpace: "nowrap",
			left: "1.6875rem",
			right: "1.6875rem",
			margin: "0 auto",
			transition: "0.15s all linear"
		},

		"&>.checkboxContainer": {
			position: "absolute",
			bottom: "calc(1.5rem - 1px)",
			left: 0,
			zIndex: 3,

			display: "flex",
			justifyContent: "center",
			alignItems: "center",
			width: "1.6875rem",
			height: "1.6875rem",

			opacity: 1,
			transition: "0.15s all linear",
			cursor: "pointer",
			background: "#e8e8e8",

			"&>span": {
				zIndex: 4,
				width: "50%",
				height: "50%",
				color: "rgba(0, 0, 0, 1) !important",
			}
		},

		"&.logo": {border: "2px solid gold"},
		"&.thumbnail": {border: "2px solid limegreen"},

		"&.artist-picture": {border: "2px solid #00b1d9"},
		"&.album-cover": {border: "2px solid #00b1d9"},
		"&.sleeve": {border: "2px solid #00d96b"},
		"&.cover": {border: "2px solid #00d96b"},

		"&.logo>span, &.thumbnail>span": {
			marginTop: "-0.2rem"
		},

		"&.props:hover": {cursor: "move"},
		"&:hover": {
			"&>div.type, &>div.edit, &>div.delete, &>.selected": {opacity: 1}
		}
	},

	editPanel: {
        width: "45%",
        minWidth: "30rem",
        border: "1px solid #e8e8e8",
        background: "#fff",
        marginBottom: "15px",
		overflow: "visible !important",

        "&>div>hr": {
            width: "100%",
            height: "1px",
            background: "#e8e8e8",
            borderTop: "none"
        }
    },

	contextMenu: {
        position: "fixed",
        zIndex: 9999999999999,
        background: "white",
        transform: "scale(0)",
        transformOrigin: "top left",
        border: "1px solid #e8e8e8",

        "&.menu-hidden": {
            transform: "scale(0)",
            transformOrigin: "top left"
        },
        "&.menu-visible": {
            transform: "scale(1)",
            transition: "transform 200ms ease-in-out"
        },

        "&>li": {
            fontSize: "18px",
            userSelect: "none",
            transition: "0.15s all linear",
            cursor: "pointer",

            "&:hover": {
                background: "#e8e8e8"
            },
            "&>i": {marginRight: "8px"}
        }
    },

	uploadFromPC: {
        width: "100%",
        height: "10rem",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        fontSize: "2rem",
        border: "3px dashed #e8e8e8",
        cursor: "pointer",

        "& .dropzone": {
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },

        "& p": {
            marginBottom: "0px",
            userSelect: "none"
        }
    },

	uploadedFiles: {
        marginTop: "2rem",
        display: "grid",
        flexWrap: "wrap",
        columnGap: "0.5rem",
        rowGap: "0.5rem",
        gridTemplateColumns: "repeat(auto-fill, 8rem)",
        gridTemplateRows: "repeat(auto-fill, 8rem)",

        "&>.single-content-item" : {
           cursor: "initial"
        }
    }

}));
