// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {PropsStore} from "../../../stores/PropsStore";

// MUI
import Image from 'material-ui-image';
import {StyledInput} from "../../../components/styled/StyledInput";
import {StyledButton} from "../../../components/styled/StyledButton";

export const PropInfo = ({roomId, propId, onError}) => {
    const {state, dispatch} = useContext(PropsStore);

    const [title, setTitle] = useState(state.model?.title);
    const [description, setDescription] = useState(state.model?.description);
    const [isUpdating, setIsUpdating] = useState(false);

    useEffect(() => {
        setTitle(state.model?.title);
        setDescription(state.model?.description);
    }, [state.model]);

    const updateInfo = useCallback(async () => {
        setIsUpdating(true);

        const result = (await API.Props.updateProp({roomId, propId, title, description}, onError));

        if (result?.error?.code === 200) dispatch({type: "SET_PROP_MODEL", payload: result?.data?.prop});
        else onError(new Error("Can't update prop information."));

        setIsUpdating(false);
    }, [description, propId, roomId, title]);

    return (
        <div className="d-flex flex-column align-items-start">
            <div className="form-group w-100 d-flex">
                <div className="col-2"><label className="col-form-label">ID:</label></div>
                <div className="col-10 d-flex align-items-center">
                    {state.model?.id}
                </div>
            </div>

            <StyledInput title="Title: " placeholder="Type new prop title here..."
                         value={title} onChange={e => setTitle(e.target.value)} fullWidth/>
            <StyledInput title="Description: " placeholder="Type new prop description here..." fullWidth
                         value={description} onChange={e => setDescription(e.target.value)} isTextarea/>

            <div className="form-group w-100 d-flex">
                <div className="col-2"><label className="col-form-label">Thumbnail:</label></div>
                <div className="col-10 d-flex justify-content-start">
                    <Image src={Utils.buildURLs(`/asset/thumbnail/${state.model?.assetId}`)}
                           imageStyle={{
                               position: "initial",
                               width: "6rem", height: "6rem",
                               objectFit: "cover"
                           }}
                           style={{
                               display: "flex",
                               justifyContent: "center",
                               background: "transparent",
                               padding: 0
                           }}/>
                </div>
            </div>

            <div className="w-100 d-flex justify-content-end">
                <StyledButton title="Update info" type="success" onClick={updateInfo} disabled={isUpdating}/>
            </div>

        </div>
    );
}
