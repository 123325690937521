// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";

import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {PropsStore} from "../../../stores/PropsStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, InputAdornment, IconButton, Box, CircularProgress} from "@material-ui/core";
import {ArrowDropUp, ArrowDropDown, Loop, Tune} from '@material-ui/icons';
import Image from 'material-ui-image';

// Style
import useStyles from "../../../styles/dropdownStyles";

// Components
import {StyledImage} from "../../styled/StyledImage";

let timeout = null;
let prevBody = {};

export const RoomProps = props => {
    const {
        prop, onChange, networkId = null, roomId = null,
        allowAll = false, disabled = false
    } = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(PropsStore);

    const classes = useStyles();
    const readOnly = true; // prevent all searching functional

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const [selectedNetworkId] = useState(networkId || Utils.getNetwork());
    const [selectedRoomId, setSelectedRoomId] = useState(roomId);
    const [value, setValue] = useState(prop);
    const [isLoading, setIsLoading] = useState(false);

    // Base state
    const [needRender, setNeedRender] = useState(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);

    // Search state
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);

    // Buttons, dropdown state, errors state, etc
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isErrorInInput, setIsErrorInInput] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);

    const loadMoreItems = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);
        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };

    const getList = useCallback(() => {
        let list = state.list;
        if (allowAll && list[0]?.id !== "all") list.unshift({id: "all", title: "All props"});

        return list;
    }, [state.list]);
    const setList = (list) => {
        if (allowAll && list[0]?.id !== "all") list.unshift({id: "all", title: "All props"});
        dispatch({type: 'SET_LIST', payload: list});
    };

    const getListItems = async (body, list) => {
        if (!loadMoreActive) setIsLoading(true);

        const data = (await (API.Rooms.getRoomPropsList(roomId, networkId, onError))).data;

        const items = data?.props ?? [];

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && items.length > 0) ? list : [];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);

        setList(itemsToAdd);
        if (!loadMoreActive) setValue(itemsToAdd[0]);

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setSearchBtnLoading(false);
    };

    // Handlers, listeners
    const onOpenDropdown = () => {if (!disabled) setIsDropdownOpen(true);};
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !loadMoreActive) loadMoreItems();
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        clearTimeout(timeout);
        timeout = setTimeout(search, 1000);
        setPrevSearchInputText(searchInputText);
        setSearchInputText(e.target.value);
    };
    const onItemSelected = (e, value) => {
        setValue(value);
        setIsDropdownOpen(false);
        setIsErrorInInput(false);
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (needRender && selectedRoomId && !isLoading) {
                const body = {
                    offset,
                    size,
                    roomId:        selectedRoomId === "all" ? "" : selectedRoomId,
                    asPropManager: false,
                    propTypes:     ["booth", "fileCabinet"]
                };

                if (Utils.isObjectEmpty(prevBody) || (prevBody.offset !== body.offset) || (prevBody.size !== body.size)
                    || (prevBody.roomId !== body.roomId) || (prevBody.asPropManager !== body.asPropManager) || !Utils.isArraysIdentical(prevBody.propTypes, body.propTypes)) {
                    const list = state.list;

                    await getListItems(body, list);
                    prevBody = body;
                }
            }
        })();
    }, [needRender, selectedRoomId]);

    useEffect(() => {
        if (roomId && selectedRoomId !== roomId) {
            setSearchBtnLoading(false);
            setOffset(0);
            setSelectedRoomId(roomId);
            setList([]);
            setNeedRender(true);
        }
    }, [roomId]);

    useEffect(() => {
        return () => {
            dispatch({type: 'CLEAR_LIST'});

            setValue(null);
            setSelectedRoomId(null);
            setIsLoading(false);
            setOffset(0);
            setSearchInputText("");
            setPrevSearchInputText("");
            setLoadMoreActive(false);
            setIsDropdownOpen(false);
            setIsErrorInInput(false);
            setSearchBtnLoading(false);

            prevBody = {};
            timeout = null;
        };
    }, []);
    useEffect(() => {
        if (value) setIsLoading(false);

        if (onChange && typeof onChange === "function") onChange(value);
    }, [value]);

    const isSearchActive = typeof searchInputText === "string" && searchInputText.length > 0

    return (
        (((getList())?.length > 0) && !isLoading) || isSearchActive
            ?
            <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                          renderInput={params => (
                              <div className="d-flex align-items-center">
                                  {value?.assetId && <Image imageStyle={{
                                      position: "initial",
                                      margin: "0.25rem",
                                      width: "3rem",
                                      height: "calc(3.5rem - 0.5rem - 2px)",
                                      objectFit: "cover"
                                  }} style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      background: "#fff",
                                      padding: 0,
                                      border: "1px solid hsl(0, 0%, 80%)",
                                      borderRight: 0,
                                      borderTopRightRadius: 0,
                                      borderTopLeftRadius: "4px",
                                      borderBottomRightRadius: 0,
                                      borderBottomLeftRadius: "4px",
                                  }} src={Utils.buildURLs(`/asset/thumbnail/${value?.assetId}`,
                                      false, null, {networkId: selectedNetworkId})}/>}
                                  <TextField {...params} variant="outlined" value={searchInputText} disabled={disabled}
                                             onChange={onSearchInputChange} error={isErrorInInput} size="small"
                                             InputProps={{
                                                 readOnly,
                                                 ...params.InputProps,
                                                 style: {
                                                     borderTopLeftRadius: 0,
                                                     borderBottomLeftRadius: 0,
                                                     height: "3.5rem"
                                                 },
                                                 endAdornment: (
                                                     <InputAdornment position="end">
                                                         {searchBtnLoading
                                                             ? <IconButton edge="end"><Loop/></IconButton>
                                                             :
                                                             <IconButton edge="end" onClick={onOpenDropdown}>
                                                                 {isDropdownOpen ? <ArrowDropUp/> :
                                                                     <ArrowDropDown/>}
                                                             </IconButton>}
                                                     </InputAdornment>
                                                 ),
                                                 onKeyDown: e => {
                                                     if (e.key === 'Enter') {
                                                         e.preventDefault();
                                                         e.stopPropagation();
                                                         return;
                                                     }
                                                 },
                                                 onClick: () => setIsDropdownOpen(true)
                                             }}
                                             onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                                  />
                              </div>
                          )} style={{width: "100%"}} freeSolo disableClearable forcePopupIcon={false}
                          open={isDropdownOpen}
                          value={value} onChange={onItemSelected} filterOptions={options => options}
                          options={getList()}
                          getOptionLabel={option => {
                              const title = option?.title === "" ? `[${option?.assetTitle ?? ""}]` : option?.title;
                              return option?.id === "all" ? title : `${title} (#${option?.id})`;
                          }}
                          renderOption={option => (
                              <Box data-id={option?.id} className="d-flex align-items-center"
                                   style={{height: `${Utils.RemToPx(4.125)}px`}}>
                                  <StyledImage size={3} src={Utils.buildURLs(`/asset/thumbnail/${option?.assetId}`,
                                      false, null, {networkId: selectedNetworkId})}/>

                                  <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                                  <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                                      {option?.title === "" ? `[${option?.assetTitle ?? ""}]` : option?.title}
                                  </span>
                                      <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                                      {option?.id} /
                                          {option?.propTypes?.indexOf("booth") > -1
                                              ? " Booth"
                                              : (option?.propTypes?.indexOf("fileCabinet") > -1 ? " File Cabinet" : " Prop")}
                                  </span>
                                  </div>
                              </Box>
                          )}
            />
            : (
                <div style={{
                    border: "1px solid hsl(0, 0%, 80%)",
                    background: "#fff",
                    width: "100%", height: "3.5rem",
                    padding: "9px", borderRadius: "4px"
                }} className="d-flex align-items-center">
                    {isLoading
                        ? <><CircularProgress className="mr-3"/> Loading...</>
                        : "No props in this room"}
                </div>
            )
    );
}