// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useCallback, useContext} from "react";
import {SortableContainer, SortableElement} from 'react-sortable-hoc';
import Utils from "../../../../../scripts/Utils";
import {API} from "../../../../../api/API";
import {CMSStore} from "../../../../../stores/CMSStore";
import {UsersStore} from "../../../../../stores/UsersStore";

// Styles
import {imageContainerStyles, imageStyles, useStyles} from "../../../style";

// MUI
import {Audiotrack, Description, InsertDriveFile, InsertLink, Panorama, Videocam} from "@material-ui/icons";
import Image from 'material-ui-image';
import {Checkbox} from "@material-ui/core";

export const MySortableContainer = SortableContainer(({children, itemsCount}) => {
	const classes = useStyles();

	return (
		<div className={`p-2 mx-2 ${classes.contentContainer}`}>
			{itemsCount > 0 ? children : <h3 className={classes.noItems}>No items...</h3>}
		</div>
	);
});

export const MyItem = ({value: item, isCustom = false, settings = {}, callbacks = {}, onError}) => {
	const {onDeleteCustom = () => false} = callbacks;
	const {canSelect = false} = settings;

	const classes = useStyles();

	// Context
	const {state: usersState, dispatch: usersDispatch} = useContext(UsersStore);
	const {state, dispatch} = useContext(CMSStore);

	// Base state
	const hasBorder = item?.isLogo || item?.isThumbnail;

	// Main functions
	const onSelect = useCallback(() => {
        dispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: false});
        dispatch({type: "SET_FILE_INFO", payload: {}});
        dispatch({type: "SET_HIDE_CONTEXT_MENU", payload: true});

        if (!(state.selectedItemsIds.indexOf(item?.id) > -1)) dispatch({type: "ADD_SELECTED_ITEM", payload: item?.id});
        else dispatch({type: "REMOVE_SELECTED_ITEM", payload: item?.id});
    }, [state.selectedItemsIds]);
	const onDelete = async () => {
		const content = usersState.cmsContent.filter(el => el?.id !== item?.id);
        usersDispatch({type: "SET_CMS_CONTENT", payload: content});

        await API.Users.deleteResourcesFromSortingTable({resourceIds: [item?.id]}, onError);
    };
	const onEdit = () => {
		dispatch({type: "SET_FILE_INFO", payload: {
			id: item.id,
			fileName: item.metadata.fileName,
			title: item.title,
			description: item.description,
			author: item.author || "",
			linkShortDescription: item.linkShortDescription || "",
			webLink: item.webLink || "",
			date: item.date,
			location: item.location
		}});

		dispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: true});
	};

	// Components
	const Icon = ({type}) => {
        if (type === "youtube#video") return <i className="fab fa-youtube"/>;
        else if (type === "roomful#url") return <i className="fas fa-link"/>;
        else if (type.includes("remote#")) return <i className="far fa-link"/>;
        else if (type.includes("image")) return <i className="far fa-file-image"/>;
        else if (type.includes("video")) return <i className="fas fa-photo-video"/>;
        else if (type.includes("audio")) return <i className="fas fa-file-audio"/>;
        else return  <i className="fas fa-file-alt"/>
    };
	const getContent = type => {
		if (type === "roomful#url" || type.includes("remote#")) {
			return (
				item.thumbnail?.length > 0 ?
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} className="img-fluid" onClick={isCustom && !canSelect ? () => false : onSelect}
					src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					loading={<div className="placeholder"><InsertLink/></div>} errorIcon={<InsertLink/>}
				/> : <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><InsertLink/></div>
			);
		}
		else if (type === "youtube#video") {
			return (
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} onClick={isCustom && !canSelect ? () => false : onSelect} className="img-fluid"
					   src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					   loading={<div className="placeholder"><InsertDriveFile/></div>}/>
			);
		}
		else if (type.includes("video")) {
			return (
				item.thumbnail?.length > 0 ?
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} className="img-fluid" onClick={isCustom && !canSelect ? () => false : onSelect}
					src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					loading={<div className="placeholder"><Videocam/></div>} errorIcon={<Videocam/>}
				/> : <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><Videocam/></div>
			);
		}
		else if (type.includes("audio")) {
			return <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><Audiotrack/></div>;
		}
		else if (type.includes("image")) {
			return (
				item.thumbnail?.length > 0 ?
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} className="img-fluid" onClick={isCustom && !canSelect ? () => false : onSelect}
					src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					loading={<div className="placeholder"><Panorama/></div>} errorIcon={<Panorama/>}
				/> : <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><Panorama/></div>
			);
		}
		else if (type === "text/plain" || type === "application/pdf") {
			return (
				item.thumbnail?.length > 0 ?
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} className="img-fluid" onClick={isCustom && !canSelect ? () => false : onSelect}
					src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					loading={<div className="placeholder"><Description/></div>} errorIcon={<Description/>}
				/> : <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><Description/></div>
			);
		}
		else {
			return (
				item.thumbnail?.length > 0 ?
				<Image imageStyle={imageStyles} style={imageContainerStyles(hasBorder)} className="img-fluid" onClick={isCustom && !canSelect ? () => false : onSelect}
					src={item?.filePreview ?? Utils.buildURLs(`/resource/thumbnail/256/${item.id}`, false, null, {networkId: Utils.getNetwork()})}
					loading={<div className="placeholder"><InsertDriveFile/></div>} errorIcon={<InsertDriveFile/>}
				/> : <div className="placeholder" onClick={isCustom && !canSelect ? () => false : onSelect}><InsertDriveFile/></div>
			);
		}
    };

	return (
		<div data-id={item?.id} title={item?.title ? item?.title : (item?.metadata?.fileName ?? "")} key={`item-${item?.id}`}
			 className={`single-content-item ${classes.singleItemContent} ${item?.isLogo ? "logo" : ""} ${item?.isThumbnail ? "thumbnail" : ""}`}>
			<div className="type"><Icon type={item?.metadata?.contentType}/></div>
			{!isCustom && <div className="edit" onClick={onEdit}><i className="fas fa-pencil-alt"/></div>}
			<div className="delete" onClick={isCustom ? onDeleteCustom : onDelete}><i className="fas fa-trash"/></div>

			{!isCustom && hasBorder && (
				<div className={`uptext ${item?.isThumbnail ? "small" : ""}`}>
					{item?.isLogo ? "Logo" : ""} {item?.isLogo ? item?.count : (item?.isThumbnail ? item?.count - state?.logoCount : "")}
				</div>)}

			{(!isCustom || (isCustom && canSelect)) && (
				<div className="checkboxContainer">
					<Checkbox checked={state.selectedItemsIds.indexOf(item.id) > -1} color="default" disableRipple
							  onChange={isCustom && !canSelect ? () => false : onSelect}/>
				</div>)}

			{getContent(item?.metadata?.contentType)}

			<span>{item?.title ? item?.title : (item?.metadata?.fileName ?? "")}</span>
		</div>
	);
};

export const MySortableItem = SortableElement(({value: item, isCustom = false, callbacks = {}, onError}) => {
	return <MyItem value={item} isCustom={isCustom} callbacks={callbacks} onError={onError}/>
});
