// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {initialState, Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {SocialEventsStore} from "../../stores/SocialEventsStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {StyledButton} from "../../components/styled/StyledButton";
import CustomTable from "../../components/table/CustomTable";
import {StyledImage} from "../../components/styled/StyledImage";
import CreateNewSocialEvents from "./CreateNewSocialEvents";

export default function SocialEvents() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state: usersState} = useContext(UsersStore);
    const {state, dispatch} = useContext(SocialEventsStore);

    const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(usersState.myRoles);

    // Handlers
    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // One-row functions
    const updateEvent = event => {
        dispatch({type: "SET_EDIT_MODE", payload: true});
        dispatch({type: "SET_EDITABLE_EVENT", payload: event});
        dispatch({type: "SET_IS_OPENED", payload: true});
    };
    const deleteEvent = ({id, title}) => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Warning!",
                text: `Do you want to delete "${title}" social event?`,
                color: "warning",
                onCancel: () => mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal}),
                useCustomOnCancelFunction: true,
                onOk: async () => {
                    const result = await API.SocialEvents.deleteSocialEvent({eventId: id}, onError);

                    const newList = state?.list?.filter(el => el?.id !== id);
                    dispatch({type: "SET_LIST", payload: newList});

                    if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'avatar',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
            cell: rowData =>
                rowData?.previewResourceId && rowData?.previewResourceId?.length > 0
                    ? <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.previewResourceId}`,
                    false, null, {networkId: Utils.getNetwork()})} size={5}/>
                    : null,
            width: Utils.RemToPx(9)
        },
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.id}</span>
                </div>
            )
        },
        {
            name: 'title',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Title</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <span>{rowData?.title}</span>
                </div>
            )
        },
        {
            name: 'description',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Description</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.description}
                </span>
            )
        },
        {
            name: 'date',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Date</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {`${rowData?.startDate.split("T")[0]} — ${rowData?.endDate.split("T")[0]}`}
                </span>
            )
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-wrap">
                    {!state.editMode && <StyledButton className="btn btn-sm bg-warning h-100 mr-2"
                                   title={<i className="fas fa-pencil-alt"/>} tooltip="Update"
                                   onClick={() => updateEvent(rowData)}/>}
                    <StyledButton className="btn btn-sm bg-danger h-100 mr-2"
                                  title={<i className="fas fa-trash-alt"/>} tooltip="Delete"
                                  onClick={() => deleteEvent(rowData)}/>
                </div>
            ),
            width: Utils.RemToPx(10)
        }
    ].filter(el => el));
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = useCallback(async ({offset, size, text}, onError) => {
        const {events = {}, total = 0} = (await (API.SocialEvents.getSocialEvents(onError))).data;

        dispatch({type: "SET_LIST", payload: events});
        dispatch({type: "SET_TOTAL_COUNT", payload: total});

        return total ?? 0;
    }, []);

    const clearList = () => dispatch({type: "SET_LIST", payload: []});

    // Effects
    useEffect(() => {
        return () => {
            clearList();
            dispatch({type: "SET_LIST", payload: 0});
        };
    }, []);

    return (
        <div className="content-wrapper">

            <ContentHeader title="Events" breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Events",
                    link: `/socialEvents`,
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    {(isSuperAdmin || isNetworkAdmin) && (
                        <div className="card card-body d-flex flex-column">
                            <h5 className="mb-4 d-inline-block p-1">Admin functions:</h5>
                            <CreateNewSocialEvents onError={onError}/>
                        </div>)}

                    <div id="table-container" className="mr-0">
                        <CustomTable
                            state={{canSelect: false, canHover: false, canSearch: false, pagination: false}}
                            callbacks={{getList, getColumns, getListItems, clearLists: clearList, onError}}
                            texts={{noItems: "No social events..."}}/>
                    </div>
                </div>
            </section>
        </div>
    );
}