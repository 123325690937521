// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
  list: [],
  isOpened: false,
  ticketTypeOptions: {list: ["single", "days:1of3", "days:2of3", "days:3of3"], value: "single", returnedType: "string"},

  editMode: false,
  editableTicket: null
};

export const TicketsStore = createContext(initialState);

function reducer(state, action) {
  switch (action.type) {
    case 'SET_LIST':
      return {...state, list: action.payload};
    case 'ADD_TO_LIST':
      return {...state, list: [...state.list, action.payload]};
    case 'EDIT_ITEM':
      const oldState = [...state.list];
      const newState = state.list.map(el => {
        if (el?.productId === action.productId) return action.payload;
        else return el;
      });
      return {...state, list: newState};
    case 'SET_IS_OPENED':
      return {...state, isOpened: action.payload};
    case 'SET_EDIT_MODE':
      return {...state, editMode: action.payload};
    case 'SET_EDITABLE_TICKET':
      return {...state, editableTicket: action.payload};
    default:
      return state;
  }
}

export const SocialEventTicketsProvider = props => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = {state, dispatch};

  return <TicketsStore.Provider value={value}>{props.children}</TicketsStore.Provider>;
};
