// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useEffect, useState } from "react";
import { CircleTabPanel, ThemedCircleTab, ThemedCircleTabs } from "../../components/styled/StyledTabs";
import ContentHeader from "./components/ContentHeader";

export default function PageWithTabs({tabs, currentTabName, useWhiteBackground}) {
    const currentTabNameLower = currentTabName.toLowerCase();
    const index = tabs.findIndex(tab => tab.name.toLowerCase() === currentTabNameLower);

    const [currentTabIndex, setCurrentTabIndex] = useState(index === -1 ? 0 : index);
    const onTabChange = (event, newIndex) => setCurrentTabIndex(newIndex);

    useEffect(() => {
        setCurrentTabIndex(index === -1 ? 0 : index);
    })

    const tabNavigationList = [];
    tabs.forEach((tab, tabIndex) => {
        if(tab.show) {
            tabNavigationList.push(
                <ThemedCircleTab key={tabIndex} href={tab.path} label={tab.label} tabIndex={tabIndex}
                                 activeTab={currentTabIndex} setActiveTab={setCurrentTabIndex}/>
            );
        }
    });

    const tabContent = (
        <div className="tab-content">
            <CircleTabPanel value={currentTabIndex} index={currentTabIndex}>
                {tabs[currentTabIndex]?.content}
            </CircleTabPanel>
        </div>
    );

    return (
        <div className="content-wrapper">

            <ContentHeader hasContentHeader={false}/>

            <section className="content">
                <div className="container-fluid">
                    <div className="card card-tabs" style={{background: "transparent", boxShadow: "none"}}>
                        <div className="card-header p-0 pt-1 d-flex custom justify-content-between"
                             style={{borderTop: "none"}} id="table-header">
                            <ThemedCircleTabs value={currentTabIndex} onChange={onTabChange}>
                                {tabNavigationList}
                            </ThemedCircleTabs>
                        </div>

                        {
                            useWhiteBackground ?
                                <div className="card">
                                    <div className="card-body">
                                        {tabContent}
                                    </div>
                                </div> :
                                <div className="card-body px-0" id="table-container"
                                     style={{borderTop: "1px solid rgb(222, 226, 230)"}}>
                                    <div className="tab-content">
                                        {tabContent}
                                    </div>
                                </div>
                        }
                    </div>
                </div>
            </section>
        </div>
    );
}
