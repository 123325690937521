// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import {Link} from "react-router-dom";

// Components
import ContentHeader from "../Template/components/ContentHeader";

export default function AccessRestricted () {
    return (
        <div className="content-wrapper position-relative">
            <ContentHeader title={"Access Restricted"} breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Access restricted",
                    active: true
                }
            ]}/>

            <section className="content d-flex align-items-center" style={{position: "absolute", top: 0, bottom: 0, width: "100%"}}>
                <div className="error-page">
                    <h2 className="headline text-danger">403</h2>

                    <div className="error-content">
                        <h3><i className="fas fa-lock text-danger mr-3"/>Access restricted</h3>

                        <p>
                            You do not have enough rights to view and perform any operations with this content. In order
                            to gain access, you need a special role, write about this to your administrator.
                        </p>
                    </div>
                </div>
            </section>
        </div>
    );
}