// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch, customFetchOld} from "../Requests";

export class ResourcesAPI {
    static async createResourceMetadata(body, network = null, onError) {
        return await customFetch({path: "/jsonRpc/resource.createResource", body, method: "POST", network, withoutNetwork: !!network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getLink(resourceId, network = null, onError) {
        const settings = {
            headers: {"X-Resource-Id": resourceId},
            network,
            withoutNetwork: !!network
        };
        return await customFetchOld({path: "/resource/upload/link", body: null, method: "POST", ...settings})
            .then(resultData => resultData.text())
            .catch(onError);
    }

    static async directUploadToGoogleBucket(uploadLink, arrayBuffer, type, onError) {
        const settings = {
            method: "PUT",
            headers: {
                'Content-Type': type
            },
            body: arrayBuffer,
        };
        return await fetch(uploadLink, settings).then(resultData => resultData).catch(onError);
    }

    static async completeUpload(resourceId, network = null, onError) {
        const settings = {
            headers: {"X-Resource-Id": resourceId},
            network,
            withoutNetwork: !!network
        };
        return await customFetch({path: "/resource/upload/link/complete", body: null, method: "POST", ...settings})
            .then(resultData => resultData.text())
            .catch(onError);
    }

    static async uploadRemoteImage(body, onError) {
        return await customFetch({path: "/resource/upload/remote/url", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchPixabayImages(body, onError) {
        return await customFetch({path: "/jsonRpc/resource.searchPixabay", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async searchYoutubeVideos(body, onError) {
        return await customFetch({path: "/jsonRpc/resource.searchYoutube", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}