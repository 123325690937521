// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch, parseSpreadsheetRequest} from "../Requests";
import Utils from "../../scripts/Utils";

export class RoomsAPI {
    static async getList(body, network = null, onError, withoutNetwork = false) {
        return await customFetch({path: "/jsonRpc/room.listRooms", body, network, withoutNetwork})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async search(body, network = null, onError, withoutNetwork = false) {
        return await customFetch({path: "/jsonRpc/room.searchRooms", body, network, withoutNetwork})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getModel(roomId, onError, network = null) {
        return await customFetch({path: "/jsonRpc/room.getRoomModel", body: {roomId}, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async parseBooths(file, onError) {
        return await parseSpreadsheetRequest({path: "/files/parseRoomBoothsSpreadsheet", body: file})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateBoothTitle(body, onError) {
        return await customFetch({path: "/jsonRpc/room.setPropTitle", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateBoothMember(body, onError) {
        return await customFetch({path: "/jsonRpc/room.setPropTeamMemberByEmail", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async parseBoothMembers({roomId, file}, onError) {
        return await parseSpreadsheetRequest({path: `/files/parseBoothsMembersSpreadsheet/${roomId}`, body: file})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async copyRoom(body, onError) {
        return await customFetch({path: "/jsonRpc/room.copyRoom", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async addOwner(body, onError) {
        return await customFetch({path: "/jsonRpc/room.addRoomOwner", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getRoomBoothList(roomId, onError) {
        return await customFetch({path: "/jsonRpc/room.listRoomBooths", body: {roomId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async getRoomPropsList(roomId, network, onError) {
        return await customFetch({path: "/jsonRpc/room.listRoomProps", body: {roomId}, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async getRoomAllPropsList(body, onError, network = Utils.getNetwork()) {
        return await customFetch({path: "/jsonRpc/room.listProps", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getUserRoomsList(onError, network) {
        return await customFetch({path: "/jsonRpc/room.listUserRooms", body: null, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async getUserPropsList(body = null, onError, network) {
        return await customFetch({path: "/jsonRpc/room.listUserProps", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getUserAvailableRoomsList(body, onError, network) {
        return await customFetch({path: "/jsonRpc/room.listUserAvailableRooms", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async searchUserAvailableRoomsList(body, onError, network) {
        return await customFetch({path: "/jsonRpc/room.searchUserAvailableRooms", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getSortingTable(body, onError) {
        return await customFetch({path: "/jsonRpc/room.getSortingTable", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async deleteResourcesFromSortingTable(body, onError) {
        return await customFetch({path: "/jsonRpc/room.deleteResourcesFromSortingTable", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getTemplateRooms(onError) {
        return await customFetch({path: "/jsonRpc/room.getTemplateRooms", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async deleteRoom(roomId, onError) {
        return await customFetch({path: "/jsonRpc/room.deleteRoom", body: {roomId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateRoomExplorerStatus(roomId, explorerStatus, onError) {
        return await customFetch({path: "/jsonRpc/room.updateRoomExplorerStatus", body: {roomId, explorerStatus}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async updateRoomFeaturingScore(roomId, score, onError) {
        return await customFetch({path: "/jsonRpc/room.updateRoomFeaturingScore", body: {roomId, score}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async updateRoomPrice(roomId, price, onError) {
        return await customFetch({path: "/jsonRpc/room.updateRoomPrice", body: {roomId, price}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async updateRoomMetadata(body, onError) {
        return await customFetch({path: "/jsonRpc/room.updateRoomMetadata", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async expireRoomSubscription(roomId, onError) {
        return await customFetch({path: "/jsonRpc/room.expireRoomSubscription", body: {roomId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async createNewRoomSubscription(roomId, onError) {
        return await customFetch({path: "/jsonRpc/room.createNewRoomSubscription", body: {roomId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getTemplateStyles(onError) {
        return await customFetch({path: "/jsonRpc/room.getTemplateStyles", body: null})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async setRoomAsTemplate(body, onError) {
        return await customFetch({path: "/jsonRpc/room.setRoomAsTemplate", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async unsetRoomTemplate(roomId, onError) {
        return await customFetch({path: "/jsonRpc/room.unsetRoomTemplate", body: {roomId}})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async setRoomAlias(body, onError) {
        return await customFetch({path: "/jsonRpc/room.setRoomAlias", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }
    static async deleteRoomAlias(body, onError) {
        return await customFetch({path: "/jsonRpc/room.deleteRoomAlias", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

}