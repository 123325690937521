// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import {useNavigate} from "react-router-dom";
import {API} from "../../../api/API";
import {initialState, Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const RoomDelete = ({onError}) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(RoomsStore);

    const navigate = useNavigate();

    // Base state
    const [isDisabled, setIsDisabled] = useState(false);

    const onDelete = async () => {
        setIsDisabled(true);

        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Room deletion",
                text: `This operation cannot be restored. Are you sure you want to delete room?`,
                color: "danger",
                onCancel: () => {
                    setIsDisabled(false);
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal})
                },
                useCustomOnCancelFunction: true,
                onOk: async () => {
                    const result = await API.Rooms.deleteRoom(state?.model?.id, onError);

                    navigate(-1);

                    if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});

                    setIsDisabled(false);
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };

    return (
        <div className="w-100">
            <h4 className="my-4">Room deletion</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0">
                        <StyledButton onClick={onDelete} disabled={isDisabled} title="Delete room" type="danger"/>
                    </div>
                    <p className="mt-3 text-muted">
                        By pressing "Delete room" button you will delete this room from this network. This operation cannot be restored.
                    </p>
                </div>

                <hr/>
            </div>
        </div>
    );
}
