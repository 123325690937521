import {useCallback, useEffect, useRef, useState} from "react";

export const useStateSafe = initialValue => {
    const [val, setVal] = useState(initialValue);
    const mountedRef = useRef();

    useEffect(() => {
        mountedRef.current = true;
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const setValue = useCallback(s => {
        if (mountedRef.current) {
            setVal(s);
        }
    }, [setVal]);

    return [val, setValue];
};