// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import {withStyles} from "@material-ui/styles";
import {Accordion, AccordionDetails} from "@material-ui/core";

export const MyAccordion = withStyles({
    root: {
        border: "1px solid rgba(0, 0, 0, 0.125) !important",
        borderRadius: "8px",
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    }
})(Accordion);
export const MyAccordionDetails = withStyles({
    root: {
        display: "flex",
        flexDirection: "column"
    }
})(AccordionDetails);
