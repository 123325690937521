// Copyright © 2015-2022 Roomful Co. All rights reserved

import Cookies from 'js-cookie';
import {customFetch, customFetchOld} from "../Requests";
import Utils from "../../scripts/Utils";

export class AuthAPI {
    static async login(body, onError) {
        return await customFetchOld({path: "/auth/login", body, noSession: true})
            .then(response => ({response: response.json(), status: response.status}))
            .catch(onError);
    }

    static async isLogged() {
        const sessionId = Cookies.get('sessionId');
        const userId = Cookies.get('userId');
        const isCorrectSession = sessionId && sessionId.length > 0 && userId && userId.length > 0;

        if (isCorrectSession)
            return customFetchOld({path: "/auth/check", network: Utils.getNetwork()}).then(response => response.ok);
        else return false;
    }

    static logout() {
        customFetchOld({path: "/auth/logout"})
            .finally(() => {
                Cookies.remove("networkId");
                Cookies.remove("sessionId");
                Cookies.remove("userId");
                Cookies.remove("subdomainName");

                window.location.href = "/auth";
            });
    }
}