// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import {API} from "../../../api/API";
import {RoomsStore} from "../../../stores/RoomsStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const RoomPrice = ({onError}) => {
    const {state} = useContext(RoomsStore);

    // Base state
    const [value, setValue] = useState(state?.model?.price || 0);
    const [isLoading, setIsLoading] = useState(false);

    // Main functions
    const update = async () => {
        setIsLoading(true);
        const result = await API.Rooms.updateRoomPrice(state?.model?.id, value, onError);
        setIsLoading(false);

        if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
    };

    return (
        <>
            <h4 className="my-4">Room price</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0">
                        <div className="d-flex align-items-center" id="room-show-status">
                            <h6 className="mr-3 mb-1">Price: </h6>
                            <div className="mx-2 d-flex">
                                <input autoComplete="off" className="form-control" type="number"
                                       defaultValue={value} style={{width: "7rem"}}
                                       onChange={e => setValue(parseFloat(e.target.value))}/>
                            </div>
                            <StyledButton onClick={update} disabled={isLoading} title="Update" type="success"/>
                        </div>
                    </div>
                    <p className="mt-3 text-muted">
                        Price for using current room as a template.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
}
