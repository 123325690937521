// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";

// Components
import {NetworkGeneratePluginSettings} from "../components/NetworkGeneratePluginSettings";
import {NetworkCopyRoom} from "../components/NetworkCopyRoom";
import {NetworkSubscribeUsers} from "../components/NetworkSubscribeUsers";

export const NetworkCommands = props => {
    const {onError} = props;

    return (
        <form className="form-horizontal" autoComplete="new-password">
            <NetworkGeneratePluginSettings onError={onError}/>
            <NetworkCopyRoom onError={onError}/>
            <NetworkSubscribeUsers onError={onError}/>
        </form>
    );
}