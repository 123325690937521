// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
    list: [],
    total: 0
};

export const GamificationRankingStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'SET_TOTAL_COUNT':
            return {...state, total: action.payload};

        default:
            return state;
    }
}

export const GamificationRankingProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <GamificationRankingStore.Provider value={value}>{props.children}</GamificationRankingStore.Provider>;
};