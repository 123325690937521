// Copyright © 2015-2023 Roomful Co. All rights reserved

import {AuthAPI} from "./modules/AuthAPI";
import {UsersAPI} from "./modules/UsersAPI";
import {ResourcesAPI} from "./modules/ResourcesAPI";
import {NetworksAPI} from "./modules/NetworksAPI";
import {RoomsAPI} from "./modules/RoomsAPI";
import {AnalyticsAPI} from "./modules/AnalyticsAPI";
import {RolesManagerAPI} from "./modules/RolesManagerAPI";
import {NotificationAPI} from "./modules/NotificationAPI";
import {PropsAPI} from "./modules/PropsAPI";
import {GamificationRankingAPI} from "./modules/GamificationRankingAPI";
import {SocialEventsAPI} from "./modules/SocialEventsAPI";
import {KaraokeAPI} from "./modules/KaraokeAPI";

export class API {
    static get Auth () {return AuthAPI;}
    static get Resource () {return ResourcesAPI;}

    static get Users () {return UsersAPI;}
    static get Rooms () {return RoomsAPI;}
    static get Props () {return PropsAPI;}
    static get Networks () {return NetworksAPI;}

    static get Analytics () {return AnalyticsAPI;}
    static get RolesManager () {return RolesManagerAPI;}
    static get Notification () {return NotificationAPI;}
    static get GamificationRanking () {return GamificationRankingAPI;}
    static get SocialEvents () {return SocialEventsAPI;}
    static get Karaoke () {return KaraokeAPI;}
}