// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {useStyles} from "../style";
import {CMSStore} from "../../../stores/CMSStore";

export default function ContextMenu(props) {
    const {options = []} = props;
    const classes = useStyles();

    const {state, dispatch} = useContext(CMSStore);

    // Base state
    const [mouseX, setMouseX] = useState(0);
    const [mouseY, setMouseY] = useState(0);
    const [show, setShow] = useState(false);

    // Main functions
    const normalizePosition = (mouseX, mouseY) => {
        const contextMenu = document.querySelector(".context-menu");
        const scope = document.body;

        const {top: scopeOffsetY, left: scopeOffsetX} = scope.getBoundingClientRect();

        const scopeX = mouseX - scopeOffsetX;
        const scopeY = mouseY - scopeOffsetY;

        const outOfBoundsOnX = scopeX + contextMenu.clientWidth > scope.clientWidth;
        const outOfBoundsOnY = scopeY + contextMenu.clientHeight > scope.clientHeight;

        let normalizedX = mouseX;
        let normalizedY = mouseY;

        if (outOfBoundsOnX) normalizedX = scopeOffsetX + scope.clientWidth - contextMenu.clientWidth;
        if (outOfBoundsOnY) normalizedY = scopeOffsetY + scope.clientHeight - contextMenu.clientHeight;

        return {normalizedX, normalizedY};
    };
    const findParent = e => {
        let parent = e.target.closest(".single-content-item");
        return parent.dataset.id;
    };

    // Handlers
    const onContextMenu = e => {
        e.preventDefault();

        const {clientX, clientY} = e;
        const {normalizedX, normalizedY} = normalizePosition(clientX, clientY);

        setMouseX(normalizedX);
        setMouseY(normalizedY);

        dispatch({type: "SET_SELECTED_CONTENT_ID", payload: findParent(e)});
        setShow(true);
    };
    const onClickOutside = e => {
        const contextMenu = document.querySelector(".context-menu");
        if (e.target.offsetParent !== contextMenu)
            setShow(false);
    };
    const onResizeCloseMenu = () => {
        setShow(false);
    };

    // Effects
    useEffect(() => {
        window.addEventListener("click", onClickOutside);
        window.addEventListener("resize", onResizeCloseMenu);

        return () => {
            window.removeEventListener("click", onClickOutside);
            window.removeEventListener("resize", onResizeCloseMenu);
        };
    }, []);
    useEffect(() => {
        const items = document.querySelectorAll(".single-content-item");

        Array.from(items).forEach(item => item.addEventListener("contextmenu", onContextMenu));

        return () => {
            Array.from(items).forEach(item => item.removeEventListener("contextmenu", onContextMenu));
        };
    });
    useEffect(() => {
        if (state.hideContextMenu) {
            setShow(false);
            dispatch({type: "SET_HIDE_CONTEXT_MENU", payload: false});
        }
    }, [state.hideContextMenu]);

    return (
        <ul className={`${show ? "menu-visible" : "menu-hidden"} py-1 d-flex flex-column ${classes.contextMenu} context-menu`}
            style={{top: `${mouseY}px`, left: `${mouseX}px`}}>

            {options.map((option, key) =>
                !state.showFileEditMenu && !option.showWhenOpenedEditPanel && (
                    <li className="px-2 py-1 d-flex align-items-center" onClick={() => option.callback(setShow)} key={key}>
                        {option.icon}
                        <span>{option.title}</span>
                    </li>
                )
            )}
        </ul>
    );
}
