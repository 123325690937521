import {makeStyles} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    root: {
        background: "#fff",

        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid hsl(0, 0%, 80%) !important"
        },
        "& .rightBar .MuiIconButton-label": {
            paddingLeft: "0.4rem",
            borderLeft: "1px solid hsl(0, 0%, 80%) !important"
        },
        "& .search .MuiIconButton-label>svg": {color: "black !important"},
        "& .search-clear .MuiIconButton-label>svg": {color: "red !important"},
        "& .rightBar.loop .MuiIconButton-label>svg": {color: "#007bff !important"},
        "& .MuiSvgIcon-root" : {
            color: "hsl(0, 0%, 80%) !important"
        },
        "& .MuiIconButton-root": {background: "transparent !important"}
    }
}));

export default useStyles;