// Copyright © 2015-2023 Roomful Co. All rights reserved

/* eslint-disable array-callback-return */
import React, {useContext, useEffect, useState} from "react";
import Select from "react-select";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";

// MUI
import {AccordionSummary, FormHelperText} from "@material-ui/core";
import {ExpandMore} from "@material-ui/icons";

// Components
import {StyledCheckbox, StyledInput, StyledTimeInput} from "../../../components/styled/StyledInput";
import {MyAccordion, MyAccordionDetails} from "../../../components/styled/StyledAccordion";
import {StyledButton} from "../../../components/styled/StyledButton";
import {customStyles} from "../../../styles/dropdown";

const CategoryAccordion = ({title, children}) => (
    <MyAccordion style={{marginBottom: 16}} defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMore/>}><h4 style={{marginBottom: 0}}>{title}</h4></AccordionSummary>
        <MyAccordionDetails style={{display: "block"}}>{children}</MyAccordionDetails>
    </MyAccordion>
);

const Field = props => {
    const {title, type, value, helperText, returnedType, list, path, setValue} = props;

    let Element = null;

    // eslint-disable-next-line default-case
    switch (type) {
        case "input" :
            Element = (<div className="mb-3">
                <StyledInput title={title} noMargin helperText={helperText ?? ""}
                             defaultValue={value ?? ""}
                             onBlur={e => setValue(`${path}.value`, e.target.value)}/>
            </div>);
            break;
        case "int" :
            Element = (<div className="mb-3 col-4 d-inline-flex align-items-center" style={{verticalAlign: "top"}}>
                <label className="form-label pt-0 mr-2 mb-0 d-inline-block">{title}</label>
                <input autoComplete="off" className="form-control" type="number"
                       placeholder={title} defaultValue={value} style={{width: "3.5rem"}}
                       onBlur={e => setValue(`${path}.value`, parseInt(e.target.value))}/>
            </div>);
            break;
        case "checkbox" :
            Element = (<div className="mb-3 col-4 d-inline-flex flex-column justify-content-center" style={{verticalAlign: "top", minHeight: "2.375rem"}}>
                <StyledCheckbox title={title} checked={value || false} centerHorizontal={false}
                                onChange={e => setValue(`${path}.value`, e.target.checked)}/>
                {helperText?.length > 0 && <FormHelperText>{helperText}</FormHelperText>}
            </div>);
            break;
        case "timestamp" :
            Element = (<div className="mb-3 col-4 d-inline-block" style={{verticalAlign: "top"}}>
                <StyledTimeInput title={title} value={value?.slice(0, 16)}
                                 onChange={e => setValue(`${path}.value`, Utils.formatTime(new Date(e.target.value)))}/>
                {helperText?.length > 0 && <FormHelperText>{helperText}</FormHelperText>}
            </div>);
            break;
        case "select" :
            Element = (<div className="mb-3">
                <div style={{display: "flex"}}>
                            <span className="nav-link col-2"
                                  style={{paddingLeft: 7.5}}><b>{title}</b></span>
                    <div className="col-10 flex-column">
                        <Select
                            onChange={selected =>
                                setValue(`${path}.value`, returnedType === "string"
                                    ? selected.value
                                    : list.indexOf(selected.value))}
                            value={{
                                label: returnedType === "string" ? value : list[value],
                                value: returnedType === "string" ? value : list[value]
                            }}
                            options={list.map(el => ({label: el, value: el}))}
                            styles={customStyles(true)}
                        />
                        {helperText?.length > 0 && <FormHelperText>{helperText}</FormHelperText>}
                    </div>
                </div>
            </div>);
            break;
    }

    return Element;
};

export const NetworkInfo = props => {
    const {onError, noDefaultValues = false, saveNetwork = (settings, setSaveSettingsBtnLoading) => {}} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);

    const [saveSettingsBtnLoading, setSaveSettingsBtnLoading] = useState(false);

    const setSettings = settings => {
        console.log("SET_NETWORK_MODEL_SETTINGS", settings);
        dispatch({type: "SET_NETWORK_MODEL_SETTINGS", payload: settings});
    };
    const normalizeTitle = camelCaseTitle => {
        const normal = camelCaseTitle.replace(/([A-Z])/g, " $1");
        return normal.charAt(0).toUpperCase() + normal.slice(1);
    };

    // Path functions
    // const getValue = path => path.split(/[.\[\]'"]/).filter(p => p).reduce((o, p) => o ? o[p] : undefined, state?.openedNetwork?.settingsNetworks);
    const setValue = (param, value) => {
        const pluginSettings = state?.openedNetwork?.settingsNetworks;
        setToValue(pluginSettings, value, param);
        setSettings(pluginSettings);
    };
    const getPathByValue = (obj, value, path) => {
        let newPath = "";
        if (typeof obj !== 'object') return;

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let t = path;
                let v = obj[key];

                if (!path) newPath = key;
                else newPath = path + '.' + key;

                if (v === value) return newPath;
                else if (typeof v !== 'object') newPath = t;

                let res = getPathByValue(v, value, newPath);
                if (res) return res;
            }
        }
    };
    const setToValue = (obj, value, path) => {
        let i;
        path = path.split('.');
        for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

        obj[path[i]] = value;
    };

    // Render recursion
    const Items = props => {
        const {items = {}, keyName = "", itemKey : key} = props;
        const path = getPathByValue(state?.openedNetwork?.settingsNetworks, items[keyName]);
        const hasChildes = !items[keyName].hasOwnProperty("value");

        return (
            hasChildes
                ? (
                    <CategoryAccordion title={normalizeTitle(keyName)} key={key}>
                        {Object.keys(items[keyName]).map((nestedKeyName, nestedKey) =>
                            <Items items={items[keyName]} keyName={nestedKeyName} itemKey={nestedKey} key={nestedKey}/>)}
                    </CategoryAccordion>
                )
                : <Field title={normalizeTitle(keyName)} type={items[keyName]?.type} path={path} key={key}
                         helperText={items[keyName]?.helperText} value={items[keyName]?.value}
                         returnedType={items[keyName]?.returnedType} list={items[keyName]?.list}
                         setValue={setValue}
                />
        );
    };

    // Save settings
    const mergeDeep = (target, ...sources) => {
        const isObject = item => (item && typeof item === 'object' && !Array.isArray(item));

        if (!sources.length) return target;
        const source = sources.shift();

        if (isObject(target) && isObject(source)) {
            for (const key in source) {
                if (isObject(source[key])) {
                    if (!target[key]) Object.assign(target, {[key]: {}});
                    mergeDeep(target[key], source[key]);
                } else Object.assign(target, {[key]: source[key]});
            }
        }

        return mergeDeep(target, ...sources);
    };
    const getSettings = () => {
        const customItems = Object.freeze({...state?.openedNetwork?.settingsNetworks});
        const newItems = {};

        const iterate = (items, keyName, newItems) => {
            const hasChildes = !items[keyName].hasOwnProperty("value");

            if (hasChildes)
                Object.keys(items[keyName]).forEach(nestedKeyName => {
                    if (!newItems[keyName]) newItems[keyName] = {};
                    return iterate(items[keyName], nestedKeyName, newItems[keyName]);
                });
            else {
                newItems[keyName] = items[keyName].value;
            }
        };

        Object.keys(customItems)?.forEach(keyName => iterate(customItems, keyName, newItems));

        if (!Array.isArray(newItems.general.ssoProviders)) newItems.general.ssoProviders = newItems.general.ssoProviders?.replace(/\s/g, '')?.split(",");
        if (!Array.isArray(newItems.general.domainNames)) newItems.general.domainNames = newItems.general.domainNames?.replace(/\s/g, '')?.split(",");
        if (!Array.isArray(newItems.defaults.paymentProviders)) newItems.defaults.paymentProviders = newItems.defaults.paymentProviders?.replace(/\s/g, '')?.split(",");

        return newItems;
    };
    const setFormattedSettings = () => {
        const iterateThroughout = (items, keyName, filledItems) => {
            const hasChildes = !items[keyName].hasOwnProperty("value");

            if (hasChildes)
                Object.keys(items[keyName]).forEach(nestedKeyName =>
                    iterateThroughout(items[keyName], nestedKeyName, filledItems[nestedKeyName]));
            else items[keyName].value = filledItems;
        };

        const customItems = {...(state?.openedNetwork?.settingsNetworks ?? {})};
        const filledItems = state?.openedNetwork?.model?.settings;

        Object.keys(customItems)?.forEach(keyName =>
            iterateThroughout(customItems, keyName, filledItems[keyName]));

        dispatch({type: "SET_NETWORK_MODEL_SETTINGS", payload: customItems});
    };

    const saveSettings = async () => {
        const updatedSettings = getSettings();
        if (!noDefaultValues) {
            setSaveSettingsBtnLoading(true);

            const baseSettings = {...state?.openedNetwork?.model?.settings};
            const merged = mergeDeep(baseSettings, updatedSettings);

            const result = await API.Networks.setSettings({
                networkId: state?.openedNetwork?.model?.id,
                settings: merged
            }, onError);

            if (result?.error?.code === 200 && result?.data?.network)
                mainDispatch({
                    type: "SET_MODAL_SETTINGS",
                    payload: {
                        show: true,
                        title: "Success!",
                        text: "Network settings was updated successfully!",
                        color: "success"
                    }
                });
            else onError(new Error(result?.error?.message));

            setSaveSettingsBtnLoading(false);

        } else {
            if (saveNetwork && typeof saveNetwork === "function") saveNetwork(updatedSettings, setSaveSettingsBtnLoading);
        }
    };

    // Load settings
    useEffect(() => {
        if (!noDefaultValues) setFormattedSettings();
    }, [state?.openedNetwork?.model]);

    return (
        <>
            <div className={`${!noDefaultValues ? "mt-5" : ""} mb-2`}>
                {Object.keys(state?.openedNetwork?.settingsNetworks ?? {})?.map((keyName, key) =>
                    <Items items={state?.openedNetwork?.settingsNetworks} keyName={keyName} itemKey={key} key={key}/>)}
            </div>

            <div className="mb-5 w-100">
                <StyledButton onClick={saveSettings} disabled={saveSettingsBtnLoading} title="Save settings" type="success" fullWidth/>
            </div>
        </>
    );
}
