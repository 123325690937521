// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const ConferenceVisitsReport = props => {
    const {onError, params : {roomID, startDate, endDate}} = props;
    const [disabled, setDisabled] = useState(false);

    const onClick = async () => {
        setDisabled(true);

        await API.Analytics.generateReport(`/files/analytics/conferenceVisits`, {
            roomId: roomID ?? "",
            startDate,
            endDate,
            networkId: Utils.getNetwork()
        }, Utils.getNetwork(), onError);

        setDisabled(false);
    };

    return (
        <>
            <h4 className="my-4">Conference visits.</h4>
            <div>
                <div className="mb-1">
                    <StyledButton title="Generate analytics: conference visits" type="success"
                                  disabled={disabled} onClick={onClick}/>
                    <p className="mt-3 text-muted">
                        Report represents accumulative visits of company booths and file cabinets per user.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
};