// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useState} from "react";
import {useStyles} from "../../../style";
import Utils from "../../../../../scripts/Utils";
import {API} from "../../../../../api/API";
import {CMSStore} from "../../../../../stores/CMSStore";
import {PropsStore} from "../../../../../stores/PropsStore";

// Components
import {StyledButton} from "../../../../../components/styled/StyledButton";
import {StyledInput} from "../../../../../components/styled/StyledInput";

export default function EditPanel({onError}) {
    const classes = useStyles();

    // Context
    const {state, dispatch} = useContext(CMSStore);
    const {state: propsState, dispatch: propsDispatch} = useContext(PropsStore);

    // Base state
	const [isSaveBtnLoading, setIsSaveBtnLoading] = useState(false);

    // Main function
    const updatePropContentField = (key, value) => {
        const currentContent = state.fileInfo;
        currentContent[key] = value;

        dispatch({type: "SET_FILE_INFO", payload: currentContent});
    };
    const updatePropContent = useCallback(async () => {
    	setIsSaveBtnLoading(true);

        const {id, title, description, location, date, author, webLink, linkShortDescription} = state.fileInfo;
        const result = await API.Props.updateResourceAttributes({
            resourceId: id,
            attributes: {
                title,
                description,
                location,
                date,
                category: "",
                params: {
                    author,
                    webLink,
                    webLinkDescription: linkShortDescription ?? "",
                }
            }
        }, onError);

        if (result.error.code === 200) {
            const newPropContent = [];
            propsState.cmsContent.forEach(el => {
                if (el.id === id) {
                    el.title = title;
                    el.description = description;
                    el.location = location;
                    el.date = date;
                    el.category = "";
                    el.author = author;
                    el.webLink = webLink;
                    el.webLinkDescription = linkShortDescription;
                }

                newPropContent.push(el);
            });

            propsDispatch({type: "SET_CMS_CONTENT", payload: newPropContent});
        } else onError(new Error(result.error.message));

        dispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: false});
        setIsSaveBtnLoading(false);
    }, [state.fileInfo, propsState.cmsContent]);
    const close = () => {
        dispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: false});
    };

    return (
        <div className={`${classes.editPanel} p-2 edit-panel ${state.showFileEditMenu ? "d-flex" : "d-none"} flex-column align-items-start overflow-auto`}>
            <div className="p-2 w-100">
                <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0 mr-3">Edit {`"${Utils.limitStringLength(state.fileInfo?.fileName ?? "", 15)}"`}</h5>
                    <div className="d-flex">
                        <StyledButton onClick={updatePropContent} type="success" title="Save" size="sm"
									  disabled={isSaveBtnLoading} noLoading={!isSaveBtnLoading}/>
                        <div className="ml-1"><StyledButton onClick={close} type="danger" title="Close" size="sm"/></div>
                    </div>
                </div>
                <hr/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Title</p>
                <StyledInput title="" value={state.fileInfo.title || ""}
                         onChange={e => {updatePropContentField("title", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Description</p>
                <StyledInput title="" value={state.fileInfo.description || ""} isTextarea={true}
                         onChange={e => {updatePropContentField("description", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Author</p>
                <StyledInput title="" value={state.fileInfo.author || ""}
                         onChange={e => {updatePropContentField("author", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Link short description</p>
                <StyledInput title="" value={state.fileInfo.linkShortDescription || ""}
                         onChange={e => {updatePropContentField("linkShortDescription", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Web link</p>
                <StyledInput title="" value={state.fileInfo.webLink || ""}
                         onChange={e => {updatePropContentField("webLink", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Date</p>
                <StyledInput title="" value={state.fileInfo.date || ""}
                         onChange={e => {updatePropContentField("date", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <p className="px-2 mb-0">Location</p>
                <StyledInput title="" value={state.fileInfo.location || ""}
                         onChange={e => {updatePropContentField("location", e.target.value)}} smallSize fullWidth noMargin/>
            </div>

            <div className="w-100">
                <hr/>
                <div className="d-flex justify-content-end">
					<StyledButton onClick={updatePropContent} type="success" title="Save" size="sm"
								  disabled={isSaveBtnLoading} noLoading={!isSaveBtnLoading}/>
                    <div className="ml-1"><StyledButton onClick={close} type="danger" title="Close" size="sm"/></div>
                </div>
            </div>

        </div>
    );
}