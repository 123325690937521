// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {PropsStore} from "../../stores/PropsStore";
import Utils from "../../scripts/Utils";
import {useNavigate, useParams} from "react-router-dom";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {TabPanel, ThemedTab, ThemedTabs} from "../../components/styled/StyledTabs";
import {PropInfo} from "./tabs/PropInfo";
import Loading from "../../components/Loading";
import {TeamMembers} from "./tabs/TeamMembers";
import {StyledButton} from "../../components/styled/StyledButton";
import {RoomsStore} from "../../stores/RoomsStore";

export default function Prop() {
    const {roomId, propId} = useParams();
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: usersState} = useContext(UsersStore);
    const {state, dispatch} = useContext(PropsStore);
    const {dispatch: roomsDispatch} = useContext(RoomsStore);

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    useEffect(() => {
        (async () => {
            const result = (await API.Props.getModel({roomId, propId}, onError));

            if (result.error.code === 200) {
                if (result?.data?.prop && !Utils.isObjectEmpty(result?.data?.prop))
                    dispatch({
                        type: "SET_PROP_MODEL",
                        payload: result?.data?.prop
                    });
                else navigate(`/notFound`);
            }
            else onError(new Error("Can't load prop"));
        })();

        return () => {
            roomsDispatch({type: "CLEAR_LIST"});
            roomsDispatch({type: "CLEAR_USER_AVAILABLE_LIST"});
            dispatch({type: "SET_PROP_MODEL", payload: {}});
        };
    }, []);

    const [tabValue, setTabValue] = useState(1);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    return (
        state.model && !Utils.isObjectEmpty(state.model) ?
            <div className="content-wrapper">

                <ContentHeader title={`Prop: ${state.model?.title ?? (state.model.metadata?.name ?? "")} (#${state.model.id})`}
                               breadcrumbs={[
                    {
                        title: "Home",
                        link: "/home"
                    },
                    {
                        title: "Props",
                        link: `/props/list`,
                    },
                    {
                        title: `Prop: ${state.model?.title ?? (state.model.metadata?.name ?? "")} (#${state.model.id})`,
                        link: `/room/${roomId}/prop/${propId}`,
                        active: true
                    }
                ]}/>

                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header custom p-2 d-flex justify-content-between">
                                        <ThemedTabs value={tabValue} onChange={onTabChange}>
                                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0} label="Info"/>
                                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1} label="Exhibitors"/>
                                        </ThemedTabs>

                                        <StyledButton title="Open in Content Management System" type="warning"
                                                onClick={() => navigate(`/cms/room/${roomId}/prop/${propId}`)}/>
                                    </div>

                                    <div className="card-body">
                                        <div className="tab-content">
                                            <TabPanel value={tabValue} index={0}>
                                                <PropInfo roomId={roomId} propId={propId} onError={onError}/>
                                            </TabPanel>
                                            <TabPanel value={tabValue} index={1}>
                                                <TeamMembers roomId={roomId} propId={propId} onError={onError}/>
                                            </TabPanel>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            : <Loading/>
    );
}