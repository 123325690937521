// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useStyles} from "../style";
import Utils from "../../../scripts/Utils";

// MUI
import Image from 'material-ui-image';
import CircularProgress from "@material-ui/core/CircularProgress";

// Stores
import {UsersStore} from "../../../stores/UsersStore";
import {ChatsStore} from "../../../stores/ChatsStore";
import {useNavigate} from "react-router";
import {Store} from "../../../stores/MainStore";
import {API} from "../../../api/API";

export const SystemMessage = ({messageBody, created, isJson}) => {
    const classes = useStyles();
    const {userName, propName, eventType, userId, propId, roomId = null} = JSON.parse(messageBody);

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: usersState} = useContext(UsersStore);
    const {state, dispatch} = useContext(ChatsStore);
    const navigate = useNavigate();

    const onError = error => mainDispatch({
        type: "SET_MODAL_SETTINGS",
        payload: {
            show: true,
            title: "Error!",
            text: error.message,
            color: "default"
        }
    });

    const openUserChat = async () => {
        const result = await API.Users.getSimpleModel(userId, onError);

        if (result?.error?.code === 200 && result?.data?.user && !Utils.isObjectEmpty(result?.data?.user)) {
            const {error, data} = await state.connection.sendSocketRequest("textchat:getTextchatForUser", {data: {targetUser: userId}});

            if (error.code === 200) {
                dispatch({type: "CLEAR_MESSAGES"});

                const {id, avatar, companyName, companyTitle, firstName, lastName} = result?.data?.user;
                dispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: {user: {id, avatar, firstName, lastName, companyName, companyTitle}}});
                dispatch({type: "SET_CURRENT_TEXTCHAT", payload: data?.textchat});
                dispatch({type: "SET_IS_FIRST_LOADING_FOR_CURRENT_CHAT", payload: true});
                dispatch({type: "CHANGE_CATEGORY_ACTIVE", category: "all", payload: true});

                navigate('/chats');
            }
            else onError(new Error(error.message));
        }
    };
    const openPropChat = useCallback(async () => {
        const result = await API.Props.getModel({roomId, propId}, onError);

        if (result?.error?.code === 200 && result?.data?.prop && !Utils.isObjectEmpty(result?.data?.prop)) {
            const {error, data} = await state.connection.sendSocketRequest("textchat:getTextchatForProp", {data: {roomId, propId}});

            if (error.code === 200) {
                dispatch({type: "CLEAR_MESSAGES"});

                const {id, thumbnail, title} = data?.textchat;
                dispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: {
                        user: {
                            id,
                            avatar: thumbnail,
                            firstName: title,
                            lastName: "",
                            companyName: "",
                            companyTitle: ""
                        }
                    }
                });
                dispatch({type: "SET_CURRENT_TEXTCHAT", payload: data?.textchat});
                dispatch({type: "SET_IS_FIRST_LOADING_FOR_CURRENT_CHAT", payload: true});
                dispatch({type: "CHANGE_CATEGORY_ACTIVE", category: "all", payload: true});

                navigate('/chats');
            }
            else onError(new Error(error.message));
        }
    }, [roomId, propId]);

    const getActionText = () => {
        if (eventType === 1) return "entered your booth";
        if (eventType === 2) return "wrote to your booth";
        if (eventType === 3) return "entered your social circle";
        if (eventType === 4) return "wrote to your social circle";
    };
    const getAction = () => {
        return (
            <>
                User
                <b><a href="#" onClick={openUserChat}> {userName} </a></b>
                {getActionText()}
                {(roomId && roomId?.length > 0 && propId && propId?.length > 0)
                    ? <b><a href="#" onClick={openPropChat}> {propName}</a></b>
                    : <b> {propName}</b>}.
            </>
        );
    };

    return (
        isJson
            ? (
                <div className={classes.systemMessage}>
                    <p>{getAction(eventType)}</p>
                    <span>{Utils.formatDate(new Date(created))}</span>
                </div>
            )
            : (
                <div className={classes.systemMessage}>
                    <h6>System message</h6>
                    <p>{messageBody}</p>
                    <span>{Utils.formatDate(new Date(created))}</span>
                </div>
            )
    );
};

export const ChatMessage = props => {
    const {data, fieldWidth} = props;

    const {state: usersState} = useContext(UsersStore);
    const {state} = useContext(ChatsStore);
    const classes = useStyles();
    const ref = useRef();

    const {userId, messageBody, created, updated, index} = data;
    const isMy = userId === usersState.model.id;

    const getMessageStatus = () => {
        const lastReadMessageTime = new Date(state.currentInterlocutor?.lastReadMessageTime);

        return +lastReadMessageTime > +(new Date(updated)) ? "" : "sended";
    };

    const [status, setStatus] = useState(getMessageStatus());

    const isNextMessageMine = (Object.values(state.messages)[index - 1]?.userId === userId);
    const isPrevMessageMine = (Object.values(state.messages)[index + 1]?.userId === userId);

    const getAvatar = () => {
        if (isMy) return usersState.model?.avatar;
        else {
            if (state.currentTextchat.textchatMode !== "direct") return state.participants[userId]?.avatar;
            else return state.currentInterlocutor?.user?.avatar
        }
    };

    useEffect(() => {
        let messageWidth = ref.current.getBoundingClientRect().width;

        const margin = fieldWidth - messageWidth;

        if (isMy) ref.current.style.marginLeft = `${margin}px`;
        else ref.current.style.marginRight = `${margin}px`;

        return () => {
            if (ref.current) {
                if (isMy) ref.current.style.marginLeft = 0;
                else ref.current.style.marginRight = 0;
            }
        };
    });

    useEffect(() => {
        const newStatus = getMessageStatus();

        if (newStatus !== status) setStatus(newStatus);
    }, [state.currentInterlocutor, state.currentInterlocutor.lastReadMessageTime]);

    return (
        <div className={`${classes.message} ${isMy ? classes.myMessage : ""} message-${index}`} ref={ref}>
            <div className={`direct-chat-msg position-relative ${isMy ? "right" : ""}`}
                 style={isNextMessageMine ? {marginBottom: "-3px"} : {}}>
                {
                    !isPrevMessageMine ? (
                        <div className="direct-chat-infos clearfix">
                        <span className={`direct-chat-name ${isMy ? "float-right" : "float-left"}`}
                              style={{...(isMy ? {marginRight: "50px"} : {marginLeft: "50px"})}}>
                            {
                                isMy
                                    ? `${usersState.model?.firstName} ${usersState.model?.lastName} (you)`
                                    : (
                                        state.filters.chosenRoom?.id?.length > 0
                                            ? `${state.participants[userId]?.firstName} ${state.participants[userId]?.lastName}`
                                            : `${state.currentInterlocutor?.user?.firstName} ${state.currentInterlocutor?.user?.lastName}`
                                    )
                            }
                        </span>
                        </div>
                    ) : null
                }

                {!isNextMessageMine
                    ? <Image className="direct-chat-img" alt="User Avatar"
                             imageStyle={{width: "2.5em", height: "2.5em"}}
                             style={{
                                 width: "2.5em",
                                 height: "2.5em",
                                 padding: 0,
                                 borderRadius: "50%",
                                 position: "absolute",
                                 top: "50%",
                                 transform: "translateY(-50%)", ...(isMy ? {right: 0} : {left: 0})
                             }}
                             src={Utils.buildURLs(`/resource/${getAvatar()}`, false, null, {networkId: Utils.getNetwork()})}
                             loading={<CircularProgress/>}
                    />
                    : null}

                <div className={`direct-chat-text ${isNextMessageMine ? classes.disableTriangle : ""}`}>
                    <div align={isMy ? "right" : "left"}
                         style={{wordBreak: "break-word", textAlign: "left"}}>{messageBody}</div>
                    <div className="d-flex justify-content-end" style={{fontSize: "0.875em", whiteSpace: "nowrap"}}>
                        <div className="direct-chat-timestamp message-status" style={{whiteSpace: "nowrap"}}>
                            {Utils.formatDate(new Date(updated))}
                        </div>
                        <div className="message-status text-right ml-2">
                            {
                                isMy ? (
                                    status === "waiting" ? <i className={`fas fa-history fa-xs message-status`}/>
                                        : status === "sended" ? <i className={`fas fa-check fa-xs message-status`}/>
                                        : <i className={`fas fa-check-double fa-xs message-status`}/>
                                ) : null
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
