// Copyright © 2015-2022 Roomful Co. All rights reserved

export const imageContainerStyles = {
	width: "100%",
	height: "14.063rem",
	padding: 0,
	color: "gray",
	backgroundColor: "#f1f1f1"
};

export const imageStyles = {
	objectFit: "contain",
	paddingTop: "2rem",
	paddingBottom: "2rem",
	border: "1px solid #e8e8e8"
};
