// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    model: {},
    balance: {},
    myRoles: null,
    updatingModel: false,

    openedUserModel: {},
    roles: {},
    updateUserRolesList: true,

    socialModels: [],
    updatingSocialModels: true,

    passwordRetype: {
        type: "password",
        value: ""
    },
    password: {
        type: "password",
        value: ""
    },

    list: [],
    totalCount: 0,
    selectedUsers: [],
    selectAllToggle: false,
    activeUsersList: [],

    contacts: [],

    cmsContent: [],

    customBody: {
        asPropManager: false,
        propTypes: [],
        roomId: ""
    }
};

export const UsersStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_USER_MODEL':
            return {...state, [action.key]: action.payload};
        case 'SET_USER_STATUS':
            return {...state, [action.key]: Object.assign(state[action.key], {isDisabled: action.payload})};
        case 'SET_FIRSTNAME':
            return {...state, [action.key]: Object.assign(state[action.key], {firstName: action.payload})};
        case 'SET_LASTNAME':
            return {...state, [action.key]: Object.assign(state[action.key], {lastName: action.payload})};
        case 'SET_BIRTHDAY':
            return {...state, [action.key]: Object.assign(state[action.key], {birthday: action.payload})};
        case 'SET_HOMETOWN':
            return {...state, [action.key]: Object.assign(state[action.key], {hometown: action.payload})};
        case 'SET_EDUCATION':
            return {...state, [action.key]: Object.assign(state[action.key], {education: action.payload})};
        case 'SET_DESCRIPTION':
            return {...state, [action.key]: Object.assign(state[action.key], {description: action.payload})};
        case 'SET_LINKURL':
            return {...state, [action.key]: Object.assign(state[action.key], {linkUrl: action.payload})};
        case 'SET_VIDEOCHATURL':
            return {...state, [action.key]: Object.assign(state[action.key], {params: {...state[action.key]?.params, videochatUrl: action.payload}})};


        case 'SET_COMPANY_NAME':
            return {...state, [action.key]: Object.assign(state[action.key], {companyName: action.payload})};
        case 'SET_COMPANY_TITLE':
            return {...state, [action.key]: Object.assign(state[action.key], {companyTitle: action.payload})};

        case 'SET_PASSWORD_TYPE':
            return {...state, password: {type: action.payload, value: state.password.value}};
        case 'SET_PASSWORD_VALUE':
            return {...state, password: {type: state.password.type, value: action.payload}};
        case 'SET_PASSWORD_CONFIRM_TYPE':
            return {...state, passwordRetype: {type: action.payload, value: state.passwordRetype.value}};
        case 'SET_PASSWORD_CONFIRM_VALUE':
            return {...state, passwordRetype: {type: state.passwordRetype.type, value: action.payload}};

        case 'SET_USER_BALANCE':
            return {...state, balance: action.payload};

        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'SET_TOTAL_COUNT':
            return {...state, totalCount: action.payload};
        case 'CLEAR_LIST':
            return {...state, list: [], totalCount: 0};

        case 'SET_ACTIVE_USERS_LIST':
            return {...state, activeUsersList: action.payload};

        case 'SET_SELECTED_USERS_LIST':
            return {...state, selectedUsers: action.payload};
        case 'SET_SELECT_ALL_TOGGLE':
            return {...state, selectAllToggle: action.payload};

        case 'SET_ROLES':
            return {...state, roles: action.payload};
        case 'UPDATE_USER_ROLES_LIST':
             return {...state, updateUserRolesList: action.payload};

        case 'SET_MY_ROLES':
            return {...state, myRoles: action.payload};
        case 'SET_UPDATING_MODEL':
            return {...state, updatingModel: action.payload};

        case 'SET_CONTACTS':
            return {...state, contacts: action.payload};

        case 'SET_CMS_CONTENT':
            return {...state, cmsContent: action.payload};
        case 'SET_CUSTOM_BODY':
            return {...state, customBody: {...state?.customBody, roomId: action.payload}};

        case 'SET_SOCIAL_MODELS':
            return {...state, socialModels: action.payload, updatingSocialModels: false};
        case 'SET_UPDATING_SOCIAL_MODELS':
            return {...state, updatingSocialModels: action.payload};
        case 'CLEAR_SOCIAL_MODELS':
            return {...state, socialModels: [], updatingSocialModels: true};

        default:
            return state;
    }
}

export const UserProvider = ({children}) => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <UsersStore.Provider value={value}>{children}</UsersStore.Provider>;
};