// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../../api/API";
import {initialState, Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import TextField from "@material-ui/core/TextField";
import {createFilterOptions} from "@material-ui/lab";
import Chip from "@material-ui/core/Chip";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

const filter = createFilterOptions();

export const RoomSetAsTemplate = ({roomId, onError}) => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(RoomsStore);

    // Base state
    const [isTemplate, setIsTemplate] = useState(state?.model?.templateStyles?.length > 0);
    const [isLoading, setIsLoading] = useState(false);

    const [predefinedValues, setPredefinedValues] = useState([]);
    const [value, setValue] = useState((state?.model?.templateStyles ?? []).map(el => ({title: el, inputValue: null})));

    // Main functions
    const setAsTemplate = useCallback(async () => {
        setIsLoading(true);

        const styles = value.map(el => el.title);

        await API.Rooms.setRoomAsTemplate({roomId, styles}, onError);
        setIsTemplate(true);

        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Room styles updated",
                text: `Room styles successfully updated!`,
                color: "success"
            }
        });

        setIsLoading(false);
    }, [isTemplate, value]);
    const deleteTemplate = async () => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Room template deletion",
                text: `Are you sure you want to delete this room template? Nobody else will be able to purchase it.`,
                color: "danger",
                onCancel: () => {
                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal})
                },
                useCustomOnCancelFunction: true,
                onOk: async () => {
                    await API.Rooms.unsetRoomTemplate(roomId, onError);
                    setIsTemplate(false);
                    setValue([]);

                    mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                },
                useCustomOnOkFunction: true,
                useCustomOkText: "Yes"
            }
        });
    };

    // Handlers
    const onChangeValue = useCallback((event, newValue) => {
        if (typeof newValue === 'string')
            setValue([...(state?.model?.templateStyles.map(el => ({title: el, inputValue: null}))), {title: newValue}]);
        else if (newValue && newValue.inputValue)
            setValue([...(state?.model?.templateStyles.map(el => ({title: el, inputValue: null}))), {title: newValue.inputValue}]);
        else if (Array.isArray(newValue))
            setValue(newValue.map(el => {
                if (typeof el === 'object' && el !== null)
                    return {...el, title: el?.title?.replace("Add ", "")?.replace(/"/gim, "")};
                else return {title: el};
            }));
        else setValue(newValue);
    }, [state?.model?.templateStyles]);
    const onFilterOptions = (options, params) => {
        const filtered = filter(options, params);

        const {inputValue} = params;
        // Suggest the creation of a new value
        const isExisting = options.some((option) => inputValue === option.title);
        if (inputValue !== '' && !isExisting) {
            filtered.push({
                inputValue,
                title: `Add "${inputValue}"`,
            });
        }

        return filtered;
    };
    const getOptionLabel = (option) => {
        // Value selected with enter, right from the input
        if (typeof option === 'string') {
            return option;
        }
        // Add "xxx" option created dynamically
        if (option.inputValue) {
            return option.inputValue;
        }
        // Regular option
        return option.title;
    };

    // Effects
    useEffect(() => {
        (async () => {
            const result = (await API.Rooms.getTemplateStyles(onError)).data.styles;
            const values = result.map(el => ({title: el, inputValue: null}));
            setPredefinedValues(values);
        })();
    }, []);

    return (
        <>
            <h4 className="my-4">Template settings</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0">
                        <div className="d-flex align-items-center w-100">
                            <Autocomplete multiple
                                onChange={onChangeValue}
                                filterOptions={onFilterOptions} value={value}
                                selectOnFocus clearOnBlur handleHomeEndKeys freeSolo
                                options={predefinedValues}
                                getOptionLabel={getOptionLabel}
                                renderOption={option => <span>{option?.title}</span>}
                                sx={{width: 300}} style={{width: "100%"}} size="small"
                                renderInput={(params) => (<TextField {...params} variant="outlined" label="Styles"/>)}
                                renderTags={(tagValue, getTagProps) =>
                                    tagValue.map((option, index) =>
                                        (<Chip label={option?.title} {...getTagProps({index})}/>))}
                            />

                            <div className="ml-2">
                                <StyledButton onClick={setAsTemplate} disabled={isLoading} title="Set&nbsp;as&nbsp;template" type="success"/>
                            </div>
                        </div>
                        <p className="mt-3 text-muted">
                            Make room a template. Other users could create their own rooms based on the template.
                        </p>
                        <hr className="mt-0 w-100"/>
                        <div className="mt-2 mb-3 w-100">
                            <StyledButton onClick={deleteTemplate} disabled={!isTemplate ? true : isLoading} noLoading={!isTemplate} title="Delete template" type="danger"/>
                        </div>
                        <p className="mt-0 text-muted">
                            Remove room from templates.
                        </p>
                    </div>
                </div>

                <hr className="mt-0"/>
            </div>
        </>
    );
}
