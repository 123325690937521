// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { API } from "../../api/API";
import Utils from "../../scripts/Utils";
import { Store } from "../../stores/MainStore";
import { RoomsStore } from "../../stores/RoomsStore";
import { UsersStore } from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import { CircleTabPanel, ThemedCircleTab, ThemedCircleTabs } from "../../components/styled/StyledTabs";
import Loading from "../../components/Loading";

// Tabs
import { RoomInfo } from "./tabs/RoomInfo";
import { RoomCommands } from "./tabs/RoomCommands";
import { RoomBoothsList } from "./tabs/RoomBoothsList";
import { RoomFileCabinetsList } from "./tabs/RoomFileCabinetsList";

export default function Room () {
    const {roomId, tab = "info"} = useParams();

    const tabs = ["info", "commands", "booths", "filecabinets"];
    const tabIndex = tabs.indexOf(tab.toLowerCase());
    const index = tabIndex === -1 ? 0 : tabIndex;

    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: userState} = useContext(UsersStore);
    const {state, dispatch} = useContext(RoomsStore);

    const isSuperAdmin = () => Utils.isSuperAdmin(userState.myRoles);
    const isNetworkAdmin = () => Utils.isNetworkAdmin(userState.myRoles);

    // Base state
    const [hasFeaturing, setHasFeaturing] = useState(false);
    const [tabValue, setTabValue] = useState(index);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // Effects
    useEffect(() => {
        (async () => {
            const data = (await API.Rooms.getModel(roomId, onError, Utils.getNetwork())).data;

            if(data?.room && !Utils.isObjectEmpty(data?.room)) {
                dispatch({type: "SET_ROOM_MODEL", payload: data?.room});

                if(data?.featuring && !Utils.isObjectEmpty(data?.featuring)) {
                    setHasFeaturing(true);
                    dispatch({type: "SET_ROOM_FEATURING", payload: data?.featuring});
                }
            } else navigate(`/notFound`);
        })();

        return () => {
            dispatch({type: "SET_ROOM_MODEL", payload: {}});
            dispatch({type: "SET_ROOM_FEATURING", payload: {}});
        };
    }, []);

    const tabNavigation = (
        <ThemedCircleTabs value={tabValue} onChange={onTabChange}>
            <ThemedCircleTab href={`/room/${roomId}/info`} label="Info" tabIndex={0}
                             activeTab={tabValue} setActiveTab={setTabValue}/>
            {(isSuperAdmin || isNetworkAdmin) &&
                <ThemedCircleTab href={`/room/${roomId}/commands`} label="Commands" tabIndex={1}
                                 activeTab={tabValue} setActiveTab={setTabValue}/>}
            <ThemedCircleTab href={`/room/${roomId}/booths`} label="Booths" tabIndex={2}
                             activeTab={tabValue} setActiveTab={setTabValue}/>
            <ThemedCircleTab href={`/room/${roomId}/filecabinets`} label="File cabinets" tabIndex={3}
                             activeTab={tabValue} setActiveTab={setTabValue}/>
        </ThemedCircleTabs>
    );

    const tabContent = (
        <>
            <CircleTabPanel value={tabValue} index={0}>
                <RoomInfo roomId={roomId} onError={onError}/>
            </CircleTabPanel>
            {(isSuperAdmin || isNetworkAdmin) &&
                <CircleTabPanel value={tabValue} index={1}>
                    <RoomCommands roomId={roomId} onError={onError}/>
                </CircleTabPanel>}
            <CircleTabPanel value={tabValue} index={2}>
                <RoomBoothsList onError={onError}/>
            </CircleTabPanel>
            <CircleTabPanel value={tabValue} index={3}>
                <RoomFileCabinetsList onError={onError}/>
            </CircleTabPanel>
        </>
    )

    return (
        state.model && !Utils.isObjectEmpty(state.model) && (hasFeaturing ? (state.featuring && !Utils.isObjectEmpty(state.featuring)) : true) ?
            <div className="content-wrapper">

                <ContentHeader title={`Room: ${state.model.metadata?.name} (${state.model.id})`}/>

                <section className="content">
                    <div className="container-fluid">
                        <div className="card card-tabs" style={{background: "transparent", boxShadow: "none"}}>
                            <div className="card-header p-0 pt-1 d-flex custom justify-content-between"
                                 style={{borderTop: "none", borderBottom: "1px solid rgb(222, 226, 230)"}}
                                 id="table-header">
                                {tabNavigation}
                            </div>

                            <br/>

                            <div className="card">
                                <div className="card-body">
                                    <div className="tab-content">
                                        {tabContent}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            : <Loading/>
    );
}
