// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import Switch from "react-switch";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";

// MUI
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";

// Components
import {ThemedTabs, ThemedTab, TabPanel} from "../../../components/styled/StyledTabs";
import {UserSettings} from "../tabs/UserSettings";
import {UserAvatar} from "../tabs/UserAvatar";
import {UserRoles} from "../tabs/UserRoles";
import {UserSocialLinks} from "../tabs/UserSocialLinks";
import {StyledButton} from "../../../components/styled/StyledButton";

export const UserPanel = props => {
    const {model, modelKey, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const [tabValue, setTabValue] = useState(0);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const [isDisabled, setIsDisabled] = useState(false);
    const [statusModalShow, setStatusModalShow] = useState(false);
    const [changeStatusBtnDisabled, setChangeStatusBtnDisabled] = useState(false);

    useEffect(() => {
        if (!Utils.isObjectEmpty(state.roles)) dispatch({type: "SET_ROLES", payload: {}});
        if (!state.updateUserRolesList) dispatch({type: "UPDATE_USER_ROLES_LIST", payload: true});
    }, []);

    const changeStatus = async () => {
        setChangeStatusBtnDisabled(true);

        let result;
        if (isDisabled) result = await API.Users.activate(model?.id, onError);
        else result = await API.Users.disable(model?.id, onError);

        if (result && result.error && result.error.code === 200) {
            setChangeStatusBtnDisabled(false);
            setStatusModalShow(false);
            dispatch({
                type: "SET_USER_STATUS",
                key: modelKey,
                payload: !isDisabled
            });

            mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Success!",
                    text: "User status was updated successfully!",
                    color: "success"
                }
            });
        } else onError(new Error("Unknown error occurred during status updating, please try again later."));
    };

    return (
        <div className="col-md-8">
            <div className="card">
                <div className="card-header p-2 d-flex custom justify-content-between">
                    <ThemedTabs value={tabValue} onChange={onTabChange}>
                        <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0} label="Settings"/>
                        <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1} label="Avatar"/>
                        {(Utils.isSuperAdmin(state.myRoles) || Utils.isNetworkAdmin(state.myRoles)) &&
                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={2} label="Roles"/>}
                        {Utils.hasPermission(state.myRoles, "user.manageSocialLinks") &&
                            <ThemedTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={3} label="Social Links"/>
                        }
                    </ThemedTabs>

                    {Utils.hasPermission(state.myRoles, "all") &&
                        <div className="d-flex align-items-center" id="user-status">
                            <h5 className="mr-3 mb-0">User status</h5>
                            <Switch onChange={checked => {
                                setStatusModalShow(true);
                                setIsDisabled(checked);
                            }} checked={!model?.isDisabled}
                                    width={Utils.emToPx(3.5, document.querySelector("#user-status > div"))}
                                    height={Utils.emToPx(1.75, document.querySelector("#user-status > div"))}
                            />
                        </div>
                    }
                </div>

                <div className="card-body">
                    <div className="tab-content">
                        <TabPanel value={tabValue} index={0}><UserSettings model={model} modelKey={modelKey} onError={onError}/></TabPanel>
                        <TabPanel value={tabValue} index={1}><UserAvatar model={model} onError={onError}/></TabPanel>
                        <TabPanel value={tabValue} index={2}><UserRoles model={model} onError={onError}/></TabPanel>
                        <TabPanel value={tabValue} index={3}><UserSocialLinks model={model} onError={onError}/></TabPanel>
                    </div>
                </div>
            </div>

            <Dialog open={statusModalShow} onClose={() => setStatusModalShow(false)}>
                <DialogTitle>
                    Are you sure to change user status
                    to {model.isDisabled ? "Active" : "Disabled"}?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        By pressing "Agree" you will change user status immediately!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={() => setStatusModalShow(false)} title="Disagree" type="danger"
                                  variant="contained"/>
                    <StyledButton onClick={changeStatus} autoFocus disabled={changeStatusBtnDisabled}
                                  title="Agree" type="success" variant="contained"/>
                </DialogActions>
            </Dialog>
        </div>
    );
};
