// Copyright © 2015-2023 Roomful Co. All rights reserved

import { customFetch, parseSpreadsheetRequest } from "../Requests";

export class KaraokeAPI {
	static async getKaraokeInfo(onError) {
		return await customFetch({path: "/jsonRpc/karaoke.getKaraokeInfo", body: null})
			.then(resultData => resultData.json())
			.catch(onError);
	}

	static async parseAndImportKaraokeSongsFromSpreadsheet(body, onSuccess, onError) {
		return await parseSpreadsheetRequest({path: "/files/parseAndImportKaraokeSongsFromSpreadsheet", body})
			.then(onSuccess)
			.catch(onError);
	}
}
