// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {SocialEventsStore} from "../../stores/SocialEventsStore";

// MUI
import Image from 'material-ui-image';
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stepper, Step, StepLabel, Box} from "@material-ui/core";

// Components
import {StyledInput, StyledTimeInput} from "../../components/styled/StyledInput";
import {StyledImage} from "../../components/styled/StyledImage";
import {StyledButton} from "../../components/styled/StyledButton";
import {imageContainerStyles, imageStyles} from "./style";
import {createResource} from "../../api/Requests";

export default function CreateNewSocialEvents({onError}) {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(SocialEventsStore);

    // Base state
    const [createNewEventBtnDisabled, setCreateNewEventBtnDisabled] = useState(false);

    // New event state
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [startDate, setStartDate] = useState(Utils.getTime());
    const [endDate, setEndDate] = useState(Utils.getTime());

    const [thumbnail, setThumbnail] = useState("null");
    const [uploadedFile, setUploadedFile] = useState("");
    const [saveFileBtnDisabled, setSaveFileBtnDisabled] = useState(false);

    const [createBtnLoading, setCreateBtnLoading] = useState(false);
    const [createBtnDisabled, setCreateBtnDisabled] = useState(true);

    const [updateBtnLoading, setUpdateBtnLoading] = useState(false);
    const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

    // Main functions
    const open = () => dispatch({type: "SET_IS_OPENED", payload: true});
    const close = () => dispatch({type: "SET_IS_OPENED", payload: false});

    // Create/update event state
    const uploadThumbnail = () => Utils.uploadFileInput("image/*",file => {
        setSaveFileBtnDisabled(true);
        setUploadedFile(file);
        setThumbnail(URL.createObjectURL(file));
        setSaveFileBtnDisabled(false);
    });
    const createNewSocialEvent = async () => {
        setCreateBtnDisabled(true);

        const resourceID = await createResource(uploadedFile, Utils.getNetwork(), onError);
        const result = await API.SocialEvents.createSocialEvent({
            event: {
                title, description, previewResourceId: resourceID,
                startDate: Utils.dateToISO(new Date(startDate)),
                endDate: Utils.dateToISO(new Date(endDate))
            }
        }, onError);

        if (result?.error?.code === 200)
            dispatch({type: "ADD_TO_LIST", payload: result?.data?.event});
        else onError(new Error(result?.error?.message));

        setCreateBtnDisabled(false);
        close();
    };
    const updateSocialEvent = async () => {
        setUpdateBtnDisabled(true);
        setUpdateBtnLoading(true);

        const resourceID = uploadedFile?.length === 0
            ? state.editableEvent?.previewResourceId
            : await createResource(uploadedFile, Utils.getNetwork(), onError);
        const result = await API.SocialEvents.updateSocialEvent({
            event: {
                ...state.editableEvent,
                title, description, previewResourceId: resourceID,
                startDate: Utils.dateToISO(new Date(startDate)),
                endDate: Utils.dateToISO(new Date(endDate)),
            }
        }, onError);

        if (result?.error?.code === 200)
            dispatch({type: "EDIT_ITEM", id: state.editableEvent?.id, payload: result?.data?.event});
        else onError(new Error(result?.error?.message));

        setUpdateBtnLoading(false);
        setUpdateBtnDisabled(false);

        close();
        cancel();
    };
    const cancel = () => {
        dispatch({type: "SET_EDIT_MODE", payload: false});
        dispatch({type: "SET_EDITABLE_EVENT", payload: null});

        setTitle("");
        setDescription("");
        setStartDate(Utils.getTime());
        setEndDate(Utils.getTime());
        setThumbnail("null");
        setUploadedFile("");
    };

    // Effects
    useEffect(() => {
        setCreateBtnDisabled(!(title?.length > 0 && description?.length > 0 && startDate?.length > 0 && endDate?.length > 0 && uploadedFile));
        setCreateBtnLoading(!!(title?.length > 0 && description?.length > 0 && startDate?.length > 0 && endDate?.length > 0 && uploadedFile));
    }, [title, description, startDate, endDate, uploadedFile]);
    useEffect(() => {
        if (state.editMode) {
            setTitle(state.editableEvent?.title);
            setDescription(state.editableEvent?.description);
            setStartDate(state.editableEvent?.startDate);
            setEndDate(state.editableEvent?.endDate);
            setThumbnail(Utils.buildURLs(`/resource/thumbnail/256/${state.editableEvent?.previewResourceId}`, false, null, {networkId: Utils.getNetwork()}));
        }
    }, [state.editMode]);

    return (
        <div>
            <StyledButton onClick={open} disabled={createNewEventBtnDisabled} title="Create new event" type="success" size="sm"/>

            <Dialog open={state.isOpened} onClose={close} fullWidth={true} maxWidth="md" disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                <DialogTitle id="form-dialog-title">
                    {state.editMode
                        ? `Update social event: ${state.editableEvent?.title}`
                        : "Create new social event"}
                </DialogTitle>
                <DialogContent>
                    <div className="row align-items-stretch w-100" style={{minHeight: "30vh"}}>
                        <div className="col-5">
                            <div className="row px-3 h-100 flex-column">
                                <Image imageStyle={imageStyles} style={imageContainerStyles} src={thumbnail}/>
                                <StyledButton fullWidth onClick={uploadThumbnail} disabled={saveFileBtnDisabled}
                                              title="Upload thumbnail" type="primary" size="sm"
                                              style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}/>
                            </div>
                        </div>
                        <div className="col-7">
                            <StyledInput fullWidth placeholder="Title" value={title}
                                         onChange={e => setTitle(e.target.value)}/>
                            <StyledInput fullWidth placeholder="Description" value={description}
                                         onChange={e => setDescription(e.target.value)} isTextarea={true}/>

                            <div className="d-flex ml-3">
                                <div className="mr-5">
                                    <StyledTimeInput title="Start date: " value={startDate.slice(0, 16)} column
                                                     fullWidth onChange={e => setStartDate(e.target.value)}/>
                                </div>
                                <StyledTimeInput title="End date: " value={endDate.slice(0, 16)} column fullWidth
                                                 onChange={e => setEndDate(e.target.value)}/>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <div className="mr-3">
                            <StyledButton onClick={() => {close(); cancel();}} type="default" noLoading={true}
                                          title="Cancel" size="lg" disabled={(!(!updateBtnDisabled || !createBtnDisabled))}/>
                        </div>
                        {state.editMode
                            ? <StyledButton onClick={updateSocialEvent} type="warning" title="Update" size="lg"
                                      noLoading={!updateBtnLoading} disabled={updateBtnDisabled}/>
                            : <StyledButton onClick={createNewSocialEvent} type="success" title="Create" size="lg"
                                      noLoading={!createBtnLoading} disabled={createBtnDisabled}/>}
                    </Box>
                </DialogActions>
            </Dialog>

        </div>
    );
}