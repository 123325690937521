// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useState} from "react";
import {useStyles} from "../style";
import Utils from "../../../scripts/Utils";
import {ChatsStore} from "../../../stores/ChatsStore";

// MUI
import {IconButton} from '@material-ui/core';
import {Tune, Done} from "@material-ui/icons";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {UserRooms} from "../../../components/list/Rooms/UserRooms";

export const Filters = () => {
    const classes = useStyles();

    const {state, dispatch} = useContext(ChatsStore);
    const [show, setShow] = useState(false);
    const [room, setRoom] = useState(null);

    const getState = index => state.filters.activeElement === index ? "active" : "";
    const onRoomSelected = selectedRoom => {
        setRoom(selectedRoom)
        dispatch({type: "SET_FILTERS_CHOSEN_ROOM", payload: selectedRoom});
    };

    const standardSelectItem = (e, index) => {
        e.preventDefault();
        setShow(false);
        dispatch({type: "SET_FILTERS_ACTIVE_ELEMENT", payload: index});
    };
    const itemWithInput = e => {
        e.preventDefault();

        if (state.filters.chosenRoom?.id?.length > 0 && e.currentTarget.id === "select-room-id-btn") {
            setShow(false);
            dispatch({type: "SET_FILTERS_ACTIVE_ELEMENT", payload: 3});
            dispatch({type: "SET_FILTERS_NEED_RENDER", payload: true});
        }

        console.log(e.currentTarget.id);
    };

    return (
        <div className="input-group">
            <div className="input-group-prepend position-relative">
                <IconButton aria-label="Filters" component="span" id="filters" onClick={() => setShow(true)}>
                    <Tune/>
                </IconButton>
                <div id="pad" className={`${show ? "show" : ""} ${classes.pad}`} onClick={() => setShow(false)}/>
                <div className={`dropdown-menu ${show ? "show" : ""} ${classes.filtersDropdownMenu}`} >
                    <a className={`dropdown-item ${getState(4)}`} onClick={e => standardSelectItem(e, 4)}>Important Chats</a>
                    <a className={`dropdown-item ${getState(0)}`} onClick={e => standardSelectItem(e, 0)}>All Chats</a>
                    <a className={`dropdown-item ${getState(1)}`} onClick={e => standardSelectItem(e, 1)}>Direct Chats</a>
                    <a className={`dropdown-item ${getState(2)}`} onClick={e => standardSelectItem(e, 2)}>Group Chats</a>
                    <a className={`dropdown-item noActiveStyles ${getState(3)}`} onClick={e => e.preventDefault()}>
                        <span>Group Chats within Room</span>
                        <div className="d-flex mt-1" style={{minWidth: "500px"}}>
                            <div className="mr-3 w-100 d-flex align-items-center">
                                <UserRooms room={room} onChange={onRoomSelected} networkId={Utils.getNetwork()}/>
                            </div>

                            <StyledButton onClick={itemWithInput} type="default" title={<Done/>} id="select-room-id-btn"/>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    );
};