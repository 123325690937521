// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
    title: "DEFAULT",
    modal: {
        show: false,
        title: "",
        text: "Please, reload page or try later!",
        type: "error",
        color: "danger",
        useCustomOnOkFunction: false,
        onOk: () => {},
        useCustomOnCancelFunction: false,
        onCancel: () => {}
    },

    isFocused: true,
    lastReceivedMessageTime: 0,
    needUpdate: false
};

export const Store = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_TITLE':
            return {...state, title: action.payload};
        case 'SET_MODAL_SETTINGS':
            return {...state, modal: {...state.modal, ...action.payload}};

        case 'SET_IS_FOCUSED':
            return {...state, isFocused: action.payload};
        case 'SET_LAST_RECEIVED_MESSAGE_TIME':
            return {...state, lastReceivedMessageTime: action.payload};
        case 'SET_NEED_UPDATE':
            return {...state, needUpdate: action.payload};

        default:
            return state;
    }
}

export const MainProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <Store.Provider value={value}>{props.children}</Store.Provider>;
};