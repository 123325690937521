// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useRef, useState} from "react";
import Cookies from 'js-cookie';
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {createResource} from "../../../api/Requests";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import Image from 'material-ui-image';
import {MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList} from "@material-ui/core";
import {ArrowDropDown, ArrowDropUp} from "@material-ui/icons";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const UserAvatar = props => {
    const {model, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);
    const [avatar, setAvatar] = useState(Utils.buildURLs(`/resource/${model.avatar}`, false, null, {networkId: Utils.getNetwork()}));

    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);

    const [uploadedFile, setUploadedFile] = useState("");
    const [uploadFileBtnDisabled, setUploadFileBtnDisabled] = useState(false);
    const [saveFileBtnDisabled, setSaveFileBtnDisabled] = useState(false);

    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);

    // Handlers
    const handleToggle = () => setOpen(prevOpen => !prevOpen);
    const handleClose = event => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) return;
        setOpen(false);
    };
    const handleListKeyDown = event => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setOpen(false);
        }
    };

    // Main functions
    const uploadAvatar = () => {
        Utils.uploadFileInput("image/*",file => {
            setUploadFileBtnDisabled(true);
            setUploadedFile(file);
            setAvatar(URL.createObjectURL(file));
            setUploadFileBtnDisabled(false);
        });
    };
    const saveAvatar = async mode => {
        if (uploadedFile && uploadedFile instanceof File) {
            setSaveFileBtnDisabled(true);
            setUploadFileBtnDisabled(true);
            setOpen(false);

            const resourceID = await createResource(uploadedFile, mode === "default" ? "roomful" : Cookies.get("networkId") || "roomful", onError);
            const result = await API.Users.setAvatar(model?.id, resourceID, onError, mode === "default" ? "roomful" : null);
            if (result.error.code === 200) {
                mainDispatch({
                    type: "SET_MODAL_SETTINGS",
                    payload: {
                        show: true,
                        title: "Success!",
                        text: "User avatar was updated successfully!",
                        color: "success"
                    }
                });
            }
            else onError(result.error);

            dispatch({
                type: "SET_USER_MODEL", key: "openedUserModel",
                payload: {...model, ...{avatar: resourceID}}
            });

            setSaveFileBtnDisabled(false);
            setUploadFileBtnDisabled(false);
        }
    };

    return (
        <>
            <div className="mb-3">
                <Image imageStyle={{objectFit: "contain"}} style={{width: "100%", height: "14.063rem", padding: 0}}
                       src={avatar}/>
            </div>
            <div className="row">
                <StyledButton onClick={uploadAvatar} disabled={uploadFileBtnDisabled} noLoading={true}
                              title="Upload new avatar" type="primary"/>
                <div className="ml-5">
                    <StyledButton fullWidth variant="contained" type="primary" elRef={anchorRef} disabled={saveFileBtnDisabled}
                                  onClick={handleToggle} title={<>Save avatar {open ? <ArrowDropUp/> : <ArrowDropDown/>} </>}/>

                    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal placement="bottom-start">
                        {({TransitionProps, placement}) => (
                            <Grow {...TransitionProps} style={{transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'}}>
                                <Paper>
                                    <ClickAwayListener onClickAway={handleClose}>
                                        <MenuList autoFocusItem={open} onKeyDown={handleListKeyDown}>
                                            {isSuperAdmin &&
                                            <MenuItem onClick={() => saveAvatar("default")}>To default network</MenuItem>}
                                            <MenuItem onClick={() => saveAvatar()}>To current network</MenuItem>
                                        </MenuList>
                                    </ClickAwayListener>
                                </Paper>
                            </Grow>
                        )}
                    </Popper>
                </div>
            </div>
        </>
    );
}
