// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import Utils from "../../../scripts/Utils";
import { API } from "../../../api/API";
import { Store } from "../../../stores/MainStore";
import { RoomsStore } from "../../../stores/RoomsStore";
import { UsersStore } from "../../../stores/UsersStore";

// Material UI
import {
    Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Stepper, Step, StepLabel, Box
} from "@material-ui/core";

// Components
import { StyledButton } from "../../../components/styled/StyledButton";
import { StyledCheckbox, StyledInput } from "../../../components/styled/StyledInput";
import { ActiveNetworks } from "../../../components/list/Networks/ActiveNetworks";
import { AllRooms } from "../../../components/list/Rooms/AllRooms";
import { ActiveUsers } from "../../../components/list/Users/ActiveUsers";

export const RoomCopy = props => {
    const {onError, chooseRoom} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(RoomsStore);
    const {state: userState} = useContext(UsersStore);

    const DIALOG = [
        ...([
            chooseRoom
                ? {
                    title: "Select network and room",
                    text:  "Please, choose network from list and then select room:"
                }
                : {
                    title: "Select room network",
                    text:  "Please, choose network from list:"
                }
        ]),

        {
            title: "Select room owner",
            text:  "Please, choose target owner:"
        },
        {
            title: "Select room parameters",
        }
    ];

    // Base state
    const [isOpened, setIsOpened] = useState(false);
    const [isCopyRoomBtnLoading, setIsCopyRoomBtnLoading] = useState(false);
    const [step, setStep] = useState(0);

    // Dropdowns && input fields state
    const [network, setNetwork] = useState(null);
    const [room, setRoom] = useState(null);
    const [user, setUser] = useState(userState?.model);
    const [newRoomName, setNewRoomName] = useState("");
    const [newRoomPrivacy, setNewRoomPrivacy] = useState("");
    const [copyRoomLinks, setCopyRoomLinks] = useState(false);

    // Handlers
    const onNewRoomNameChange = e => setNewRoomName(e.target.value);
    const onNewRoomPrivacyChange = selected => setNewRoomPrivacy(selected.value);
    const onCopyRoomLinksChange = e => setCopyRoomLinks(e.target.checked);
    const toPrevStep = () => setStep(prevActiveStep => prevActiveStep - 1);
    const toNextStep = () => setStep(prevActiveStep => prevActiveStep + 1);
    const open = () => setIsOpened(true);
    const close = () => {
        setIsOpened(false);
        setIsCopyRoomBtnLoading(false);
        setStep(0);
        setRoom(null);
        setUser(userState?.model);
        setNewRoomName("");
        setNewRoomPrivacy("");
        setCopyRoomLinks(false);
    };

    // Main functions
    const onAllDone = async () => {
        if (network && network?.id?.length > 0 && user && user?.id?.length > 0 && newRoomName && newRoomName?.length > 0) {
            setIsCopyRoomBtnLoading(true);

            const body = {
                roomId:          chooseRoom ? room?.id : state.model?.id,
                targetNetworkId: network?.id,
                targetOwnerId:   user?.id,
                roomName:        newRoomName,
                privacy:         newRoomPrivacy,
                copyRoomLinks:   copyRoomLinks,
            };

            const result = await API.Rooms.copyRoom(body, error => {
                setIsCopyRoomBtnLoading(false);
                close();
                onError(error);
            });

            if (result?.error?.code === 200) {
                setIsCopyRoomBtnLoading(false);
                close();

                const newRoomID = result?.data?.roomId;

                mainDispatch({
                    type: "SET_MODAL_SETTINGS",
                    payload: {
                        show: true,
                        title: "Success!",
                        text: `Room was successfully copied! New room ID : ${newRoomID}`,
                        color: "success"
                    }
                });
            } else onError(new Error(result?.error?.message || "Error when copying room."));
        } else {
            console.log(network, user, newRoomName);
            onError(new Error(`Please fill all fields before pressing "Done" button`));
        }
    };

    // Effects
    useEffect(() => {
        (async () => {
            const result = await API.Networks.getModel(Utils.getNetwork());

            if (result?.error?.code === 200) setNetwork(result?.data?.network);
            else onError(new Error(result?.error?.message));
        })();
    }, []);

    return (
        <>
            <StyledButton onClick={open} disabled={isCopyRoomBtnLoading} title="Copy room" type="info"/>

            <Dialog open={isOpened} onClose={close} fullWidth={true} maxWidth="sm" disableBackdropClick={true}
                    disableEscapeKeyDown={true}>
                <Stepper activeStep={step} alternativeLabel>
                    {DIALOG.map(el =>
                        <Step key={el.title}>
                            <StepLabel>{el.title}</StepLabel>
                        </Step>
                    )}
                </Stepper>
                <DialogTitle id="form-dialog-title">{DIALOG[step].title}</DialogTitle>
                <DialogContent>
                    <DialogContentText>{DIALOG[step].text}</DialogContentText>
                    {step === 0 && (
                        chooseRoom
                            ? (
                                <>
                                    {network && <ActiveNetworks network={network} settings={{preLoadedNetwork: true}}
                                                                onChange={selectedNetwork => setNetwork(selectedNetwork)}/>}

                                    <div className="mt-3">
                                        <AllRooms room={room} onChange={selectedRoom => setRoom(selectedRoom)}
                                                  networkId={network?.id}/>
                                    </div>
                                </>
                            )
                            : (network && <ActiveNetworks network={network} settings={{preLoadedNetwork: true}}
                                                          onChange={selectedNetwork => setNetwork(selectedNetwork)}/>)
                    )}
                    {step === 1 && (
                        <ActiveUsers user={user} onChange={selectedUser => setUser(selectedUser)}
                                     networkId={Utils.getNetwork()} settings={{preloadUser: true}}/>
                    )}
                    {step === 2 && (
                        <>
                            <StyledInput placeholder="Room name" value={newRoomName} onChange={onNewRoomNameChange}
                                         noPadding/>
                            <Select placeholder="Room privacy" onChange={onNewRoomPrivacyChange}
                                    options={[
                                        {label: "Private", value: "private"},
                                        {label: "Public", value: "public"},
                                        {label: "Preserve", value: "preserve"},
                                    ]}/>
                            <label style={{
                                display: "flex", alignItems: "center", marginTop: "1rem", fontWeight: "normal"
                            }}>
                                Copy Rooms pointed via room-links:
                                <span style={{marginLeft: "1rem"}}>
                                    <StyledCheckbox checked={copyRoomLinks} onChange={onCopyRoomLinksChange}/>
                                </span>
                            </label>
                        </>
                    )}
                </DialogContent>
                <DialogActions>
                    <Box display="flex" justifyContent="flex-end" width="100%">
                        <div className="mr-3"><StyledButton onClick={close} type="default" title="Cancel"/></div>
                        {step !== 0 ? <div className="mr-3"><StyledButton onClick={toPrevStep} type="default"
                                                                          title="Previous step"/></div> : null}
                        {step !== DIALOG.length - 1
                            ? <StyledButton onClick={toNextStep} type="success" title="Next step"/>
                            : <StyledButton onClick={onAllDone} type="success" title="Done"/>
                        }
                    </Box>
                </DialogActions>
            </Dialog>
        </>
    );
}
