// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import {Navigate} from "react-router-dom";
import {API} from "../api/API";

export const RequireAuth = ({ children }) => {
    const isLogged = API.Auth.isLogged();

    return isLogged ? children : <Navigate to="/auth" replace />;
}
