// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {API} from "../../../api/API";
import Select from 'react-select';
import {Store} from "../../../stores/MainStore";
import {NetworksStore} from "../../../stores/NetworksStore";
import {UsersStore} from "../../../stores/UsersStore";

// MUI
import {FormHelperText} from "@material-ui/core";
import {JsonFormatter} from 'react-json-formatter';

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {StyledCheckbox, StyledInput} from "../../../components/styled/StyledInput";

export const NetworkPluginConfigurator = props => {
    const {onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(NetworksStore);

    // Base state
    const [generateBtnLoading, setGenerateBtnLoading] = useState(false);
    const [saveBtnLoading, setSaveBtnLoading] = useState(false);

    const setSettings = settings => {
        console.log(settings);
        dispatch({type: "SET_NETWORK_PLUGIN_SETTINGS", payload: settings});
    };

    // Path functions
    const getValue = path => path.split(/[.\[\]'"]/).filter(p => p).reduce((o, p) => o ? o[p] : undefined, state?.openedNetwork?.pluginSettings);
    const setValue = (param, value) => {
        const pluginSettings = state?.openedNetwork?.pluginSettings;
        setToValue(pluginSettings, value, param);
        setSettings(pluginSettings);
    };
    const getPathByValue = (obj, value, path) => {
        let newPath = "";
        if (typeof obj !== 'object') return;

        for (let key in obj) {
            if (obj.hasOwnProperty(key)) {
                let t = path;
                let v = obj[key];

                if (!path) newPath = key;
                else newPath = path + '.' + key;

                if (v === value) return newPath;
                else if (typeof v !== 'object') newPath = t;

                let res = getPathByValue(v, value, newPath);
                if (res) return res;
            }
        }
    }
    const setToValue = (obj, value, path) => {
        let i;
        path = path.split('.');
        for (i = 0; i < path.length - 1; i++) obj = obj[path[i]];

        obj[path[i]] = value;
    }

    // Menu items
    const addMenuItem = () => {
        const prevValues = state?.openedNetwork?.pluginSettings?.menuItems ?? [];
        state.openedNetwork.pluginSettings.menuItems = [...prevValues, {
            directory: "",
            link: "",
            title: "",
            room: "",
            prop: "",
            onlyForAttendees: false,
            items: []
        }];
        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const addMenuNestedItem = el => {
        el.items = [...(el.items ?? []), {directory: "", link: "", title: "", room: "", prop: "", onlyForAttendees: false, items: []}];
        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const removeThisMenuItem = (index, path) => {
        const lastIndex = path.lastIndexOf(".");
        const parentPath = path.substring(0, lastIndex);
        const parent = getValue(parentPath);

        parent.splice(index, 1);

        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const renderMenuItem = (el, key, isNested) => {
        let path = getPathByValue(state?.openedNetwork?.pluginSettings, el);

        return (
            <div key={key} className={`ml-${isNested ? 5 : 0} mb-2`}>
                <div className="card card-primary card-outline p-2" key={key}>
                    <div className="row px-2 mt-3">
                        <div className="col-3"><StyledInput fullWidth placeholder="Directory" value={el.directory}
                                                            onChange={e => setValue(`${path}.directory`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Link" value={el.link}
                                                            onChange={e => setValue(`${path}.link`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Title" value={el.title}
                                                            onChange={e => setValue(`${path}.title`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Room" value={el.room}
                                                            onChange={e => setValue(`${path}.room`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Prop" value={el.prop}
                                                            onChange={e => setValue(`${path}.prop`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Target" value={el.target}
                                                            onChange={e => setValue(`${path}.target`, e.target.value)}/>
                        </div>
                        <div className="col-3 mb-3 d-flex align-items-center">
                            <div className="col-12">
                                <StyledCheckbox title="Only for attendees" checked={el.onlyForAttendees}
                                                onChange={e => setValue(`${path}.onlyForAttendees`, e.target.checked)}/>
                            </div>
                        </div>
                        <div className="col-3 d-flex justify-content-end align-items-start">
                            <div className="mr-3"><StyledButton onClick={() => addMenuNestedItem(el)}
                                                                title={<i className="fas fa-plus-circle"/>}
                                                                type="success"/></div>
                            <StyledButton onClick={() => removeThisMenuItem(key, path)}
                                          title={<i className="fas fa-trash-alt"/>} type="danger"/>
                        </div>
                    </div>
                </div>
                {el.items && el.items.length > 0 && (
                    <div className="ml-3 mb-2">
                        {el.items.map((nestedEl, nestedKey) => renderMenuItem(nestedEl, nestedKey, true))}
                    </div>
                )}
            </div>
        );
    };

    // Directory
    const addDirectoryItem = () => {
        const prevValues = state?.openedNetwork?.pluginSettings?.directory ?? [];
        state.openedNetwork.pluginSettings.directory = [...prevValues, {
            title:            "",
            room:             "",
            prop:             "",
            tag:              "",
            tagSortBy:        "",
            link:             "",
            interactive:      false,
            onlyForAttendees: false,
            icon:             "",
            items:            []
        }];
        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const addDirectoryNestedItem = el => {
        el.items = [...(el.items ?? []), {
            title:       "",
            room:        "",
            prop:        "",
            tag:         "",
            tagSortBy:   "",
            link:        "",
            interactive: false,
            icon:        "",
            items:       []
        }];
        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const removeThisDirectoryItem = (index, path) => {
        const lastIndex = path.lastIndexOf(".");
        const parentPath = path.substring(0, lastIndex);
        const parent = getValue(parentPath);

        parent.splice(index, 1);

        setSettings(state?.openedNetwork?.pluginSettings);
    };
    const renderDirectory = (el, key, isNested) => {
        let path = getPathByValue(state?.openedNetwork?.pluginSettings, el);

        return (
            <div key={key} className={`ml-${isNested ? 5 : 0} mb-2`}>
                <div className="card card-primary card-outline p-2" key={key}>
                    <div className="row px-2 mt-3">
                        <div className="col-3"><StyledInput fullWidth placeholder="Title" value={el.title}
                                                            onChange={e => setValue(`${path}.title`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Room" value={el.room}
                                                            onChange={e => setValue(`${path}.room`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Prop" value={el.prop}
                                                            onChange={e => setValue(`${path}.prop`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Tag" value={el.prop}
                                                            onChange={e => setValue(`${path}.tag`, e.target.value)}/>
                        </div>
                        <div className="col-3">
                            <Select onChange={e => setValue(`${path}.tagSortBy`, e.value)}
                                    defaultValue={{label: "TagSortBy", value: ""}}
                                    options={[{label: "Default", value: ""},
                                        {label: "By Popularity", value: "popularity"},
                                        {label: "By User Activity", value: "userActivity"}]}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Link" value={el.link}
                                                            onChange={e => setValue(`${path}.link`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Icon" value={el.icon}
                                                            onChange={e => setValue(`${path}.icon`, e.target.value)}/>
                        </div>
                        <div className="col-3"><StyledInput fullWidth placeholder="Target" value={el.target}
                                                            onChange={e => setValue(`${path}.target`, e.target.value)}/>
                        </div>
                        <div className="col-3">
                            <div className="col-12">
                                <StyledCheckbox title="Interactive" checked={el.interactive}
                                                onChange={e => setValue(`${path}.interactive`, e.target.checked)}/>
                                <FormHelperText style={{whiteSpace: "nowrap"}}>(If true, not interactive.)
                                </FormHelperText>
                            </div>
                        </div>
                        <div className="col-3 d-flex align-items-center">
                            <div className="col-12">
                                <StyledCheckbox title="Only for attendees" checked={el.onlyForAttendees}
                                                onChange={e => setValue(`${path}.onlyForAttendees`, e.target.checked)}/>
                            </div>
                        </div>
                        <div className="col-3"></div>
                        <div className="col-3 d-flex justify-content-end align-items-start">
                            <div className="mr-3"><StyledButton onClick={() => addDirectoryNestedItem(el)}
                                                                title={<i className="fas fa-plus-circle"/>}
                                                                type="success"/></div>
                            <StyledButton onClick={() => removeThisDirectoryItem(key, path)}
                                          title={<i className="fas fa-trash-alt"/>} type="danger"/>
                        </div>
                    </div>
                </div>
                {el.items && el.items.length > 0 && (
                    <div className="ml-3 mb-2">
                        {el.items.map((nestedEl, nestedKey) => renderDirectory(nestedEl, nestedKey, true))}
                    </div>
                )}
            </div>
        );
    };

    // Main functions
    const generatePluginSettings = async () => {
        setGenerateBtnLoading(true);

        const result = (await API.Networks.generatePluginSettings(state?.openedNetwork?.model?.id, onError));

        if (result.error.code === 200) {
            state.openedNetwork.pluginSettings = result.data.pluginSettings;

            setSettings(state.openedNetwork.pluginSettings);

            if (result.data && result.data.pluginSettings) mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show: true,
                    title: "Success!",
                    text: "Plugin settings was successfully generated!",
                    color: "success"
                }
            });
        }
        else onError(new Error(result.error.message));

        setGenerateBtnLoading(false);
    };
    const savePluginSettings = async () => {
        setSaveBtnLoading(true);

        const result = (await API.Networks.setNetworkPluginSettings({
            networkId: state?.openedNetwork?.model?.id,
            pluginSettings: state?.openedNetwork?.pluginSettings
        }, onError)).data;

        console.log(result);

        if (result.network) mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Success!",
                text: "Plugin settings was saved successfully!",
                color: "success"
            }
        });

        setSaveBtnLoading(false);
    };

    useEffect(() => {
        setSettings(state?.openedNetwork?.model?.pluginSettings);
    }, []);

    const list = [
        {
            label: "Horizontal",
            value: "horizontal"
        },
        {
            label: "Vertical",
            value: "vertical"
        }
    ];
    const capitalize = s => s && s[0].toUpperCase() + s.slice(1);

    return (
        <>
            <div className="row px-2" style={{marginBottom: "1.25rem"}}>
                <div className="mr-5"><StyledButton onClick={generatePluginSettings} disabled={generateBtnLoading}
                                                    title="Generate settings" type="info"/></div>
                <StyledButton onClick={savePluginSettings} disabled={saveBtnLoading} title="Save settings"
                              type="success"/>
            </div>
            <hr/>
            <div className="mt-5">
                <div className="row">
                    <div className="col-4 pl-0 pb-4 d-flex justify-content-center align-items-center">
                        <div className="pb-2">
                            <StyledCheckbox title="Enable plugin" checked={getValue("enablePlugin") ?? false}
                                        onChange={e => setValue("enablePlugin", e.target.checked)}/>
                        </div>
                    </div>
                    <div className="col-4 pl-0 pb-4 d-flex justify-content-center align-items-center">
                        <div className="pb-2">
                            <StyledCheckbox title="Autoplay on click" checked={getValue("autoPlayOnClick") ?? false}
                                        onChange={e => setValue("autoPlayOnClick", e.target.checked)}/>
                        </div>
                    </div>
                    <div className="col-4 pl-0 pb-4 d-flex justify-content-center align-items-center">
                        <div className="pb-2">
                            <StyledCheckbox title="Autoplay on start" checked={getValue("autoPlayOnStart") ?? false}
                                        onChange={e => setValue("autoPlayOnStart", e.target.checked)}/>
                        </div>
                    </div>
                    <div className="col-4 pb-4">
                        <Select onChange={e => setValue("scroll", e.value)}
                                defaultValue={{label: capitalize(getValue("scroll") ?? ""), value: getValue("scroll") ?? ""}} options={list}/>
                    </div>
                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="App store Id" value={getValue("appStoreId") ?? ""}
                                                             onChange={e => setValue("appStoreId", e.target.value)}/></div>
                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Google store Id " value={getValue("googleStoreId") ?? ""}
                                                             onChange={e => setValue("googleStoreId", e.target.value)}/></div>
                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Style" value={getValue("style")}
                                                             onChange={e => setValue("style", e.target.value)}/></div>

                    {state?.openedNetwork?.pluginSettings?.initRoute && (
                        <>
                            <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Init route (room)"
                                                                     value={getValue("initRoute.room") ?? ""}
                                                                     onChange={e => setValue("initRoute.room", e.target.value)}/>
                            </div>
                            <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Init route (prop)"
                                                                     value={getValue("initRoute.prop") ?? ""}
                                                                     onChange={e => setValue("initRoute.prop", e.target.value)}/>
                            </div>
                        </>
                    )}

                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Logo URL" value={getValue("logoUrl")}
                                                        onChange={e => setValue("logoUrl", e.target.value)}/></div>
                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Exposition domain"
                                                        value={getValue("expositionDomain")}
                                                        onChange={e => setValue("expositionDomain", e.target.value)}/>
                    </div>
                    <div className="col-4 pl-0"><StyledInput fullWidth placeholder="Expo title"
                                                        value={getValue("expoTitle")}
                                                        onChange={e => setValue("expoTitle", e.target.value)}/>
                    </div>
                    <div className="col-4 pl-0">
                        <StyledInput fullWidth placeholder="Disclaimer" value={getValue("disclaimer")} isTextarea
                                     onChange={e => setValue("disclaimer", e.target.value)}
                        />
                    </div>
                </div>

                <div className="row flex-column px-2 mt-3">
                    <h5>Help link:</h5>
                    <div className="row">
                        <div className="col-4 pl-0">
                            <StyledInput fullWidth placeholder="Link" value={getValue("helpLink.link")}
                                         onChange={e => setValue("helpLink.link", e.target.value)}/>
                        </div>
                        <div className="col-4">
                            <StyledInput fullWidth placeholder="Title" value={getValue("helpLink.title")}
                                         onChange={e => setValue("helpLink.title", e.target.value)}/>
                        </div>
                        <div className="col-4">
                            <StyledInput fullWidth placeholder="Target" value={getValue("helpLink.target") ?? ""}
                                         onChange={e => setValue("helpLink.target", e.target.value)}/>
                        </div>
                    </div>
                </div>

                <div className="row flex-column px-2 mt-3">
                    <div className="row px-2 mb-3 align-items-center">
                        <h5 className="mr-2">Menu items:</h5>
                        <StyledButton onClick={addMenuItem} title="Add" type="success" size="sm"/>
                    </div>

                    <div>
                        {state?.openedNetwork?.pluginSettings?.menuItems?.map((el, key) => renderMenuItem(el, key))}
                    </div>
                </div>

                <div className="row flex-column px-2 mt-3">
                    <div className="row px-2 mb-3 align-items-center">
                        <h5 className="mr-2">Directory items:</h5>
                        <StyledButton onClick={addDirectoryItem} title="Add" type="success" size="sm"/>
                    </div>

                    <div>
                        {state?.openedNetwork?.pluginSettings?.directory?.map((el, key) => renderDirectory(el, key))}
                    </div>
                </div>

                <div className="row flex-column px-2 mt-5">
                    <h5 className="mr-2">Output JSON preview:</h5>
                    <div className="card card-primary card-outline d-inline-flex p-5"
                         style={{maxHeight: "31.25rem", overflowY: "scroll"}}>
                        <JsonFormatter json={JSON.stringify(state?.openedNetwork?.pluginSettings)} tabWith='4'
                                       JsonStyle={{
                                           propertyStyle: {color: '#976a6e'},
                                           stringStyle: {color: 'green'},
                                           numberStyle: {color: 'darkorange'}
                                       }}/>
                    </div>
                </div>

            </div>
        </>
    );
}
