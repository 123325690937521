// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";

import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {TextField, InputAdornment, IconButton, Box, CircularProgress} from "@material-ui/core";
import {ArrowDropUp, ArrowDropDown, Loop, Search, Clear} from '@material-ui/icons';
import Image from 'material-ui-image';

// Style
import useStyles from "../../../styles/dropdownStyles";

// Components
import {StyledImage} from "../../styled/StyledImage";

let timeout = null;
let prevBody = {};

export const AllRooms = props => {
    const {room, onChange, networkId = null,
        allowAll = false, // add special "All" option with id = "all"
        settings = {}
    } = props;
    const {preLoadedRoom = false} = settings;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    const classes = useStyles();
    const readOnly = false; // prevent all searching functional

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    const [selectedNetworkId, setSelectedNetworkId] = useState(networkId || Utils.getNetwork());
    const [value, setValue] = useState(room);
    const [isLoading, setIsLoading] = useState(false);

    // Base state
    const [needRender, setNeedRender] = useState(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);

    // Search state
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);
    const [needClear, setNeedClear] = useState(false);

    // Buttons, dropdown state, errors state, etc
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [isErrorInInput, setIsErrorInInput] = useState(false);
    const [searchBtnLoading, setSearchBtnLoading] = useState(false);

    const loadMoreItems = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);
        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const search = () => {
        setIsLoading(false);
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };
    const initSearch = () => {
        setNeedClear(true);
        setSearchBtnLoading(false);
        setValue(null);
        if (!isDropdownOpen) setIsDropdownOpen(true);
    };
    const clear = () => {
        dispatch({type: 'CLEAR_LIST'});
        setLoadMoreActive(false);
        setValue(null);
        setIsLoading(false);
        setSearchInputText("");
        setPrevSearchInputText("");
        setNeedClear(false);
        setSearchBtnLoading(false);
        setOffset(0);
        setIsDropdownOpen(false);
        prevBody = {};

        setNeedRender(true);
    };

    const getList = useCallback(() => {
        let list = allowAll ? state.listAllowAll : state.list;
        if(allowAll && list[0]?.id !== "all") list.unshift({id: "all", metadata: {name: "All rooms"}});

        return list;
    }, [state.list, state.listAllowAll]);
    const setList = (list) => {
        if(allowAll && list[0]?.id !== "all") list.unshift({id: "all", metadata: {name: "All rooms"}});

        dispatch({type: allowAll ? 'SET_ALLOW_ALL_LIST' : 'SET_LIST', payload: list});
    };

    const getListItems = async (body, list) => {
        if (!loadMoreActive) setIsLoading(true);

        const data = (await (typeof searchInputText === "string" && searchInputText.length > 0
            ? API.Rooms.search(body, selectedNetworkId, onError, true)
            : API.Rooms.getList(body, selectedNetworkId, onError, true))).data;

        const items = data?.rooms ?? [];

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && items.length > 0) ? list : [];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);

        setList(itemsToAdd);
        if (!loadMoreActive && !needClear && !preLoadedRoom) setValue(itemsToAdd[0]);

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setSearchBtnLoading(false);
        setIsLoading(false);
    };

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(true);
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !readOnly && !loadMoreActive) loadMoreItems();
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        clearTimeout(timeout);

        if (!needClear) setNeedClear(true);
        timeout = setTimeout(search, 1000);

        setPrevSearchInputText(searchInputText);
        setSearchInputText(e.target.value);
    };
    const onItemSelected = (e, value) => {
        setValue(value);
        setNeedClear(false);
        setIsDropdownOpen(false);
        setIsErrorInInput(false);
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (needRender && selectedNetworkId !== "" && !isLoading) {
                const body = {offset, size, text: searchInputText};

                if (Utils.isObjectEmpty(prevBody) || (prevBody.offset !== body.offset) || (prevBody.size !== body.size)
                    || (prevBody.text !== body.text) || (prevBody.networkId !== selectedNetworkId)) {
                    const list = getList();

                    await getListItems(body, list);
                    prevBody = {...body, networkId: selectedNetworkId};
                }
                else console.log("this");
            }
        })();
    }, [needRender, selectedNetworkId, searchInputText]);

    useEffect(() => {
        if (networkId && selectedNetworkId !== networkId) {
            setSearchBtnLoading(false);
            setOffset(0);
            setSelectedNetworkId(networkId);
            setList([]);
            setNeedRender(true);
        }
    }, [networkId]);

    useEffect(() => {
        return () => {
            setSelectedNetworkId(null);
            setValue(null);
            setIsLoading(false);
            setOffset(0);
            setSearchInputText("");
            setPrevSearchInputText("");
            setLoadMoreActive(false);
            setNeedClear(false);
            setIsDropdownOpen(false);
            setIsErrorInInput(false);
            setSearchBtnLoading(false);

            prevBody = {};
            timeout = null;

            dispatch({type: 'CLEAR_LIST'});
            dispatch({type: 'CLEAR_ALLOW_ALL_LIST'});
        };
    }, []);
    useEffect(() => {
        if (value) setIsLoading(false);

        if (onChange && typeof onChange === "function") onChange(value);
    }, [value]);

    useEffect(() => {
        setValue(room);
        if (room?.id !== value?.id) setIsDropdownOpen(false);
    }, [room]);

    const isActiveSearch = typeof searchInputText === "string" && searchInputText.length > 0;

    return (
        !isLoading || isActiveSearch
        ?
        <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                      renderInput={params => (
                          <div className="d-flex align-items-center">
                              {value?.thumbnail?.id && <Image imageStyle={{
                                  position: "initial",
                                  margin: "0.25rem",
                                  width: "3rem",
                                  height: "calc(3.5rem - 0.5rem - 2px)",
                                  objectFit: "cover"
                              }} style={{
                                  display: "flex",
                                  justifyContent: "center",
                                  background: "#fff",
                                  padding: 0,
                                  border: "1px solid hsl(0, 0%, 80%)",
                                  borderRight: 0,
                                  borderTopRightRadius: 0,
                                  borderTopLeftRadius: "4px",
                                  borderBottomRightRadius: 0,
                                  borderBottomLeftRadius: "4px",
                              }} src={Utils.buildURLs(`/resource/thumbnail/256/${value?.thumbnail?.id}`,
                                                          false, null, {networkId: selectedNetworkId})}/>}
                              <TextField {...params} variant="outlined" value={searchInputText}
                                         onChange={onSearchInputChange} error={isErrorInInput} size="small"
                                         InputProps={{
                                             readOnly,
                                             ...params.InputProps,
                                             style: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "3.5rem"},
                                             endAdornment: (
                                                 <InputAdornment position="end">
                                                     {needClear
                                                         ? <IconButton edge="end" className="search-clear" onClick={clear}><Clear/></IconButton>
                                                         : <IconButton edge="end" className="search" onClick={initSearch}><Search/></IconButton>}

                                                     {searchBtnLoading && searchInputText !== ""
                                                         ? <IconButton edge="end" className="rightBar loop"><Loop/></IconButton>
                                                         :
                                                         <IconButton edge="end" onClick={onOpenDropdown} className="rightBar">
                                                             {isDropdownOpen ? <ArrowDropUp/> : <ArrowDropDown/>}
                                                         </IconButton>}
                                                 </InputAdornment>
                                             ),
                                             onKeyDown: e => {
                                                 if (e.key === 'Enter') {
                                                     e.preventDefault();
                                                     e.stopPropagation();

                                                     if (searchInputText !== "") search();
                                                     return;
                                                 }
                                             },
                                             onClick: () => setIsDropdownOpen(true)
                                         }}
                                         onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                              />
                          </div>
                      )} style={{width: "100%"}} freeSolo disableClearable forcePopupIcon={false} open={isDropdownOpen}
                      value={value} onChange={onItemSelected} filterOptions={options => options}
                      options={getList()}
                      getOptionLabel={option => {
                          return option?.id === "all" ? option?.metadata?.name : `${option?.metadata?.name} (#${option?.id})`;
                      }}
                      renderOption={option => (
                          <Box data-id={option?.id} className="d-flex align-items-center" style={{height: `${Utils.RemToPx(4.125)}px`}}>
                              {option?.id !== "all" &&
                              <StyledImage size={3} src={Utils.buildURLs(`/resource/thumbnail/256/${option?.thumbnail?.id}`,
                                                false, null, {networkId: selectedNetworkId})}/>}

                              <div className="h-100 ml-2 d-flex flex-column justify-content-center">
                                  <span style={{fontSize: `${Utils.RemToPx(1.25)}px`}}>
                                      {option?.metadata?.name}
                                  </span>
                                  <span style={{fontSize: `${Utils.RemToPx(1)}px`, color: "rgba(0, 0, 0, .5)"}}>
                                      {option?.id === "all" ? "" : option?.id}
                                  </span>
                              </div>
                          </Box>
                      )}
        />
        : (
            <div style={{
                border: "1px solid hsl(0, 0%, 80%)",
                background: "#fff",
                width: "100%", height: "3.5rem",
                padding: "9px", borderRadius: "4px"
            }} className="d-flex align-items-center">
                {isLoading
                    ? <><CircularProgress className="mr-3"/> Loading...</>
                    : "No rooms in this network"}
            </div>
        )
    );
}