// Copyright © 2015-2023 Roomful Co. All rights reserved

import Cookies from 'js-cookie';
import Utils from "../scripts/Utils";
import io from 'socket.io-client';

export default class Socket {
    instance;
    options = {
        path: '/socket',
        protocol: 'wss:',
        allowRequest: true,
        transports: ['websocket'],
        upgrade: false,
        autoConnect: false,
        query: `sessionId=${Cookies.get("sessionId")}&clientVersion=2.4.1`
    };
    URL = Utils.isLocalhost() ? 'wss://dev.roomful.net' : '';

    constructor () {
        this.connect();
    }

    connect () {
        if (!this.instance) {
            this.instance = io(this.URL, this.options);
            this.instance.open();
        }
        else if (!this.instance.connected) this.instance.open();

        return this;
    }

    resumeConnection = cb => {
        if (!this.instance) {
            this.connect();
            this.instance.once('connect', () => cb);
        }
        else cb();
    }

    _request = (eventType, data = {}, cb = () => false) => this.resumeConnection(() => this.instance.emit(eventType, data, cb));
    sendSocketRequest = (eventType, data) => {
        return new Promise((resolve, reject) => {
            this._request(eventType, data, response => {
                if (response?.error?.code === 200) resolve(response);
                else reject(response?.error);
            });
        });
    }

    addEventListener = (eventType, cb = () => false) => this.resumeConnection(() => this.instance.on(eventType, cb));
    removeEventListener = (eventType, cb = () => false) => this.resumeConnection(() => this.instance.off(eventType, cb));

};
