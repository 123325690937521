// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useEffect, useState} from "react";
import {useStateSafe} from "../../scripts/SaveUseState";
import Utils from "../../scripts/Utils";

// MUI
import Autocomplete from "@material-ui/lab/Autocomplete";
import {IconButton, InputAdornment, makeStyles, TextField} from "@material-ui/core";
import {ExpandLess, ExpandMore, Loop} from '@material-ui/icons';
import Image from 'material-ui-image';
import CircularProgress from "@material-ui/core/CircularProgress";

let timeout = null;

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiOutlinedInput-notchedOutline": {
            border: "1px solid hsl(0, 0%, 80%) !important"
        },
        "& .MuiIconButton-label": {
            paddingLeft: "0.4rem",
            borderLeft: "1px solid hsl(0, 0%, 80%) !important"
        },
        "& .MuiSvgIcon-root" : {
            color: "hsl(0, 0%, 80%) !important"
        },
        "& .MuiIconButton-root": {background: "transparent !important"}
    }
}));

export const UniversalList = props => {
    const {
        OnChange, settings = {}, isDisabled = false,
        callbacks: {getList, setList, getItems, getOptionLabel, getOption}
    } = props;
    const {withoutSearch = false, customBody = null, canType = true} = settings;

    const classes = useStyles();

    // Base state
    const [prevSelectedValue, setPrevSelectedValue] = useStateSafe(null);
    const [selectedValue, setSelectedValue] = useStateSafe(null);
    const [searchBtnLoading, setSearchBtnLoading] = useStateSafe(false);
    const [isDropdownOpen, setIsDropdownOpen] = useStateSafe(false);
    const [loadMoreActive, setLoadMoreActive] = useStateSafe(false);
    const [isLoading, setIsLoading] = useStateSafe(false);

    // Base rendering
    const [needRender, setNeedRender] = useStateSafe(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);

    const [searchInputText, setSearchInputText] = useStateSafe("");
    const [prevSearchInputText, setPrevSearchInputText] = useStateSafe("");

    // Functions
    const loadMoreItems = useCallback(() => {
        setPrevSearchInputText(searchInputText);
        setOffset(offset + size);
        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    }, [offset, searchInputText, setLoadMoreActive, setNeedRender, setOffset, setPrevSearchInputText, setSearchBtnLoading, size]);
    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };

    const renderList = useCallback(async (offsetToUse = null) => {
        const items = await getItems(offsetToUse ?? offset, size, searchInputText);

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && searchInputText !== "" && items.length > 0) ? getList() : [];
        // const itemsToAdd = [..._list, ...items];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i);
        setList(itemsToAdd);
        if (!loadMoreActive) setSelectedValue(itemsToAdd[0]);

        // setSelectedValue(itemsToAdd[0]);
        // if (!selectedValue && !preSelectedValue) setSelectedValue(itemsToAdd[0]);
        // if (!selectedValue && preSelectedValue) {
        //     const selected = itemsToAdd.find(item => item.id === preSelectedValue);
        //     setSelectedValue(selected);
        // }

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setIsLoading(false);
        setSearchBtnLoading(false);
    }, [getItems, getList, loadMoreActive, offset, prevSearchInputText, searchInputText, setIsLoading, setList, setLoadMoreActive, setNeedRender, setSearchBtnLoading, setSelectedValue, size]);

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const onListScroll = e => {
        const listHeight = e.target.scrollHeight;
        const scrollTop = e.target.scrollTop;
        const visibleHeight = Utils.RemToPx(4.875) * 6;

        if ((listHeight - scrollTop <= visibleHeight) && !withoutSearch && !isLoading) loadMoreItems();
        else console.log(`${listHeight} - ${scrollTop} <= ${visibleHeight}`);
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        if (!withoutSearch) {
            clearTimeout(timeout);
            timeout = setTimeout(search, 1000);
            setPrevSearchInputText(searchInputText);
            setSearchInputText(e.target.value);
        }
    };
    const onSelected = (e, value) => {
        setPrevSelectedValue(selectedValue);
        setSelectedValue(value);
        setIsDropdownOpen(false);
    };

    // On value changed
    useEffect(() => {
        if (selectedValue && (prevSelectedValue?.id !== selectedValue?.id)) OnChange(selectedValue);
    }, [selectedValue]);

    // Main render effects
    useEffect(() => {
        if (!isLoading) {
            if (needRender && selectedValue !== "") {
                setIsLoading(true);
                (async () => {
                    await renderList();
                })();
            }
        }
    }, [needRender, selectedValue]);

    useEffect(() => {
        if (!isLoading) {
            if (customBody) {
                setPrevSelectedValue(selectedValue);
                setSelectedValue(null);
                setList([]);
                (async () => {
                    setIsLoading(true);
                    await renderList(0);
                })();
            }
        }
    }, [customBody]);

    // OnUnMounted event
    useEffect(() => {
        return () => {
            setList([]);
        };
    }, []);

    useEffect(() => {
        setSearchBtnLoading(isLoading);
    }, [isLoading]);

    return (
        (getList())?.length > 0
        ? <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                      renderInput={params => (
                          <TextField {...params} variant="outlined" value={searchInputText}
                                     onChange={onSearchInputChange} size="small"
                                     InputProps={{
                                         readOnly: !canType,
                                         ...params.InputProps,
                                         endAdornment: (
                                             !isDisabled && <InputAdornment position="end">
                                                 {searchBtnLoading
                                                     ? <IconButton edge="end" disableRipple><Loop/></IconButton>
                                                     :
                                                     <IconButton edge="end" onClick={onOpenDropdown} disableRipple>
                                                         {isDropdownOpen ? <ExpandLess/> :
                                                             <ExpandMore/>}
                                                     </IconButton>}
                                             </InputAdornment>
                                         ),
                                     }}
                                     onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                          />
                      )} style={{width: "100%"}} value={selectedValue}
                      noOptionsText={"No results found..."}
                      onChange={onSelected} disableClearable forcePopupIcon={false}
                      open={isDropdownOpen} filterOptions={options => options} disabled={isDisabled}
                      options={getList()} getOptionLabel={getOptionLabel} renderOption={getOption}
        />
        : (
            isLoading
                ? <div style={{border: "1px solid hsl(0, 0%, 80%)", height: "3.5rem", padding: "9px", borderRadius: "4px",}}
                 className="d-flex align-items-center"><CircularProgress className="mr-3"/> Loading...</div>
                : <div style={{border: "1px solid hsl(0, 0%, 80%)", height: "3.5rem", padding: "9px", borderRadius: "4px",}}
                 className="d-flex align-items-center">No results found, please refine your search (in filters on the right)</div>
        )
    );
};

export const ControlledUniversalList = props => {
    const {
        value, OnChange, settings = {}, isDisabled = false,
        callbacks: {getList, setList, getItems, getOptionLabel, getOption}
    } = props;
    const {withoutSearch = false, customBody = null, preSelectedFirstValue = false, withImage = false} = settings;

    const classes = useStyles();

    // Base state
    const [prevSelectedValue, setPrevSelectedValue] = useStateSafe(null);
    const [selectedValue, setSelectedValue] = useStateSafe(value);
    const [searchBtnLoading, setSearchBtnLoading] = useStateSafe(false);
    const [isDropdownOpen, setIsDropdownOpen] = useStateSafe(false);
    const [loadMoreActive, setLoadMoreActive] = useStateSafe(false);
    const [canLoadMoreActive, setCanLoadMoreActive] = useStateSafe(true);
    const [isLoading, setIsLoading] = useStateSafe(false);

    // Base rendering
    const [needRender, setNeedRender] = useStateSafe(true);
    const [size] = useState(25);
    const [offset, setOffset] = useState(0);

    const [searchInputText, setSearchInputText] = useStateSafe("");
    const [prevSearchInputText, setPrevSearchInputText] = useStateSafe("");

    // Functions
    const loadMoreItems = useCallback(() => {
        if (canLoadMoreActive) {
            setPrevSearchInputText(searchInputText);
            setOffset(offset + size);
            setSearchBtnLoading(true);
            setLoadMoreActive(true);
            setNeedRender(true);
        }
    }, [offset, searchInputText, setLoadMoreActive, setNeedRender, setOffset, setPrevSearchInputText, setSearchBtnLoading, size]);
    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };

    const renderList = useCallback(async (offsetToUse = null) => {
        const items = await getItems(offsetToUse ?? offset, size, searchInputText);

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && searchInputText !== "" && items.length > 0) ? getList() : [];
        // const itemsToAdd = [..._list, ...items];
        const itemsToAddAll = [..._list, ...items];
        const itemsToAdd = itemsToAddAll.filter((v,i,a) => a.findIndex(t => (t.id === v.id)) === i).filter(item => item !== undefined && item?.id !== undefined);
        setList(itemsToAdd);
        if (!loadMoreActive && !preSelectedFirstValue) setSelectedValue(itemsToAdd[0]);

        // setSelectedValue(itemsToAdd[0]);
        // if (!selectedValue && !preSelectedValue) setSelectedValue(itemsToAdd[0]);
        // if (!selectedValue && preSelectedValue) {
        //     const selected = itemsToAdd.find(item => item.id === preSelectedValue);
        //     setSelectedValue(selected);
        // }

        if (items?.length < size) setCanLoadMoreActive(false);
        else console.log(itemsToAdd?.length, size);

        if (loadMoreActive) setLoadMoreActive(false);
        setNeedRender(false);
        setIsLoading(false);
        setSearchBtnLoading(false);
    }, [getItems, getList, loadMoreActive, offset, prevSearchInputText, searchInputText, setIsLoading, setList, setLoadMoreActive, setNeedRender, setSearchBtnLoading, setSelectedValue, size, preSelectedFirstValue]);

    // Handlers, listeners
    const onOpenDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const onListScroll = e => {
        const listboxNode = e.currentTarget;
        if ((listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) && !withoutSearch && !isLoading) {
            loadMoreItems()
        }

        // const listHeight = e.target.scrollHeight;
        // const scrollTop = e.target.scrollTop;
        // const visibleHeight = Utils.RemToPx(4.875) * 6;

        // if ((listHeight - scrollTop <= visibleHeight) && !withoutSearch && !isLoading) loadMoreItems();
        // else console.log(`${listHeight} - ${scrollTop} <= ${visibleHeight}`);
    };
    const onSearchInputFocus = () => setIsDropdownOpen(true);
    const onSearchInputBlur = () => setIsDropdownOpen(false);
    const onSearchInputChange = e => {
        if (!withoutSearch) {
            clearTimeout(timeout);
            timeout = setTimeout(search, 1000);
            setPrevSearchInputText(searchInputText);
            setSearchInputText(e.target.value);
        }
    };
    const onSelected = (e, value) => {
        setPrevSelectedValue(selectedValue);
        setSelectedValue(value);
        setIsDropdownOpen(false);
    };

    // On value changed
    useEffect(() => {
        if (selectedValue && (prevSelectedValue?.id !== selectedValue?.id)) OnChange(selectedValue);
    }, [selectedValue]);

    // Main render effects
    useEffect(() => {
        if (!isLoading) {
            if (needRender && selectedValue !== "") {
                setIsLoading(true);
                setCanLoadMoreActive(true);
                (async () => {
                    await renderList();
                })();
            }
        }
    }, [needRender, selectedValue]);

    useEffect(() => {
        if (!isLoading) {
            if (customBody) {
                setPrevSelectedValue(selectedValue);
                if (!preSelectedFirstValue) setSelectedValue(null);
                setList([]);
                setCanLoadMoreActive(true);
                (async () => {
                    setIsLoading(true);
                    await renderList(0);
                })();
            }
        }
    }, [customBody]);

    // OnUnMounted event
    useEffect(() => {
        return () => {
            setList([]);
        };
    }, []);

    useEffect(() => {
        setSearchBtnLoading(isLoading);
    }, [isLoading]);

    return (
        (getList())?.length > 0
        ? <Autocomplete ListboxProps={{style: {maxHeight: "24.375rem"}, onScroll: onListScroll}} classes={classes}
                      renderInput={params => (<div className={`${withImage ? "d-flex align-items-center" : ""}`}>
                          {withImage && selectedValue?.assetId && <Image imageStyle={{
                              position: "initial",
                              margin: "0.25rem",
                              width: "3rem",
                              height: "calc(3.5rem - 0.5rem - 2px)",
                              objectFit: "cover"
                          }} style={{
                              display: "flex",
                              justifyContent: "center",
                              background: "transparent",
                              padding: 0,
                              border: "1px solid hsl(0, 0%, 80%)",
                              borderRight: 0,
                              borderTopRightRadius: 0,
                              borderTopLeftRadius: "4px",
                              borderBottomRightRadius: 0,
                              borderBottomLeftRadius: "4px",
                          }} src={`/asset/thumbnail/${selectedValue?.assetId}`}/>}
                          <TextField {...params} variant="outlined" value={searchInputText}
                                     onChange={onSearchInputChange} size={withImage ? "medium" : "small"}
                                     InputProps={{
                                         ...params.InputProps,
                                         ...(withImage ? {style: {borderTopLeftRadius: 0, borderBottomLeftRadius: 0, height: "3.5rem"}} : {}),
                                         endAdornment: (
                                             !isDisabled && <InputAdornment position="end">
                                                 {searchBtnLoading
                                                     ? <IconButton edge="end" disableRipple><Loop/></IconButton>
                                                     :
                                                     <IconButton edge="end" onClick={onOpenDropdown} disableRipple>
                                                         {isDropdownOpen ? <ExpandLess/> :
                                                             <ExpandMore/>}
                                                     </IconButton>}
                                             </InputAdornment>
                                         ),
                                     }}
                                     onFocus={onSearchInputFocus} onBlur={onSearchInputBlur}
                          />
                      </div>)} style={{width: "100%"}} value={selectedValue}
                      noOptionsText={"No results found..."}
                      onChange={onSelected} disableClearable forcePopupIcon={false}
                      open={isDropdownOpen} filterOptions={options => options} disabled={isDisabled}
                      options={getList()} getOptionLabel={getOptionLabel} renderOption={getOption}
        />
        : (
            isLoading
                ? <div style={{border: "1px solid hsl(0, 0%, 80%)", height: "3.5rem", padding: "9px", borderRadius: "4px",}}
                 className="d-flex align-items-center"><CircularProgress className="mr-3"/> Loading...</div>
                : <div style={{border: "1px solid hsl(0, 0%, 80%)", height: "3.5rem", padding: "9px", borderRadius: "4px",}}
                 className="d-flex align-items-center">No results found, please refine your search (in filters on the right)</div>
        )
    );
};

