// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";

// Components
import {RoomCopy} from "../../Room/components/RoomCopy";

export const NetworkCopyRoom = props => {
    const {onError} = props;

    return (
        <>
            <h4 className="my-4">Copy room</h4>
            <div>
                <div>
                    <RoomCopy onError={onError} chooseRoom/>
                </div>

                <p className="mt-3 text-muted">Copy room with target network set as current</p>

                <hr/>
            </div>
        </>
    );
}
