// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext} from "react";
import Utils from "../../../scripts/Utils";
import {UsersStore} from "../../../stores/UsersStore";

// Components
import {RoomBoothsSpreadsheets} from "../components/RoomBoothsSpreadsheets";
import {RoomBoothsMembers} from "../components/RoomBoothsMembers";
import {RoomCopy} from "../components/RoomCopy";
import {RoomAddOwner} from "../components/RoomAddOwner";
import {RoomShowStatus} from "../components/RoomShowStatus";
import {RoomFeaturingScore} from "../components/RoomFeaturingScore";
import {RoomPrice} from "../components/RoomPrice";
import {RoomSubscriptionStatus} from "../components/RoomSubscriptionStatus";
import {RoomSetAsTemplate} from "../components/RoomSetAsTemplate";
import {RoomAlias} from "../components/RoomAlias";

export const RoomCommands = props => {
    const {roomId, onError} = props;
    const {state} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(state.myRoles);

    return (
        <form className="form-horizontal" autoComplete="new-password">
            {Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]) &&
            <RoomAlias roomId={roomId} onError={onError}/>}

            {Utils.hasPermission(state.myRoles, "room.manage", [Utils.getNetwork(), roomId]) &&
            <RoomPrice onError={onError}/>}

            {Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]) &&
            <RoomSubscriptionStatus onError={onError}/>}

            <RoomBoothsSpreadsheets onError={onError}/>
            <RoomBoothsMembers onError={onError}/>

            {Utils.hasPermission(state.myRoles, "all") &&
            <>
                <h4 className="my-4">Copy room</h4>
                <div>
                    <div className="mb-1">
                        <RoomCopy onError={onError}/>
                        <p className="mt-3 text-muted">By pressing "Copy room" button you will open dialog window,
                            with
                            steps to copy room to another network.</p>
                    </div>

                    <hr/>
                </div>
            </>}

            {Utils.hasPermission(state.myRoles, "room.manage", [Utils.getNetwork(), roomId]) &&
            <RoomAddOwner onError={onError}/>}

            {Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]) &&
            <RoomShowStatus onError={onError}/>}

            {Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]) &&
            <RoomFeaturingScore onError={onError}/>}

            {Utils.hasPermission(state.myRoles, "network.manage", [Utils.getNetwork()]) &&
            <RoomSetAsTemplate roomId={roomId} onError={onError}/>}
        </form>
    );
}
