// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, { useCallback, useContext, useEffect, useState } from "react";
import { API } from "../../api/API";
import Utils from "../../scripts/Utils";
import { Store } from "../../stores/MainStore";
import { UsersStore } from "../../stores/UsersStore";
import { KaraokeStore } from "../../stores/KaraokeStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import { StyledButton } from "../../components/styled/StyledButton";

export default function Karaoke() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state: usersState} = useContext(UsersStore);
    const {state, dispatch} = useContext(KaraokeStore);

    const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);

    const [refreshBtnLoading, setRefreshBtnLoading] = useState(false);
    const [importBtnLoading, setImportBtnLoading] = useState(false);

    const onError = useCallback(error => {
        mainDispatch({
            type:    "SET_MODAL_SETTINGS",
            payload: {
                show:  true,
                title: "Error!",
                text:  error.message,
                color: "default"
            }
        });
    }, []);

    const onImportStart = useCallback(() => {
        mainDispatch({
            type:    "SET_MODAL_SETTINGS",
            payload: {
                show:  true,
                title: "Import started!",
                text:  "Karaoke songs import started. " +
                           "Process could take plenty of time, depending on amount of entries in the manifest. " +
                           "You can track import process by refreshing karaoke info.",
                color: "default"
            }
        });
    }, []);

    const getKaraokeInfo = useCallback(async() => {
        setRefreshBtnLoading(true);
        dispatch({type: "SET_KARAOKE_INFO", payload: {}});
        const info = (await API.Karaoke.getKaraokeInfo(onError))?.data || {};
        dispatch({type: "SET_KARAOKE_INFO", payload: info});
        setRefreshBtnLoading(false);
    }, []);

    const importKaraokeManifest = useCallback(async(file) => {
        setImportBtnLoading(true);
        if(file && file instanceof File) {
            await API.Karaoke.parseAndImportKaraokeSongsFromSpreadsheet(file, onImportStart, onError)
        }
        setImportBtnLoading(false);
    }, []);

    useEffect(getKaraokeInfo, []);

    return (
        <div className="content-wrapper">
            <ContentHeader title="Karaoke" breadcrumbs={[
                {title: "Home", link: "/home"},
                {title: "Karaoke", link: `/karaoke`, active: true}
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    {(isSuperAdmin) && (
                        <div className="card card-body d-flex flex-column">
                            <h6 className="mb-2 d-inline-block p-1">
                                Total songs: {state.totalCount || "..."}
                            </h6>
                            <h6 className="mb-2 d-inline-block p-1">
                                Last
                                modified: {state.lastAddedAt ? new Date(state.lastAddedAt).toLocaleString() : "..."}
                            </h6>
                            <div>
                                <StyledButton className="btn bg-light h-100 mr-2"
                                              title={<><i className="fas fa-sync-alt mr-3"/>Refresh karaoke info</>}
                                              tooltip="Refresh karaoke info"
                                              onClick={getKaraokeInfo}
                                              disabled={refreshBtnLoading} noLoading={!refreshBtnLoading}/>
                            </div>
                            <div>
                                <StyledButton className="btn bg-success h-100 mr-2"
                                              title={<><i className="fas fa-file-audio mr-3"/>
                                                  Import karaoke songs from manifest
                                              </>}
                                              tooltip="Import karaoke songs from manifest"
                                              onClick={() => Utils.uploadFileInput(null, importKaraokeManifest)}
                                              disabled={importBtnLoading} noLoading={!importBtnLoading}/>
                            </div>
                        </div>
                    )}
                </div>
            </section>
        </div>
    );
}
