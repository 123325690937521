// Copyright © 2015-2022 Roomful Co. All rights reserved

import {makeStyles} from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
    textareaContainer: {
        "&>.form-group>div>textarea": {
            minHeight: "calc(2.25rem + 2px)",
            height: "calc(2.25rem + 2px)"
        }
    },
}));