// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext} from "react";
import Utils from "../../../scripts/Utils";
import {ChatsStore} from "../../../stores/ChatsStore";
import {useStyles} from "../style";
import {Link} from "react-router-dom";

// MUI
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions} from "@material-ui/core";
import Image from 'material-ui-image';
import {StyledButton} from "../../../components/styled/StyledButton";

const Participant = ({item, onError}) => {
    const classes = useStyles();

    const {state, dispatch} = useContext(ChatsStore);

    const openChat = async () => {
        const {error, data} = await state.connection.sendSocketRequest("textchat:getTextchatForUser", {data: {targetUser: item.id}});

        if (error.code === 200) {
            dispatch({type: "CLEAR_MESSAGES"});

            const {id, avatar, companyName, companyTitle, firstName, lastName} = item;
            dispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: {user: {id, avatar, firstName, lastName, companyName, companyTitle}}});
            dispatch({type: "SET_CURRENT_TEXTCHAT", payload: data?.textchat});
            dispatch({type: "CHANGE_CATEGORY_ACTIVE", category: "all", payload: true});

            dispatch({type: "RERENDER_LIST", payload: true});
            dispatch({type: "SET_SHOW_PARTICIPANTS", payload: false});

            // navigate('/chats');
        }
        else onError(new Error(error.message));
    };

    return (
        <li className={classes.participant}>
            <span>
                <Image className="contacts-list-img" alt="User Avatar" imageStyle={{width: "2.5rem", height: "2.5rem"}}
                       style={{width: "2.5rem", height: "2.5rem", padding: 0, borderRadius: "50%", float: "left", marginRight: 20}}
                       src={Utils.buildURLs(`/resource/${item?.avatar}`)}/>

                <div className={`contacts-list-info ${classes.primaryColor}`}>
                    <span className="contacts-list-name d-flex justify-content-between align-items-center w-100">
                        <span>{`${item?.firstName} ${item?.lastName}`}</span>
                        <div className="d-flex">
                            <StyledButton className={`btn btn-sm bg-teal h-100 mr-2`} onClick={openChat} title={<i className="fas fa-comments"/>}/>
                            <Link to={`/user/${item.id}`} className="btn btn-sm btn-primary">
                                <i className="fas fa-user"/> View Profile
                            </Link>
                        </div>
                    </span>
                </div>
            </span>
        </li>
    );
};

export const ChatShowParticipants = ({onError}) => {
    const {state, dispatch} = useContext(ChatsStore);
    const classes = useStyles();

    const close = () => dispatch({type: "SET_SHOW_PARTICIPANTS", payload: false});

    const hideScrollbar = () => document.querySelector("html").style.overflowY = "hidden";
    const showScrollbar = () => document.querySelector("html").style.overflowY = "auto";

    return (
        <Dialog open={state.showParticipants} onClose={close} fullWidth={true} maxWidth="sm" scroll="body"
            onEntered={hideScrollbar} onExited={showScrollbar}>
            <DialogTitle id="form-dialog-title">Chat participants:</DialogTitle>
            <DialogContent className={classes.participantContainer}>
                <div className="mt-3">
                    <ul className="contacts-list">
                        {Object.values(state.participants).map((participant, index) =>
                            <Participant key={index} item={participant} onError={onError}/>)}
                    </ul>
                </div>
            </DialogContent>
            <DialogActions/>
        </Dialog>
    );
}