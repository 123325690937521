// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useCallback, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "../../../api/API";
import Utils from "../../../scripts/Utils";
import { UsersStore } from "../../../stores/UsersStore";
import { useStateSafe } from "../../../scripts/SaveUseState";

// Material UI
import 'react-virtualized/styles.css';
import Checkbox from '@material-ui/core/Checkbox';

// Components
import { StyledButton } from "../../../components/styled/StyledButton";
import { StyledImage } from "../../../components/styled/StyledImage";
import AddAsTeamMember from "../modals/AddAsTeamMember";
import CustomTable from "../../../components/table/CustomTable";

export default function UsersListTable(props) {
    const {onError} = props;

    const {state, dispatch} = useContext(UsersStore);

    // Modal state
    const [isOpened, setIsOpened] = useStateSafe(false);

    // Functions
    const formatItems = usersList => {
        return usersList.map(({id, avatar, firstName, lastName, email, companyName, companyTitle, isDisabled}) => ({
            avatar,
            id,
            fullname: `${firstName} ${lastName}`,
            email,
            company: `${companyName}, ${companyTitle}`,
            isDisabled
        }));
    };
    const selectAllToggle = data => {
        dispatch({type: "SET_SELECT_ALL_TOGGLE", payload: data});

        if (data) {
            const newSelectedUsersList = state.list.filter(({isDisabled}) => !isDisabled).map(({id}) => id);
            dispatch({type: "SET_SELECTED_USERS_LIST", payload: newSelectedUsersList});
            return newSelectedUsersList;
        }
        else {
            dispatch({type: "SET_SELECTED_USERS_LIST", payload: []});
            return [];
        }
    };

    // Single row actions
    const selectSingleItem = useCallback(id => {
        if (state.selectedUsers.indexOf(id) > -1) {
            state.selectedUsers.splice(state.selectedUsers.indexOf(id), 1)
            dispatch({type: "SET_SELECTED_USERS_LIST", payload: state.selectedUsers});
            return [...state.selectedUsers];
        }
        else {
            dispatch({type: "SET_SELECTED_USERS_LIST", payload: [...state.selectedUsers, id]})
            return [...state.selectedUsers, id]
        }
    }, [state.selectedUsers]);

    // Callbacks function
    const getColumns = () => ([
        {
            name:      'checkbox',
            header:    (
                           <div className="d-flex justify-content-center">
                               <Checkbox checked={state.selectedUsers.length > 0} color="primary"
                                         onChange={e => {
                                             selectAllToggle(e.target.checked);
                                         }}/>
                           </div>
                       ),
            cell:      rowData => (
                !rowData?.isDisabled
                    ? (
                        <div className="d-flex justify-content-center">
                            <Checkbox checked={state.selectedUsers.some(id => rowData.id === id)} color="primary"/>
                        </div>
                    )
                    : <div/>
            ),
            cellProps: {style: {paddingRight: 0}},
            width:     Utils.RemToPx(4)
        },
        {
            name:   'avatar',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Avatar</span>,
            cell:   rowData =>
                        rowData?.avatar && rowData?.avatar?.length > 0
                            ? <Link to={`/user/${rowData?.id}`}>
                                <StyledImage src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.avatar}`,
                                    false, null, {networkId: Utils.getNetwork()})}
                                             size={5}/>
                            </Link> : null,
            width:  Utils.RemToPx(9)
        },
        {
            name:   'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell:   rowData => (
                <div className="d-flex flex-column align-items-center">
                    <Link to={`/user/${rowData?.id}`} className="underline-link">
                        <span>{rowData?.id}</span>
                    </Link>
                    {rowData?.isDisabled
                        ? <small style={{color: "red"}}>user disabled</small>
                        : ""}
                </div>
            )
        },
        {
            name:   'fullname',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Full name</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.fullname}
                </span>
            )
        },
        {
            name:   'email',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Email</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.email}
                </span>
            )
        },
        {
            name:   'company',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Company</span>,
            cell:   rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {(rowData?.company || "")?.trim() === ',' ? "" : (rowData?.company || "")}
                </span>
            )
        },
        {
            name:   'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell:   rowData => (
                <div className="d-flex flex-column align-items-center">
                    <StyledButton href={`/user/${rowData?.id}`}
                                  title={<i className="fas fa-pencil-alt"/>} type="success"
                                  size="large" tooltip="Open user profile"/>
                </div>
            )
        }
    ]);
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = async (body, onError) => {
        const {users, total} = (
            await (typeof body.text === "string" && body.text.length > 0
                    ? API.Users.search(body, onError, Utils.getNetwork())
                    : API.Users.getList(body, onError, Utils.getNetwork())
            )
        ).data;

        dispatch({type: "SET_LIST", payload: formatItems(users)});
        dispatch({type: "SET_TOTAL_COUNT", payload: total});

        return total ?? 0;
    };
    const getSelectedItems = useCallback(() => state.selectedUsers, [state.selectedUsers]);
    const setSelectedItems = list => dispatch({type: "SET_SELECTED_USERS_LIST", payload: list});
    const clearList = () => dispatch({type: "CLEAR_LIST"});

    useEffect(() => {
        return () => {
            clearList();
            dispatch({type: "SET_SELECTED_USERS_LIST", payload: []});
            dispatch({type: "SET_SELECT_ALL_TOGGLE", payload: false});
        };
    }, []);

    return (
        <>
            <div id="commands">
                <div className="d-flex align-items-center mb-3">
                    <StyledButton title="Add as exhibitor" type="success" size="sm" noLoading
                                  onClick={() => setIsOpened(true)} disabled={!(state.selectedUsers?.length > 0)}/>
                    <AddAsTeamMember isOpened={isOpened} setIsOpened={setIsOpened} onError={onError}/>
                </div>
            </div>

            <CustomTable state={{}}
                         callbacks={{
                             getList, getColumns, getListItems, selectSingleItem,
                             getSelectedItems, setSelectedItems, clearLists: clearList, onError
                         }}/>
        </>
    );
}
