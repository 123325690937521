// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";

// MUI
import Image from 'material-ui-image';

export const StyledImage = props => {
    const {src, size, circle = false, highlightOnHover, fit = 'cover'} = props;

    const highlightOnHoverClass = highlightOnHover ? 'highlight-on-hover' : 'cover';
    const classes = `${highlightOnHoverClass}`

    return (
        <Image src={src}
               className={classes}
               imageStyle={{
                   position:     "initial",
                   width:        `${size}rem`,
                   height:       `${size}rem`,
                   objectFit:    fit,
                   borderRadius: circle ? "50%" : 0
               }}
               style={{
                   display:        "flex",
                   justifyContent: "center",
                   background:     "transparent",
                   padding:        0
               }}
        />
    );
};
