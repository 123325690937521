// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import { v4 as uuidv4 } from 'uuid';

// MUI
import FormHelperText from "@material-ui/core/FormHelperText";

export const StyledInput = props => {
    const {title, value, defaultValue = undefined,
        type, placeholder, helperText = "",
        onChange = () => {}, onBlur = () => {},
        isTextarea, disabled, style = {},
        isInvalid, noMargin, noPadding, fullWidth, smallSize
    } = props;

    return (
        <div className={`form-group align-items-center row ${fullWidth ? "w-100" : ""}`} style={Object.assign({}, style, {marginLeft: 0, marginRight: 0, ...(noMargin ? {marginBottom: 8} : {})})}>
            {
                title?.length > 0
                    ? <label className="col-2 col-form-label" style={{whiteSpace: "nowrap"}}>{title}</label>
                    : null
            }
            <div className={`${title?.length > 0 ? "col-10" : "col-12"} ${noPadding ? "px-0" : ""}`}>
                {isTextarea
                    ? <textarea className={`form-control ${isInvalid ? "is-invalid" : ""}`} placeholder={placeholder}
                                value={value} onChange={e => {
                        if (!e.isTrusted) return;
                        onChange(e);
                    }} disabled={disabled} defaultValue={defaultValue}/>
                    :
                    (
                        <>
                            <input autoComplete="off" className={`form-control ${isInvalid ? "is-invalid" : ""} ${smallSize ? "form-control-sm" : ""}`}
                                   type={type} name={`not_the_pass_${uuidv4()}`}
                                   placeholder={placeholder ? placeholder : title} value={value}
                                   onChange={onChange} disabled={disabled} onBlur={onBlur} defaultValue={defaultValue}/>
                            {helperText?.length > 0 && <FormHelperText>{helperText}</FormHelperText> }
                        </>
                    )
                }
            </div>
        </div>
    );
};

export const StyledInputWithButton = props => {
    const {
        title = "", icon = null, value, type, placeholder,
        onChange, onIconClick, onKeyDown,
        disabled, isInvalid, id, buttonStyle = "btn-success", fullWidth = false
    } = props;

    return (
        <div className={`form-group row ${fullWidth ? "w-100" : ""}`}>
            {
                title.length > 0
                    ? <label className="col-2 col-form-label">{title}</label>
                    : null
            }
            <div className={title.length > 0 ? "col-10" : "col-12"}>
                <div className="input-group">
                    <input autoComplete="new-password" disabled={disabled} type={type} name={`not_the_pass_${uuidv4()}`}
                           className={`form-control ${isInvalid ? "is-invalid" : ""}`}
                           placeholder={placeholder ?? title} value={value} onChange={onChange}
                           onKeyDown={onKeyDown} id={id ?? uuidv4()}/>
                    {icon && <span className="input-group-append">
                        <button type="button" className={`btn ${buttonStyle} btn-flat`}
                                onClick={onIconClick}>{icon}</button>
                    </span>}
                </div>
            </div>
        </div>
    );
};

export const StyledCheckbox = props => {
    const {title, checked, onChange, centerHorizontal = false, isLarge = null} = props;

    const ID = uuidv4();

    return (
        <div className={`custom-control custom-checkbox ${isLarge ? "large" : ""} ${centerHorizontal ? "text-center" : ""}`}>
            <input className="custom-control-input" type="checkbox" id={ID} checked={checked} onChange={onChange} />
            <label className="custom-control-label" htmlFor={ID}>{title}</label>
        </div>
    );
};

export const StyledTimeInput = props => {
    const {title, onChange, column, centered, fullWidth} = props;

    const ID = uuidv4();
    const value = props.value === "0001-01-01T00:00" ? "" : props.value;

    return (
        <div className={`form-group row mb-0 ${column ? "flex-column" : ""} ${centered ? (column ? "align-items-center" : "justify-content-center") : ""}`}>
            <label className="col-form-label d-inline-flex mr-3" htmlFor={ID} style={{paddingLeft: 7.5}}>{title}</label>
            <input className="form-control d-inline-flex" type="datetime-local" value={value} onChange={onChange} style={fullWidth ? {maxWidth: "18.75rem"} : {maxWidth: "12.5rem"}}/>
        </div>
    );
};

export const StyledCalendarInput = props => {
    const {title, value, onChange} = props;

    const ID = uuidv4();

    return (
        <div className="form-group row"  style={{marginLeft: 0, marginRight: 0}}>
            <label className="col-2 col-form-label" htmlFor={ID}>{title}</label>
            <div className="col-10">
                <input className="form-control" type="date"  value={value} onChange={onChange}/>
            </div>
        </div>
    );
};
