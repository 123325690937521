// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch, customFetchOld} from "../Requests";

export class AnalyticsAPI {
    static async generateReport(path, params, network, onError) {
        let filename = "";
        return await customFetchOld({method: "GET", path, body: null, params, network})
            .then(response => {
                filename = response.headers.get('content-disposition').match(/"(.*?)"/g)[0].replace(/"/g, "");
                return response.blob();
            })
            .then(blob => URL.createObjectURL(blob))
            .then(url => {
                const link = document.createElement('a');
                link.style.display = "none";
                link.href = url;
                link.setAttribute('download', filename);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
            })
            .catch(onError);
    }

    static async getRegistrations(localDate, network, onError) {
        return await customFetchOld({path: "/jsonRpc/analytics.registrations", body: {localDate}, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getSessions(localDate, network, onError) {
        return await customFetchOld({path: "/jsonRpc/analytics.sessions", body: {localDate}, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getRooms(network, onError) {
        return await customFetchOld({path: "/jsonRpc/analytics.rooms", body: null, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getRoomsVisits(body, network, onError) {
        return await customFetchOld({path: "/jsonRpc/analytics.roomVisits", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getPropsVisits(body, network, onError) {
        return await customFetchOld({path: "/jsonRpc/analytics.propVisits", body, network})
            .then(resultData => resultData.json())
            .catch(onError);
    }
}