// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Select from "react-select";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {RoomsStore} from "../../../stores/RoomsStore";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import {customStyles} from "../../../styles/dropdown";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const RoomShowStatus = ({onError}) => {
    const {state} = useContext(RoomsStore);

    // Base state
    const [isShow, setIsShow] = useState(state?.model?.explorerStatus?.length > 0 ? state?.model?.explorerStatus : "show");
    const [isLoading, setIsLoading] = useState(false);

    // Main functions
    const updateShowStatus = useCallback(async () => {
        setIsLoading(true);
        const result = await API.Rooms.updateRoomExplorerStatus(state?.model?.id, isShow, onError);
        setIsLoading(false);

        if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
    }, [isShow]);

    return (
        <>
            <h4 className="my-4">Room explorer status</h4>
            <div>
                <div className="mb-1">
                    <div className="row ml-0">
                        <div className="d-flex align-items-center mr-3" id="room-show-status">
                            <Select onChange={selected => setIsShow(selected?.value)}
                                    defaultValue={{label: Utils.capitalizeFirstLetter(isShow), value: isShow}}
                                    styles={customStyles(true)}
                                    options={[
                                        {label: "Show", value: "show"},
                                        {label: "Hide", value: "hide"}
                                    ]}/>
                            {isLoading && <CircularProgress size={18} className="ml-3"/>}
                        </div>
                        <StyledButton onClick={updateShowStatus} disabled={isLoading} title="Update" type="success"/>
                    </div>
                    <p className="mt-3 text-muted">
                        Hidden room will not be present in room explorer in application.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
}
