import React from 'react';
import {Pie} from '@reactchartjs/react-chart.js';

const buildInfo = (labels, data) => ({
    labels,
    datasets: [
        {
            data,
            backgroundColor: [
                'rgba(243, 156, 18, 0.2)',
                'rgba(71, 210, 110, 0.2)',
            ],
            borderColor: [
                'rgba(243, 156, 18, 1)',
                'rgba(71, 210, 110, 1)',
            ],
            borderWidth: 1,
        },
    ],
});

export const RoomsChart = props => {
    const {data} = props;

    return (
        <div className="card card-warning px-0" style={{width: "48%"}}>
            <div className="card-header">
                <h2 className="card-title mr-2">Rooms privacy</h2>

                <div className="card-tools"/>
            </div>
            <div className="card-body">
                <div className="chart">
                    <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                            <div className=""/>
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                            <div className=""/>
                        </div>
                    </div>
                    <Pie data={buildInfo(['Public', 'Private'], data)}/>
                </div>
            </div>
        </div>
    )
};
