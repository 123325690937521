// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import { Store } from "../../stores/MainStore";
import { UsersStore } from "../../stores/UsersStore";
import { NetworksStore } from "../../stores/NetworksStore";
import Utils from "../../scripts/Utils";

// Components
import PageWithTabs from "../Template/PageWithTabs";
import UsersListTable from "./components/UsersListTable";
import UploadFileMenu from "./components/UploadFileMenu";
import { NetworkSubscribeUsers } from "../Network/components/NetworkSubscribeUsers";

export default function UsersList() {
    const {tab = "list"} = useParams();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: userState} = useContext(UsersStore);
    const {state: networkState} = useContext(NetworksStore);

    const onError = Utils.dispatchErrorModalFunc(mainDispatch);

    const isSuperAdmin = Utils.isSuperAdmin(userState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(userState.myRoles);

    return (
        <PageWithTabs currentTabName={tab} tabs={[
            {
                name:    "list",
                label:   "All Users",
                path:    "/users/list",
                content: <UsersListTable onError={onError}/>,
                show:    isSuperAdmin || isNetworkAdmin,
            },
            {
                name:    "addParticipants",
                label:   "Add Participants",
                path:    "/users/addParticipants",
                content: <UploadFileMenu onError={onError}/>,
                show:    isSuperAdmin || isNetworkAdmin,
            },
            {
                name:    "subscribe",
                label:   "Subscribe To Network",
                path:    "/users/subscribe",
                content: <NetworkSubscribeUsers onError={onError}/>,
                show:    (isSuperAdmin || isNetworkAdmin)
                             && !Utils.isDefaultNetwork(networkState.currentNetworkInfo?.network?.id),
            }
        ]}/>
    );
}
