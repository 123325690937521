// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext} from "react";
import {Store} from "../stores/MainStore";

// Components
import {StyledButton} from "./styled/StyledButton";

// Material UI
import {Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Box} from "@material-ui/core";

function MessageDialog() {
    const {state, dispatch} = useContext(Store);

    const {
        title, type, text, color,
        onOk, useCustomOnOkFunction, useCustomOkText = "OK",
        onCancel = () => false, useCustomOnCancelFunction = false, useCustomCancelText = "Cancel"
    } = state.modal;

    const messageTitle = title ? title : (type === "error" ? "Error!" : (type === "info" ? "Info" : "Success"));
    const okButtonColor = color ? color : (type === "error" ? "danger" : (type === "info" ? "primary" : "success"));

    const close = () => dispatch({type: "SET_MODAL_SETTINGS", payload: {show : false}});
    const onOKClicked = useCustomOnOkFunction ? onOk : close;

    return (
        <Dialog open={state.modal.show} onClose={close} aria-labelledby="form-dialog-title" fullWidth={true}
                maxWidth="sm" disableBackdropClick={true} disableEscapeKeyDown={true}>
            <DialogTitle id="form-dialog-title" align="center">{messageTitle}</DialogTitle>
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Box display="flex" justifyContent="center" width="100%">
                    {useCustomOnCancelFunction &&
                        <StyledButton className="btn btn-default mr-3" onClick={onCancel} title={useCustomCancelText}/>}
                    <StyledButton onClick={onOKClicked} type={okButtonColor} title={useCustomOkText}/>
                </Box>
            </DialogActions>
        </Dialog>
    );
}

export default MessageDialog;