// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

export const initialState = {
    totalCount: 0,
    lastAddedAt: "No data"
};

export const KaraokeStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_KARAOKE_INFO':
            return {...state, totalCount: action.payload.totalCount, lastAddedAt: action.payload.lastAddedAt};
        default:
            return state;
    }
}

export const KaraokeProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <KaraokeStore.Provider value={value}>{props.children}</KaraokeStore.Provider>;
};
