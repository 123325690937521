// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useState} from "react";
import Utils from "../../../scripts/Utils";

// Components
import {StyledTimeInput} from "../../../components/styled/StyledInput";
import {AllRooms} from "../../../components/list/Rooms/AllRooms";
import {UserRooms} from "../../../components/list/Rooms/UserRooms";
import {RoomProps} from "../../../components/list/Props/RoomProps";
import {UserPropsWithFilters} from "../../../components/list/Props/UserPropsWithFilters";
import {AllProps} from "../../../components/list/Props/AllProps";

export const ReportFilters = props => {
    const {
        onRoomSelected, onPropSelected,
        isRoomAdmin, isPropAdmin,
        startDate, endDate, onStartDateSelected, onEndDateSelected
    } = props;

    // Base state
    const [room, setRoom] = useState(null);
    const [prop, setProp] = useState(null);

    // Main functions
    const dateToISO = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    const onRoomChanged = selectedRoom => {
        setRoom(selectedRoom);
        onRoomSelected(selectedRoom);
    };
    const onPropChanged = selectedProp => {
        setProp(selectedProp);
        onPropSelected(selectedProp);
    };

    return (
        <>
            <h4 className="my-4">Filters</h4>
            <div>
                <div className="row align-items-center">
                    <div className="col-5 mb-3">
                        <div className={`row mx-0 w-100 d-flex flex-column`}>
                            <span className={`nav-link col-12 pl-0`}>Rooms:</span>
                            <div className={`col-12 select-styles-reset pl-0`}>
                                {isRoomAdmin
                                    ? <UserRooms room={room} onChange={onRoomChanged} networkId={Utils.getNetwork()}/>
                                    : <AllRooms room={room} onChange={onRoomChanged} networkId={Utils.getNetwork()} allowAll={true}/>}
                            </div>
                        </div>
                    </div>
                    <div className="col-5 mb-3">
                        <div className={`row mx-0 w-100 d-flex flex-column`}>
                            <span className={`nav-link col-12 pl-0`}>Props:</span>
                            <div className={`col-12 select-styles-reset pl-0`}>
                                {isPropAdmin
                                    ? <UserPropsWithFilters prop={prop} onChange={onPropChanged}/>
                                    : <AllProps prop={prop} onChange={onPropSelected} networkId={Utils.getNetwork()}
                                                roomId={room?.id} disabled={room?.id === "all"} allowAll={true}/>}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row align-items-center">
                    <div className="col-3 ml-2">
                        <StyledTimeInput title="Start date: " value={startDate.slice(0, 16)} column fullWidth
                                         onChange={e => onStartDateSelected(dateToISO(new Date(e.target.value)))}/>
                    </div>
                    <div className="col-3 ">
                        <StyledTimeInput title="End date: " value={endDate.slice(0, 16)} column fullWidth
                                         onChange={e => onEndDateSelected(dateToISO(new Date(e.target.value)))}/>
                    </div>
                </div>

                <hr/>
            </div>
        </>
    );
};