// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router";
import Utils from "../../../../scripts/Utils";
import {Store} from "../../../../stores/MainStore";
import {UsersStore} from "../../../../stores/UsersStore";
import {CMSStore} from "../../../../stores/CMSStore";

// Components
import {ThemedCircleTabs, ThemedCircleTab, CircleTabPanel} from "../../../../components/styled/StyledTabs";

// Tabs
import PageContent from "./components/PageContent";

export default function PropContent () {
	const navigate = useNavigate();

	// Context
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(UsersStore);
    const {dispatch: cmsDispatch} = useContext(CMSStore);

    // Tabs state
	const [tabValue, setTabValue] = useState(2);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    // Roles
    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(state.myRoles);
    const isRoomAdmin = Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = Utils.isPropAdmin(state.myRoles);

    // Handlers
    const onError = error => {
    	mainDispatch({
			type: "SET_MODAL_SETTINGS",
			payload: {
				show: true,
				title: "Error!",
				text: error.message,
				color: "default"
			}
		});
	};

    // Effects
	useEffect(() => {
		cmsDispatch({type: "CLEAR_SELECTED_ITEMS"});
        cmsDispatch({type: "SET_SHOW_FILE_EDIT_MENU", payload: false});
	}, []);
	useEffect(() => {
		if (tabValue === 0) navigate("/cms");
		if (tabValue === 1) navigate("/cms/room");
	}, [tabValue]);

    return (
        <div className="content-wrapper">

            <section className="content">
                <div className="container-fluid pt-3">

					<div className="card card-tabs" style={{background: "transparent", boxShadow: "none"}}>
                        <div className="card-header p-0 pt-1 d-flex custom justify-content-between"
                             style={{borderTop: "none"}} id="table-header">
                            <ThemedCircleTabs value={tabValue} onChange={onTabChange}>
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0} label="My content"/>
                                {!isPropAdmin && <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1}
												  label="Room contribution panel"/>}
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={2} label="Prop content"/>
                            </ThemedCircleTabs>
                        </div>

                        <div className="card-body px-0" id="table-container" style={{borderTop: "1px solid rgb(222, 226, 230)"}}>
                            <div className="tab-content">
                                <CircleTabPanel value={tabValue} index={2}>
									<PageContent onError={onError}/>
                                </CircleTabPanel>
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    );
}
