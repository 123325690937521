// Copyright © 2015-2023 Roomful Co. All rights reserved

import Cookies from 'js-cookie';

class Utils {
    static buildURLs(url, withoutNetwork = true, withoutSession = (!Utils.isLocalhost()), params = {}) {
        if (!withoutNetwork && !params.networkId) params.networkId = Utils.getNetwork();
        if (!withoutSession) params.sessionId = Cookies.get("sessionId");

        const queryData = Utils.encodeQueryData(params);

        return `${url}${queryData}`;
    }

    static isLocalhost() {
        return (typeof window !== "undefined") &&
            (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1");
    }

    static getNetwork() {
        if (Cookies.get("networkId")) return Cookies.get("networkId");
        else return "roomful";
    }

    static isDefaultNetwork(networkId) {
        return !networkId || networkId === "roomful";
    }

    static redirectIfUnauthorize() {
        if (typeof window !== "undefined") window.location.href = '/';
    }

    static isObject = object => object != null && typeof object === 'object';

    static isObjectEmpty = obj => Object.keys(obj).length === 0 && obj.constructor === Object;

    static isArraysIdentical(a, b) {
        let i = a.length;
        if (i !== b.length) return false;
        while (i--) {
            if (a[i] !== b[i]) return false;
        }
        return true;
    };

    static isEquivalent(a, b) {
        let aProps = Object.getOwnPropertyNames(a);
        let bProps = Object.getOwnPropertyNames(b);

        if (aProps.length !== bProps.length) return false;

        for (let i = 0; i < aProps.length; i++) {
            let propName = aProps[i];

            if (a[propName] !== b[propName]) return false;
        }

        return true;
    };

    static uploadFileInput(accept, callback) {
        if (typeof window !== "undefined" && typeof document !== "undefined") {
            const input = document.createElement("input");
            if (accept) input.accept = accept;
            input.type = "file";
            input.addEventListener("change", () => callback && typeof callback === "function" && callback(input.files[0]));
            input.click();
        }
    }

    static encodeQueryData(data) {
        const ret = [];
        for (let d in data)
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        return ret.length > 0 ? "?" + ret.join('&') : "";
    }

    static hasPermissionSkipTargetIdCheck(userRoles, permission) {
        if (userRoles && userRoles.length > 0) {
            for (let role of userRoles) {
                if (role?.permissions?.includes("all") || role?.permissions?.includes(permission)) return true;
            }
        }
        return false;
    }

    static hasPermission(userRoles, permission, targetIdParts = []) {
        const targetId = targetIdParts?.length > 0 ? "/" + targetIdParts.join("/") : "";
        if (userRoles && userRoles.length > 0) {
            for (let role of userRoles) {
                if (role?.permissions?.includes("all")) return true;
                if (role?.permissions?.includes(permission) &&
                    (role.targetType === "" || targetId?.startsWith(role.targetId))) return true;
            }
        }
        return false;
    }

    static isSuperAdmin = userRoles => Utils.hasPermission(userRoles, "all");

    // User doesn't have super admin permissions, but have permission to manage current network
    static isNetworkAdmin = userRoles =>
        !Utils.hasPermission(userRoles, "all") &&
        Utils.hasPermission(userRoles, "network.manage", [Utils.getNetwork()]);

    // User doesn't have network admin permissions, but have permission to manage one of rooms in current network
    static isRoomAdmin = userRoles =>
        !Utils.hasPermission(userRoles, "network.manage", [Utils.getNetwork()]) &&
        Utils.hasPermissionSkipTargetIdCheck(userRoles, "room.manage");

    // User doesn't have room admin permissions (or higher), but have permission to manage one of props in current network
    static isPropAdmin = userRoles =>
        !Utils.hasPermission(userRoles, "network.manage", [Utils.getNetwork()]) &&
        !Utils.hasPermissionSkipTargetIdCheck(userRoles, "room.manage") &&
        Utils.hasPermissionSkipTargetIdCheck(userRoles, "prop.manage");

    static dispatchErrorModalFunc(dispatch) {
        return error => dispatch({
            type:    "SET_MODAL_SETTINGS",
            payload: {
                show:  true,
                title: "Error!",
                text:  error.message,
                color: "default"
            }
        });
    }

    static formatTime(date) {
        const tzo = -date.getTimezoneOffset();
        const dif = tzo >= 0 ? '+' : '-';
        const pad = num => {
            const norm = Math.floor(Math.abs(num));
            return (norm < 10 ? '0' : '') + norm;
        };
        return date.getFullYear() +
            '-' + pad(date.getMonth() + 1) +
            '-' + pad(date.getDate()) +
            'T' + pad(date.getHours()) +
            ':' + pad(date.getMinutes()) +
            ':' + pad(date.getSeconds()) +
            dif + pad(tzo / 60) +
            ':' + pad(tzo % 60);
    }

    static getTime() {
        const date = new Date();
        return Utils.formatTime(date);
    }

    static getLast7Days () {
        const days = [];
        for (let i = 0; i < 7; i++) {
            const currentDate = new Date();
            currentDate.setDate(currentDate.getDate() - i);
            const date = currentDate.toISOString().split('T')[0].split(/-/gim);

            days.push(`${date[2]}.${date[1]}`);
        }
        return days.reverse();
    }

    static emToPx (em, el) {
        const getElementFontSize = context => parseFloat(getComputedStyle(context || document.documentElement).fontSize);
        return em * getElementFontSize(el);
    }

    static formatDate = date => {
        const dateTimeFormat = new Intl.DateTimeFormat('en', {
            year: 'numeric',
            month: 'numeric',
            day: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric'
        });
        return dateTimeFormat?.format(date);
    };

    static limitStringLength = (str, MAX_STRING_LENGTH) =>
        str.length > MAX_STRING_LENGTH ? str.substring(0, MAX_STRING_LENGTH - 3) + "..." : str;

    static dateDiffInDays = (a, b) => {
        const utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
        const utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

        return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
    }

    static RemToPx = rem => rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
    static vwToPx = vw => window.innerWidth * vw / 100;

    static getDateText = (_date, capitalizeMonthName = false) => {
        const monthNames = ["january", "february", "march", "april", "may", "june",
            "july", "august", "september", "october", "november", "december"];
        const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const date = new Date(_date);

        const dayName = dayNames[date.getDay()];
        const monthName = monthNames[date.getMonth()];
        const yearName = date.getFullYear();

        return `${dayName}, ${date.getDate()} ${capitalizeMonthName ? monthName.toLocaleUpperCase() : monthName} ${yearName}`;
    };

    static camelize = str => str.replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) => index === 0 ? word.toLowerCase() : word.toUpperCase()).replace(/\s+/g, '');

    static unCamelize = (text, separator = "_") => text.replace(/[A-Z]/g, (letter) => separator + letter.toLowerCase()).replace("/^" + separator + "/", '');

    static capitalizeFirstLetter = string => string.charAt(0).toUpperCase() + string.slice(1);

    static awaitAll(list, asyncFn) {
        const promises = [];

        for (let i = 0; i < list?.length; i++) promises.push(asyncFn(list[i], i));

        return Promise.all(promises);
    }

    static getCurrentTime = () => {
        let current = new Date();
        return new Date(current.getTime() - (current.getTimezoneOffset() * 60000)).toISOString();
    };

    static dateToISO = date => new Date(date.getTime() - (date.getTimezoneOffset() * 60000)).toISOString();

    static groupBy = (items, key) => items.reduce(
        (result, item) => ({
            ...result,
            [item[key]]: [
                ...(result[item[key]] || []),
                item,
            ],
        }),
        {},
    );
}

export default Utils;