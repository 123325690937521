// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';
import { API } from "../../../api/API";
import Utils from "../../../scripts/Utils";
import { initialState, Store } from "../../../stores/MainStore";
import { UsersStore } from "../../../stores/UsersStore";
import { ChatsStore } from "../../../stores/ChatsStore";
import { NetworksStore } from "../../../stores/NetworksStore";

// MUI
import { Autorenew, Business, Email, Height, InsertDriveFile, Person } from "@material-ui/icons";
import { List, ListItem, ListItemText, ListItemIcon } from "@material-ui/core";

// Components
import { StyledButton } from "../../../components/styled/StyledButton";
import { FeedbackTable } from "../../../components/table/FeedbackTable";
import { ActiveNetworks } from "../../../components/list/Networks/ActiveNetworks";

export default function UploadFileMenu({isCard = true, onError}) {
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state: userState, dispatch: userDispatch} = useContext(UsersStore);
    const {state, dispatch} = useContext(NetworksStore);
    const {state: chatsState} = useContext(ChatsStore);

    const isSuperAdmin = Utils.isSuperAdmin(userState.myRoles);

    // Base state
    const [isLoading, setIsLoading] = useState(false);
    const [file, setFile] = useState(null);

    // Network state
    const [network, setNetwork] = useState({
        id:       Utils.getNetwork(),
        fullName: Cookies.get("subdomainName")
    });

    // Main functions
    const uploadFile = () => Utils.uploadFileInput(null, file => {
        setIsLoading(true);
        setFile(file);
    });
    const getColumns = () => ([
        {
            name:   "email",
            header: "Email",
            cell:   rowData => rowData?.email
        },
        {
            name:   "fullName",
            header: "Full name",
            cell:   rowData => `${rowData?.firstName} ${rowData?.lastName}`
        },
        {
            name:   "company",
            header: "Company",
            cell:   rowData => rowData?.companyName
        },
        {
            name:   "companyTitle",
            header: "Company position",
            cell:   rowData => rowData?.companyTitle
        },
        {
            name:   "status",
            header: "Status",
            cell:   rowData => rowData?.status
        }
    ]);
    const getData = useCallback(() => state?.feedbackTableItems?.participants, [state?.feedbackTableItems?.participants]);

    // Callbacks
    const getItems = useCallback(() => state.parsedParticipants, [state.parsedParticipants]);
    const getResult = async participant => {
        const result = await API.Networks.addParticipant({
            participant,
            networkId: Utils.getNetwork()
        }, onError);

        return (!Utils.isObjectEmpty(participant) && result?.error?.code === 200
            ? {error: {code: 200}, feedback: {...participant, ...{status: "success"}}}
            : {error: result?.error});
    };
    const addItemToFeedbackTable = item => {
        dispatch({
            type:    "SET_FEEDBACK_TABLE_ITEMS",
            key:     "participants",
            payload: item
        });
    };
    const onFinish = () => {
        setFile(null);
        setIsLoading(false);
        dispatch({type: "SET_PARTICIPANTS_FEEDBACK_NEED_RENDER", key: "isParticipantsNeedRender", payload: false});
        mainDispatch({
            type:    "SET_MODAL_SETTINGS",
            payload: {
                show:  true,
                title: "All participant added correctly!",
                text:  "See results in the table below.",
                color: "success"
            }
        });
    };
    const onAllDone = () => {
        dispatch({type: "SET_SHOW_FEEDBACK", key: "showParticipantsFeedback", payload: true});

        if(state?.feedbackTableItems?.participants?.length > 0) {
            dispatch({type: "RESET_FEEDBACK_TABLE_ITEMS", key: "participants"});
            dispatch({type: "SET_FEEDBACK_NEED_RENDER", key: "isParticipantsNeedRender", payload: true});
        }
    };

    // Effects
    useEffect(() => {
        (async() => {
            if(file && file instanceof File) {
                try {
                    const result = await API.Networks.parseNetworkParticipantSpreadsheet(file)
                    dispatch({type: "SET_PARSED_PARTICIPANTS", payload: result});
                    onAllDone();
                } catch(e) {
                    setFile(null);
                    setIsLoading(false);
                    onError(e);
                }
            }
        })();
    }, [file]);

    useEffect(() => {
        if(chatsState.connection && network) {
            if(Utils.getNetwork() !== network?.id) {
                mainDispatch({
                    type:    "SET_MODAL_SETTINGS",
                    payload: {
                        show:  true,
                        title: "Warning! Network context switching",
                        text:  `Do you want to switch network context to ${network?.fullName} (#${network?.id})?`,
                        color: "warning",

                        useCustomOnCancelFunction: true,
                        onCancel:                  () => {
                            console.log("without switching network context");
                            mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
                            setNetwork({
                                id:       Utils.getNetwork(),
                                fullName: Cookies.get("subdomainName")
                            });
                        },

                        useCustomOnOkFunction: true,
                        onOk:                  async() => {
                            console.log("switching context...");
                            mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});

                            const result = await API.Users.isUserSubscribedToNetwork(network?.id, userState?.model?.id, onError);

                            if(result?.error?.code === 200) {
                                const isSubscribed = result?.data?.isSubscribed;

                                if(!isSubscribed) {
                                    const result = await API.Users.subscribeUserToNetwork(network?.id, userState?.model?.id, onError);

                                    if(result?.error?.code !== 200) onError(new Error(result?.error?.message));
                                }

                                // Switching network
                                Cookies.set("networkId", network?.id);
                                Cookies.set("subdomainName", network?.fullName?.trim());

                                dispatch({
                                    type:    "SET_CURRENT_NETWORK",
                                    payload: state?.openedNetwork?.model
                                });
                                userDispatch({type: "SET_UPDATING_MODEL", payload: true});

                                await chatsState?.connection?.sendSocketRequest("network:switch", {data: {networkId: network?.id}});
                                navigate(0);
                            } else onError(new Error(result?.error?.message));
                        },
                    }
                });
            }
        }
    }, [network, chatsState.connection]);

    return (
        <>
            <div className="row mb-5">
                <div className={`${isCard ? "card" : ""} p-4 w-100`}>
                    <h5>Upload file to parse network participants</h5>
                    <hr/>
                    <div>
                        <List>
                            <ListItem>
                                <ListItemIcon><InsertDriveFile/></ListItemIcon>
                                <ListItemText>Format - <b>.xlsx</b></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Height/></ListItemIcon>
                                <ListItemText>Max size - <b>2MB</b></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Email/></ListItemIcon>
                                <ListItemText>Must contain column with <b>email</b> title</ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Person/></ListItemIcon>
                                <ListItemText>Must contain
                                    columns <b>firstName</b> and <b>lastName</b></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Business/></ListItemIcon>
                                <ListItemText>Must contain
                                    columns <b>companyName</b> and <b>companyTitle</b></ListItemText>
                            </ListItem>
                            <ListItem>
                                <ListItemIcon><Autorenew/></ListItemIcon>
                                <ListItemText>It could take some time to load files with many
                                    entries</ListItemText>
                            </ListItem>
                        </List>

                        <hr/>

                        {isSuperAdmin && <div className="row d-flex align-items-center mb-3">
                            <h6 className="col-3">Add participants to network:</h6>
                            <div className="col-9">
                                <ActiveNetworks network={network}
                                                onChange={selectedNetwork => setNetwork(selectedNetwork)}
                                                settings={{preLoadedNetwork: true}}/>
                            </div>
                        </div>}

                        <StyledButton onClick={uploadFile} disabled={isLoading} title="Upload file"
                                      type="success"/>
                    </div>
                </div>
            </div>

            <FeedbackTable show={state?.feedbackTableItems?.showParticipantsFeedback} settings={{needSubLoop: true}}
                           callbacks={{
                               onError, getColumns, getData, getItems,
                               getResult, addItemToFeedbackTable, onFinish
                           }}
                           texts={{title: "Participants feedback table", progressBarTitles: ["Added participants : "]}}
            />
        </>
    );
}
