// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Select from "react-select";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";
import {RoomsStore} from "../../../stores/RoomsStore";

// MUI
import CircularProgress from "@material-ui/core/CircularProgress";
import {customStyles} from "../../../styles/dropdown";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {StyledInput} from "../../../components/styled/StyledInput";

export const RoomAlias = ({onError}) => {
    const {state} = useContext(RoomsStore);

    // Base state
    const [alias, setAlias] = useState(state?.model?.alias || "");
    const [isUpdating, setIsUpdating] = useState(false);
    const [isDeleting, setIsDeleting] = useState(false);

    // Main functions
    const updateAlias = async () => {
        setIsUpdating(true);

        const result = await API.Rooms.setRoomAlias({roomId: state?.model?.id, alias}, onError);
        if (result?.error?.code !== 200) onError(new Error(result?.error?.message));

        setIsUpdating(false);
    };
    const deleteAlias = async () => {
        setIsDeleting(true);

        const result = await API.Rooms.deleteRoomAlias({roomId: state?.model?.id, alias}, onError);
        if (result?.error?.code !== 200) onError(new Error(result?.error?.message));

        setIsDeleting(false);
        setAlias("");
    };

    return (
        <>
            <h4 className="mb-4">Room alias</h4>
            <div>
                <div className="mb-1">
                    <div className="w-100 d-flex align-items-start">
                        <div className="mr-3 w-100">
                            <StyledInput placeholder="Write alias here..." value={alias} onChange={e => setAlias(e.target.value)} fullWidth noPadding/>
                        </div>
                        <div className="mr-3">
                            <StyledButton onClick={updateAlias} disabled={isUpdating} noLoading={!isUpdating} title="Update" type="success"/>
                        </div>
                        {alias?.length > 0 &&
                        <StyledButton onClick={deleteAlias} disabled={isDeleting} noLoading={!isDeleting} title="Delete&nbsp;alias" type="danger"/>}
                    </div>
                    <p className="mt-3 text-muted">
                        Create alias as alternative to room id.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
}
