// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {RolesStore} from "../../stores/RolesStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import AddRole from "./components/AddRole";
import SimpleCustomTable from "../../components/table/SimpleCustomTable";
import {StyledButton} from "../../components/styled/StyledButton";

export default function RolesManager() {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RolesStore);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    useEffect(() => {
        (async () => {
            if (state.updateRolesList) {
                const {error, data} = await API.RolesManager.list(onError);

                if (error.code === 200) {
                    const items = {};
                    data.roles.map(item => items[item.id] = {
                        ...item,
                        disabled: true,
                        editedPermissions: item.permissions.join(", ")
                    });
                    dispatch({type: "SET_ROLES", payload: items});
                    dispatch({type: "UPDATE_ROLES_LIST", payload: false});
                } else onError(new Error(error.message));
            }
        })();
    }, [state.updateRolesList]);

    useEffect(() => {
        return () => {
            dispatch({type: "SET_ROLES", payload: {}});
            dispatch({type: "UPDATE_ROLES_LIST", payload: true});
        };
    }, []);

    const Actions = ({id}) => {
        const [isSaveButtonLoading, setIsSaveButtonLoading] = useState(false);
        const [isDeleteButtonLoading, setIsDeleteButtonLoading] = useState(false);

        const onEditClicked = () => dispatch({type: "SET_ROLE_NOT_EDITABLE", id, payload: false});
        const onSaveClicked = async () => {
            setIsSaveButtonLoading(true);

            const {error} = await API.RolesManager.setPermissions(id, state.roles[id].editedPermissions.split(",").map(el => el.trim()), onError);

            if (error.code === 200) dispatch({type: "SET_ROLE_NOT_EDITABLE", id, payload: true});
            else onError(new Error(error.message));

            setIsSaveButtonLoading(false);
        };
        const onDeleteClicked = async () => {
            setIsDeleteButtonLoading(true);

            const {error} = await API.RolesManager.delete(id, onError);

            if (error.code === 200) dispatch({type: "UPDATE_ROLES_LIST", payload: true});
            else onError(new Error(error.message));

            setIsDeleteButtonLoading(false);
        };

        return (
            state.roles[id]?.isBuildIn
                ? <span>System Role</span>
                :
                <div className="d-flex flex-wrap">
                    <StyledButton title={<>Edit <i className="ml-2 fas fa-edit"/></>} type="warning" size="sm"
                                  onClick={onEditClicked}/>
                    <div className="ml-3">
                        <StyledButton title={<>Save <i className="ml-2 fas fa-save"/></>} type="success" size="sm"
                                      onClick={onSaveClicked} disabled={isSaveButtonLoading}/>
                    </div>
                    <div className="ml-3">
                        <StyledButton id="add-role-button" title={<>Delete <i className="ml-2 fas fa-trash-alt"/></>}
                                      type="danger" size="sm" onClick={onDeleteClicked}
                                      disabled={isDeleteButtonLoading}/>
                    </div>
                </div>
        );
    };

    return (
        <div className="content-wrapper">

            <ContentHeader title="Role manager" breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Role manager",
                    link: `/roles`,
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    <AddRole onError={onError}/>

                    <div id="table-container">
                        <SimpleCustomTable data={Object.values(state.roles)}
                                           settings={{rowHeight: Utils.RemToPx(4)}}
                                           columns={[
                                               {
                                                   name: "id",
                                                   header: "ID",
                                                   cell: rowData => rowData.id
                                               },
                                               {
                                                   name: "roleName",
                                                   header: "Role name",
                                                   cell: rowData => rowData.roleName
                                               },
                                               {
                                                   name: "targetType",
                                                   header: "Target type",
                                                   cell: rowData => <i>{rowData.targetType}</i>
                                               },
                                               {
                                                   name: "permissions",
                                                   header: "Permissions",
                                                   cell: rowData => {
                                                       const onValueChanged = e => {
                                                           e.preventDefault();
                                                           dispatch({
                                                               type: "SET_PERMISSIONS",
                                                               id: rowData.id,
                                                               payload: e.target.value
                                                           });
                                                           if (e.relatedTarget?.id === "add-role-button") e.relatedTarget.click();
                                                       };

                                                       if (!state.roles[rowData.id].hasOwnProperty("disabled"))
                                                            return state.roles[rowData.id].permissions.join(", ");
                                                       else {
                                                           if (state.roles[rowData.id].disabled)
                                                               return state.roles[rowData.id].editedPermissions;
                                                           else return (
                                                               <textarea className="w-100 p-2" onBlur={onValueChanged}
                                                                         defaultValue={state.roles[rowData.id].editedPermissions}/>
                                                           );
                                                       }
                                                   }
                                               },
                                               {
                                                   name: "actions",
                                                   header: "Actions",
                                                   cell: rowData => <Actions id={rowData.id}/>
                                               },
                                           ]}/>
                    </div>
                </div>
            </section>
        </div>
    );
}