// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {TicketsStore} from "../../stores/TicketsStore";

// MUI
import Image from 'material-ui-image';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box
} from "@material-ui/core";

// Components
import {StyledInput, StyledTimeInput} from "../../components/styled/StyledInput";
import {StyledButton} from "../../components/styled/StyledButton";
import {imageContainerStyles, imageStyles} from "./style";
import {createResource} from "../../api/Requests";
import Select from "react-select";
import {customStyles} from "../../styles/dropdown";

export default function CreateNewTicket({onError}) {
  const {state, dispatch} = useContext(TicketsStore);

  // Base state
  const [createNewTicketBtnDisabled, setCreateNewEventBtnDisabled] = useState(false);

  const [createBtnLoading, setCreateBtnLoading] = useState(false);
  const [createBtnDisabled, setCreateBtnDisabled] = useState(true);

  const [updateBtnLoading, setUpdateBtnLoading] = useState(false);
  const [updateBtnDisabled, setUpdateBtnDisabled] = useState(false);

  const {list: ticketTypeList, value: ticketTypeValue} = state?.ticketTypeOptions;

  // New event state
  const [eventName, setEventName] = useState("");
  const [ticketName, setTicketName] = useState("");
  const [ticketType, setTicketType] = useState(ticketTypeValue);
  const [ticketPrice, setTicketPrice] = useState("");
  const [ticketDiscount, setTicketDiscount] = useState("");
  const [ticketDescription, setTicketDescription] = useState("");
  const [eventStartDate, setEventStartDate] = useState(Utils.getTime());
  const [eventEndDate, setEventEndDate] = useState(Utils.getTime());

  const [thumbnail, setThumbnail] = useState("null");
  const [uploadedFile, setUploadedFile] = useState("");
  const [saveFileBtnDisabled, setSaveFileBtnDisabled] = useState(false);

  // Main functions
  const open = () => dispatch({type: "SET_IS_OPENED", payload: true});
  const close = () => dispatch({type: "SET_IS_OPENED", payload: false});

  const cancel = () => {
    dispatch({type: "SET_EDIT_MODE", payload: false});
    dispatch({type: "SET_EDITABLE_EVENT", payload: null});

    setEventName("");
    setTicketName("");
    setTicketType("");
    setTicketPrice("");
    setTicketDiscount("");
    setTicketDescription("");

    setEventStartDate(Utils.getTime());
    setEventEndDate(Utils.getTime());

    setThumbnail("null");
    setUploadedFile("");
  };

  const setValue = value => {
    setTicketType(value);
  };

  const uploadThumbnail = () => Utils.uploadFileInput("image/*", file => {
    setSaveFileBtnDisabled(true);
    setUploadedFile(file);
    setThumbnail(URL.createObjectURL(file));
    setSaveFileBtnDisabled(false);
  });

  const createNewSocialEventTicket = async () => {
    setCreateBtnDisabled(true);

    const resourceID = await createResource(uploadedFile, Utils.getNetwork(), onError);
    const data = {
      ticket: {
        eventName,
        eventStartDate: Utils.dateToISO(new Date(eventStartDate)),
        eventEndDate: Utils.dateToISO(new Date(eventEndDate)),
        ticketName,
        ticketType: ticketType || 'single',
        ticketDescription,
        price: parseFloat(parseFloat(ticketPrice).toFixed(2)),
        ticketDiscount,

        thumbnailId: resourceID || '',
        grants: [
          {grantType: "userRole", grantTarget: `conferenceAttendee:/${Utils.getNetwork()}`}
        ]
      }
    };
    const result = await API.SocialEvents.createSocialEventTicket(data, onError);

    if (result?.error?.code === 200) dispatch({type: "ADD_TO_LIST", payload: result?.data?.ticket});
    else onError(new Error(result?.error?.message));

    setCreateBtnDisabled(false);
    close();
  };

  const updateSocialEventTicket = async () => {
    setUpdateBtnDisabled(true);
    setUpdateBtnLoading(true);

    const resourceID = uploadedFile?.length === 0
      ? state.editableTicket?.thumbnailId
      : await createResource(uploadedFile, Utils.getNetwork(), onError);

    const result = await API.SocialEvents.updateSocialEventTicket({
      ticket: {
        ...state.editableTicket,
        eventName,
        eventStartDate: Utils.dateToISO(new Date(eventStartDate)),
        eventEndDate: Utils.dateToISO(new Date(eventEndDate)),
        ticketName,
        ticketType: ticketType || 'single',
        ticketDescription,
        price: parseFloat(parseFloat(ticketPrice).toFixed(2)),
        ticketDiscount,

        thumbnailId: resourceID || '',
        grants: [
          {grantType: "userRole", grantTarget: `conferenceAttendee:/${Utils.getNetwork()}`}
        ]
      }
    }, onError);

    if (result?.error?.code === 200) dispatch({type: "EDIT_ITEM", productId: state.editableTicket?.productId, payload: result?.data?.ticket});
    else onError(new Error(result?.error?.message));

    setUpdateBtnLoading(false);
    setUpdateBtnDisabled(false);

    close();
    cancel();
  };

  // Effects
  useEffect(() => {
    setCreateBtnDisabled(!(eventName?.length > 0 && ticketName?.length > 0 && ticketPrice?.length > 0
      && ticketDescription?.length > 0 && eventStartDate?.length > 0 && eventEndDate?.length > 0 && uploadedFile));
    setCreateBtnLoading(!!(eventName?.length > 0 && ticketName?.length > 0 && ticketPrice?.length > 0
      && ticketDescription?.length > 0 && eventStartDate?.length > 0 && eventEndDate?.length > 0 && uploadedFile));
  }, [eventName, ticketName, ticketDescription, ticketPrice, eventStartDate, eventEndDate, uploadedFile]);

  useEffect(() => {
    if (state.editMode) {
      setEventName(state.editableTicket?.eventName);
      setTicketName(state.editableTicket?.ticketName);
      setTicketType(state.editableTicket?.ticketType || 'single');
      setTicketPrice(state.editableTicket?.price);
      setTicketDiscount(state.editableTicket?.ticketDiscount);
      setTicketDescription(state.editableTicket?.ticketDescription);

      setEventStartDate(state.editableTicket?.eventStartDate);
      setEventEndDate(state.editableTicket?.eventEndDate);

      setThumbnail(Utils.buildURLs(`/resource/thumbnail/256/${state.editableTicket?.thumbnailId}`, false, null, {networkId: Utils.getNetwork()}));
    }
  }, [state.editMode]);

  return (
    <div>
      <StyledButton onClick={open} disabled={createNewTicketBtnDisabled} title="Create new ticket" type="success"
                    size="sm"/>

      <Dialog open={state.isOpened} onClose={close} fullWidth={true} maxWidth="md" disableBackdropClick={true}
              disableEscapeKeyDown={true}>

        <DialogTitle id="form-dialog-title">{state.editMode
          ? `Update ticket: ${state.editableTicket?.ticketTitle}`
          : 'Create new ticket'
        }</DialogTitle>

        <DialogContent>
          <div className="row align-items-stretch w-100" style={{minHeight: "30vh"}}>
            <div className="col-5">
              <div className="row px-3 h-100 flex-column">
                <Image imageStyle={imageStyles} style={imageContainerStyles} src={thumbnail}/>
                <StyledButton fullWidth onClick={uploadThumbnail} disabled={saveFileBtnDisabled}
                              title="Upload thumbnail" type="primary" size="sm"
                              style={{borderTopLeftRadius: 0, borderTopRightRadius: 0}}/>
              </div>
            </div>
            <div className="col-7">
              <div className="row">
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Event Name</b></span>
                <StyledInput fullWidth placeholder="Event Name" value={eventName}
                             onChange={e => setEventName(e.target.value)}/>
              </div>

              <div className="row">
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Ticket Name</b></span>
                <StyledInput fullWidth placeholder="Ticket Name" value={ticketName}
                             onChange={e => setTicketName(e.target.value)}/>
              </div>

              <div className="row" style={{marginBottom: '1rem'}}>
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Ticket Type</b></span>
                <Select
                  className="col-12"
                  onChange={selected => setValue(selected.value.toLowerCase())}
                  value={{label: ticketType, value: ticketType}}
                  options={ticketTypeList.map($element => ({label: $element, value: $element}))}
                  styles={customStyles(true)}
                />
              </div>

              <div className="row">
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Ticket Description</b></span>
                <StyledInput fullWidth placeholder="Ticket Description"
                             value={ticketDescription}
                             onChange={e => setTicketDescription(e.target.value)} isTextarea={true}/>
              </div>
              <div className="row">
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Ticket Price</b></span>
                <StyledInput fullWidth placeholder="Ticket Price" value={ticketPrice} style={{width: '8rem'}}
                             onChange={e => {
                               setTicketPrice(e.target.value)
                             }}/>
              </div>
              <div className="row">
                <span className="col-2" style={{whiteSpace: "nowrap"}}><b>Ticket Discount</b></span>
                <StyledInput fullWidth placeholder="Ticket Discount" value={ticketDiscount} style={{width: '8rem'}}
                             onChange={e => {
                               setTicketDiscount(e.target.value)
                             }}/>
              </div>

              <div className="d-flex" style={{marginLeft: '0.5rem'}}>
                <div className="mr-5">
                  <StyledTimeInput title="Event start date: " value={eventStartDate?.slice(0, 16)} column
                                   fullWidth onChange={e => setEventStartDate(e.target.value)}/>
                </div>
                <div className="mr-5">
                  <StyledTimeInput title="Event end date: " value={eventEndDate?.slice(0, 16)} column fullWidth
                                   onChange={e => setEventEndDate(e.target.value)}/>
                </div>
              </div>

            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" width="100%">
            <div className="mr-3">
              <StyledButton onClick={() => {
                close();
                cancel();
              }} type="default" noLoading={true} title="Cancel" size="lg" disabled={(!(!updateBtnDisabled || !createBtnDisabled))}/>
            </div>
            {state.editMode
              ? <StyledButton onClick={updateSocialEventTicket} type="warning" title="Update" size="lg"
                              noLoading={!updateBtnLoading} disabled={updateBtnDisabled}/>
              : <StyledButton onClick={createNewSocialEventTicket} type="success" title="Create" size="lg"
                              noLoading={!createBtnLoading} disabled={createBtnDisabled}/>
            }
          </Box>
        </DialogActions>
      </Dialog>
    </div>
  );
}
