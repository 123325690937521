import React, {useContext, useEffect, useState} from "react";
import Cookies from 'js-cookie';
import Utils from "../../scripts/Utils";
import {API} from "../../api/API";

// Stores
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {NetworksStore} from "../../stores/NetworksStore";

// Styles
import 'react-perfect-scrollbar/dist/css/styles.css';
import "../../plugins/FontAwesome/css/all.min.css";
import "../../plugins/AdminLTE3/adminlte.min.css";

// Components
import "../../plugins/AdminLTE3/NavbarAndSidebar";
import Navbar from "./components/Navbar";
import {Sidebar} from "./components/Sidebar";
import Footer from "./components/Footer";
import MessageDialog from "../../components/MessageDialog";
import Loading from "../../components/Loading";

export default function Template(props) {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);
    const {state: networkState} = useContext(NetworksStore);

    // Base state
    const [modelRequestLoading, setModelRequestLoading] = useState("loading");

    // Main functions
    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };
    const getUserModel = async network => {
        const result = (await API.Users.getModel(Cookies.get("userId"), onError, network));

        if (result?.error.code === 200) {
            dispatch({
                type: "SET_USER_MODEL",
                key: "model",
                payload: result?.data?.user
            });
            dispatch({type: "SET_MY_ROLES", payload: result?.data?.roles});
        }
        else if (result?.error.code === 1021) {
            console.log("There no such user in network");
        }
    };

    // Effects
    useEffect(() => {
        const roomful = Cookies.get('roomful');
        const sessionId = Cookies.get('sessionId');

        const isSessionIdCorrect = !!(sessionId && typeof sessionId === "string");
        const isRoomfulCorrect = !!(roomful && typeof roomful === "string");

        if (!isRoomfulCorrect) window.location.href = "/auth";
        else {
            if (!isSessionIdCorrect) {
                console.log("#257", roomful, sessionId);
                Cookies.set("sessionId", roomful);
            }
        }

        document.body?.classList?.add("sidebar-mini");

        window.addEventListener("click", () => {
            const notifications = document.querySelector("#notifications");
            if (notifications && notifications.classList.contains("show")) notifications.classList.remove("show");
        });
    }, []);

    useEffect(() => {
        (async () => {
            if (Utils.isObjectEmpty(state.model) && Cookies.get("userId") && Cookies.get("userId")?.length > 0) {
                setModelRequestLoading("loading");

                await getUserModel(networkState?.openedNetwork?.model?.id);

                setModelRequestLoading("loaded");
            }
            else if (modelRequestLoading === "loading") setModelRequestLoading("loaded");
        })();
    }, [state.model]);
    useEffect(() => {
        (async () => {
            if (state.updatingModel) {
                setModelRequestLoading("loading");

                await getUserModel(networkState?.openedNetwork?.model?.id);
                dispatch({type: "SET_UPDATING_MODEL", payload: false});

                setModelRequestLoading("loaded");
            }
        })();
    }, [state.updatingModel]);

    return (
        <div className="wrapper">
            <Navbar loading={modelRequestLoading} onError={onError}/>

            <Sidebar/>

            {modelRequestLoading === "loading" ? <Loading/> : props.children}

            <Footer/>

            <MessageDialog/>
        </div>
    );
}
