// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext} from "react";
import Utils from "../../../scripts/Utils";
import {NetworksStore} from "../../../stores/NetworksStore";

// Components
import {StyledDownloadButton} from "../../../components/styled/StyledButton";

export const NetworkGeneratePluginSettings = props => {
    const {onError} = props;
    const {state} = useContext(NetworksStore);

    return (
        <>
            <h4 className="my-4">Generate plugin settings</h4>
            <div>
                <div>
                    <StyledDownloadButton
                            href={Utils.buildURLs(`/files/generateNetworkPluginSettings/${state?.openedNetwork?.model?.id}.json`)}
                            title="Generate plugin settings" type="success" download={`${state?.openedNetwork?.model?.id}.json`}/>
                </div>

                <p className="mt-3 text-muted">Generate plugin settings JSON for current network</p>

                <hr/>
            </div>
        </>
    );
}
