// Copyright © 2015-2022 Roomful Co. All rights reserved

import {customFetch} from "../Requests";

export class PropsAPI {
    static async changePropContent(body, onError) {
        return await customFetch({path: "/jsonRpc/prop.changePropContent", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateResourceAttributes(body, onError) {
        return await customFetch({path: "/jsonRpc/resource.updateResourceAttributes", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getPropContent(body, onError) {
        return await customFetch({path: "/jsonRpc/prop.getPropContent", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async changeResourcePosition(body, onError) {
        return await customFetch({path: "/jsonRpc/prop.changeResourcePosition", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getModel(body, onError) {
        return await customFetch({path: "/jsonRpc/prop.getPropModel", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async getPropInvitations(body, onError) {
        return await customFetch({path: "/jsonRpc/room.getPropInvitations", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async inviteToProp(body, onError) {
        return await customFetch({path: "/jsonRpc/room.inviteToProp", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async deletePropInvitation(body, onError) {
        return await customFetch({path: "/jsonRpc/room.deletePropInvitation", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

    static async updateProp(body, onError) {
        return await customFetch({path: "/jsonRpc/prop.updateProp", body})
            .then(resultData => resultData.json())
            .catch(onError);
    }

}