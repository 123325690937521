// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, { useCallback, useContext, useEffect, useState } from "react";
import Cookies from 'js-cookie';
import Socket from "../../api/Socket";
import Utils from "../../scripts/Utils";
import { Sounds } from "../../scripts/Audio";

// Stores & providers
import { Store } from "../../stores/MainStore";
import { ChatsStore } from "../../stores/ChatsStore";
import { UsersStore } from "../../stores/UsersStore";

export const EventsWrapper = ({children}) => {
    const {state: mainState, dispatch: mainDispatch} = useContext(Store);
    const {state: userState} = useContext(UsersStore);
    const {state, dispatch} = useContext(ChatsStore);

    const onBlur = () => {
        mainDispatch({type: "SET_IS_FOCUSED", payload: false});
        // console.log("blurred");
    }
    const onFocus = useCallback(async() => {
        mainDispatch({type: "SET_IS_FOCUSED", payload: true});
        // console.log("focused");

        if(state.connection && state.connection?.instance?.connected && !Utils.isObjectEmpty(state.currentTextchat) && mainState.lastReceivedMessageTime !== 0) {
            const {error} = (await state.connection.sendSocketRequest("textchat:markTextchatAsRead", {data: {textchatId: state.currentTextchat?.id}}));
            if(error?.code !== 200) console.warn("markTextchatAsRead", error);

            mainDispatch({type: "SET_LAST_RECEIVED_MESSAGE_TIME", payload: 0});
            dispatch({
                type:    "SET_CURRENT_INTERLOCUTOR_LAST_READ_MESSAGES_TIME",
                payload: mainState.lastReceivedMessageTime
            });
            dispatch({type: "CLEAR_UNREAD_MESSAGE_COUNT", payload: state.currentTextchat?.id});
            dispatch({type: "SET_RERENDER_NOTIFICATIONS", payload: true});
        }
    }, [state.connection, state.currentTextchat?.id, state.currentTextchat, state.currentTextchat?.lastMessageTime, state.currentTextchat?.lastReadMessageTime, mainState.lastReceivedMessageTime]);

    const onMessage = useCallback(async ({data, error}) => {
        if (error?.code === 200) {
            const {textchatId, message} = data;

            dispatch({type: "SET_INTERLOCUTOR_LAST_MESSAGE_TIME", id: textchatId, payload: message?.created});

            const isCurrentTextchat = state.currentTextchat?.id === textchatId;
            const isMessageFromUserHimself = userState.model?.id === message?.userId;

            switch(true) {
                case isMessageFromUserHimself && isCurrentTextchat:
                    dispatch({type: "ADD_SINGLE_MESSAGE", payload: message});
                    break;

                case isMessageFromUserHimself:
                    break;

                case isCurrentTextchat && mainState?.isFocused:
                    dispatch({type: "ADD_SINGLE_MESSAGE", payload: message});

                    const {error} = (await state.connection.sendSocketRequest("textchat:markTextchatAsRead", {data: {textchatId: state.currentTextchat?.id}}));
                    if(error?.code !== 200) console.warn("markTextchatAsRead", error);

                    dispatch({type: "SET_CURRENT_INTERLOCUTOR_LAST_READ_MESSAGES_TIME", payload: message?.created});
                    break;

                case isCurrentTextchat:
                    dispatch({type: "ADD_SINGLE_MESSAGE", payload: message});
                    mainDispatch({type: "SET_LAST_RECEIVED_MESSAGE_TIME", payload: message?.created});
                    dispatch({type: "SET_RERENDER_NOTIFICATIONS", payload: true});
                    dispatch({type: "ADD_UNREAD_MESSAGE_COUNT", payload: textchatId});
                    await Sounds.play("notification");
                    break;

                default:
                    dispatch({type: "SET_RERENDER_NOTIFICATIONS", payload: true});
                    dispatch({type: "ADD_UNREAD_MESSAGE_COUNT", payload: textchatId});
                    await Sounds.play("notification");
            }
        }
    }, [state.currentTextchat?.id, state.connection, mainState?.isFocused, userState.model?.id]);

    const onMessagesRead = useCallback(({data, error}) => {
        if (error?.code === 200) {
            const {textchatId, userId, lastReadMessageTime} = data;

            console.log("Message read: ", lastReadMessageTime);
            dispatch({type: "SET_CURRENT_INTERLOCUTOR_LAST_READ_MESSAGES_TIME", payload: lastReadMessageTime});
        }
    }, [state.currentInterlocutor]);

    useEffect(() => {
        const registerEvents = () => {
            state.connection?.addEventListener("textchat:onMessage", onMessage);
            state.connection?.addEventListener("textchat:onMessagesRead", onMessagesRead);

            window.addEventListener("blur", onBlur);
            window.addEventListener("focus", onFocus);
        };

        if (!state.connection && !state.connection?.instance?.connected) {
            const connection = new Socket();
            dispatch({type: "SET_CONNECTION", payload: connection});

            registerEvents();
        }
        else registerEvents();

        return () => {
            state.connection?.removeEventListener("textchat:onMessage", onMessage);
            state.connection?.removeEventListener("textchat:onMessagesRead", onMessagesRead);

            window.removeEventListener("blur", onBlur);
            window.removeEventListener("focus", onFocus);
        };
    }, [state.connection, onMessage, onMessagesRead]);

    return children;
};