// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {RoomsStore} from "../../../stores/RoomsStore";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";
import {FeedbackTable} from "../../../components/table/FeedbackTable";

export const RoomBoothsMembers = props => {
    const {onError} = props;
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(RoomsStore);

    // Base state
    const [updateBoothMembers, setUpdateBoothMembers] = useState(null);
    const [isUpdateBoothBtnLoading, setIsUpdateBoothBtnLoading] = useState(false);

    // Main functions
    const uploadFile = () => Utils.uploadFileInput(null, file => {
        setIsUpdateBoothBtnLoading(true);
        setUpdateBoothMembers(file);
    });
    const getColumns = () => ([
        {
            name: "updatedElement",
            header: "Updated element",
            cell: rowData => rowData?.updatedElement
        },
        {
            name: "boothId",
            header: "Booth ID",
            cell: rowData => rowData?.boothId
        },
        {
            name: "status",
            header: "Status",
            cell: rowData => rowData?.status
        }
    ]);
    const getData = useCallback(() => state?.feedbackTableItems?.members, [state?.feedbackTableItems?.members]);

    // Callbacks
    const getItems = useCallback(() => state?.parsedBooths[0]?.members, [state?.parsedBooths]);
    const getResult = useCallback(async memberEmail => {
        const result = await API.Rooms.updateBoothMember({
            roomId: state.model?.id,
            propId: state?.parsedBooths[0]?.id,
            email: memberEmail
        }, onError);

        return {
            ...(memberEmail && memberEmail?.length > 0 && result?.error?.code === 200 ? {error: {code: 200}} : {error: result?.error}),
            feedback: {
                updatedElement: `member : ${memberEmail}`,
                boothId: state?.parsedBooths[0]?.id,
                status: result?.error?.code === 200 ? "success" : result?.error?.message
            }
        };
    }, [state?.parsedBooths, state.model?.id]);
    const onFinish = useCallback(() => {
        setIsUpdateBoothBtnLoading(false);
        setUpdateBoothMembers(null);
        dispatch({type: "SET_MEMBERS_FEEDBACK_NEED_RENDER", payload: false});
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: `All members of booth ${state?.parsedBooths[0]?.id} updated`,
                text: "See results in the table below.",
                color: "success"
            }
        });
    }, [state?.parsedBooths]);
    const addItemToFeedbackTable = item => {
        dispatch({type: "SET_MEMBERS_FEEDBACK_TABLE_ITEMS", payload: item});
    };

    // Effects
    useEffect(() => {
        (async () => {
            if (updateBoothMembers && updateBoothMembers instanceof File) {
                try {
                    const result = await API.Rooms.parseBoothMembers({
                        file: updateBoothMembers,
                        roomId: state.model?.id
                    }, onError);

                    dispatch({
                        type: "SET_PARSED_BOOTHS",
                        payload: result?.booths
                    });

                    dispatch({type: "SET_SHOW_MEMBERS_FEEDBACK", payload: true});

                    if (state?.feedbackTableItems?.members?.length > 0) {
                        dispatch({type: "RESET_MEMBERS_FEEDBACK_TABLE_ITEMS"});
                        dispatch({type: "SET_MEMBERS_FEEDBACK_NEED_RENDER", payload: true});
                    }
                }
                catch (e) {
                    onError(e);
                }
            }
        })();
    }, [updateBoothMembers]);

    return (
        <>
            <h4 className="my-4">Booths members</h4>
            <div>
                <div className="mb-3">
                    <StyledButton onClick={uploadFile} disabled={isUpdateBoothBtnLoading}
                                  title="Update booths members" type="warning"/>
                </div>

                <FeedbackTable show={state?.feedbackTableItems?.showMembersFeedback} settings={{needSubLoop: false}}
                           callbacks={{onError, getColumns, getData, getItems, getResult, addItemToFeedbackTable, onFinish}}
                           texts={{title: "Booth members feedback table", progressBarTitles: ["Updated members : "]}}
                />

                <hr/>
            </div>
        </>
    );
}
