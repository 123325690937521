// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {Store} from "../../stores/MainStore";
import {RoomsStore} from "../../stores/RoomsStore";
import {PropsStore} from "../../stores/PropsStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import {StyledButton} from "../../components/styled/StyledButton";
import { StyledImage } from "../../components/styled/StyledImage";
import {CircleTabPanel, ThemedCircleTab, ThemedCircleTabs} from "../../components/styled/StyledTabs";
import CustomTable from "../../components/table/CustomTable";
import Filters from "../CMS/components/Filters";

let customBody = {
    asPropManager: true,
    propTypes: ["booth", "fileCabinet"],
    roomId: ""
};

export default function TeamMemberPropsList () {
    const navigate = useNavigate();

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(PropsStore);
    const {dispatch: roomsDispatch} = useContext(RoomsStore);
    const {state: usersState} = useContext(UsersStore);

    const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(usersState.myRoles);
    const isRoomAdmin = Utils.isRoomAdmin(usersState.myRoles);

    const [needReRender, setNeedReRender] = useState(false);

    const [tabValue, setTabValue] = useState(1);
    const onTabChange = (event, newValue) => setTabValue(newValue);

    const onError = error => {
        mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Error!",
                text: error.message,
                color: "default"
            }
        });
    };

    // Functions
    const formatItems = list => list.map(({assetId, id, title, roomId}) => ({assetId, id, roomId, title}));
    const onFiltersChanged = filters => {
        customBody = filters;
        setNeedReRender(true);
    };

    // Callbacks function
    const getColumns = () => ([
        {
            name: 'avatar',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
            cell: rowData =>
                rowData?.assetId && rowData?.assetId?.length > 0
                    ? <Link to={`/room/${rowData?.roomId}/prop/${rowData?.id}`}>
                        <StyledImage src={Utils.buildURLs(`/asset/thumbnail/${rowData?.assetId}`,
                            false, null, {networkId: Utils.getNetwork()})} size={5}/>
                    </Link> : null,
            width: Utils.RemToPx(9)
        },
        {
            name: 'prop_name',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Prop name</span>,
            cell: rowData => (
                <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData.title}
                </span>
            )
        },
        {
            name: 'id',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <Link to={`/room/${rowData?.roomId}/prop/${rowData?.id}`} className="underline-link">
                        <span>{rowData?.id}</span>
                    </Link>
                </div>
            )
        },
        {
            name: 'roomId',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Room Id</span>,
            cell: rowData => (
                <div className="d-flex flex-column align-items-center">
                    <Link to={`/room/${rowData?.roomId}`} className="underline-link">
                        <span>{rowData?.roomId}</span>
                    </Link>
                </div>
            )
        },
        {
            name: 'actions',
            header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
            cell: rowData => (
                <div className="d-flex flex-wrap align-items-center">
                    <StyledButton href={`/cms/room/${rowData?.roomId}/prop/${rowData?.id}`}
                                  title={<i className="fas fa-images"/>} type="warning" tooltip="Open prop content"
                                  className="btn btn-sm btn-warning h-100 mr-2"/>
                    <StyledButton href={`/room/${rowData?.roomId}/prop/${rowData?.id}`}
                                  title={<i className="fas fa-pencil-alt"/>} type="success" tooltip="Open prop page"
                                  className="btn btn-sm bg-success h-100 mr-2"/>
                </div>
            )
        }
    ]);
    const getList = useCallback(() => {
        return state.list;
    }, [state.list]);
    const getListItems = useCallback(async ({offset, size, text}, onError) => {
        const {props, total} = (await API.Rooms.getRoomAllPropsList({offset, size, ...customBody}, onError)).data;

        dispatch({type: "SET_LIST", payload: formatItems(props)});
        dispatch({type: "SET_TOTAL_COUNT", payload: props?.length});

        return total ?? 0;
    }, [customBody]);
    const clearList = () => dispatch({type: "CLEAR_LIST"});

    useEffect(() => {
        return () => {
            clearList()
            roomsDispatch({type: "CLEAR_LIST"});
            roomsDispatch({type: "CLEAR_USER_AVAILABLE_LIST"});
        };
    }, []);

    useEffect(() => {
        if (tabValue === 0) navigate("/props/list");
    }, [tabValue]);

    return (
        <div className="content-wrapper">
            <section className="content">
                <div className="container-fluid pb-3">
                    <div className="card card-tabs" style={{background: "transparent", boxShadow: "none"}}>
                        <div className="card-header p-0 pt-1 d-flex custom justify-content-between"
                             style={{borderTop: "none"}} id="table-header">
                            <ThemedCircleTabs value={tabValue} onChange={onTabChange}>
                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin) &&
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={0}
                                                  label="All props"/>}
                                <ThemedCircleTab activeTab={tabValue} setActiveTab={setTabValue} tabIndex={1} label="My props"/>
                            </ThemedCircleTabs>
                        </div>

                        <div className="card-body px-0" id="table-container"
                             style={{borderTop: "1px solid rgb(222, 226, 230)"}}>
                            <div className="tab-content">
                                <CircleTabPanel value={tabValue} index={1}>
                                    <div className="container-fluid">
                                        <div id="commands" className="card p-3">
                                            <div className="d-flex align-items-center mb-3">
                                                <Filters onFiltersChanged={onFiltersChanged} hideSelect
                                                settings={{defaultAsPropManager: customBody?.asPropManager, fullWidth: true}}/>
                                            </div>
                                        </div>

                                        <div id="table-container" className="mb-3"
                                             style={{width: `calc(100% - ${Utils.RemToPx(1)}px)`}}>
                                            <CustomTable state={{
                                                canSelect: false, canHover: false, canSearch: false,
                                                reRender: needReRender,
                                                setReRender: value => setNeedReRender(value)
                                            }} callbacks={{
                                                getList,
                                                getColumns,
                                                getListItems,
                                                clearLists: clearList,
                                                onError
                                            }}/>
                                        </div>
                                    </div>
                                </CircleTabPanel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
