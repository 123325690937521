// Copyright © 2015-2022 Roomful Co. All rights reserved

import React from "react";
import { Link } from "react-router-dom";
import { withStyles } from "@material-ui/styles";
import { Tabs } from "@material-ui/core";

// Basic tabs
export const TabPanel = props => {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} className={`tab-pane ${value === index ? "active" : ""}`}
             aria-labelledby={`scrollable-force-tab-${index}`}{...other}>
            {value === index && children}
        </div>
    );
}

export const ThemedTabs = withStyles(theme => ({
    indicator: {
        display: "none"
    }
}))(props => <Tabs component="ul" className="nav nav-pills" {...props}/>);

export const ThemedTab = props => {
    const {activeTab, setActiveTab, label, tabIndex} = props;
    const active = activeTab === tabIndex;

    return (
        <li onClick={() => setActiveTab(tabIndex)} className="nav-item">
            <a className={`nav-link ${active ? "active" : ""}`} data-toggle="tab">{label}</a>
        </li>
    );
};



// Circle tabs
export const CircleTabPanel = props => {
    const {children, value, index, ...other} = props;

    return (
        <div role="tabpanel" hidden={value !== index} className={`tab-pane ${value === index ? "active" : ""}`}
            aria-labelledby={`scrollable-force-tab-${index}`}{...other}>
            {value === index && children}
        </div>
    );
}

export const ThemedCircleTabs = withStyles(theme => ({
    root: {width: "100%", borderBottom: 0},
    indicator: {display: "none"},
    flexContainer: {height: "100%"}
}))(props => <Tabs component="ul" className="nav nav-tabs" {...props} role="tablist" />);

export const ThemedCircleTab = props => {
    const {activeTab, setActiveTab, label, tabIndex, href = ""} = props;
    const active = activeTab === tabIndex;

    const isLink = href !== "";
    const className = `h-100 nav-link ${active ? "active" : ""}`;
    const style = {fontSize: "1.6rem", borderColor: active ? "#dee2e6 #dee2e6 #fff #dee2e6" : "transparent"}

    return (
        <li onClick={() => setActiveTab(tabIndex)} className="nav-item h-100">
            {isLink
                ? <Link to={href} className={className} style={style} data-toggle="tab">{label}</Link>
                : <a className={className} style={style} data-toggle="tab">{label}</a>
            }
        </li>
    );
};

