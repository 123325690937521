// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useRef, useState} from "react";
import {ChatsStore} from "../../../stores/ChatsStore";
import Utils from "../../../scripts/Utils";
import {useStyles} from "../style";

export const ChatSendMessage = () => {
    const {state, dispatch} = useContext(ChatsStore);
    const classes = useStyles();

    const [messageText, setMessageText] = useState("");
    const textarea = useRef(null);

    const submit = async () => {
        if (messageText?.trim()?.length > 0) {
            const {message, error} = (await state.connection.sendSocketRequest("textchat:sendNewMessage",
                {data: {messageType: 1, message: messageText, textchatId: state.currentTextchat.id}})).data;

            dispatch({type: "ADD_SINGLE_MESSAGE", payload: message});
            dispatch({
                type:    "SET_INTERLOCUTOR_LAST_MESSAGE_TIME",
                id:      state.currentTextchat.id,
                payload: message?.updated
            });
            setMessageText("");

            const result = state.list.find(el => el.textchat.id === state.currentTextchat.id);

            if (!result) dispatch({type: "SET_NEED_RE_RENDER_INTERLOCUTORS_LIST", payload: true});
            else console.log(result);
        }
    };

    const onKeyUp = e => {if ((e.which || e.keyCode) === 13 && !e.shiftKey) submit()};
    const onKeyDown = e => {if ((e.which || e.keyCode) === 13 && !e.shiftKey) e.preventDefault();}

    useEffect(() => {
        console.log("THAT", state.currentInterlocutor, state.currentTextchat);
    }, []);

    return (
        (!Utils.isObjectEmpty(state.currentInterlocutor ?? {}) || !Utils.isObjectEmpty(state.currentTextchat ?? {})) ?
        (<div className={`card-footer h-100 p-0 d-flex justify-content-center align-items-center ${classes.sendMessage}`}>
            <form action="#" method="post" className="px-2" style={{width: "100%"}}>
                <div className="input-group">
                    <textarea placeholder="Type Message ..." ref={textarea} className="form-control"
                              onKeyUp={onKeyUp} onKeyDown={onKeyDown}
                              onChange={e => setMessageText(e.target.value)} value={messageText} />
                    <span className="input-group-append">
                        <button type="button" className="btn btn-success" onClick={submit}>Send</button>
                    </span>
                </div>
            </form>
        </div>) : ""
    );
}