// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {ChatsStore} from "../../../stores/ChatsStore";
import {useStyles} from "../style";
import Utils from "../../../scripts/Utils";

// Scrollbars
import '../../../plugins/OverlayScrollbars/OverlayScrollbars.min.css';
import 'overlayscrollbars/css/OverlayScrollbars.css';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';

// MUI
import {Loop, Search} from "@material-ui/icons";

// Components
import {Interlocutor, NoInterlocutors} from "./Interlocutor";
import {Filters} from "./Filters";

let timeout = null;

export const InterlocutorsList = () => {
    const {state, dispatch} = useContext(ChatsStore);
    const classes = useStyles();

    const [needRender, setNeedRender] = useState(true);

    const [offset, setOffset] = useState(0);
    const [size] = useState(14);

    const [searchBtnLoading, setSearchBtnLoading] = useState(false);
    const [searchInputText, setSearchInputText] = useState("");
    const [prevSearchInputText, setPrevSearchInputText] = useState("");
    const [loadMoreActive, setLoadMoreActive] = useState(false);

    const onInterlocutorSelected = ({textchat, opponentUser}) => {
        if (state?.currentTextchat?.id !== textchat?.id) {
            dispatch({type: "CLEAR_MESSAGES"});
            dispatch({type: "SET_CURRENT_INTERLOCUTOR", payload: (
                (textchat?.textchatMode === "room" || textchat?.textchatMode === "prop")
                    ? {
                        lastReadMessageTime: textchat?.lastReadMessageTime,
                        user: {
                            id: textchat?.id,
                            avatar: textchat?.thumbnail,
                            companyName: "",
                            companyTitle: "",
                            firstName: textchat?.title,
                            lastName: ""
                        }
                    }
                    : (textchat?.textchatMode === "videochat"
                        ? {
                            lastReadMessageTime: textchat?.lastReadMessageTime,
                            user: {
                                id: textchat?.id,
                                avatar: textchat?.thumbnail,
                                companyName: "",
                                companyTitle: "",
                                firstName: `ID: ${textchat.id}`,
                                lastName: ""
                            }
                        }
                        : opponentUser)
            )});
            dispatch({type: "SET_CURRENT_TEXTCHAT", payload: textchat});
            dispatch({type: "SET_IS_FIRST_LOADING_FOR_CURRENT_CHAT", payload: true});
            dispatch({type: "SET_MESSAGES_OFFSET", payload: 0});

            const list = state.list;
            list.forEach(el => {if (el.textchat.id === textchat?.id) el.textchat.unreadMessageCount = 0;});
            dispatch({type: "SET_LIST", payload: list});
        }
    };

    const onScrollBottom = () => {
        setPrevSearchInputText(searchInputText);
        setOffset(prevOffset => prevOffset + size);

        setSearchBtnLoading(true);
        setLoadMoreActive(true);
        setNeedRender(true);
    };
    const onScrollInitialized = () => {
        const list = document.querySelector(".interlocutors-list .os-viewport");
        list.addEventListener('scroll', event => {
            const element = event.target;
            if (element.scrollHeight - element.scrollTop === element.clientHeight) onScrollBottom();
        });
    };
    const onSearchInputChange = e => {
        clearTimeout(timeout);

        const changeModeToBasic = e.target.value.trim() === "";

        if (changeModeToBasic) {
            // Set default settings and clear lists
            setOffset(0);
            setPrevSearchInputText("");
            setSearchInputText("");
            setSearchBtnLoading(true);
            dispatch({type: "CLEAR_LIST"});
            setNeedRender(true);
        }
        else {
            setSearchInputText(e.target.value);
            timeout = setTimeout(search, 1000);
            setPrevSearchInputText(searchInputText);
        }
    };

    const search = () => {
        setSearchBtnLoading(true);
        setOffset(0);
        setNeedRender(true);
    };
    const setList = (list) => {
        dispatch({type: 'SET_LIST', payload: list});
    };
    const renderUsersList = async (body, list, setList) => {
        let filter = "all";

        if (state.filters.activeElement === 0) filter = "all";
        else if (state.filters.activeElement === 1) filter = "direct";
        else if (state.filters.activeElement === 2) filter = "group";
        else if (state.filters.activeElement === 3) filter = "allWithinRoom";
        else if (state.filters.activeElement === 4) filter = "important";

        const {textchats} = (await (typeof searchInputText === "string" && searchInputText.length > 0
            ? state.connection.sendSocketRequest("textchat:searchTextchats", {data: {
                offset, size, filter, roomId: state.filters.chosenRoom?.id ?? "", query: searchInputText
            }})
            : state.connection.sendSocketRequest("textchat:listTextchats", {data: {
                offset, size, filter, roomId: state.filters.chosenRoom?.id ?? ""
            }})
        )).data;

        const _list = loadMoreActive || (prevSearchInputText === searchInputText && textchats.length <= 0 && state.filters?.chosenRoom?.id?.length <= 0) ? list : [];
        setList([..._list, ...textchats]);
        setNeedRender(false);
        dispatch({type: "SET_FILTERS_NEED_RENDER", payload: false});
        setSearchBtnLoading(false);
        if (loadMoreActive) setLoadMoreActive(false);
    };

    useEffect(() => {
        setOffset(0);
        setNeedRender(true);
    }, [state.filters.activeElement]);
    useEffect(() => {if (state.filters.chosenRoom?.id !== "" && state.filters.isOKButtonPressed) setNeedRender(true);}, [state.filters.chosenRoom?.id, state.filters.isOKButtonPressed]);
    useEffect(() => {
        (async () => {
            if (needRender && state.connection) {
                const body = {offset, size, text: searchInputText};

                await renderUsersList(body, state.list, setList);
            }
        })();
    }, [needRender, state.connection]);
    useEffect(() => {
        (async () => {
            if (state.reRenderInterlocutorsList && state.connection) {
                setOffset(0);
                const body = {offset, size, text: searchInputText};
                await renderUsersList(body, state.list, setList);
                dispatch({type: "SET_NEED_RE_RENDER_INTERLOCUTORS_LIST", payload: false});
            }
        })();
    }, [state.reRenderInterlocutorsList, state.connection]);

    const getActiveFilter = () => {
        if (state.filters.activeElement === 0)
            return <span>all chats</span>;
        else if (state.filters.activeElement === 1)
            return <span>direct chats</span>;
        else if (state.filters.activeElement === 2)
            return <span>group chats</span>;
        else if (state.filters.activeElement === 3)
            return <>
                <span>group chats</span>
                <span>room {Utils.limitStringLength(state.filters.chosenRoom?.id, 15)}</span>
            </>;
        else if (state.filters.activeElement === 4)
            return <span>important chats</span>;
    };

    return (
        <div className={`flex-column h-100 ${classes.leftColumn}`}>
            <div className={`${classes.search} row align-items-center mx-0 px-3`}>
                {<>
                    <Search className="mr-3"/>
                    <input autoComplete="off" type="text" placeholder="Search..." onChange={onSearchInputChange} disabled={false}/>

                    <div style={{width: "2rem"}}>{searchBtnLoading && <Loop/>}</div>

                    <div className={classes.filter}><Filters /></div>
                </>}
            </div>
            <div className={`${classes.activeFilters} d-flex align-items-center position-relative mx-0 px-3`}>
                {getActiveFilter()}
            </div>
            <div className="direct-chat-contacts-open">
                <div className="card-body p-0 h-100">
                    <div className={`direct-chat-contacts ${classes.contacts}`}>
                        {
                            state.list.length > 0
                                ? <OverlayScrollbarsComponent style={{maxHeight: "100%",}} options={{
                                        className: `os-theme-dark interlocutors-list`,
                                        resize: 'none',
                                        paddingAbsolute: true,
                                        scrollbars: {autoHide: 'move'},
                                        overflowBehavior: {x: "h"},
                                        callbacks: {onInitialized: onScrollInitialized}
                                    }}>
                                        <ul className="contacts-list">
                                            {state.list.map(contact => {
                                                // console.log(contact);
                                                return <Interlocutor key={contact?.textchat?.id} data={contact} onInterlocutorSelected={onInterlocutorSelected}/>
                                            })}
                                        </ul>
                                    </OverlayScrollbarsComponent>
                                : <NoInterlocutors/>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}
