// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import {API} from "../../api/API";
import Utils from "../../scripts/Utils";
import {initialState, Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";
import {TicketsStore} from "../../stores/TicketsStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {StyledButton} from "../../components/styled/StyledButton";
import CustomTable from "../../components/table/CustomTable";
import {StyledImage} from "../../components/styled/StyledImage";
import CreateNewTicket from "./CreateNewTicket";

export default function SocialEvents() {
  const {dispatch: mainDispatch} = useContext(Store);
  const {state: usersState} = useContext(UsersStore);
  const {state, dispatch} = useContext(TicketsStore);

  const isSuperAdmin = Utils.isSuperAdmin(usersState.myRoles);
  const isNetworkAdmin = Utils.isNetworkAdmin(usersState.myRoles);

  // Handlers
  const onError = error => {
    mainDispatch({
      type: "SET_MODAL_SETTINGS",
      payload: {
        show: true,
        title: "Error!",
        text: error.message,
        color: "default"
      }
    });
  };

  const updateTicket = ticket => {
    dispatch({type: "SET_EDIT_MODE", payload: true});
    dispatch({type: "SET_EDITABLE_TICKET", payload: ticket});
    dispatch({type: "SET_IS_OPENED", payload: true});
  };

  const deleteTicket = ({productId, ticketName}) => {
    mainDispatch({
      type: "SET_MODAL_SETTINGS",
      payload: {
        show: true,
        title: "Warning!",
        text: `Do you want to delete "${ticketName}" ticket?`,
        color: "warning",
        onCancel: () => mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal}),
        useCustomOnCancelFunction: true,
        onOk: async () => {
          const result = await API.SocialEvents.deleteSocialEventTicket({productId}, onError);

          const newList = state?.list?.filter(el => el?.productId !== productId);
          dispatch({type: "SET_LIST", payload: newList});

          if (result?.error?.code !== 200) onError(new Error(result?.error?.message));
          mainDispatch({type: "SET_MODAL_SETTINGS", payload: initialState.modal});
        },
        useCustomOnOkFunction: true,
        useCustomOkText: "Yes"
      }
    });
  };

  // Callbacks function
  const getColumns = () => ([
    {
      name: 'thumbnail',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Thumbnail</span>,
      cell: rowData =>
        rowData?.thumbnailId && rowData?.thumbnailId?.length > 0
          ? <StyledImage fit="contain" src={Utils.buildURLs(`/resource/thumbnail/256/${rowData?.thumbnailId}`,
            false, null, {networkId: Utils.getNetwork()})} size={5}/>
          : null,
      width: Utils.RemToPx(9)
    },
    {
      name: 'productId',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>ID</span>,
      cell: rowData => (
        <div className="d-flex flex-column align-items-center">
          <span>{rowData?.productId}</span>
        </div>
      )
    },
    {
      name: 'eventName',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Event Name</span>,
      cell: rowData => (
        <div className="d-flex flex-column align-items-center">
          <span>{rowData?.eventName}</span>
        </div>
      )
    },
    {
      name: 'ticketName',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Ticket Name</span>,
      cell: rowData => (
        <div className="d-flex flex-column align-items-center">
          <span>{rowData?.ticketName}</span>
        </div>
      )
    },
    {
      name: 'ticketType',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Ticket Type</span>,
      cell: rowData => (
        <div className="d-flex flex-column align-items-center">
          <span>{rowData?.ticketType}</span>
        </div>
      )
    },
    {
      name: 'ticketDates',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Dates</span>,
      cell: rowData => {
        return rowData?.eventStartDate && rowData?.eventEndDate ? (
          <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
            {`${rowData?.eventStartDate?.split("T")[0]} — ${rowData?.eventEndDate?.split("T")[0]}`}
          </span>
        ) : null;
      }
    },
    {
      name: 'ticketDescription',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Description</span>,
      cell: rowData => (
        <span style={{
          textAlign: "center",
          display: "block",
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap'
        }}>{rowData?.ticketDescription}</span>
      )
    },
    {
      name: 'ticketPrice',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Price</span>,
      cell: rowData => (
        <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {(rowData?.price || 0).toFixed(2)}
                </span>
      )
    },
    {
      name: 'ticketDiscount',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Discount</span>,
      cell: rowData => (
        <span style={{textOverflow: "unset", whiteSpace: "normal", textAlign: "center", display: "block"}}>
                    {rowData?.discount}
                </span>
      )
    },
    {
      name: 'actions',
      header: <span style={{fontSize: "1rem", display: "block", textAlign: "center"}}>Actions</span>,
      cell: rowData => (
        <div className="flex-wrap" style={{textAlign: 'center'}}>
          {!state.editMode && <StyledButton className="btn btn-sm bg-warning h-100 mr-2"
                                            title={<i className="fas fa-pencil-alt"/>} tooltip="Update"
                                            onClick={() => updateTicket(rowData)}/>}
          <StyledButton className="btn btn-sm bg-danger h-100 mr-2"
                        title={<i className="fas fa-trash-alt"/>} tooltip="Delete"
                        onClick={() => deleteTicket(rowData)}/>
        </div>
      ),
      width: Utils.RemToPx(10)
    }
  ].filter(el => el));
  const getList = useCallback(() => {
    return state.list;
  }, [state.list]);
  const getListItems = useCallback(async ({offset, size, text}, onError) => {
    const {tickets = [], total = 0} = (await (API.SocialEvents.getSocialEventTickets(onError))).data;

    dispatch({type: "SET_LIST", payload: tickets || []});
    dispatch({type: "SET_TOTAL_COUNT", payload: total || 0});

    return total ?? 0;
  }, []);

  const clearList = () => dispatch({type: "SET_LIST", payload: []});

  // Effects
  useEffect(() => {
    return () => {
      clearList();
    };
  }, []);

  return (
    <div className="content-wrapper">

      <ContentHeader title="Tickets" breadcrumbs={[
        {
          title: "Home",
          link: "/home"
        },
        {
          title: "Tickets",
          link: `/socialEvents/tickets`,
          active: true
        }
      ]}/>

      <section className="content">
        <div className="container-fluid">
          {(isSuperAdmin || isNetworkAdmin) && (
            <div className="card card-body d-flex flex-column">
              <h5 className="mb-4 d-inline-block p-1">Admin functions:</h5>
              <CreateNewTicket onError={onError}/>
            </div>)}

          <div id="table-container" className="mr-0">
            <CustomTable
              state={{canSelect: false, canHover: false, canSearch: false, pagination: false}}
              callbacks={{getList, getColumns, getListItems, clearLists: clearList, onError}}
              texts={{noItems: "No tickets..."}}/>
          </div>
        </div>
      </section>

    </div>
  );
}
