// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import {CMSStore} from "../../../stores/CMSStore";

// MUI
import {Checkbox} from "@material-ui/core";

// Components

export default function Filters (props) {
    const {settings = {}, onFiltersChanged = () => false} = props;
    const {doNotClearSelectedItems = false} = settings;

    const {dispatch} = useContext(CMSStore);

    // Base state
    const [showOnlyPublicRooms, setShowOnlyPublicRooms] = useState(false);

    // Custom body
    const [customBody, setCustomBody] = useState({
        onlyPublic: showOnlyPublicRooms
    });

    // Effects
     useEffect(() => {
        const settings = {
        	onlyPublic: showOnlyPublicRooms
        };

        if (!doNotClearSelectedItems) dispatch({type: "CLEAR_SELECTED_ITEMS"});
        setCustomBody(settings);
        onFiltersChanged(settings);
    }, [showOnlyPublicRooms]);

    return (
        <div className="d-flex align-items-center w-100">
            <div className={`w-100 d-flex flex-column`}>
                <div className="d-flex flex-column w-100">
					<h4 className="d-block w-100 mb-0">Search filters </h4>
					<hr className="w-100"/>
                    <div className={`input-group-prepend position-relative`}>
						<div className="w-100 d-flex align-items-center">
							<h6 className="mb-1 font-weight-normal">Show only public rooms:</h6>
							<div className="d-flex align-items-center">
								<Checkbox checked={showOnlyPublicRooms} color="primary"
										  onChange={e => setShowOnlyPublicRooms(e.target.checked)}/>
								<b>Yes</b>
							</div>
						</div>
                    </div>
                </div>
            </div>
        </div>
    );
}