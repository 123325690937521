// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect, useState} from "react";
import Utils from "../../../scripts/Utils";
import {API} from "../../../api/API";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";

// Components
import {StyledCalendarInput, StyledInput, StyledInputWithButton} from "../../../components/styled/StyledInput";
import {StyledButton} from "../../../components/styled/StyledButton";

export const UserSettings = props => {
    const {model, modelKey, onError} = props;

    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const [isErrorInRetype, setIsErrorInRetype] = useState(false);
    const update = (type, payload) => dispatch({type, key: modelKey, payload});
    const toggleShowPassword = mode => {
        if(mode === "SET_PASSWORD_TYPE") update(mode, state.password.type === "password" ? "text" : "password");
        else update(mode, state.passwordRetype.type === "password" ? "text" : "password");
    };
    useEffect(() => {
        setIsErrorInRetype(state.password.value.trim() !== state.passwordRetype.value.trim());
    }, [state.password.value, state.passwordRetype.value]);

    const [isInfoBtnDisabled, setIsInfoBtnDisabled] = useState(false);
    const [isCompanyInfoBtnDisabled, setIsCompanyInfoBtnDisabled] = useState(false);
    const [isPasswordBtnDisabled, setIsPasswordBtnDisabled] = useState(false);
    const [isUserBalanceBtnDisabled, setUserBalanceBtnDisabled] = useState(false);
    const [balanceUpdateAmountValue, setBalanceUpdateAmountValue] = useState(0);

    const updateUserInfo = async() => {
        setIsInfoBtnDisabled(true);

        const {firstName, lastName, birthday, hometown, education, description, linkUrl} = model;

        const result = await API.Users.updateProfile({
            userId: model?.id,
            firstName, lastName,
            birthday,
            hometown,
            education,
            description,
            linkUrl
        }, onError);
        if (result.error.code === 200) mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Success!",
                text: "Profile info was successfully updated!",
                color: "success"
            }
        });

        setIsInfoBtnDisabled(false);
    };

    const updateUserCompanyInfo = async () => {
        setIsCompanyInfoBtnDisabled(true);

        const {companyName, companyTitle} = model;

        const result = await API.Users.updateCompanyInfo({userId: model?.id, companyName, companyTitle}, onError);
        if (result.error.code === 200) mainDispatch({
            type: "SET_MODAL_SETTINGS",
            payload: {
                show: true,
                title: "Success!",
                text: "User company info was successfully updated!",
                color: "success"
            }
        });

        setIsCompanyInfoBtnDisabled(false);
    };

    const updateUserPassword = async () => {
        if (state.password.value === state.passwordRetype.value && !isErrorInRetype) {
            setIsPasswordBtnDisabled(true);

            const result = await API.Users.updatePassword(model?.id, state.password.value, onError);
            if (result.error.code === 200) mainDispatch({
                type: "SET_MODAL_SETTINGS",
                payload: {
                    show:  true,
                    title: "Success!",
                    text:  "Password was successfully updated!",
                    color: "success"
                }
            });

            setIsPasswordBtnDisabled(false);
        }
    };

    const updateUserBalance = async() => {
        if(balanceUpdateAmountValue !== 0) {
            setUserBalanceBtnDisabled(true);

            const result = await API.Users.updateBalance(model?.id, balanceUpdateAmountValue, onError);
            if(result.error.code === 200) {
                mainDispatch({
                    type:    "SET_MODAL_SETTINGS",
                    payload: {
                        show:  true,
                        title: "Success!",
                        text:  "User balance was successfully updated!",
                        color: "success"
                    }
                });
                dispatch({type: "SET_USER_BALANCE", payload: result?.data?.balance});
            }

            setUserBalanceBtnDisabled(false);
        }
    };

    return (
        <>
            <form className="form-horizontal" autoComplete="new-password">
                <h4 className="my-4">General</h4>
                <StyledInput title="First name" defaultValue={model?.firstName || ""}
                             onChange={e => update("SET_FIRSTNAME", e.target.value)}/>
                <StyledInput title="Last name" defaultValue={model?.lastName || ""}
                             onChange={e => update("SET_LASTNAME", e.target.value)}/>
                <StyledCalendarInput title="Birthday" value={model?.birthday || ""}
                             onChange={e => update("SET_BIRTHDAY", e.target.value)}/>
                <StyledInput title="Hometown" defaultValue={model?.hometown || ""}
                             onChange={e => update("SET_HOMETOWN", e.target.value)}/>
                <StyledInput title="Education" defaultValue={model?.education || ""}
                             onChange={e => update("SET_EDUCATION", e.target.value)}/>
                <StyledInput title="About me" isTextarea={true} defaultValue={model?.description || ""}
                             onChange={e => update("SET_DESCRIPTION", e.target.value)}/>
                <StyledInput title="My link" defaultValue={model?.linkUrl || ""}
                             onChange={e => update("SET_LINKURL", e.target.value)}/>
                <StyledInput title="My videochat link" defaultValue={model?.params?.videochatUrl || ""}
                             onChange={e => update("SET_VIDEOCHATURL", e.target.value)}/>


                <div className="mt-4 mb-5"><StyledButton onClick={updateUserInfo} disabled={isInfoBtnDisabled}
                                                         title="Update user info" type="success"/></div>

                <h4 className="my-4">Company info</h4>
                <StyledInput title="Company name" defaultValue={model.companyName || ""}
                             onChange={e => update("SET_COMPANY_NAME", e.target.value)}/>
                <StyledInput title="Company position" defaultValue={model.companyTitle || ""}
                             onChange={e => update("SET_COMPANY_TITLE", e.target.value)}/>


                <div className="mt-4 mb-5">
                    <StyledButton onClick={updateUserCompanyInfo} disabled={isCompanyInfoBtnDisabled}
                                  title="Update user company info" type="success"/>
                </div>
            </form>

            {
                (Utils.hasPermission(state.myRoles,"all") || model?.id === state?.model?.id) &&
                <>
                    <h4 className="mt-5 mb-4">Password settings</h4>
                    <StyledInputWithButton title="Password" type={state.password.type}
                                           value={state.password.value || ""}
                                           onChange={e => update("SET_PASSWORD_VALUE", e.target.value)}
                                           icon={<i className={`fas ${state.password.type === "password" ? "fa-eye" : "fa-eye-slash"}`}/>}
                                           onIconClick={() => toggleShowPassword("SET_PASSWORD_TYPE")}
                    />
                    <StyledInputWithButton title="Confirm password" type={state.passwordRetype.type}
                                           value={state.passwordRetype.value || ""}
                                           onChange={e => update("SET_PASSWORD_CONFIRM_VALUE", e.target.value)}
                                           icon={<i
                                               className={`fas ${state.passwordRetype.type === "password" ? "fa-eye" : "fa-eye-slash"}`}/>}
                                           onIconClick={() => toggleShowPassword("SET_PASSWORD_CONFIRM_TYPE")}
                                           isInvalid={isErrorInRetype}
                    />
                    <div className="mt-4 mb-5">
                        <StyledButton onClick={updateUserPassword} disabled={isPasswordBtnDisabled}
                                      title="Update password"
                                      type="success"/>
                    </div>
                </>
            }

            {
                Utils.hasPermission(state.myRoles, "balance.manage") &&
                <>
                    <h4 className="mt-5 mb-4">Balance</h4>
                    <h6 className="mt-4 mb-4">Total: {state.balance?.token}</h6>
                    <StyledInput title="Add amount" type="number" placeholder="Amount"
                                 onChange={e => setBalanceUpdateAmountValue(parseFloat(e.target.value))}/>

                    <div className="mt-4 mb-5">
                        <StyledButton onClick={updateUserBalance} disabled={isUserBalanceBtnDisabled}
                                      title="Update user balance" type="success"/>
                    </div>
                </>
            }
        </>
    );
};