// Copyright © 2015-2023 Roomful Co. All rights reserved

import React, {useCallback, useContext, useEffect, useState} from "react";
import $ from 'jquery';
import {useNavigate, useLocation} from "react-router-dom";
import {Link} from "react-router-dom";
import {Store} from "../../../stores/MainStore";
import {UsersStore} from "../../../stores/UsersStore";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";

// Styles
import '../../../plugins/OverlayScrollbars/OverlayScrollbars.min.css';

//IMGS
import Logo from "../../../imgs/RoomfulLogo.svg";

// Components
import 'overlayscrollbars/css/OverlayScrollbars.css';
import {OverlayScrollbarsComponent} from 'overlayscrollbars-react';
import {StyledButton} from "../../../components/styled/StyledButton";

// MUI
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    CircularProgress
} from "@material-ui/core";
import Image from 'material-ui-image';
import {NetworksStore} from "../../../stores/NetworksStore";

const SidebarLink = props => {
    const {nested = false, link, icon, title, badgeNumber, firstElLink, imageSrc, imageStyle, style} = props;

    const [opened, setOpened] = useState(false);
    const [isActiveChildPage, setIsActiveChildPage] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const isPageActive = pageName => location.pathname === pageName;
    const isChildPageActive = () => {
        let isActive = false;
        props?.children?.forEach(child => {
            if (isPageActive(child?.props?.link)) {
                isActive = true;
                return true;
            }
        });
        return isActive;
    };

    const onClickEvent = e => {
        const parent = e.currentTarget;
        const ul = e.currentTarget.children[1];

        if (opened) setOpened(false);
        else {
            parent.classList.add("menu-is-opening");
            $(ul).slideDown(300, () => {
                $(ul).first().addClass("menu-open");
                setOpened(true);
            });
        }
    };
    const selectFirst = e => {
        e.preventDefault();

        const target = e.target;

        if (target.id !== "open-menu-list") {
            navigate(firstElLink ?? props?.children[0]?.props?.link);
        } else {
            e.currentTarget = e.target.parentNode.parentNode.parentNode;
            onClickEvent(e);
        }
    };

    useEffect(() => {
        if (!link) setIsActiveChildPage(isChildPageActive(link));
    }, [link, location.pathname]);

    return (
        <li className={`nav-item ${nested && isActiveChildPage ? "has-treeview" : ""} ${nested && isActiveChildPage ? "menu-is-opening" : ""}`}
            onClick={nested ? () => {
            } : onClickEvent}>
            <Link to={nested ? "#" : link} style={{fontSize: "1rem"}}
                  className={`nav-link ${isPageActive(link) || (nested && isActiveChildPage) ? "active" : ""}`}
                  onClick={nested ? selectFirst : () => false}>
                {imageSrc ? <Image src={imageSrc} imageStyle={imageStyle} style={style} disableSpinner={true}/> :
                    <i className={`nav-icon ${icon}`} style={{fontSize: "1.1rem"}}/>}
                <p style={{textOverflow: 'ellipsis'}}>
                    {Utils.limitStringLength(title, 18)}
                    {nested ? <i className="right fas fa-angle-left" id="open-menu-list"/> : null}
                    {badgeNumber ? <span className="badge badge-info right">{badgeNumber}</span> : null}
                </p>
            </Link>
            <ul className={`nav nav-treeview ${nested && isActiveChildPage ? "menu-open" : ""}`}
                style={{display: (nested && isActiveChildPage) ? "block" : "none"}}>
                {nested ? React.Children.map(props.children, child => child) : null}
            </ul>
        </li>
    );
};

export const Sidebar = () => {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state} = useContext(UsersStore);
    const {state: networkState} = useContext(NetworksStore);

    const isSuperAdmin = Utils.isSuperAdmin(state.myRoles);
    const isNetworkAdmin = Utils.isNetworkAdmin(state.myRoles);
    const isRoomAdmin = Utils.isRoomAdmin(state.myRoles);
    const isPropAdmin = Utils.isPropAdmin(state.myRoles);

    const navigate = useNavigate();
    const location = useLocation();

    // Base state
    const [logoutModalShow, setLogoutModalShow] = useState(false);
    const [logoutBtnDisabled] = useState(false);
    const [network, setNetwork] = useState('');

    // Main functions
    const onLogoutBtnClicked = e => {
        e.preventDefault();
        setLogoutModalShow(true);
    };
    const getMostValuableRole = useCallback(() => {
        let bestRole = "";

        for (let role of state.myRoles) {
            if (role.id === "superAdmin" && bestRole !== "superAdmin") bestRole = "<span>Super</span><span>Admin</span>";
            if (role.id === "networkAdmin" && (bestRole === "roomAdmin" || bestRole === "propAdmin" || bestRole === "")) bestRole = "<span>Network</span><span>Admin</span>";
            if (role.id === "roomAdmin" && (bestRole === "propAdmin" || bestRole === "")) bestRole = "<span>Room</span><span>Admin</span>";
            if (role.id === "propAdmin" && bestRole === "" && networkState.currentNetworkInfo?.networkDefaults?.hasExpo === true) bestRole = "<span>Exhibitor</span>";
            if (role.id === "propAdmin" && bestRole === "") bestRole = "<span>Prop</span><span>Admin</span>";
        }

        const result = bestRole.replace(/([A-Z])/g, ' $1');
        return result.charAt(0).toUpperCase() + result.slice(1);
    }, [state.myRoles, networkState.currentNetworkInfo?.networkDefaults?.hasExpo]);
    const isRolesNotEmpty = () => state.myRoles && state.myRoles?.length !== 0;

    // Effects
    useEffect(() => {
        if (state.myRoles && state.myRoles?.length === 0) navigate("/profile");
    }, []);

    useEffect(() => {
        setNetwork(networkState.currentNetwork);
    }, [networkState]);

    return (
        <aside className="main-sidebar sidebar-dark-primary elevation-4">
            <Link to="/dashboard" className="brand-link d-flex align-items-center" style={{height: "4rem"}}
                  id="logo-text">
                <img src={Logo} alt="AdminLTE Logo" className="brand-image img-circle" style={{opacity: "0.8"}}/>
                <span className="brand-text font-weight-light"
                      style={{fontSize: "0.95em !important"}}>Roomful Admin</span>
            </Link>

            <OverlayScrollbarsComponent style={{maxHeight: '100%'}} options={{
                className: "os-theme-light",
                resize: 'none',
                paddingAbsolute: true,
                scrollbars: {
                    autoHide: 'move'
                }
            }}>

                <div className="sidebar">
                    <div className={`user-panel mt-3 mb-3 d-flex flex-column justify-content-center`}>
                        <div className="d-flex">
                            <div className="image mr-3">
                                {
                                    state && state.model && state.model.avatar ?
                                        <Image imageStyle={{
                                            position: "initial",
                                            width: "2.125rem", height: "2.125rem",
                                            objectFit: "cover"
                                        }}
                                               style={{
                                                   display: "flex",
                                                   justifyContent: "center",
                                                   background: "transparent",
                                                   padding: 0
                                               }}
                                               loading={<CircularProgress/>}
                                               className="img-circle elevation-2"
                                               src={Utils.buildURLs(`/resource/${state.model.avatar}`, false, null, {networkId: Utils.getNetwork()})}/>
                                        : <div style={{width: "2.125rem", height: "2.125rem"}}/>
                                }
                            </div>
                            <div className="info d-flex row justify-content-between mr-3" style={{width: "100%"}}>
                                <Link to={`/profile/`} className="d-block">
                                    {Utils.limitStringLength(
                                        state && state.model && (state.model.firstName || state.model.lastName)
                                            ? `${state.model.firstName} ${state.model.lastName}`
                                            : " User Profile"
                                        , 15)}
                                </Link>
                                <a href="" onClick={logoutBtnDisabled ? () => false : onLogoutBtnClicked}
                                   className="d-flex align-items-center">
                                    <i className="fas fa-sign-out-alt" style={{fontSize: "1.25em"}}/>
                                </a>
                            </div>
                        </div>

                        <div className="d-flex justify-content-center align-items-center" id="userRoleContainer">
                            <div className="mb-0" id="userRole">
                                <span>Role : </span>
                                <span
                                    dangerouslySetInnerHTML={{__html: state.myRoles && state.myRoles?.length > 0 ? getMostValuableRole() : "Guest"}}/>
                            </div>
                        </div>
                    </div>

                    <nav className="mt-2">
                        <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu"
                            data-accordion="false">

                            {/* DASHBOARD */}
                            {isRolesNotEmpty() &&
                            <SidebarLink title="Dashboard" link="/dashboard" icon="fas fa-chart-line"/>}

                            {/* ANALYTICS REPORT */}
                            {isRolesNotEmpty() && !isPropAdmin &&
                            <SidebarLink title="Analytics Report" link="/analytics" icon="fas fa-chart-pie"/>}

                            {/* MY PROFILE */}
                            <SidebarLink title="My Profile" link="/profile" icon="fas fa-user-edit"/>

                            {/* CONTACTS */}
                            {isRolesNotEmpty() &&
                            <SidebarLink title="Contacts" link="/contacts" icon="fas fa-id-card"/>}

                            {/* CHATS */}
                            {isRolesNotEmpty() && <SidebarLink title="Chats" link="/chats" icon="fas fa-comments"/>}

                            {isRolesNotEmpty() && <li className="nav-header">ADMIN VIEW</li>}

                            {/* CONTENT MANAGEMENT SYSTEM */}
                            <SidebarLink title="My content" link="/cms" icon="fas fa-photo-video"/>

                            {/* ROLES MANAGER */}
                            {isRolesNotEmpty() && isSuperAdmin &&
                            <SidebarLink title="Roles manager" link="/roles" icon="fas fa-user-tag"/>}

                            {/* GAMIFICATION RANKING */}
                            {isRolesNotEmpty() && (isSuperAdmin || isNetworkAdmin) && networkState.currentNetworkInfo?.networkDefaults?.hasGamingBalance &&
                            <SidebarLink title="Gamification ranking" link="/gamificationRanking"
                                         icon="fas fa-gamepad"/>}

                            {/* SOCIAL EVENTS */}
                            {isRolesNotEmpty() && (isSuperAdmin || isNetworkAdmin) &&
                            <SidebarLink title="Events" link="/socialEvents" icon="fas fa-calendar-alt"/>}

                            {/* SOCIAL EVENT TICKETS */}
                            {isRolesNotEmpty() && (isSuperAdmin || isNetworkAdmin) &&
                            <SidebarLink title="Tickets" link="/socialEvents/tickets" icon="fas fa-ticket-alt"/>}

                            {/* KARAOKE */}
                            {isRolesNotEmpty() && isSuperAdmin &&
                            <SidebarLink title="Karaoke" link="/karaoke" icon="fas fa-music"/>}

                            {/* ROOMS: LIST */}
                            {isRolesNotEmpty() && !isPropAdmin &&
                            <SidebarLink title="Rooms" icon="fas fa-person-booth" nested={true}
                                         firstElLink={(isSuperAdmin || isNetworkAdmin) ? "/rooms/list" : "/rooms/my"}>
                                {(isSuperAdmin || isNetworkAdmin) ?
                                    <SidebarLink title="All rooms" link="/rooms/list" icon="fas fa-list"/> : null}
                                <SidebarLink title="My rooms" link="/rooms/my" icon="fas fa-users-cog"/>
                                {(isSuperAdmin || isNetworkAdmin) &&
                                <SidebarLink title="Template rooms" link="/rooms/templates" icon="fas fa-sticky-note"/>}
                            </SidebarLink>}

                            {/* PROPS: LIST */}
                            {isRolesNotEmpty() &&
                            <SidebarLink title="Props" icon="fas fa-puzzle-piece" nested={true}
                                         firstElLink={(isSuperAdmin || isNetworkAdmin || isRoomAdmin) ? "/props/list" : "/props/my"}>
                                {(isSuperAdmin || isNetworkAdmin || isRoomAdmin) &&
                                <SidebarLink title="All props" link="/props/list" icon="fas fa-list"/>}
                                <SidebarLink title="My props" link="/props/my" icon="fas fa-users-cog"/>
                            </SidebarLink>}

                            {/* USERS: LIST */}
                            {isRolesNotEmpty() && (isSuperAdmin || isNetworkAdmin) &&
                            <SidebarLink title="Users" icon="fas fa-users" nested={true}>
                                <SidebarLink title="List Users" link="/users/list" icon="fas fa-list"/>
                                <SidebarLink title="Add participants" link="/users/addParticipants"
                                             icon="fas fa-user-plus"/>
                                {!Utils.isDefaultNetwork(networkState.currentNetworkInfo?.network?.id) &&
                                <SidebarLink title="Subscribe To Network" link="/users/subscribe"
                                             icon="fas fa-user-plus"/>
                                }
                            </SidebarLink>}

                            {/* NETWORKS: LIST, CREATE, ADD PARTICIPANTS */}
                            {isRolesNotEmpty() &&
                            <SidebarLink title="Networks" icon="fas fa-network-wired" nested={true}>
                                {network?.fullName ? (
                                    <SidebarLink title={"" + network?.fullName} link={"/network/" + network?.id}
                                                 icon="fas fa-list" basicLink
                                                 imageSrc={Utils.buildURLs(`/resource/thumbnail/256/${network?.thumbnail}`)}
                                                 imageStyle={{
                                                     position: "initial",
                                                     width: "1.27rem",
                                                     margin: 2,
                                                     marginTop: -1,
                                                     height: "19px",
                                                     objectFit: "cover"
                                                 }} style={{
                                        float: "left",
                                        background: "#c2c7d0",
                                        padding: 0,
                                        marginRight: "3px",
                                        border: "1px solid hsl(0, 0%, 80%)",
                                        borderRight: "1px solid hsl(0, 0%, 80%)",
                                        borderTopRightRadius: "4px",
                                        borderTopLeftRadius: "4px",
                                        borderBottomRightRadius: "4px",
                                        borderBottomLeftRadius: "4px",
                                    }}/>
                                ) : null}
                                <SidebarLink title="List" link="/networks/list" icon="fas fa-list" basicLink/>

                                {isSuperAdmin &&
                                <SidebarLink title="Create" link="/networks/create" icon="fas fa-plus-circle"/>}
                            </SidebarLink>}
                        </ul>
                    </nav>
                </div>

            </OverlayScrollbarsComponent>

            <Dialog open={logoutModalShow} onClose={() => setLogoutModalShow(false)}>
                <DialogTitle>
                    Are you sure to logout from roomful admin panel?
                </DialogTitle>
                <DialogContent dividers>
                    <DialogContentText>
                        By pressing "Agree" you will logout immediately!
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <StyledButton onClick={() => setLogoutModalShow(false)} title="Disagree" type="danger"
                                  variant="contained"/>
                    <StyledButton onClick={logoutBtnDisabled ? () => false : API.Auth.logout} autoFocus
                                  disabled={logoutBtnDisabled} title="Agree"
                                  type="success" variant="contained"/>
                </DialogActions>
            </Dialog>
        </aside>
    );
}
