// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useState} from "react";
import {API} from "../../../api/API";
import Utils from "../../../scripts/Utils";

// Components
import {StyledButton} from "../../../components/styled/StyledButton";

export const BoothVisitsReport = props => {
    const {onError, params : {roomID, propID, startDate, endDate}} = props;

    const [disabled, setDisabled] = useState(false);

    const onClick = async () => {
        setDisabled(true);

        await API.Analytics.generateReport(`/files/analytics/boothResourceVisits`, {
            roomId: roomID ?? "",
            propId: propID ?? "",
            startDate,
            endDate
        }, Utils.getNetwork(), onError);

        setDisabled(false);
    };

    return (
        <>
            <h4 className="mt-4 mb-2">Booth resource visits</h4>
            <div>
                <div className="mb-1">
                    <StyledButton title="Generate analytics: booth resource visits" type="success"
                                  disabled={disabled} onClick={onClick}/>
                                  {/*<StyledButton title={`Generate analytics ONLY for room (${roomID})`} type="success" disabled={disabled} onClick={onClick}/>*/}
                        {/*<div className="ml-3">*/}
                        {/*    <StyledButton title={`Generate analytics for prop (${roomID}/${propID})`} type="success" disabled={disabled} onClick={onClick}/>*/}
                        {/*</div>*/}
                    <p className="mt-3 text-muted">
                        Report represents company booths, their content and time users spend looking at them.
                    </p>
                </div>

                <hr/>
            </div>
        </>
    );
};