// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {createContext, useReducer} from 'react';

const initialState = {
    connection: null,

    list:                      [],
    reRenderInterlocutorsList: false,
    reRenderList:              false,

    currentTextchat:     {},
    currentInterlocutor: {},

    messages:       [],
    messagesOffset: 0,

    showParticipants: false,
    participants:     {},

    filters: {
        activeElement:     4,
        chosenRoom:        {id: "", metadata: {name: "Loading..."}},
        isOKButtonPressed: false
    },

    reRenderNotifications: true
};

export const ChatsStore = createContext(initialState);

function reducer(state, action) {
    switch (action.type) {
        case 'SET_CONNECTION':
            return {...state, connection: action.payload};
        case 'SET_MESSAGES':
            return {...state, messages: action.payload};
        case 'ADD_SINGLE_MESSAGE': {
            const messages = state.messages;
            messages.unshift(action.payload);
            return {...state, messages};
        }
        case 'CLEAR_MESSAGES':
            return {...state, messages: []};
        case 'SET_MESSAGES_OFFSET':
            return {...state, messagesOffset: action.payload};

        // Participants
        case 'SET_PARTICIPANTS':
            return {...state, participants: action.payload};
        case 'SET_SHOW_PARTICIPANTS':
            return {...state, showParticipants: action.payload};

        // Interlocutors lists
        case 'SET_LIST':
            return {...state, list: action.payload};
        case 'CLEAR_LIST':
            return {...state, list: []};
        case 'RERENDER_LIST':
            return {...state, reRenderList: action.payload};
        case 'SET_NEED_RE_RENDER_INTERLOCUTORS_LIST':
            return {...state, reRenderInterlocutorsList: action.payload};
        case 'SET_TEXTCHAT_IS_IMPORTANT': {
            return {
                ...state,
                list: state.list.map(el => {
                    if(el.textchat?.id === action.id) el.textchat.isImportant = action.payload;
                    return el;
                })
            };
        }
        case 'DELETE_TEXTCHAT': {
            return {...state, list: state.list.filter(el => el.textchat?.id !== action.payload)};
        }

        // FILTERS for interlocutors lists
        case 'SET_FILTERS_ACTIVE_ELEMENT':
            return {...state, filters: {...state.filters, activeElement: action.payload}};
        case 'SET_FILTERS_CHOSEN_ROOM':
            return {...state, filters: {...state.filters, chosenRoom: action.payload}};
        case 'SET_FILTERS_NEED_RENDER':
            return {...state, filters: {...state.filters, isOKButtonPressed: action.payload}};
        case 'CLEAR_FILTERS':
            return {...state, filters: {activeElement: 0, chosenRoom: {}, isOKButtonPressed: false}};

        // Main function
        case 'SET_CURRENT_INTERLOCUTOR':
            return {...state, currentInterlocutor: action.payload};
        case 'SET_CURRENT_TEXTCHAT':
            return {...state, currentTextchat: action.payload};
        case 'CLEAR_CURRENT_TEXTCHAT':
            return {...state, currentTextchat: {}, currentInterlocutor: {}, participants: {}, messages: []};

        case 'SET_CURRENT_INTERLOCUTOR_LAST_READ_MESSAGES_TIME':
            return {...state, currentInterlocutor: {...state.currentInterlocutor, lastReadMessageTime: action.payload}};
        case 'SET_RERENDER_NOTIFICATIONS':
            return {...state, reRenderNotifications: action.payload};
        case 'SET_INTERLOCUTOR_LAST_MESSAGE_TIME': {
            return {
                ...state,
                list: state.list.map(el => {
                    if(el?.textchat?.id === action.id) el.textchat.lastMessageTime = action.payload;
                    return el;
                })
            };
        }
        case 'ADD_UNREAD_MESSAGE_COUNT': {
            return {
                ...state,
                list: state.list.map(el => {
                    if(el.textchat?.id === action.payload) el.textchat.unreadMessageCount++;
                    return el;
                })
            };
        }
        case 'CLEAR_UNREAD_MESSAGE_COUNT': {
            return {
                ...state,
                list: state.list.map(el => {
                    if(el.textchat?.id === action.payload) el.textchat.unreadMessageCount = 0;
                    return el;
                })
            };
        }

        default:
            return state;
    }
}

export const ChatsProvider = props => {
    const [state, dispatch] = useReducer(reducer, initialState);
    const value = {state, dispatch};

    return <ChatsStore.Provider value={value}>{props.children}</ChatsStore.Provider>;
};