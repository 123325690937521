// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useCallback, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import Utils from "../../scripts/Utils";

// Material UI
import 'react-virtualized/styles.css';

// Components
import Pagination from "@material-ui/lab/Pagination";
import {StyledInputWithButton} from "../styled/StyledInput";
import Select from "react-select";
import MuiTable from "./MuiTable";
import CircularProgress from "@material-ui/core/CircularProgress";

let prevBody = {};

export default function CustomTable(props) {
    const {
        state: {
            canSelect = true, canHover = true, canSearch = true, pagination = true,
            reRender = false, setReRender = () => false
        },
        callbacks : {
            getList, getListItems, getColumns, selectSingleItem = () => false,
            getSelectedItems = () => [], setSelectedItems = () => false, clearLists
        },
        texts = {},
        onError
    } = props;
    const {noItems = null} = texts;

    const [searchParams, setSearchParams] = useSearchParams();

    // Base table state
    const [offset, setOffset] = useState(parseInt(searchParams.get('offset')) || 0);
    const [size, setSize] = useState(parseInt(searchParams.get('size')) || 25);
    const [total, setTotal] = useState(size);
    const [searchInputValue, setSearchInputValue] = useState(searchParams.get('search') || '');
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const [needRender, setNeedRender] = useState(true);
    const [tableWidth, setTableWidth] = useState(0);
    const [isError, setIsError] = useState(false);

    // Height calculation
    const getTableSize = () => {
        const tableContainer = document.querySelector("#table-container");

        if (tableContainer) {
            const tableContainerWidth = tableContainer.getBoundingClientRect().width;
            setTableWidth(tableContainerWidth - Utils.RemToPx(1));
        }
    };
    useEffect(() => {
        window.addEventListener("resize", getTableSize);
        getTableSize();

        return () => {
            window.removeEventListener("resize", getTableSize);
            if (canSelect) setSelectedItems([]);
            prevBody = {};
        };
    }, []);

    // Functions
    const search = () => {
        searchInputValue ? searchParams.set('search', searchInputValue) : searchParams.delete('search');
        searchParams.delete('offset')
        setSearchParams(searchParams);

        setOffset(0);
        setIsPageLoaded(false);
        setNeedRender(true);
    };
    const calculatePage = () => {
        return offset === 0 ? 1 : (offset / size) + 1;
    };
    const requestToRenderList = useCallback(async body => {
        const result = await getListItems(body, onError);

        if (typeof result === "object") {
            setIsError(true);
        }
        else {
            setTotal(Math.ceil(result / size));
            setIsError(false);
        }

        setNeedRender(false);
        setIsPageLoaded(true);
    }, [isPageLoaded, size]);
    const buildBody = useCallback(() => ({
        offset,
        size,
        text: searchInputValue,
    }), [offset, searchInputValue, size]);
    const callRender = () => {
        setIsPageLoaded(false);

        const body = buildBody();
        if (!Utils.isEquivalent(body, prevBody) || reRender) {
            prevBody = body;
            requestToRenderList(body);
            if (reRender) setReRender(false);
        }
    };

    // Handlers
    const onPageChanged = (event, clickedPage) => {
        if (typeof window !== "undefined" && clickedPage !== calculatePage()) {
            const newOffset = clickedPage === 1 ? 0 : (clickedPage - 1) * size;
            setOffset(newOffset);
            newOffset === 0 ? searchParams.delete('offset') : searchParams.set('offset', newOffset);
            setSearchParams(searchParams);

            if (canSelect) setSelectedItems([]);
            setNeedRender(true);

            if (canSearch) document.querySelector('#table-search-input').scrollIntoView({behavior: 'smooth'});
        }
    };
    const onSearchInputChange = e => setSearchInputValue(e.target.value);
    const onSearchInputKeyDown = e => e.key === "Enter" ? search() : false;
    const onRowsPerPageChanged = async ({value: size}) => {
        await clearLists();
        size === 25 ? searchParams.delete('size') : searchParams.set('size', size);
        searchParams.delete('offset')
        setSearchParams(searchParams);
        setSize(size);
        setOffset(0);
        setNeedRender(true);
    };
    const onCellClick = canSelect ? (e, {rowData}) => {
        if(!rowData?.isDisabled) selectSingleItem(rowData.id)
    } : null

    // Effects
    useEffect(callRender, []);

    useEffect(() => {
        if (needRender) callRender();
    }, [needRender]);

    useEffect(() => {
        if (needRender !== reRender && reRender) {
            setOffset(0);
            setNeedRender(reRender);
        }
    }, [reRender]);

    const data = getList();

    return (
        <>
            {canSearch && <StyledInputWithButton id="table-search-input" title="" icon={<i className="fas fa-search"/>}
                                    placeholder="Search in table..."
                                    value={searchInputValue} type="text" onChange={onSearchInputChange}
                                    onIconClick={search} onKeyDown={onSearchInputKeyDown}/>}

            {isPageLoaded ? (
                (data?.length > 0 && !isError) ? (
                    <>
                        <MuiTable
                            data={data}
                            width={tableWidth}
                            rowHeight={Utils.RemToPx(6)}
                            columns={getColumns()}
                            isCellSelected={(column, rowData) => canSelect ? (getSelectedItems()).some(id => rowData && rowData.id === id) : false}
                            isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => canHover && rowData.id && rowData.id === hoveredRowData.id}
                            onCellClick={onCellClick}
                            includeHeaders={true}
                        />

                        {pagination && <div className="mt-3" id="pagination">
                            <div className="d-flex justify-content-end">
                                <div style={{width: `${Utils.RemToPx(7)}px`}}>
                                    <Select menuPlacement="top"
                                            onChange={selected => onRowsPerPageChanged(selected)}
                                            value={typeof size === "number" ? {label: size, value: size} : size}
                                            options={[25, 50, 100, 200].map(el => ({label: el, value: el}))}
                                    />
                                </div>
                                <Pagination count={total} page={calculatePage()} onChange={onPageChanged} color="primary"
                                            size="medium"
                                            variant="outlined"/>
                            </div>
                        </div>}
                    </>
                ) : (
                    <div className="card" style={{width: `${tableWidth}px`}}>
                        <div className="card-body">
                            <h6 className="mb-0">
                                {noItems ?? "No items found by query, please use filters to change query parameters."}
                            </h6>
                        </div>
                    </div>
                )
            ) : (
                <div className="card" style={{width: `${tableWidth}px`}}>
                    <div className="card-body">
                        <div className="d-flex justify-content-center align-items-center w-100 py-3">
                            <h6 className="mr-3 mb-0">Search is initiated. Please wait a second...</h6>
                            <CircularProgress/>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}
