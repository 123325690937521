// Copyright © 2015-2022 Roomful Co. All rights reserved

import React, {useContext, useEffect} from "react";
import {API} from "../../api/API";
import {Store} from "../../stores/MainStore";
import {UsersStore} from "../../stores/UsersStore";

// Components
import ContentHeader from "../Template/components/ContentHeader";
import {UserCard} from "../User/components/UserCard";
import Pagination from "@material-ui/lab/Pagination";
import {StyledInputWithButton} from "../../components/styled/StyledInput";
import {useStateSafe} from "../../scripts/SaveUseState";

export default function Contacts () {
    const {dispatch: mainDispatch} = useContext(Store);
    const {state, dispatch} = useContext(UsersStore);

    const onSearchInputChange = e => setSearchInputValue(e.target.value);
    const onSearchInputKeyDown = e => e.key === "Enter" ? search() : false;

    // Base state
    const [size] = useStateSafe(25);
    const [offset, setOffset] = useStateSafe(0);
    const [page, setPage] = useStateSafe(offset === 0 ? 1 : (offset / size) + 1);
    const [searchInputValue, setSearchInputValue] = useStateSafe("");
    const [total, setTotal] = useStateSafe(size);
    const [needRender, setNeedRender] = useStateSafe(true);
    const [isPageLoaded, setIsPageLoaded] = useStateSafe(false);

    const onPageChanged = (event, clickedPage) => {
        if(typeof window !== "undefined" && clickedPage !== page) {
            setOffset(clickedPage === 1 ? 0 : (clickedPage - 1) * size);
            setPage((offset === 0 ? 1 : (offset / size) + 1) + 1);
            setNeedRender(true);

            document.querySelector('#root .content-header h1').scrollIntoView({behavior: 'smooth'});
        }
    };

    // Main functions
    const search = () => {
        setOffset(0);
        setNeedRender(true);
    };
    const setList = (items) => {
        dispatch({type: "SET_CONTACTS", payload: items});
    };
    const requestToRenderList = async(body, list, setList) => {
        const onError = error => mainDispatch({
            type:    "SET_MODAL_SETTINGS",
            payload: {
                show:  true,
                title: "Error!",
                text:  error.message,
                color: "default"
            }
        });

        const {contacts, total} = (
            await (
                typeof body.text === "string" && body.text.length > 0
                    ? API.Users.searchContacts(body, onError)
                    : API.Users.getContacts(body, onError)
            )
        ).data;
        const array = [...contacts];

        setList(array);
        setTotal(Math.ceil(total / size));
        setPage(offset === 0 ? 1 : (offset / size) + 1);
        setNeedRender(false);
        if (!isPageLoaded) setIsPageLoaded(true);
    };

    useEffect(() => {
        if (needRender) {
            const body = {offset, size, text: searchInputValue.trim()};
            const list = state.contacts;
            requestToRenderList(body, list, setList);
        }
    }, [needRender]);

    return (
        <div className="content-wrapper position-relative">
            <ContentHeader title={"Your contacts"} breadcrumbs={[
                {
                    title: "Home",
                    link: "/home"
                },
                {
                    title: "Your contacts",
                    link: "/contacts",
                    active: true
                }
            ]}/>

            <section className="content">
                <div className="container-fluid">
                    {state.contacts.length > 0
                        ? (
                            <>
                                <StyledInputWithButton id="table-search-input" title=""
                                                       icon={<i className="fas fa-search"/>}
                                                       placeholder="Search in contacts..."
                                                       value={searchInputValue} type="text"
                                                       onChange={onSearchInputChange}
                                                       onIconClick={search} onKeyDown={onSearchInputKeyDown}/>
                                <div className="row align-items-stretch">
                                    {
                                        Object.values(state.contacts.map((contact, index) =>
                                            <UserCard showChatBtn={true} showViewProfileBtn={true} key={index}
                                                      model={{
                                                          id:           contact.id,
                                                          companyName:  contact.companyName,
                                                          companyTitle: contact.companyTitle,
                                                          firstName:    contact.firstName,
                                                          lastName:     contact.lastName,
                                                          description:  contact.description,
                                                          email:        contact?.sharedEmail,
                                                          phoneNumber:  contact?.sharedPhone,
                                                          avatar:       contact.avatar
                                                      }}
                                            />
                                        ))
                                    }
                                </div>

                                <div className="my-4">
                                    <div className="gridjs-footer"
                                         style={{borderRadius: "5px", boxShadow: "none"}}>
                                        <div className="gridjs-pagination">
                                            <Pagination count={total} page={page}
                                                        onChange={onPageChanged} color="primary"
                                                        size="large"
                                                        variant="outlined"/>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <h3 className="w-100 text-center position-absolute"
                                style={{top: "50%", left: "50%", transform: "translate(-50%)"}}>
                                You have no contacts...
                            </h3>
                        )
                    }
                </div>
            </section>
        </div>
    );
}